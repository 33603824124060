export default {
	FREE: '免费',
	CONTINUE: '继续',
	OK: '好的',
	SUBSCRIBE: '订阅',
	EMPTY_BOOKMARK_TITLE: '你没有保存的书签',
	EMPTY_PUBLICATION_TITLE: '该出版物是空的',
	EMPTY_PURCHASES_TITLE: '你没有购买',
	EMPTY_ARTICLES_TITLE: '这个问题是空的',
	EMPTY_BOOKMARK_CONTENT: `书签功能可以让您保存并快速访问您的收藏夹。
    最喜欢的文章。只需点击任何一篇文章上的书签图标
    文章保存，然后通过书签菜单查看。`,
	EMPTY_PUBLICATION_CONTENT: `本刊没有问题，请尽快再次查询。`,
	EMPTY_PURCHASES_CONTENT: `你还没有购买任何内容。 一旦支付了一笔或多笔款项，您就可以查看 
    这里的交易细节。`,
	EMPTY_ARTICLES_CONTENT: '本期没有文章，请稍后再查。',
	SIGN_UP_WITH_EMAIL: '用电子邮件注册',
	SIGN_UP_WITH_EMAIL_BACK_TITLE: '所有注册选项',
	SIGN_UP_WITH_EMAIL_CHECK_INBOX: '检查您的收件箱',
	SIGN_IN_WITH_EMAIL: '用电子邮件登录',
	SIGN_IN_WITH_EMAIL_BACK_TITLE: '所有登录选项',
	SIGN_IN_WITH_EMAIL_CHECK_INBOX: '检查您的收件箱',
	SIGN_UP_HEADER: '加入我们',
	SIGN_UP_SUBHEADER: `创建一个账户，以便在您的 收件箱，个性化您的主页，并关注作者和。
    你喜欢的话题。`,
	SIGN_IN_HEADER: '欢迎回来',
	SIGN_IN_SUBHEADER: `登录后可获得个性化的故事推荐。
    跟随您喜欢的作者和话题，并与您互动。
    故事。`,
	SIGN_UP_WITH_GOOGLE: '用谷歌注册',
	SIGN_UP_WITH_APPLE: '用苹果注册',
	SIGN_IN_WITH_GOOGLE: '用谷歌登录',
	SIGN_IN_WITH_APPLE: '用苹果公司登录',
	AUTHENTICATE_SIGN_IN: '登录',
	AUTHENTICATE_YOUR_EMAIL: '您的电子邮件',
	AUTHENTICATE_OFFLINE_ERROR: '您需要在线才能继续',
	AUTHENTICATE_SIGN_UP_MESSAGE: '已经有一个帐户？',
	AUTHENTICATE_SIGN_UP: '注册',
	AUTHENTICATE_SIGN_IN_MESSAGE: '没有账户？',
	AUTHENTICATE_SIGN_IN_CREATE_ONE: '创建一个',
	AUTHENTICATE_EMAIL_NOT_EMPTY_ERROR: `电子邮件不能是空的`,
	AUTHENTICATE_EMAIL_INVALID: `无效的电子邮件地址`,
	AUTHENTICATE_EMAIL_DIGIT_MESSAGE:
		'我们刚刚通过电子邮件发送了一个六位数的代码到',
	AUTHENTICATE_EMAIL_DIGIT_MESSAGE_SUBMIT:
		'我们刚刚通过电子邮件发送了一个六位数的代码到',
	SETTINGS_SIGN_IN: '登录',
	SETTINGS_SIGN_OUT: '退出',
	SETTINGS_SUBSCRIPTION: '订阅',
	SETTINGS_SUBSCRIPTION_INACTIVE_ERROR: '您没有有效的订阅',
	SETTINGS_SUBSCRIPTION_EXPIRE_AT: '您的订阅结束于',
	SETTINGS_STORAGE: '存储',
	SETTINGS_LANGUAGE: '语种',
	SETTINGS_FREE_SPACE: '自由空间',
	SETTINGS_CLEAR_CACHE: '清除缓存',
	SETTINGS_ASSETS_REMOVED_INTRO_MESSAGE: '删除的资产',
	SETTINGS_CLEAR_CACHE_IMAGES_MESSAGE: '图片',
	SETTINGS_CLEAR_CACHE_ISSUES_MESSAGE: '问题',
	SETTINGS_CLEAR_CACHE_ARTICLES_MESSAGE: '文章',
	SETTINGS_CLEAR_CACHE_FAVORITE_ARTICLES_IMAGES_MESSAGE: '收藏文章',
	SETTINGS_FREE_SPACE_IMAGES: '图像',
	PURCHASE_SUBSCRIPTION_HEADER: '订阅',
	PURCHASE_SUBSCRIPTION_SUBHEADER: '关于订阅信息的占位符',
	PURCHASE_OFFLINE_ERROR: '您无法在线下购买，请稍后再试。',
	PURCHASES_OFFLINE_ERROR: '您需要在线查看购买的商品',
	PURCHASES_PRICE: '价格',
	PURCHASES_TYPE_ISSUE: '问题',
	PURCHASES_TYPE_SUBSCRIPTION: '订阅',
};
