import React from 'react';
import PropTypes from 'prop-types';
import styles from './Icon.module.css';

const validNames = [
	'apple-icon',
	'arrow-up',
	'arrow-down',
	'arrow-right',
	'arrow-left',
	'bookmark',
	'bookmark-selected',
	'bookmarks',
	'done',
	'email',
	'font',
	'font-selected',
	'font-increase',
	'font-decrease',
	'toc',
	'horizontal-split',
	'download',
	'downloaded',
	'search',
	'search-alt',
	'cloud-off',
	'home',
	'home-selected',
	'player',
	'article',
	'share',
	'play-arrow',
	'pause',
	'forward-5',
	'replay-5',
	'cancel',
	'chevron-left',
	'chevron-right',
	'close',
	'cloud',
	'cloud-queue',
	'add-link',
	'attach-email',
	'pending',
	'cloud-done',
	'single-page-spread',
	'double-page-spread',
	'responsive-mode',
	'replica-mode',
	'external-link',
	'printer',
	'zoom-in'
];

export const Icon = ({ name, color, opacity }) => {
	if (!validNames.includes(name)) {
		return null;
	}

	const className = [styles['icon'], styles[name]];

	const style = {
		background: color,
		maskPosition: 'center',
		opacity,
	};

	return <div style={style} className={className.join(' ')} />;
};

Icon.propTypes = {
	name: PropTypes.oneOf(validNames),
	color: PropTypes.string,
	opacity: PropTypes.number,
};

Icon.defaultProps = {
	color: '#000',
	name: '',
	opacity: 1,
};
