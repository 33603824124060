import en from './locales/en';
import es from './locales/es';
import fr from './locales/fr';
import de from './locales/de';
import it from './locales/it';
import ja from './locales/ja';
import nl from './locales/nl';
import pt from './locales/pt';
import pl from './locales/pl';
import ru from './locales/ru';
import zh from './locales/zh';

export function getLocale(lang) {
	switch (lang) {
		case 'es':
			return { ...en, ...es };
		case 'fr':
			return { ...en, ...fr };
		case 'de':
			return { ...en, ...de };
		case 'it':
			return { ...en, ...it };
		case 'ja':
			return { ...en, ...ja };
		case 'nl':
			return { ...en, ...nl };
		case 'pl':
			return { ...en, ...pl };
		case 'pt-pt':
		case 'pt-br':
			return { ...en, ...pt };
		case 'ru':
			return { ...en, ...ru };
		case 'zh':
			return { ...en, ...zh };
		case 'en':
		default:
			return { ...en };
	}
}
