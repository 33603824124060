export default {
	FREE: 'Grátis',
	CONTINUE: 'Continuar',
	OK: 'Ok',
	SUBSCRIBE: 'Subscrever',
	EMPTY_BOOKMARK_TITLE: 'Não tem marcadores guardados',
	EMPTY_PUBLICATION_TITLE: 'Esta publicação está vazia',
	EMPTY_PURCHASES_TITLE: 'Não tem compras',
	EMPTY_ARTICLES_TITLE: 'Esta edição está vazia',
	EMPTY_BOOKMARK_CONTENT: `A função de marcador de páginas permite-lhe guardar e aceder rapidamente ao seu
    artigos favoritos. Basta tocar no ícone de marcador de páginas em qualquer
    artigo para o guardar, depois ver através do menu dos favoritos.`,
	EMPTY_PUBLICATION_CONTENT: `Não há edições nesta publicação, por favor verifique novamente em breve.`,
	EMPTY_PURCHASES_CONTENT: `Não adquiriu qualquer conteúdo.  Uma vez efectuados um ou mais pagamentos, poderá visualizar 
    os detalhes da transacção aqui.`,
	EMPTY_ARTICLES_CONTENT:
		'Não há artigos nesta edição, por favor verifique novamente em breve.',
	SIGN_UP_WITH_EMAIL: 'Inscrever-se com e-mail',
	SIGN_UP_WITH_EMAIL_BACK_TITLE: 'Todas as opções de inscrição',
	SIGN_UP_WITH_EMAIL_CHECK_INBOX: 'Verifique a sua caixa de entrada',
	SIGN_IN_WITH_EMAIL: 'Inicie a sessão com e-mail',
	SIGN_IN_WITH_EMAIL_BACK_TITLE: 'Todos os sinais em opções',
	SIGN_IN_WITH_EMAIL_CHECK_INBOX: 'Verifique a sua caixa de entrada',
	SIGN_UP_HEADER: 'Junte-se a nós',
	SIGN_UP_SUBHEADER: `Crie uma conta para receber grandes histórias no seu
    caixa de entrada, personalize a sua página inicial, e siga os autores e
    temas que lhe são caros.`,
	SIGN_IN_HEADER: 'Bem-vindo de volta',
	SIGN_IN_SUBHEADER: `Faça login para obter recomendações de histórias personalizadas,
    seguir os autores e temas que lhe são caros, e interagir com
    histórias.`,
	SIGN_UP_WITH_GOOGLE: 'Registe-se no Google',
	SIGN_UP_WITH_APPLE: 'Inscreva-se com a Apple',
	SIGN_IN_WITH_GOOGLE: 'Iniciar sessão com o Google',
	SIGN_IN_WITH_APPLE: 'Iniciar sessão com a Apple',
	AUTHENTICATE_SIGN_IN: 'Iniciar Sessão',
	AUTHENTICATE_YOUR_EMAIL: 'O seu e-mail',
	AUTHENTICATE_OFFLINE_ERROR: 'Tem de estar Online para continuar',
	AUTHENTICATE_SIGN_UP_MESSAGE: 'Já tem uma conta?',
	AUTHENTICATE_SIGN_UP: 'Inscrição',
	AUTHENTICATE_SIGN_IN_MESSAGE: 'Sem conta?',
	AUTHENTICATE_SIGN_IN_CREATE_ONE: 'Criar um',
	AUTHENTICATE_EMAIL_NOT_EMPTY_ERROR: `O e-mail não pode estar vazio`,
	AUTHENTICATE_EMAIL_INVALID: `Endereço de e-mail inválido`,
	AUTHENTICATE_EMAIL_DIGIT_MESSAGE:
		'Acabámos de enviar por e-mail um código de seis dígitos para',
	AUTHENTICATE_EMAIL_DIGIT_MESSAGE_SUBMIT:
		'Acabámos de enviar por e-mail um código de seis dígitos para',
	SETTINGS_SIGN_IN: 'Iniciar Sessão',
	SETTINGS_SIGN_OUT: 'Sair',
	SETTINGS_SUBSCRIPTION: 'Assinatura',
	SETTINGS_SUBSCRIPTION_INACTIVE_ERROR: 'Não tem uma subscrição activa',
	SETTINGS_SUBSCRIPTION_EXPIRE_AT: 'A sua subscrição termina em',
	SETTINGS_STORAGE: 'Armazenamento',
	SETTINGS_LANGUAGE: 'Idioma',
	SETTINGS_FREE_SPACE: 'Espaço Livre',
	SETTINGS_CLEAR_CACHE: 'Cache claro',
	SETTINGS_ASSETS_REMOVED_INTRO_MESSAGE: 'Bens removidos',
	SETTINGS_CLEAR_CACHE_IMAGES_MESSAGE: 'Imagens',
	SETTINGS_CLEAR_CACHE_ISSUES_MESSAGE: 'Edições',
	SETTINGS_CLEAR_CACHE_ARTICLES_MESSAGE: 'Artigos',
	SETTINGS_CLEAR_CACHE_FAVORITE_ARTICLES_IMAGES_MESSAGE: 'Artigos Favoritos',
	SETTINGS_FREE_SPACE_IMAGES: 'imagens',
	PURCHASE_SUBSCRIPTION_HEADER: 'Assinatura',
	PURCHASE_SUBSCRIPTION_SUBHEADER:
		'Inserção de uma mensagem sobre subscrições',
	PURCHASE_OFFLINE_ERROR:
		'Não pode comprar offline, por favor tente novamente mais tarde',
	PURCHASES_OFFLINE_ERROR: 'Precisa de estar online para ver as compras',
	PURCHASES_PRICE: 'Preço',
	PURCHASES_TYPE_ISSUE: 'Edição',
	PURCHASES_TYPE_SUBSCRIPTION: 'Assinatura',
};
