import React from 'react';
import PropTypes from 'prop-types';

import styles from './Lightbox.module.css';
import { Article } from '../../../components/canvasflow/article/Article';
import { Icon } from '../../../components/Icon';

export const Lightbox = ({
	article,
	articleStyles,
	fontSize,
	onCloseClick,
}) => {
    if(!article) {
        return null;
    }
	const { id, components, style, ArticleID } = article;
	return (
		<div className={styles['lightbox']}>
			<div>
				<button
					onClick={onCloseClick}
					className={styles['close']}
				>
                    <Icon name={'close'}/>
                </button>
				<main className={styles['content']}>
					<Article
						id={id}
						index={0}
						style={`${style}`}
						fontSize={fontSize}
						styles={articleStyles}
						ArticleID={ArticleID}
						components={components}
						onLightboxTargetClick={() => {}}
					/>
				</main>
			</div>
		</div>
	);
};

Lightbox.propTypes = {
	article: PropTypes.any,
	articleStyles: PropTypes.any,
	index: PropTypes.number,
	fontSize: PropTypes.any,
	onCloseClick: PropTypes.func,
};
