export default {
	FREE: 'Gratuito',
	CONTINUE: 'Continua',
	OK: 'Ok',
	SUBSCRIBE: 'Iscriviti',
	EMPTY_BOOKMARK_TITLE: 'Non hai salvato alcun segnalibro',
	EMPTY_PUBLICATION_TITLE: 'Questa pubblicazione è vuota',
	EMPTY_PURCHASES_TITLE: 'Non hai acquisti',
	EMPTY_ARTICLES_TITLE: 'Questo numero è vuoto',
	EMPTY_BOOKMARK_CONTENT: `La funzione di bookmark consente di salvare e accedere rapidamente al proprio
    articoli preferiti. Basta toccare l'icona del segnalibro su qualsiasi
    per salvarlo, quindi visualizzarlo tramite il menu dei segnalibri.`,
	EMPTY_PUBLICATION_CONTENT: `Non ci sono problemi in questa pubblicazione, si prega di controllare di nuovo a breve.`,
	EMPTY_PURCHASES_CONTENT: `Non avete acquistato alcun contenuto.  Una volta che uno o più pagamenti sono stati effettuati è possibile visualizzare 
    i dettagli della transazione qui.`,
	EMPTY_ARTICLES_CONTENT:
		'Non ci sono articoli in questo numero, si prega di controllare di nuovo a breve',
	SIGN_UP_WITH_EMAIL: 'Iscriviti con e-mail',
	SIGN_UP_WITH_EMAIL_BACK_TITLE: 'Tutte le opzioni di registrazione',
	SIGN_UP_WITH_EMAIL_CHECK_INBOX:
		'Controlla la tua casella di posta elettronica',
	SIGN_IN_WITH_EMAIL: 'Accedi con e-mail',
	SIGN_IN_WITH_EMAIL_BACK_TITLE: 'Tutte le opzioni di accesso',
	SIGN_IN_WITH_EMAIL_CHECK_INBOX:
		'Controlla la tua casella di posta elettronica',
	SIGN_UP_HEADER: 'Unisciti a noi',
	SIGN_UP_SUBHEADER: `Creare un account per ricevere grandi storie nel vostro
        casella di posta in arrivo, personalizzare la vostra homepage, e seguire gli autori e
        argomenti che ti piacciono.`,
	SIGN_IN_HEADER: 'Bentornato',
	SIGN_IN_SUBHEADER: `Sign in per ottenere consigli personalizzati sulla storia,
    seguire gli autori e gli argomenti che ami, e interagire con
    storie.`,
	SIGN_UP_WITH_GOOGLE: 'Iscriviti a Google',
	SIGN_UP_WITH_APPLE: 'Iscriviti con Apple',
	SIGN_IN_WITH_GOOGLE: 'Accedi con Google',
	SIGN_IN_WITH_APPLE: 'Accedi con Apple',
	AUTHENTICATE_SIGN_IN: 'Accedi',
	AUTENTICATO_YOUR_EMAIL: 'La tua e-mail',
	AUTENTICATO_OFFLINE_ERROR: 'Devi essere online per continuare',
	AUTHENTICATE_SIGN_UP_MESSAGE: 'Hai già un conto?',
	AUTHENTICATE_SIGN_UP: 'Iscriviti',
	AUTHENTICATE_SIGN_IN_MESSAGE: 'Nessun conto?',
	AUTHENTICATE_SIGN_IN_CREATE_ONE: 'Creane uno',
	AUTHENTICATE_EMAIL_NOT_EMPTY_ERROR: `Email non può essere vuota`,
	AUTENTICATO_EMAIL_INVALIDO: `Indirizzo e-mail non valido`,
	AUTHENTICATE_EMAIL_DIGIT_MESSAGE:
		'Abbiamo appena inviato via e-mail un codice a sei cifre a',
	AUTHENTICATE_EMAIL_DIGIT_MESSAGE_SUBMIT:
		'Abbiamo appena inviato via e-mail un codice a sei cifre a',
	SETTINGS_SIGN_IN: 'Accedi',
	SETTINGS_SIGN_OUT: 'Sign Out',
	SETTINGS_SUBSCRIPTION: 'Abbonamento',
	SETTINGS_SUBSCRIPTION_INACTIVE_ERROR: 'Non hai un abbonamento attivo',
	SETTINGS_SUBSCRIPTION_EXPIRE_AT: 'Il tuo abbonamento termina il',
	SETTINGS_STORAGE: 'Stoccaggio',
	SETTINGS_LANGUAGE: 'Lingua',
	SETTINGS_FREE_SPACE: 'Spazio libero',
	SETTINGS_CLEAR_CACHE: 'Clear Cache',
	SETTINGS_ASSETS_REMOVED_INTRO_MESSAGE: 'Attività rimosse',
	SETTINGS_CLEAR_CACHE_IMAGES_MESSAGE: 'Immagini',
	SETTINGS_CLEAR_CACHE_ISSUES_MESSAGE: 'Problemi',
	SETTINGS_CLEAR_CACHE_ARTICLES_MESSAGE: 'Articoli',
	SETTINGS_CLEAR_CACHE_FAVORITE_ARTICLES_IMAGES_MESSAGE: 'Articoli preferiti',
	SETTINGS_FREE_SPACE_IMAGES: 'immagini',
	PURCHASE_SUBSCRIPTION_HEADER: 'Abbonamento',
	PURCHASE_SUBSCRIPTION_SUBHEADER:
		'Segnaposto per un messaggio sulle iscrizioni',
	PURCHASE_OFFLINE_ERROR:
		'Non è possibile acquistare offline, si prega di riprovare più tardi',
	PURCHASES_OFFLINE_ERROR:
		'È necessario essere online per visualizzare gli acquisti',
	PURCHASES_PRICE: 'Prezzo',
	PURCHASES_TYPE_ISSUE: 'Emissione',
	PURCHASES_TYPE_SUBSCRIPTION: 'Abbonamento',
};
