import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';

import {
	Elements,
	useStripe,
	useElements,
	CardElement,
} from '@stripe/react-stripe-js';

import styles from './Purchase.module.css';

import { AppContext } from '../services/AppContext';

import { Loading } from '../components/loading/Loading';
import Navbar from '../components/navbar/Navbar';

import { loadStripe } from '@stripe/stripe-js';

import xIcon from './icons/x-icon.svg';

import imgLogo from '../components/navbar/logo.png';
import { useState } from 'react';

export class PurchaseIssue extends React.Component {
	static contextType = AppContext;

	state = {
		isAuthenticated: !!localStorage.getItem('token'),
		issue: null,
		redirect: null,
		stripeClientSecret: null,
		isLoading: false,
		loadingPurchase: false,
		subscriptions: [],
	};

	componentDidMount() {
		const { stripeKey, StripeAccountID, onAuthDisabled } = this.context;
		onAuthDisabled();
		const { match, location } = this.props;
		localStorage.setItem('last-path', location.pathname);
		this.id = match.params.id;

		if (StripeAccountID) {
			this.stripe = loadStripe(stripeKey, {
				stripeAccount: StripeAccountID,
			});
		} else {
			this.stripe = loadStripe(stripeKey);
		}

		const { isOnline } = this.context;
		const { isAuthenticated } = this.state;

		if (!isAuthenticated || !isOnline) {
			this.setState({ isLoading: false });
			return;
		}

		this.setState({ isLoading: true });
		this.getIssue()
			.then((issue) => this.setState({ issue, isLoading: false }))
			.catch((e) => alert(e.message));
		this.getSubscriptions()
			.then((subscriptions) => {
				this.setState({ subscriptions });
			})
			.catch((e) => alert(e.message));
	}

	onPurchaseSucess = () => {
		const { match, history } = this.props;
		const id = match.params.id;
		setTimeout(() => {
			history.replace(`/issues/${id}`);
		}, 1000);
	};

	isAuthenticated = () => {
		return !!localStorage.getItem('token');
	};

	getIssue = async () => {
		const { isOnline, db } = this.context;
		if (isOnline) {
			const issue = await this.getIssueFromRemote();
			await db.updateIssue(issue);
			return issue;
		}

		return await db.getIssue(this.id);
	};

	getSubscriptions = async () => {
		const { api, isOnline } = this.context;
		if (isOnline) {
			const subscriptions = await api.getSubscriptions();
			return subscriptions;
		}
		return [];
	};

	getIssueFromRemote = async () => {
		const { api } = this.context;
		const issue = await api.getIssueByID(this.id);
		return issue;
	};

	onClickCheckoutIssue = () => {
		const { api } = this.context;
		const { issue } = this.state;
		const { id } = issue;
		this.setState({ loadingPurchase: true });

		api.purchaseIssue(id)
			.then((paymentIntentSecret) => {
				this.setState({ paymentIntentSecret, loadingPurchase: false });
			})
			.catch((e) => alert(e.message));
	};

	onCheckoutFormClose = () => {
		this.setState({ paymentIntentSecret: null });
	};

	onCheckoutError = (err) => {
		alert(err.message);
	};

	render() {
		const { isOnline, theme, locale, enableAuthorization } = this.context;

		let {
			isLoading,
			isAuthenticated,
			issue,
			paymentIntentSecret,
			subscriptions,
			loadingPurchase,
		} = this.state;

		if (!isOnline) {
			return <Offline />;
		}

		if (!isAuthenticated) {
			return <Redirect to={'/authenticate'} />;
		}

		if (!enableAuthorization) {
			return null;
		}

		if (isLoading) {
			return <LoadingComponent />;
		}

		if (!issue) {
			return null;
		}

		const { name, description, currency, price } = issue;

		const primaryButtonStyle = {
			background: '#000',
			color: '#fff',
		};
		const secondaryButtonStyle = {
			background: '#3d3d3d',
			color: '#fff',
		};
		if (theme.buttons) {
			if (theme.buttons.primary) {
				if (theme.buttons.primary.color) {
					primaryButtonStyle.color = theme.buttons.primary.color;
				}
				if (theme.buttons.primary.background) {
					primaryButtonStyle.background =
						theme.buttons.primary.background;
				}
			}
			if (theme.buttons.secondary) {
				if (theme.buttons.secondary.color) {
					secondaryButtonStyle.color = theme.buttons.secondary.color;
				}
				if (theme.buttons.secondary.background) {
					secondaryButtonStyle.background =
						theme.buttons.secondary.background;
				}
			}
		}

		return (
			<div className="App">
				<Navbar isOnline={isOnline} theme={theme.navbar} backTo={'/'} />
				<main style={{ height: '100%' }}>
					{paymentIntentSecret ? (
						<div className={styles['checkout-container']}>
							<Elements stripe={this.stripe}>
								<CheckoutForm
									primaryButtonStyle={primaryButtonStyle}
									secondaryButtonStyle={secondaryButtonStyle}
									onClose={this.onCheckoutFormClose}
									onSuccess={this.onPurchaseSucess}
									onError={this.onCheckoutError}
									secret={paymentIntentSecret}
								/>
							</Elements>
						</div>
					) : null}
					<section className={styles['purchase-issue']}>
						<Cover issue={issue} />
						<div className={styles['details']}>
							<h1>{name}</h1>
							{description ? <p>{description}</p> : null}
							<div>
								<div
									className={styles['purchase-button']}
									onClick={this.onClickCheckoutIssue}
								>
									<div style={primaryButtonStyle}>
										{loadingPurchase ? (
											<div
												className={styles['loader']}
												style={{
													border: `4px solid transparent`,
													borderTop: `4px solid ${primaryButtonStyle.color}`,
												}}
											></div>
										) : (
											<p>
												{currencySymbol(currency)}
												{price.toFixed(2)}
											</p>
										)}
									</div>
								</div>
								{subscriptions.length ? (
									<Link
										to={`/purchase/subscription`}
										className={styles['subscribe-button']}
									>
										<div style={secondaryButtonStyle}>
											<p>{locale['SUBSCRIBE']}</p>
										</div>
									</Link>
								) : null}
							</div>
						</div>
					</section>
				</main>
			</div>
		);
	}
}

PurchaseIssue.propTypes = {
	match: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

export class PurchaseSubscription extends React.Component {
	static contextType = AppContext;

	state = {
		isAuthenticated: !!localStorage.getItem('token'),
		subscriptions: [],
		isLoading: true,
	};

	componentDidMount() {
		const {
			api,
			stripeKey,
			StripeAccountID,
			onAuthDisabled,
		} = this.context;
		onAuthDisabled();

		if (StripeAccountID) {
			this.stripe = loadStripe(stripeKey, {
				stripeAccount: StripeAccountID,
			});
		} else {
			this.stripe = loadStripe(stripeKey);
		}

		api.getSubscriptions()
			.then((subscriptions) => {
				this.setState({
					subscriptions,
					isLoading: false,
				});
			})
			.catch((e) => alert(e.message));
	}

	onClose = () => {
		const { history } = this.props;
		history.goBack();
	};

	isAuthenticated = () => {
		return !!localStorage.getItem('token');
	};

	loading = () => {
		const { config } = this.context;
		return (
			<div className={styles['subscription']}>
				<Loading theme={config?.theme?.loading} />
			</div>
		);
	};

	closeButton = () => {
		return (
			<div className={styles['close-btn']}>
				<img alt={'Close'} src={xIcon} onClick={this.onClose} />
			</div>
		);
	};

	onCheckoutFormClose = () => {
		this.setState({ paymentIntentSecret: null });
	};

	onCheckoutError = (err) => {
		alert(err.message);
	};

	onClickSubscription = (subscription) => {
		const { history } = this.props;
		const { api } = this.context;
		const { id } = subscription;

		api.purchaseSubscription(id)
			.then((paymentIntentSecret) => {
				if (paymentIntentSecret) {
					this.setState({ paymentIntentSecret });
				} else {
					history.replace(`/`);
				}
			})
			.catch((e) => alert(e.message));
	};

	onPurchaseSucess = () => {
		const { history } = this.props;
		setTimeout(() => {
			history.replace(`/`);
		}, 1000);
	};

	render() {
		const { locale, theme, enableAuthorization } = this.context;
		const { isLoading, subscriptions, paymentIntentSecret } = this.state;
		if (!enableAuthorization) {
			return null;
		}

		if (isLoading) {
			return this.loading();
		}

		const primaryButtonStyle = {
			background: '#000',
			color: '#fff',
		};
		const secondaryButtonStyle = {
			background: '#3d3d3d',
			color: '#fff',
		};
		if (theme.buttons) {
			if (theme.buttons.primary) {
				if (theme.buttons.primary.color) {
					primaryButtonStyle.color = theme.buttons.primary.color;
				}
				if (theme.buttons.primary.background) {
					primaryButtonStyle.background =
						theme.buttons.primary.background;
				}
			}
			if (theme.buttons.secondary) {
				if (theme.buttons.secondary.color) {
					secondaryButtonStyle.color = theme.buttons.secondary.color;
				}
				if (theme.buttons.secondary.background) {
					secondaryButtonStyle.background =
						theme.buttons.secondary.background;
				}
			}
		}

		return (
			<div className={styles['subscription']}>
				{this.closeButton()}
				{paymentIntentSecret ? (
					<div className={styles['checkout-container']}>
						<Elements stripe={this.stripe}>
							<CheckoutForm
								primaryButtonStyle={primaryButtonStyle}
								secondaryButtonStyle={secondaryButtonStyle}
								onClose={this.onCheckoutFormClose}
								onError={this.onCheckoutError}
								onSuccess={this.onPurchaseSucess}
								secret={paymentIntentSecret}
							/>
						</Elements>
					</div>
				) : null}
				<img src={imgLogo} alt={'icon'} />
				<div className={styles['header']}>
					<h2>{locale['PURCHASE_SUBSCRIPTION_HEADER']}</h2>
				</div>
				<div className={styles['subheader']}>
					<h4>{locale['PURCHASE_SUBSCRIPTION_SUBHEADER']}</h4>
				</div>
				{subscriptions.map((subscription, i) => (
					<SubscriptionItem
						key={i}
						data={subscription}
						onClick={this.onClickSubscription}
					/>
				))}
			</div>
		);
	}
}

PurchaseSubscription.propTypes = {
	history: PropTypes.object.isRequired,
};

function SubscriptionItem({ data, onClick }) {
	const { name, description, price, currency, unit, amount } = data;
	return (
		<div
			className={styles['subscription-item']}
			onClick={() => onClick(data)}
		>
			<div>
				<div className={styles['name']}>{name}</div>
				<div className={styles['description']}>
					<p>{description ? description : `${amount} ${unit}`}</p>
				</div>
			</div>
			<div>{`${currencySymbol(currency)}${price}`}</div>
		</div>
	);
}

SubscriptionItem.propTypes = {
	data: PropTypes.object.isRequired,
	onClick: PropTypes.func.isRequired,
};

function Cover({ issue }) {
	const { cover } = issue;
	return (
		<div className={styles['cover-container']}>
			<div className={styles['cover-blur']}>
				<img src={cover} alt="cover" />
			</div>
			<div>
				<img src={cover} alt="cover" className={styles['cover']} />
			</div>
		</div>
	);
}

Cover.propTypes = {
	issue: PropTypes.object.isRequired,
};

function LoadingComponent() {
	const { isOnline, theme, config } = useContext(AppContext);
	return (
		<div className="App">
			<Navbar isOnline={isOnline} theme={theme.navbar} backTo={'/'} />
			<main>
				<section className={styles['purchase-issue-loading']}>
					<Loading theme={config?.theme?.loading} />
				</section>
			</main>
		</div>
	);
}

function Offline() {
	const { isOnline, theme, locale } = useContext(AppContext);
	return (
		<section>
			<Navbar isOnline={isOnline} theme={theme.navbar} backTo={'/'} />
			<div className="content">
				<div style={{ color: 'red' }}>
					{locale['PURCHASE_OFFLINE_ERROR']}
				</div>
			</div>
		</section>
	);
}

export default function CheckoutForm({
	secret,
	onSuccess,
	onClose,
	primaryButtonStyle,
	onError,
	secondaryButtonStyle,
}) {
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	let me;
	if (localStorage.getItem('me')) {
		me = JSON.parse(localStorage.getItem('me'));
	}
	const stripe = useStripe();
	const elements = useElements();

	const handleSubmit = async (event) => {
		setLoading(true);
		event.preventDefault();

		if (!stripe || !elements) {
			return;
		}

		let billingDetails;

		if (me) {
			billingDetails = {
				email: me.email,
			};

			if (me.firstName || me.lastName) {
				billingDetails.name = `${me.firstName || ''} ${
					me.lastName || ''
				}`;
			}
		}

		const result = await stripe.confirmCardPayment(secret, {
			payment_method: {
				card: elements.getElement(CardElement),
				billing_details: billingDetails,
			},
		});

		setLoading(false);

		if (result.error) {
			onError(result.error);
		} else {
			if (result.paymentIntent.status === 'succeeded') {
				setSuccess(true);
			}
		}
	};

	let buttonText = <p>Confirm Order</p>;
	if (loading) {
		buttonText = (
			<div
				className={styles['loader']}
				style={{
					border: `4px solid transparent`,
					borderTop: `4px solid ${primaryButtonStyle.color}`,
				}}
			></div>
		);
	} else {
		if (success) {
			buttonText = <p>Purchase successful</p>;
			onSuccess();
		}
	}

	const style = {
		...primaryButtonStyle,
		...{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	};

	return (
		<form onSubmit={handleSubmit} className={styles['checkout']}>
			<CardSection />

			{loading ? (
				<button type="submit" disabled style={style}>
					{buttonText}
				</button>
			) : (
				<button type="submit" style={style} disabled={!stripe}>
					{buttonText}
				</button>
			)}

			<button
				type="button"
				style={secondaryButtonStyle}
				onClick={() => {
					if (!loading) {
						onClose();
					}
				}}
			>
				Cancel
			</button>
		</form>
	);
}

CheckoutForm.propTypes = {
	secret: PropTypes.any.isRequired,
	onSuccess: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	primaryButtonStyle: PropTypes.object.isRequired,
	onError: PropTypes.func.isRequired,
	secondaryButtonStyle: PropTypes.object.isRequired,
};

function CardSection() {
	const CARD_ELEMENT_OPTIONS = {
		style: {
			base: {
				color: '#32325d',
				fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#aab7c4',
				},
			},
			invalid: {
				color: '#fa755a',
				iconColor: '#fa755a',
			},
		},
	};
	return (
		<label>
			<h1>Card details</h1>
			<CardElement options={CARD_ELEMENT_OPTIONS} />
		</label>
	);
}

function currencySymbol(currency) {
	currency = currency.toUpperCase();
	switch (currency) {
		case 'EUR':
			return '€';
		case 'USD':
			return '$';
		case 'GBP':
			return '£';
		default:
			return currency;
	}
}
