import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './Sidebar.module.css';

import { AppContext } from '../../services/AppContext';

export function Sidebar({ theme, section }) {
	const { locale, config, isUserAdmin } = useContext(AppContext);
	const { background, accentColor, color } = theme;
	const selection = section;

	const isSelection = (section) => {
		return selection === section;
	};

	let settingsComponent = (
		<div
			className={styles['sidebar-icon-container']}
			style={{
				position: 'fixed',
				bottom: '10px',
			}}
		>
			<Link
				to={`/settings`}
				className={`${styles['sidebar-icon']} ${
					styles['icon-setting']
				} ${isSelection('settings') ? styles['selected'] : ''}`}
			>
				<button
					title={locale['BUTTON_TITLE_SETTINGS']}
					style={{
						background: isSelection('settings')
							? accentColor
							: color,
					}}
				/>
			</Link>
		</div>
	);

	let purchasesComponent = null;
	if (localStorage.getItem('me') && !isUserAdmin) {
		purchasesComponent = (
			<div className={styles['sidebar-icon-container']}>
				<Link
					to={`/purchases`}
					className={`${styles['sidebar-icon']} ${
						styles['icon-purchases']
					} ${isSelection('purchases') ? styles['selected'] : ''}`}
				>
					<button
						title={locale['BUTTON_TITLE_PURCHASES']}
						style={{
							background: isSelection('purchases')
								? accentColor
								: color,
						}}
					/>
				</Link>
			</div>
		);
	}

	let feedComponent = config?.rssFeed?.enabled ? (
		<div className={styles['sidebar-icon-container']}>
			<Link
				to={`/feeds`}
				className={`${styles['sidebar-icon']} ${styles['icon-feed']} ${
					isSelection('feed') ? styles['selected'] : ''
				}`}
			>
				<button
					title={locale['BUTTON_TITLE_FEEDS']}
					style={{
						background: isSelection('feed') ? accentColor : color,
					}}
				/>
			</Link>
		</div>
	) : null;

	return (
		<aside
			className={styles['sidebar']}
			style={{
				background,
			}}
		>
			<div>
				<div className={styles['sidebar-icon-container']}>
					<Link
						to={`/`}
						className={`${styles['sidebar-icon']} ${
							styles['icon-home']
						} ${isSelection('home') ? styles['selected'] : ''}`}
					>
						<button
							title={locale['BUTTON_TITLE_HOME']}
							style={{
								background: isSelection('home')
									? accentColor
									: color,
							}}
						/>
					</Link>
				</div>
				{purchasesComponent}
				{!isUserAdmin && config?.bookmarks?.enabled ? (
					<div className={styles['sidebar-icon-container']}>
						<Link
							to={`/bookmarks`}
							className={`${styles['sidebar-icon']} ${
								styles['icon-bookmarks']
							} ${
								isSelection('bookmark')
									? styles['selected']
									: ''
							}`}
						>
							<button
								title={locale['BUTTON_TITLE_BOOKMARKS']}
								style={{
									background: isSelection('bookmark')
										? accentColor
										: color,
								}}
							/>
						</Link>
					</div>
				) : null}

				{feedComponent}
			</div>
			<div>{settingsComponent}</div>
		</aside>
	);
}

Sidebar.propTypes = {
	theme: PropTypes.object,
	section: PropTypes.string.isRequired,
};

export default Sidebar;
