export default {
	FREE: 'Gratis',
	CONTINUE: 'Continuar',
	OK: 'Ok',
	SUBSCRIBE: 'Suscríbase a',
	EMPTY_BOOKMARK_TITLE: 'No tienes marcadores guardados',
	EMPTY_PUBLICATION_TITLE: 'This publication is empty',
	EMPTY_PURCHASES_TITLE: 'Esta publicación está vacía',
	EMPTY_ARTICLES_TITLE: 'Esta edición está vacía',
	EMPTY_BOOKMARK_CONTENT: `La función de marcadores te permite guardar y acceder rápidamente a tu
    artículos favoritos. Simplemente toque el icono del marcador en cualquier
    artículo para guardarlo, y luego verlo a través del menú de marcadores.`,
	EMPTY_PUBLICATION_CONTENT: `No hay números en esta publicación, por favor, compruébelo de nuevo en breve.`,
	EMPTY_PURCHASES_CONTENT: `No ha comprado ningún contenido.  Una vez que uno o más pagos se han hecho, puede ver 
    los detalles de la transacción aquí.`,
	EMPTY_ARTICLES_CONTENT:
		'No hay artículos en este número, por favor, compruébelo en breve.',
	SIGN_UP_WITH_EMAIL: 'Regístrate con el correo electrónico',
	SIGN_UP_WITH_EMAIL_BACK_TITLE: 'Todas las opciones de inscripción',
	SIGN_UP_WITH_EMAIL_CHECK_INBOX: 'Revisa tu bandeja de entrada',
	SIGN_IN_WITH_EMAIL: 'Iniciar sesión con el correo electrónico',
	SIGN_IN_WITH_EMAIL_BACK_TITLE: 'Todas las opciones de acceso',
	SIGN_IN_WITH_EMAIL_CHECK_INBOX: 'Revisa tu bandeja de entrada',
	SIGN_UP_HEADER: 'Únete a nosotros',
	SIGN_UP_SUBHEADER: `Crea una cuenta para recibir grandes historias en tu
    de la bandeja de entrada, personaliza tu página de inicio, y sigue a los autores y
    temas que te gustan.`,
	SIGN_IN_HEADER: 'Bienvenido de nuevo',
	SIGN_IN_SUBHEADER: `Entra para obtener recomendaciones de historias personalizadas,
    seguir a los autores y temas que te gustan, e interactuar con
    historias.`,
	SIGN_UP_WITH_GOOGLE: 'Regístrate con Google',
	SIGN_UP_WITH_APPLE: 'Regístrate conApple',
	SIGN_IN_WITH_GOOGLE: 'Iniciar sesión conGoogle',
	SIGN_IN_WITH_APPLE: 'Iniciar sesión con Apple',
	AUTHENTICATE_SIGN_IN: 'Iniciar sesión',
	AUTHENTICATE_YOUR_EMAIL: 'Tu Correo',
	AUTHENTICATE_OFFLINE_ERROR: 'Necesitas estar en línea para continuar',
	AUTHENTICATE_SIGN_UP_MESSAGE: '¿Ya tienes una cuenta?',
	AUTHENTICATE_SIGN_UP: 'Inscríbete',
	AUTHENTICATE_SIGN_IN_MESSAGE: '¿Sin cuenta?',
	AUTHENTICATE_SIGN_IN_CREATE_ONE: 'Crea una',
	AUTHENTICATE_EMAIL_NOT_EMPTY_ERROR: `El correo no puede estar vacío`,
	AUTHENTICATE_EMAIL_INVALID: `Dirección de correo electrónico inválida`,
	AUTHENTICATE_EMAIL_DIGIT_MESSAGE: 'We just emailed a six-digit code to',
	AUTHENTICATE_EMAIL_DIGIT_MESSAGE_SUBMIT:
		'Acabamos de enviar por correo electrónico un código de seis dígitos a',
	SETTINGS_SIGN_IN: 'Iniciar sesión',
	SETTINGS_SIGN_OUT: 'Cerrar sesión',
	SETTINGS_SUBSCRIPTION: 'Suscripción',
	SETTINGS_SUBSCRIPTION_INACTIVE_ERROR: 'No tiene una suscripción activa',
	SETTINGS_SUBSCRIPTION_EXPIRE_AT: 'Su suscripción termina en',
	SETTINGS_STORAGE: 'Almacenamiento',
	SETTINGS_LANGUAGE: 'Idioma',
	SETTINGS_FREE_SPACE: 'Espacio libre',
	SETTINGS_CLEAR_CACHE: 'Despejar la memoria caché',
	SETTINGS_ASSETS_REMOVED_INTRO_MESSAGE: 'Activos borrados',
	SETTINGS_CLEAR_CACHE_IMAGES_MESSAGE: 'Imágenes',
	SETTINGS_CLEAR_CACHE_ISSUES_MESSAGE: 'Ediciones',
	SETTINGS_CLEAR_CACHE_ARTICLES_MESSAGE: 'Artículos',
	SETTINGS_CLEAR_CACHE_FAVORITE_ARTICLES_IMAGES_MESSAGE:
		'Artículos favoritos',
	SETTINGS_FREE_SPACE_IMAGES: 'imágenes',
	PURCHASE_SUBSCRIPTION_HEADER: 'Suscripción',
	PURCHASE_SUBSCRIPTION_SUBHEADER:
		'Marcador de posición para un mensaje sobre suscripciones',
	PURCHASE_OFFLINE_ERROR:
		'No puede comprar fuera de línea, por favor, inténtelo de nuevo más tarde.',
	PURCHASES_OFFLINE_ERROR: 'Necesitas estar en línea para ver las compras',
	PURCHASES_PRICE: 'Precio',
	PURCHASES_TYPE_ISSUE: 'Edición',
	PURCHASES_TYPE_SUBSCRIPTION: 'Suscripción',
};
