import React, { useContext } from 'react';
import { ReplicaModeContext } from './ReplicaModeContext';
import styles from './ReplicaMode.module.css';
import PropTypes from 'prop-types';
import { reduceToDoublePages } from './Pages';
import { AppContext } from '../../../services/AppContext';
export function Toc() {
	const { isTocOpen, mode } = useContext(ReplicaModeContext);
	const className = [styles['toc']];
	if (!isTocOpen) {
		className.push(styles['closed']);
	}
	
	return (
		<aside className={className.join(' ')}>
			{mode === 'single' ? <SinglePageToc /> : <DoublePageToc />}
		</aside>
	);
}

function SinglePageToc() {
	const { deviceType } = useContext(AppContext);
	const {
		swiper,
		pages,
		selectedIndex,
		setSelectedIndex,
		onArticleChange,
		setSelected,
		setIsTocOpen,
	} = useContext(ReplicaModeContext);
	return (
		<ol>
			{pages.map(({ article, page, index }) => (
				<TocItem
					key={index}
					onClick={() => {
						setSelected(article);
						onArticleChange(article);
						setTimeout(() => {
							if (swiper) {
								swiper.slideTo(index);
							}
							setSelectedIndex(index);
						}, 10);
						if (deviceType === 'mobile') {
							setIsTocOpen(false);
						}
					}}
					selected={selectedIndex == index}
					image={page}
				/>
			))}
		</ol>
	);
}

function TocItem({ selected, image, onClick }) {
	const className = [styles['toc-item'], styles['single']];
	if (selected) {
		className.push(styles['selected']);
	}
	return (
		<li className={className.join(' ')} onClick={onClick}>
			<figure>
				<img src={image} />
			</figure>
			<span>{getPageNumber(image)}</span>
		</li>
	);
}

TocItem.propTypes = {
	selected: PropTypes.bool,
	image: PropTypes.string,
	onClick: PropTypes.func,
};

function DoublePageToc() {
	const { deviceType } = useContext(AppContext);
	const {
		pages,
		swiper,
		selectedIndex,
		setSelectedIndex,
		onArticleChange,
		setSelected,
		setIsTocOpen,
	} = useContext(ReplicaModeContext);

	const doublePages = pages.reduce(reduceToDoublePages, []);
	return (
		<ol>
			{doublePages.map(({ pages, article, index }) => (
				<DoubleTocItem
					key={index}
					onClick={() => {
						setSelected(article);
						onArticleChange(article);
						setTimeout(() => {
							if (swiper) {
								swiper.slideTo(index);
							}
							setSelectedIndex(index);
						}, 10);
						if (deviceType === 'mobile') {
							setIsTocOpen(false);
						}
					}}
					selected={selectedIndex == index}
					article={article}
					images={pages}
				/>
			))}
		</ol>
	);
}

function DoubleTocItem({ images, selected, onClick }) {
	images = images.filter((i) => !!i);
	const className = [styles['toc-item'], styles['double']];
	if (selected) {
		className.push(styles['selected']);
	}
	const title = images.map(getPageNumber).join(' - ');
	return (
		<li className={className.join(' ')} onClick={onClick}>
			<figure className={images.length === 1 ? styles['single'] : null}>
				{images.map((image) => (
					<img key={image} src={image} />
				))}
			</figure>
			<span>{title}</span>
		</li>
	);
}

DoubleTocItem.propTypes = {
	images: PropTypes.array,
	selected: PropTypes.bool,
	onClick: PropTypes.func,
};

function getPageNumber(url) {
	const file = url.split('/');

	return parseFloat(
		file[file.length - 1]
			.replace('pg-', '')
			.replace('.jpg', '')
			.replace('.png', '')
	);
}
