import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './InstallationOption.module.css';
import xIcon from './x.svg';
import shareIcon from './share.svg';

export default class InstallationOption extends Component {
	render() {
		const { onCloseClick } = this.props;
		return (
			<div className={styles['installation-option']}>
				<InstallationOptionIOS onCloseClick={onCloseClick} />
			</div>
		);
	}
}

InstallationOption.propTypes = {
	onCloseClick: PropTypes.func.isRequired,
};

class InstallationOptionIOS extends Component {
	render() {
		const { onCloseClick } = this.props;
		const message = `icon at the bottom of your browser and
		choose "Add to Home Screen"`;
		return (
			<div className={styles['installation-option-ios']}>
				<div>
					<div className={styles['ios-content']}>
						<div className={styles['close']} onClick={onCloseClick}>
							<img src={xIcon} alt="close" />
						</div>
						<div className={styles['title']}>
							<div
								className={styles['container']}
								style={{ paddingTop: 10, paddingBottom: 10 }}
							>
								<img
									className={styles['icon']}
									src="/assets/favicon-196.png"
									style={{ height: 45, borderRadius: 8 }}
								/>
								<h1>Install App</h1>
							</div>
						</div>
						<div className={styles['message']}>
							<div className={styles['container']}>
								<p>
									Tap the{' '}
									<img
										src={shareIcon}
										alt={'share'}
										style={{ height: 20 }}
									/>{' '}
									{message}
								</p>
							</div>
						</div>
					</div>
					<div className={styles['caret']}>
						<div></div>
					</div>
				</div>
			</div>
		);
	}
}

InstallationOptionIOS.propTypes = {
	onCloseClick: PropTypes.func.isRequired,
};
