import { useContext, useEffect } from 'react';
import { ResponsiveModeContext } from './ResponsiveModeContext';

export function useBalanceText() {
	let { article } = useContext(ResponsiveModeContext);
	useEffect(() => {
		if (!article) {
			return;
		}
		const { id } = article;
		const selector = `#article-${id} .component.text p`;
		balanceText(selector);
	}, [article]);
}

function balanceText(selector) {
	const nbspWordMin = 6;
	const nbspElements = document.querySelectorAll(selector);
	nbspElements.forEach(function (element) {
		const wordCount = element.innerHTML.split(' ').length;
		if (wordCount >= nbspWordMin) {
			element.x = null;
			console.debug(element);
			// HACK THIS FIX BALANCE TEXT
			// element.innerHTML = element.innerHTML.replace(/ ([^ ]*)$/,'&nbsp;$1');
		}
	});
}
