import { useEffect, useState, useContext } from 'react';
import { ArticleContext } from '../Context';
/**
 * React hook that process animations
 *
 * @param ref - Component reference
 * @param isInViewport - Variable that says if the element is in the viewport
 * @param animation - Animation object
 * @param setAnimations - set the classes that are use to add the animations
 *
 */
export function useComponentAnimation(
	ref,
	isInViewport,
	animation,
	setAnimations
) {
	const {isSelected} = useContext(ArticleContext);
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		if (!ref) {
			return;
		}
		if (isInViewport) {
			setIsLoaded(true);
		}
	}, [isInViewport, ref]);

	useEffect(() => {
		if (!animation) {
			setAnimations([]);
			return;
		}

		if(!isSelected) {
			setAnimations([]);
			return;
		}

		if (animation.type === 'none') {
			setAnimations([]);
			return;
		}

		if (!isLoaded) {
			setAnimations([]);
			return;
		}

		const { type, params } = animation;
		const classNames = [
			...getAnimationName(type),
			...getAnimationParams(ref.current, params),
		];
		setAnimations(classNames);
	}, [isLoaded, isSelected]);
}

function getAnimationName(type) {
	const classNames = ['animate__animated'];
	classNames.push(`animate__${type}`);

	return classNames;
}

export function getAnimationParams(element, params) {
	const classNames = [];
	if (!params) {
		return classNames;
	}
	let { delay, repeat, speed } = params;

	repeat = repeat === 'infinite' ? 'infinite' : parseInt(`${repeat}`);

	if (delay) {
		element.style.setProperty('--animate-delay', `${delay}s`);
		classNames.push(`animate__delay-1s`);
	}
	if (repeat) {
		element.style.setProperty('--animate-repeat', `${repeat}`);
		classNames.push(`animate__repeat-1`);
	}
	if (speed) {
		classNames.push(`animate__${speed}`);
	}
	return classNames;
}
