import React from 'react';
import PropTypes from 'prop-types';
// import JSXStyle from 'styled-jsx/style';

import styles from './article.module.css';

import { ArticleContext } from './Context';

import { StyledImage } from './components/Image';
import { StyledInstagram } from './components/Instagram';
import { StyledAdvert } from './components/Advert';
import { StyledSpacer } from './components/Spacer';
import { StyledVideo } from './components/Video';
import { StyledAudio } from './components/Audio';
import { StyledDivider } from './components/Divider';
import { StyledTwitterTimeline } from './components/Twitter';
import { StyledText } from './components/Text';
import { Canvas } from './Canvas';
import { StyledInfogram } from './components/Infogram';
import { StyledColumns } from './components/Columns';
import { StyledGallery } from './components/Gallery';
import { StyledCustom } from './components/Custom';
import { StyledButton } from './components/Button';
import { Anchor } from './components/Anchor';
import { StyledTabs } from './components/Tabs';
import { StyledTikTok } from './components/TikTok';

import { Lightbox } from './lightbox/Lightbox';
import { AppContext } from '../../../services/AppContext';

import { CSS, BREAKPOINTS } from './CSS';
import { isTextComponent } from '../article/Article.constants';

// import { StyledMap } from './components/Map';

const breakpoints = {
	mobile: BREAKPOINTS.mobile,
	tablet: BREAKPOINTS.tablet,
};

export class Article extends React.Component {
	static contextType = AppContext;
	ref = React.createRef();

	state = {
		width: null,
		height: null,
		device: 'mobile',
		StyleID: 0,
		isLightboxDisplayed: false,
		customCSS: [],
		sassQueue: [],
	};

	componentDidMount() {
		const { id, styles, style } = this.props;
		const element = this.ref.current;
		const width = element.clientWidth;
		const device =
			width < breakpoints.mobile + 1
				? 'mobile'
				: width < breakpoints.tablet
				? 'tablet'
				: 'desktop';

		const css = new CSS(
			id,
			new Map(styles),
			clone(style),
			this.onCustomCSS
		);

		this.setState({
			height: element.clientHeight,
			device,
		});

		const isVideoAdd = this.isVideoAdd();

		css.build().then((cssContent) => {
			if (isVideoAdd) {
				cssContent =
					`
				@media screen and (min-width: ${breakpoints.mobile + 1}px) {
					.article-${id} video {
						height: calc(100vh - var(--navbar-height)) !important;
					}
				}
				
				@media screen and (max-width: ${breakpoints.mobile}px) {
					.article-${id} > .canvas > :first-child,
					.article-${id} .video,
					.article-${id} .video a,
					.article-${id} .video video {
						height: 100%;
					}
				}
				` + cssContent;
			}
			this.setState({
				css: cssContent,
			});
			setTimeout(() => {
				this.setState({
					width,
				});
			}, 500);
		});

		// const observer = new MutationObserver(this.onResize);
		/*if(ResizeObserver) {
			try {
				const observer = new ResizeObserver(this.onResize);
				observer.observe(element, {
					attributes: true,
					attributeOldValue: true,
					attributeFilter: ['style'],
				});
			} catch(e) {
				console.log(`Observer doesn't exist, but the error was catched`);
				console.error(e);
			}
			
		}*/
		element.addEventListener('resize', this.resizeListener);

		// FIX ME Remove this to enable custom css
		// Is breaking in memory
		// setTimeout(this.processSass, this.props.index * 1500);
	}

	isVideoAdd() {
		const { components } = this.props;
		const videoComponents = components.filter(
			(c) => c.component === 'video'
		);
		return components.length === videoComponents.length;
	}

	onLightboxTargetClick = ({ id, resource, target }) => {
		if (this.props.onLightboxTargetClick) {
			this.props.onLightboxTargetClick({
				id,
				resource,
				target,
			});
		}
	};

	onCustomCSS = (scss) => {
		const { sassQueue } = this.state;
		sassQueue.push(scss);
		this.setState({
			sassQueue,
		});
	};

	processSass = () => {
		const interval = setInterval(this.onIntervalSass, 1000);
		this.setState({
			interval,
		});
	};

	onIntervalSass = () => {
		const { sassQueue, interval, customCSS } = this.state;
		if (!sassQueue.length) {
			clearInterval(interval);
			return;
		}

		const scss = sassQueue.shift();
		this.setState({
			sassQueue,
		});
		// eslint-disable-next-line no-undef
		const sass = new Sass();
		sass.compile(scss, (result) => {
			if (result.text) {
				customCSS.push(result.text);
				this.setState({
					customCSS,
				});
			}
		});
	};

	componentDidUpdate(_, prevState) {
		const { onLightboxMode } = this.props;
		const { isLightboxDisplayed } = this.state;
		if (prevState.isLightboxDisplayed !== isLightboxDisplayed) {
			if (onLightboxMode) {
				onLightboxMode(isLightboxDisplayed);
			}
		}
	}

	onResize = (mutations) => {
		const el = mutations[0].target;
		const w = el.clientWidth;
		const h = el.clientHeight;

		const isChange = mutations
			.map((m) => `${m.oldValue}`)
			.some(
				(prev) =>
					prev.indexOf(`width: ${w}px`) === -1 ||
					prev.indexOf(`height: ${h}px`) === -1
			);

		if (!isChange) {
			return;
		}
		const event = new CustomEvent('resize', {
			detail: { width: w, height: h },
		});
		el.dispatchEvent(event);
	};

	resizeListener = (e) => {
		const { width, height } = e.detail;
		const device =
			width < breakpoints.mobile + 1
				? 'mobile'
				: width < breakpoints.tablet
				? 'tablet'
				: 'desktop';
		this.setState({
			width,
			height,
			device,
		});
	};

	byDevice = ({ devices }) => {
		const { device } = this.state;
		if (
			(devices.phone === 'off' && device === 'mobile') ||
			(devices.tablet === 'off' && device === 'tablet') ||
			(devices.desktop === 'off' && device === 'desktop')
		) {
			return false;
		}

		return true;
	};

	reduceExcludeComponents = (acc, component) => {
		const { excludedchannels, columns, components } = component;
		if (excludedchannels?.length) {
			const excludeChannelSet = new Set(excludedchannels);
			if (excludeChannelSet.has('app')) {
				return acc;
			}
		}

		if (columns) {
			component.columns = columns.map((column) =>
				column.reduce(this.reduceExcludeComponents, [])
			);
		}

		if (components) {
			component.components = components.reduce(
				this.reduceExcludeComponents,
				[]
			);
		}

		acc.push(component);

		return acc;
	};

	byComponent = (component, i) => {
		if (isTextComponent(component.component)) {
			return <StyledText key={i} {...component} />;
		}
		switch (component.component) {
			case 'image':
			case 'anscene':
				return <StyledImage key={i} {...component} />;
			case 'tabs':
				return <StyledTabs key={i} {...component} />;
			case 'advert':
				return <StyledAdvert key={i} {...component} />;
			case 'infogram':
				return <StyledInfogram key={i} {...component} />;
			case 'instagram':
				return <StyledInstagram key={i} {...component} />;
			case 'map':
				return null;
			// return <StyledMap key={i}  {...component}/>;
			case 'spacer':
				return <StyledSpacer key={i} {...component} />;
			case 'button':
				return <StyledButton key={i} {...component} />;
			case 'anchor':
				return <Anchor key={i} {...component} />;
			case 'audio':
				return <StyledAudio key={i} {...component} />;
			case 'tiktok':
				return <StyledTikTok key={i} {...component} />;
			case 'video':
				return <StyledVideo key={i} {...component} />;
			case 'twitter':
				return <StyledTwitterTimeline key={i} {...component} />;
			case 'divider':
				return <StyledDivider key={i} {...component} />;
			case 'columns':
				return <StyledColumns key={component.id} {...component} />;
			case 'gallery':
				if (component['control-speed']) {
					component.control_speed = component['control-speed'];
				}
				return <StyledGallery key={i} {...component} />;
			case 'custom':
				return <StyledCustom key={i} {...component} />;
			default:
				return null;
		}
	};

	componentWillUnmount() {
		const element = this.ref.current;
		element.removeEventListener('resize', this.resizeListener);
	}

	onLightboxClick = ({ id }) => {
		console.log(`I CLICK LIGHTBOX: ${id}`);
		this.setState({ isLightboxDisplayed: true, lightboxId: id });
	};

	onEventSend = ({ event, data }) => {
		console.debug(`Event: "${event}"`);
		const { tracking } = this.context;

		const { url, componentId, componentType } = data;
		if (event === 'external' && tracking && url) {
			if (tracking) {
				tracking.external({
					url: url,
					articleId: this.props.id,
					componentId,
					componentType,
				});
			}
		}
	};

	getStyle = ({ fontSize, width, styleProperties }) => {
		const canvasStyle = {};
		let canvas = {};
		if (styleProperties?.canvas) {
			canvas = styleProperties.canvas;
		}
		const articleStyle = {
			fontSize: `${fontSize}%`,
			opacity: width ? 1 : 0,
		};
		if (canvas.background_color) {
			canvasStyle.background = `${canvas.background_color}`;
		}
		if (canvas.body_color) {
			articleStyle.background = `${canvas.body_color}`;
		}

		return {
			articleStyle,
			canvasStyle,
		};
	};

	render() {
		let {
			id,
			components,
			lang,
			fontSize,
			style,
			index,
			isVoid,
			isTouchDevice,
			textDirection,
			ArticleID,
			styleProperties,
		} = this.props;

		const { width, css, isLightboxDisplayed, lightboxId, customCSS } =
			this.state;
		let device =
			width < breakpoints.mobile
				? 'mobile'
				: width < breakpoints.tablet
				? 'tablet'
				: 'desktop';

		const className = [styles['article'], `article-${id}`];
		className.push(device !== 'mobile' ? device : '');

		if (device === 'desktop' && !isTouchDevice) {
			className.push('swiper-no-swiping');
		}

		if (isVoid) {
			components = [];
		}

		const renderComponents = JSON.parse(JSON.stringify(components));

		const { canvasStyle, articleStyle } = this.getStyle({
			fontSize,
			width,
			styleProperties,
		});

		return (
			<ArticleContext.Provider
				value={{
					id,
					device,
					width,
					isSelected: this.props.isSelected,
					styles: this.props.styles,
					style: clone(this.props.styles.get(style)),
					lang: lang ? lang : 'en',
					onLightboxClick: this.onLightboxClick,
					onLightboxTargetClick: this.onLightboxTargetClick,
					onEventSend: this.onEventSend,
				}}
			>
				<article
					id={`article-${id}`}
					data-article-id={ArticleID}
					data-style-id={style}
					dir={
						textDirection && textDirection.toLowerCase() === 'rtl'
							? 'rtl'
							: 'ltr'
					}
					ref={this.ref}
					data-index={index !== null ? index : null}
					style={articleStyle}
					className={className.join(' ')}
				>
					<Canvas
						style={canvasStyle}
						isAd={
							components.filter((c) => c.component === 'advert')
								.length > 0
						}
					>
						{renderComponents
							.reduce(this.reduceExcludeComponents, [])
							.filter(this.byDevice)
							.map(this.byComponent)
							.filter((i) => !!i)}
					</Canvas>
					{css ? <style>{css}</style> : null}
					{customCSS.length ? (
						<style>{customCSS.join('\n')}</style>
					) : null}
					<Lightbox
						display={isLightboxDisplayed}
						selected={lightboxId}
						components={renderComponents}
						onClose={() => {
							this.setState({ isLightboxDisplayed: false });
						}}
					/>
				</article>
			</ArticleContext.Provider>
		);
	}
}

function clone(obj) {
	if (structuredClone) {
		return structuredClone(obj);
	}
	return JSON.parse(JSON.stringify(obj));
}

Article.propTypes = {
	id: PropTypes.string,
	ArticleID: PropTypes.string,
	style: PropTypes.string,
	index: PropTypes.number,
	name: PropTypes.object,
	lightbox: PropTypes.bool,
	styles: PropTypes.instanceOf(Map),
	components: PropTypes.array,
	lang: PropTypes.string,
	fontSize: PropTypes.number,
	isVoid: PropTypes.bool,
	isTouchDevice: PropTypes.bool,
	onLightboxMode: PropTypes.any,
	onLightboxTargetClick: PropTypes.any,
	ref: PropTypes.any,
	textDirection: PropTypes.any,
	isSelected: PropTypes.bool,
	styleProperties: PropTypes.any,
};

Article.defaultProps = {
	color: '#ffffff',
	backgroundColor: '#ffffff',
	paddingTop: 0,
	paddingBottom: 0,
	spacingUnit: 'px',
	textDirection: 'ltr',
	fontSize: 62.5,
	horizontalPadding: 0,
	lang: 'en',
	styles: new Map(),
	components: [],
	isVoid: false,
	isSelected: false,
};
