import React from 'react';
import PropTypes from 'prop-types';

import styles from './Feed.module.css';

import { AppContext } from '../../services/AppContext';
import logo from '../../components/navbar/logo.png';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

export default class Feed extends React.Component {
	static contextType = AppContext;

	state = {
		feed: null,
		item: null,
		isLoading: true,
	};

	componentDidMount() {
		const { device } = this.context;
		const { id } = this.props.match.params;
		this.getFeed(id)
			.then((feed) => {
				this.setState({ feed });
				if (device.type !== 'mobile') {
					if (feed.items.length > 0) {
						this.setState({ item: feed.items[0] });
					}
				}
			})
			.catch(console.error);
	}

	getFeed = async (id) => {
		const { api, isOnline, db } = this.context;
		const cachedFeed = await db.getFeed(id);

		if (cachedFeed) {
			return cachedFeed;
		}

		if (isOnline) {
			console.debug('Fetching feed from remote');
			let remoteFeed = await api.getFeedById(id);

			if (remoteFeed) {
				console.debug('Store feeds in cache');
				await db.updateFeed(remoteFeed);
			}

			return remoteFeed;
		}

		return await db.getFeed(id);
	};

	timeout = async (ms = 500) => {
		return new Promise((resolve) => {
			setTimeout(resolve, ms);
		});
	};

	onClickBack = () => {
		const { item } = this.state;
		const { history } = this.props;
		const { device } = this.context;

		if (device.type === 'mobile' && item) {
			this.setState({ item: null });
			return;
		}

		history.replace('/feeds');
	};

	onItemClicked = (id) => {
		const { feed } = this.state;
		if (!feed) return;

		for (const item of feed.items) {
			if (`${id}` === `${item.guid}`) {
				this.setState({ item: null });
				setTimeout(() => {
					this.setState({ item });
				}, 100);

				break;
			}
		}
	};

	render() {
		const { feed, item } = this.state;
		const { device, theme } = this.context;
		const { navbar } = theme;

		return (
			<div className={styles['container']}>
				<nav style={{ background: navbar?.background || '#fff' }}>
					<div>
						<button
							onClick={this.onClickBack}
							style={{ background: navbar?.color || '#000' }}
						/>
					</div>
					<Link to={`/feeds`} className={styles['logo']}>
						<img src={logo} />
					</Link>
					<div></div>
				</nav>
				<main
					className={
						device.type === 'mobile' && item
							? styles['selected']
							: ''
					}
				>
					<Items
						items={feed?.items}
						onItemClicked={this.onItemClicked}
					/>
					<Article item={item} />
				</main>
			</div>
		);
	}
}

Feed.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
};

function Items({ items, onItemClicked }) {
	return (
		<div className={styles['items']}>
			{items
				? items.map((item) => (
						<Item
							onItemClicked={onItemClicked}
							item={item}
							key={item.guid}
						/>
				  ))
				: null}
		</div>
	);
}

Items.propTypes = {
	items: PropTypes.array,
	onItemClicked: PropTypes.func.isRequired,
};

function Item({ item, onItemClicked }) {
	const { title, description, author, guid } = item;
	const thumbnail = getThumbnail(description);
	return (
		<div
			className={
				thumbnail
					? styles['item']
					: `${styles['item']} ${styles['no-image']}`
			}
			onClick={() => {
				onItemClicked(guid);
			}}
		>
			<div className={styles['image']}>
				<ItemImage content={description} />
			</div>
			<div className={styles['content']}>
				<div className={styles['title']}>
					<h3>{title}</h3>
				</div>
				{author ? <small>By {author}</small> : null}
				<ItemDescription description={description} title={title} />
			</div>
		</div>
	);
}

Item.propTypes = {
	item: PropTypes.object.isRequired,
	onItemClicked: PropTypes.func.isRequired,
};

function getThumbnail(content) {
	const imgRex = /<img.*?src="(.*?)"[^>]+>/g;
	const images = [];
	let img;
	while ((img = imgRex.exec(content))) {
		images.push(img[1]);
	}

	return images.length > 0 ? images[0] : null;
}

function ItemImage({ content }) {
	const imgRex = /<img.*?src="(.*?)"[^>]+>/g;
	const images = [];
	let img;
	while ((img = imgRex.exec(content))) {
		images.push(img[1]);
	}
	return images.length > 0 ? <img src={images[0]} /> : <img />;
}

ItemImage.propTypes = {
	content: PropTypes.string.isRequired,
};

function ItemDescription({ description, title }) {
	description = description
		.replace(/<img.*?src="(.*?)"[^>]+>/g, '') // Remove images
		.replace(`<p><b>${title}</b></p>`, '')
		.replace('<p></p>', '');
	return (
		<div
			className={styles['description']}
			dangerouslySetInnerHTML={{ __html: description }}
		/>
	);
}

ItemDescription.propTypes = {
	description: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
};

function Article({ item }) {
	if (!item) {
		return <div className={styles['article-container']} />;
	}
	const [imageUrl, setImageUrl] = useState(null);
	const { title, description, author, guid, link } = item;
	useEffect(() => {
		const extension = getUrlExtension(link);
		if (extension === 'pdf') {
			const image = link.replace(extension, 'jpg');
			setImageUrl(image);
		}
	}, [imageUrl]);
	
	return (
		<div className={styles['article-container']}>
			{item ? (
				<article id={guid}>
					<h1>{title}</h1>
					<small>{author}</small>
					<section
						dangerouslySetInnerHTML={{ __html: description }}
					/>
					<br />
					{!imageUrl ? (
						<div className={styles['link']}>
							<a href={link} rel={'noreferrer'} target={'_blank'}>
								View Link
							</a>
						</div>
					) : null}

					<br />
					{imageUrl ? (
						<div
							className={styles['thumbnail']}
							onClick={() => {
								open(link);
							}}
						>
							<img
								src={imageUrl}
								width={120}
								height={164}
								onError={() => {
									setImageUrl(null);
								}}
							/>
						</div>
					) : null}
				</article>
			) : null}
		</div>
	);
}

function getUrlExtension(url) {
	return url.split(/[#?]/)[0].split('.').pop().trim();
}

Article.propTypes = {
	item: PropTypes.object,
};
