import { Component } from 'react';
import PropTypes from 'prop-types';
import { AppContext } from '../services/AppContext';

export default class InternalArticle extends Component {
	static contextType = AppContext;
	state = {
		isLoading: true,
	};

	componentDidMount() {
		const { api, isOnline } = this.context;
		const { match, history, location } = this.props;
		const { params } = match;
		const { id } = params;
		let hash = '';
		if(location.hash) {
			hash = `${location?.hash}`;
		}

		if (!localStorage.getItem('internal-article-map')) {
			localStorage.setItem('internal-article-map', '{}');
		}

		const internalArticleMap = JSON.parse(
			localStorage.getItem('internal-article-map')
		);

		if (internalArticleMap[id]) {
			// I found the item internally
			const response = internalArticleMap[id];
			
			
			location?.hash
			history.replace(`/issues/${response.issue}/${response.id}${hash}`);
			return;
		}

		// I didn't found it so i'm going to check remote
		if (!isOnline) {
			// If offline i will go to home because i can't fetch it
			history.replace('/');
			return;
		}

		api.getAppArticle(id)
			.then((response) => {
				let url = '/';
				if (response) {
					const { id, issue } = response;
					url = `/issues/${issue}/${id}${hash}`;
				}
				history.replace(url);
			})
			.catch((err) => {
				history.replace('/');
				alert(err.message);
			});
	}

	render() {
		return null;
	}
}

InternalArticle.propTypes = {
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};
