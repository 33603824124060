export default {
	FREE: 'Frei',
	CONTINUE: 'Weiter',
	OK: 'Ok',
	SUBSCRIBE: 'Abonnieren',
	EMPTY_BOOKMARK_TITLE: 'Sie haben keine gespeicherten Lesezeichen',
	EMPTY_PUBLICATION_TITLE: 'Diese Publikation ist leer',
	EMPTY_PURCHASES_TITLE: 'Sie haben keine Einkäufe',
	EMPTY_ARTICLES_TITLE: 'Diese Ausgabe ist leer',
	EMPTY_BOOKMARK_CONTENT: `Mit der Lesezeichen-Funktion können Sie Ihre Lesezeichen speichern und schnell auf
    Lieblingsartikel. Tippen Sie einfach auf das Lesezeichen-Symbol auf einem beliebigen
    Artikel zu speichern, dann über das Lesezeichen-Menü ansehen`,
	EMPTY_PUBLICATION_CONTENT: `Es gibt keine Probleme in dieser Publikation, bitte schauen Sie in Kürze noch einmal nach.`,
	EMPTY_PURCHASES_CONTENT: `Sie haben keine Inhalte gekauft.  Sobald eine oder mehrere Zahlungen erfolgt sind, können Sie 
    die Einzelheiten der Transaktion hier`,
	EMPTY_ARTICLES_CONTENT: `Es gibt keine Artikel in dieser Ausgabe, bitte schauen Sie in Kürze noch einmal nach`,
	SIGN_UP_WITH_EMAIL: 'Mit E-Mail anmelden',
	SIGN_UP_WITH_EMAIL_BACK_TITLE: 'Alle Anmeldemöglichkeiten',
	SIGN_UP_WITH_EMAIL_CHECK_INBOX: 'Überprüfen Sie Ihren Posteingang',
	SIGN_IN_WITH_EMAIL: 'Mit E-Mail anmelden',
	SIGN_IN_WITH_EMAIL_BACK_TITLE: 'Alle Anmeldungsoptionen',
	SIGN_IN_WITH_EMAIL_CHECK_INBOX: 'Überprüfen Sie Ihren Posteingang',
	SIGN_UP_HEADER: 'Kommen Sie zu uns',
	SIGN_UP_SUBHEADER: `Erstellen Sie ein Konto, um großartige Geschichten in Ihrem
        Posteingang, personalisieren Sie Ihre Homepage und verfolgen Sie Autoren und
        Themen, die Sie lieben`,
	SIGN_IN_HEADER: 'Willkommen zurück',
	SIGN_IN_SUBHEADER: `Melden Sie sich an, um personalisierte Geschichtenempfehlungen zu erhalten,
    Autoren und Themen verfolgen, die Sie lieben, und mit denen Sie interagieren
    Geschichten`,
	SIGN_UP_WITH_GOOGLE: 'Anmelden bei Google',
	SIGN_UP_WITH_APPLE: 'Bei Apple anmelden',
	SIGN_IN_WITH_GOOGLE: 'Bei Google anmelden',
	SIGN_IN_WITH_APPLE: 'Anmelden bei Apple',
	AUTHENTICATE_SIGN_IN: 'Anmelden',
	AUTHENTICATE_YOUR_EMAIL: 'Ihre E-Mail',
	AUTHENTICATE_OFFLINE_ERROR: 'Sie müssen online sein, um fortzufahren',
	AUTHENTICATE_SIGN_UP_MESSAGE: 'Haben Sie bereits ein Konto?',
	AUTHENTICATE_SIGN_UP: 'Anmelden',
	AUTHENTICATE_SIGN_IN_MESSAGE: 'Kein Konto?',
	AUTHENTICATE_SIGN_IN_CREATE_ONE: 'Ein Konto anlegen',
	AUTHENTICATE_EMAIL_NOT_EMPTY_ERROR: `E-Mail kann nicht leer sein`,
	AUTHENTICATE_EMAIL_INVALID: 'Ungültige E-Mail-Adresse',
	AUTHENTICATE_EMAIL_DIGIT_MESSAGE:
		'Wir haben gerade einen sechsstelligen Code per E-Mail an',
	AUTHENTICATE_EMAIL_DIGIT_MESSAGE_SUBMIT:
		'Wir haben gerade einen sechsstelligen Code per E-Mail an',
	SETTINGS_SIGN_IN: 'Anmelden',
	SETTINGS_SIGN_OUT: 'Abmelden',
	SETTINGS_SUBSCRIPTION: 'Abonnement',
	SETTINGS_SUBSCRIPTION_INACTIVE_ERROR: 'Sie haben kein aktives Abonnement',
	SETTINGS_SUBSCRIPTION_EXPIRE_AT: 'Ihr Abonnement endet am',
	SETTINGS_STORAGE: 'Speicherung',
	SETTINGS_LANGUAGE: 'Sprache',
	SETTINGS_FREE_SPACE: 'Freier Raum',
	SETTINGS_CLEAR_CACHE: 'Cache löschen',
	SETTINGS_ASSETS_REMOVED_INTRO_MESSAGE: 'Vermögenswerte entfernt',
	SETTINGS_CLEAR_CACHE_IMAGES_MESSAGE: 'Bilder',
	SETTINGS_CLEAR_CACHE_ISSUES_MESSAGE: 'Probleme',
	SETTINGS_CLEAR_CACHE_ARTICLES_MESSAGE: 'Artikel',
	SETTINGS_CLEAR_CACHE_FAVORITE_ARTICLES_IMAGES_MESSAGE: 'Bevorzugte Artikel',
	SETTINGS_FREE_SPACE_IMAGES: 'Bilder',
	PURCHASE_SUBSCRIPTION_HEADER: 'Abonnement',
	PURCHASE_SUBSCRIPTION_SUBHEADER:
		'Platzhalter für eine Nachricht über Abonnements',
	PURCHASE_OFFLINE_ERROR:
		'Sie können nicht offline einkaufen, bitte versuchen Sie es später noch einmal',
	PURCHASES_OFFLINE_ERROR: 'Sie müssen online sein, um Einkäufe anzusehen',
	PURCHASES_PRICE: 'Preis',
	PURCHASES_TYPE_ISSUE: 'Ausgabe',
	PURCHASES_TYPE_SUBSCRIPTION: 'Abonnement',
};
