import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import styles from './Authenticate.module.css';

import Navbar from '../components/navbar/Navbar';

import { AppContext } from '../services/AppContext';

import appleIcon from './icons/apple-icon.svg';
import googleIcon from './icons/google-icon.svg';
import emailIcon from './icons/email-icon.svg';
import xIcon from './icons/x-icon.svg';

import imgLogo from '../components/navbar/logo.png';

export default class Authenticate extends React.Component {
	static contextType = AppContext;

	state = {
		mode: 'sign-up',
	};

	componentDidMount() {
		const { onAuthDisabled } = this.context;
		onAuthDisabled();
	}

	onModeChange = (mode) => {
		this.setState({ mode });
	};

	render() {
		const { enableAuthorization } = this.context;
		const { history } = this.props;
		const { mode } = this.state;
		if (!enableAuthorization) {
			return null;
		}
		if (mode === 'sign-up') {
			return (
				<SignUp history={history} onModeChange={this.onModeChange} />
			);
		}

		return <SignIn history={history} onModeChange={this.onModeChange} />;
	}
}

Authenticate.propTypes = {
	history: PropTypes.object.isRequired,
};

class SignUp extends React.Component {
	static contextType = AppContext;

	state = {
		mode: 'all',
		email: '',
		firstName: '',
		lastName: '',
	};

	onModeChange = (mode) => {
		this.setState({ mode });
	};

	onClose = () => {
		const { history } = this.props;
		history.goBack();
	};

	onSubmitEmail = (email, firstName, lastName) => {
		const { api } = this.context;
		const { tracking } = this.context;
		tracking.event({
			category: 'User',
			event: 'sign_up',
			value: 'User click sign up',
		});
		api.authenticationRequest(email)
			.then(() => {
				this.setState({ email, mode: 'code', firstName, lastName });
			})
			.catch((e) => alert(e.message));
	};

	onSuccessCodeValidation = () => {
		document.location.href = '/';
		/*const { history } = this.props;
		history.goBack();*/
	};

	render() {
		const { onModeChange } = this.props;
		const { mode, email, firstName, lastName } = this.state;
		const { isOnline, api, locale } = this.context;

		if (!isOnline) {
			return <Offline />;
		}

		switch (mode) {
			case 'email':
				return (
					<RegisterEmailOption
						onClose={this.onClose}
						onSubmit={this.onSubmitEmail}
						onModeChange={this.onModeChange}
						title={locale['SIGN_UP_WITH_EMAIL']}
						backTitle={locale['SIGN_UP_WITH_EMAIL_BACK_TITLE']}
					/>
				);
			case 'code':
				return (
					<AuthenticateCode
						email={email}
						firstName={firstName}
						lastName={lastName}
						title={locale['SIGN_UP_WITH_EMAIL_CHECK_INBOX']}
						onSuccess={this.onSuccessCodeValidation}
					/>
				);
			default:
				return (
					<AllSignUpOptions
						api={api}
						onSuccess={this.onSuccessCodeValidation}
						onClose={this.onClose}
						goToSignIn={() => {
							onModeChange('sign-in');
						}}
						onModeChange={this.onModeChange}
					/>
				);
		}
	}
}

SignUp.propTypes = {
	onModeChange: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
};

class SignIn extends React.Component {
	static contextType = AppContext;

	state = {
		mode: 'all',
		email: '',
	};

	componentDidMount() {
		const { tracking } = this.context;
		tracking.pageView(window.location.pathname);
	}

	onModeChange = (mode) => {
		this.setState({ mode });
	};

	onClose = () => {
		const { history } = this.props;
		history.goBack();
	};

	onSubmitEmail = (email) => {
		const { api } = this.context;
		api.authenticationRequest(email)
			.then(() => {
				this.setState({ email, mode: 'code' });
			})
			.catch((e) => alert(e.message));
	};

	onSuccessCodeValidation = () => {
		document.location.href = '/';
		/*const { history } = this.props;
		history.goBack();*/
	};

	render() {
		const { onModeChange } = this.props;
		const { mode, email } = this.state;
		const { isOnline, api, locale } = this.context;

		if (!isOnline) {
			return <Offline />;
		}

		switch (mode) {
			case 'email':
				return (
					<AuthenticateEmailOption
						onClose={this.onClose}
						onSubmit={this.onSubmitEmail}
						onModeChange={this.onModeChange}
						title={locale['SIGN_IN_WITH_EMAIL']}
						backTitle={locale['SIGN_IN_WITH_EMAIL_BACK_TITLE']}
					/>
				);
			case 'code':
				return (
					<AuthenticateCode
						email={email}
						title={locale['SIGN_IN_WITH_EMAIL_CHECK_INBOX']}
						onSuccess={this.onSuccessCodeValidation}
					/>
				);
			default:
				return (
					<AllSignInOptions
						api={api}
						goToSignUp={() => {
							onModeChange('sign-up');
						}}
						onSuccess={this.onSuccessCodeValidation}
						onClose={this.onClose}
						onModeChange={this.onModeChange}
					/>
				);
		}
	}
}

SignIn.propTypes = {
	onModeChange: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
};

class AllSignUpOptions extends React.Component {
	static contextType = AppContext;

	onSuccessRegistration = (email, password) => {
		this.setState({
			email: email,
			password: password,
			mode: 'activate',
		});
	};

	authenticate = (provider) => {
		const { host, appKey, tracking } = this.context;

		if (provider !== 'apple' && provider !== 'google') {
			return;
		}

		if (provider === 'apple' || provider === 'google') {
			tracking.event({
				category: 'User',
				event: 'sign_up',
				value: `Sign up with ${provider}`,
				label: `Sign up with ${provider}`,
			});
		}

		const url = `${host}/auth/${provider}?app_key=${appKey}`;

		this.popup = window.open(
			url,
			`${provider}_window`,
			'width=500,height=800'
		);
		window.addEventListener('message', this.updateAuthInfo);
	};

	updateAuthInfo = (e) => {
		const { api, onSuccess } = this.props;
		const { token, expiredAt } = e.data;
		if (token) {
			localStorage.setItem('token', token);
			localStorage.setItem('expiredAt', expiredAt);
			this.popup.close();

			api.me()
				.then((response) => {
					const { me } = response;
					localStorage.setItem('me', JSON.stringify(me));
					onSuccess();
				})
				.catch((e) => {
					console.error(e);
					alert(e.message.replace('GraphQL error: ', ''));
				});
		}
	};

	onClose = () => {
		this.props.onClose();
	};

	render() {
		const { onModeChange, goToSignIn } = this.props;

		const { auth, locale } = this.context;

		return (
			<form className={styles['authenticate']} onSubmit={this.onSubmit}>
				<CloseButton onClick={this.onClose} />
				<img src={imgLogo} alt={'logo'} />
				<div className={styles['header']}>
					<h2>{locale['SIGN_UP_HEADER']}</h2>
				</div>
				<div className={styles['subheader']}>
					<h4>{locale['SIGN_UP_SUBHEADER']}</h4>
				</div>
				{!!auth && auth.google === false ? null : (
					<AuthButton
						text={locale['SIGN_UP_WITH_GOOGLE']}
						icon={googleIcon}
						onClick={() => this.authenticate('google')}
					/>
				)}
				{!!auth && auth.apple === false ? null : (
					<AuthButton
						text={locale['SIGN_UP_WITH_APPLE']}
						icon={appleIcon}
						onClick={() => this.authenticate('apple')}
					/>
				)}
				{!!auth && auth.email === false ? null : (
					<AuthButton
						text={locale['SIGN_UP_WITH_EMAIL']}
						icon={emailIcon}
						onClick={() => {
							onModeChange('email');
						}}
					/>
				)}

				<div className={styles['message']}>
					{locale['AUTHENTICATE_SIGN_UP_MESSAGE']}&nbsp;
					<b
						onClick={() => {
							goToSignIn();
						}}
						style={{ cursor: 'pointer' }}
					>
						{locale['AUTHENTICATE_SIGN_IN']}
					</b>
				</div>
			</form>
		);
	}
}

AllSignUpOptions.propTypes = {
	onClose: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	api: PropTypes.object.isRequired,
	goToSignIn: PropTypes.func.isRequired,
	onModeChange: PropTypes.func.isRequired,
};

class AllSignInOptions extends React.Component {
	static contextType = AppContext;

	onSuccessRegistration = (email, password) => {
		this.setState({
			email: email,
			password: password,
			mode: 'activate',
		});
	};

	authenticate = (provider) => {
		const { host, appKey, tracking } = this.context;

		if (provider !== 'apple' && provider !== 'google') {
			return;
		}

		if (tracking) {
			if (provider === 'apple' || provider === 'google') {
				tracking.event({
					category: 'User',
					event: 'authenticate',
					value: `Authenticate with ${provider}`,
					label: `Authenticate with ${provider}`,
				});
			}
		}

		const url = `${host}/auth/${provider}?app_key=${appKey}`;

		this.popup = window.open(
			url,
			`${provider}_window`,
			'width=500,height=800'
		);
		window.addEventListener('message', this.updateAuthInfo);
	};

	updateAuthInfo = (e) => {
		const { api, onSuccess } = this.props;
		const { token, expiredAt } = e.data;

		if (token) {
			localStorage.setItem('token', token);
			localStorage.setItem('expiredAt', expiredAt);
			this.popup.close();

			api.me()
				.then((response) => {
					const { me } = response;
					localStorage.setItem('me', JSON.stringify(me));
					onSuccess();
				})
				.catch((e) => {
					console.error(e);
					alert(e.message.replace('GraphQL error: ', ''));
				});
		}
	};

	render() {
		const { onModeChange, onClose, goToSignUp } = this.props;

		const { auth, locale } = this.context;

		return (
			<form className={styles['authenticate']} onSubmit={this.onSubmit}>
				<CloseButton onClick={onClose} />
				<img src={imgLogo} alt={'logo'} />
				<div className={styles['header']}>
					<h2>{locale['SIGN_IN_HEADER']}</h2>
				</div>
				<div className={styles['subheader']}>
					<h4>{locale['SIGN_IN_SUBHEADER']}</h4>
				</div>
				{!!auth && auth.google === false ? null : (
					<AuthButton
						text={locale['SIGN_IN_WITH_GOOGLE']}
						icon={googleIcon}
						onClick={() => this.authenticate('google')}
					/>
				)}
				{!!auth && auth.apple === false ? null : (
					<AuthButton
						text={locale['SIGN_IN_WITH_APPLE']}
						icon={appleIcon}
						onClick={() => this.authenticate('apple')}
					/>
				)}
				{!!auth && auth.email === false ? null : (
					<AuthButton
						text={locale['SIGN_IN_WITH_EMAIL']}
						icon={emailIcon}
						onClick={() => {
							onModeChange('email');
						}}
					/>
				)}

				<div className={styles['message']}>
					{locale['AUTHENTICATE_SIGN_IN_MESSAGE']}&nbsp;
					<b
						onClick={() => goToSignUp()}
						style={{ cursor: 'pointer' }}
					>
						{locale['AUTHENTICATE_SIGN_IN_CREATE_ONE']}
					</b>
				</div>
			</form>
		);
	}
}

AllSignInOptions.propTypes = {
	onClose: PropTypes.func.isRequired,
	onSuccess: PropTypes.func.isRequired,
	api: PropTypes.object.isRequired,
	goToSignUp: PropTypes.func.isRequired,
	onModeChange: PropTypes.func.isRequired,
};

class AuthenticateEmailOption extends React.Component {
	static contextType = AppContext;

	state = {
		email: '',
		isInvalid: false,
		invalidMessage: '',
	};

	onEmailChange = (e) => {
		this.setState({ email: e.target.value });
	};

	onSend = (e) => {
		e.preventDefault();
		const { onSubmit } = this.props;
		const { email } = this.state;
		const { locale } = this.context;
		if (!email) {
			this.setState({
				isInvalid: true,
				invalidMessage: locale['AUTHENTICATE_EMAIL_NOT_EMPTY_ERROR'],
			});
			return;
		}

		if (!/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/gi.test(email)) {
			this.setState({
				isInvalid: true,
				invalidMessage: locale['AUTHENTICATE_EMAIL_INVALID'],
			});
			return;
		}

		onSubmit(email);
	};

	onEmailFocus = () => {
		this.setState({ isInvalid: false, invalidMessage: '' });
	};

	render() {
		const { email, isInvalid, invalidMessage } = this.state;
		const { onModeChange, onClose, title, backTitle } = this.props;
		const { theme, locale } = this.context;

		const primaryButtonStyle = {
			background: '#000',
			color: '#fff',
		};
		if (theme.buttons) {
			if (theme.buttons.primary) {
				if (theme.buttons.primary.color) {
					primaryButtonStyle.color = theme.buttons.primary.color;
				}
				if (theme.buttons.primary.background) {
					primaryButtonStyle.background =
						theme.buttons.primary.background;
				}
			}
		}

		return (
			<form
				className={styles['authenticate-email']}
				onSubmit={this.onSend}
			>
				<CloseButton onClick={onClose} />
				<img src={imgLogo} alt={'logo'} />
				<h2>{title}</h2>
				<label>{locale['AUTHENTICATE_YOUR_EMAIL']}</label>
				<input
					type="email"
					onFocus={this.onEmailFocus}
					onChange={this.onEmailChange}
					value={email}
					className={`${isInvalid ? 'invalid' : ''}`}
				/>
				{isInvalid && !!invalidMessage ? (
					<small>{invalidMessage}</small>
				) : null}
				<button type="submit" style={primaryButtonStyle}>
					{locale['CONTINUE']}
				</button>
				<div
					className={styles['back']}
					onClick={() => {
						onModeChange('all');
					}}
				>
					&lt; {backTitle}
				</div>
			</form>
		);
	}
}

AuthenticateEmailOption.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	onModeChange: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	api: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired,
	backTitle: PropTypes.string.isRequired,
};

class RegisterEmailOption extends React.Component {
	static contextType = AppContext;

	state = {
		email: '',
		isInvalid: false,
		invalidMessage: '',
		firstName: '',
		lastName: '',
	};

	onEmailChange = (e) => {
		this.setState({ email: e.target.value });
	};

	onSend = (e) => {
		e.preventDefault();
		const { onSubmit } = this.props;
		const { email, firstName, lastName } = this.state;
		const { locale } = this.context;
		if (!email) {
			this.setState({
				isInvalid: true,
				invalidMessage: locale['AUTHENTICATE_EMAIL_NOT_EMPTY_ERROR'],
			});
			return;
		}

		if (!/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/gi.test(email)) {
			this.setState({
				isInvalid: true,
				invalidMessage: locale['AUTHENTICATE_EMAIL_INVALID'],
			});
			return;
		}

		onSubmit(email, firstName, lastName);
	};

	isValidEmail = (email) => {
		return /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/gi.test(email);
	};

	onEmailFocus = () => {
		this.setState({ isInvalid: false, invalidMessage: '' });
	};

	render() {
		const { email, isInvalid, invalidMessage, firstName, lastName } =
			this.state;
		const { onModeChange, onClose, title, backTitle } = this.props;
		const { theme, locale } = this.context;

		const primaryButtonStyle = {
			background: '#000',
			color: '#fff',
		};
		if (theme.buttons) {
			if (theme.buttons.primary) {
				if (theme.buttons.primary.color) {
					primaryButtonStyle.color = theme.buttons.primary.color;
				}
				if (theme.buttons.primary.background) {
					primaryButtonStyle.background =
						theme.buttons.primary.background;
				}
			}
		}

		return (
			<form
				className={
					styles['authenticate-email'] + ' ' + styles['sign-up']
				}
				onSubmit={this.onSend}
			>
				<CloseButton onClick={onClose} />
				<img src={imgLogo} alt={'logo'} />
				<h2>{title}</h2>
				<input
					type="text"
					pattern="^[a-zA-Z ]+$"
					placeholder={locale['AUTHENTICATE_FIRST_NAME']}
					onChange={(e) => {
						this.setState({ firstName: e.target.value });
					}}
					value={firstName}
					className={
						!firstName
							? null
							: /^[a-zA-Z ]+$/.test(firstName)
							? null
							: styles['invalid']
					}
					required={true}
				/>
				<input
					type="text"
					pattern="^[a-zA-Z ]+$"
					placeholder={locale['AUTHENTICATE_LAST_NAME']}
					onChange={(e) => {
						this.setState({ lastName: e.target.value });
					}}
					value={lastName}
					className={
						!lastName
							? null
							: /^[a-zA-Z ]+$/.test(lastName)
							? null
							: styles['invalid']
					}
					required={true}
				/>
				<input
					type="email"
					placeholder={locale['AUTHENTICATE_EMAIL']}
					onFocus={this.onEmailFocus}
					onChange={this.onEmailChange}
					value={email}
					className={`${isInvalid ? 'invalid' : ''}`}
				/>
				{isInvalid && !!invalidMessage ? (
					<small>{invalidMessage}</small>
				) : null}
				<button
					type="submit"
					style={primaryButtonStyle}
					disabled={
						!firstName ||
						!/^[a-zA-Z ]+$/.test(firstName) ||
						!lastName ||
						!/^[a-zA-Z ]+$/.test(lastName) ||
						!email ||
						!this.isValidEmail(email)
					}
				>
					{locale['CONTINUE']}
				</button>
				<div
					className={styles['back']}
					onClick={() => {
						onModeChange('all');
					}}
				>
					&lt; {backTitle}
				</div>
			</form>
		);
	}
}

RegisterEmailOption.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	onModeChange: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	api: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired,
	backTitle: PropTypes.string.isRequired,
};

class AuthenticateCode extends React.Component {
	static contextType = AppContext;

	state = {
		code: '',
		isInvalid: false,
		invalidMessage: '',
	};

	onCodeChange = (e) => {
		this.setState({ code: e.target.value });
	};

	onSend = (e) => {
		e.preventDefault();
		const { api } = this.context;
		const { code } = this.state;
		const { onSuccess, email, firstName, lastName } = this.props;

		api.authenticateCodeValidation(email, code, firstName, lastName)
			.then(({ token, expiredAt }) => {
				localStorage.setItem('token', token);
				localStorage.setItem('expiredAt', expiredAt);
				return api.me();
			})
			.then((response) => {
				const { me } = response;
				localStorage.setItem('me', JSON.stringify(me));
				onSuccess();
			})
			.catch((e) => {
				this.setState({
					isInvalid: true,
					invalidMessage: e.message.replace('GraphQL error: ', ''),
				});
			});
	};

	render() {
		const { code, isInvalid, invalidMessage } = this.state;
		const { onClose, title, email } = this.props;
		const { theme, locale } = this.context;

		const primaryButtonStyle = {
			background: '#000',
			color: '#fff',
		};
		if (theme.buttons) {
			if (theme.buttons.primary) {
				if (theme.buttons.primary.color) {
					primaryButtonStyle.color = theme.buttons.primary.color;
				}
				if (theme.buttons.primary.background) {
					primaryButtonStyle.background =
						theme.buttons.primary.background;
				}
			}
		}

		return (
			<form
				className={styles['authenticate-email']}
				onSubmit={this.onSend}
			>
				<CloseButton onClick={onClose} />
				<img src={imgLogo} alt={'logo'} />
				<h2>{title}</h2>
				<div className={styles['subheader']}>
					<p>
						{locale['AUTHENTICATE_EMAIL_DIGIT_MESSAGE']}&nbsp;
						<b>{email}</b>
					</p>
					<p>{locale['AUTHENTICATE_EMAIL_DIGIT_MESSAGE_SUBMIT']}</p>
				</div>
				<input
					type="number"
					size="6"
					onChange={this.onCodeChange}
					value={code}
					className={`${isInvalid ? styles['invalid'] : ''}`}
				/>
				{isInvalid && !!invalidMessage ? (
					<small>{invalidMessage}</small>
				) : null}
				<button type="submit" style={primaryButtonStyle}>
					{locale['OK']}
				</button>
			</form>
		);
	}
}

AuthenticateCode.propTypes = {
	onSuccess: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	email: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	firstName: PropTypes.string,
	lastName: PropTypes.string,
};

function Offline() {
	const { isOnline, theme, locale } = useContext(AppContext);
	return (
		<section>
			<Navbar isOnline={isOnline} theme={theme.navbar} />
			<div className={styles['content']}>
				<div style={{ color: 'red' }}>
					{locale['AUTHENTICATE_OFFLINE_ERROR']}
				</div>
			</div>
		</section>
	);
}

function AuthButton({ text, icon, onClick }) {
	return (
		<div className={styles['provider-btn']} onClick={onClick}>
			<div>
				<img alt={text} src={icon} />
			</div>
			<p className={styles['btn-text']}>
				<b>{text}</b>
			</p>
		</div>
	);
}

AuthButton.propTypes = {
	onClick: PropTypes.func.isRequired,
	icon: PropTypes.any.isRequired,
	text: PropTypes.string.isRequired,
};

function CloseButton({ onClick }) {
	return (
		<div className={styles['close-btn']}>
			<img alt={'Close'} src={xIcon} onClick={onClick} />
		</div>
	);
}

CloseButton.propTypes = {
	onClick: PropTypes.func.isRequired,
};
