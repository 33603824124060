/* eslint-disable react/no-string-refs */
/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Howl } from 'howler';

import styles from './Issue.module.css';
import { getRelevance } from '../utils/ArticleRelevance';
import Navbar from '../components/navbar/Navbar';
import Fontbar from '../components/navbar/Fontbar';
import { Loading } from '../components/loading/Loading';
import EmptyState from '../components/empty/EmptyState';
import { FontSizeSlider } from '../components/bottombar/ArticleSettings';
import { AppContext } from '../services/AppContext';
import { overWriteStyleProperties } from '../services/Api';

// import ImageMode from './issue/image/ImageMode';
import ReplicaMode from './issue/replica/ReplicaMode';
import ResponsiveMode from './issue/responsive/ResponsiveMode';
import JSXStyle from 'styled-jsx/style';

export default class Issue extends React.Component {
	static contextType = AppContext;

	sound = null;
	soundInterval = null;

	state = {
		currentPage: 0,
		page: 0,
		articles: null,
		article: null,
		lang: localStorage.getItem('language'),
		languages: [],
		dragEnabled: true,
		isIndexOpen: false,
		isReaderMode: false,
		isImageMode: false,
		isImageModeCapable: false,
		isFavorite: false,
		isFontOpen: false,
		fontSize: 0,
		isArticleSettingsDisplay: true,
		imageModalSrc: null,
		isDisplayImageModal: false,
		isLoading: true,
		redirect: null,
		disableSwipe: false,
		downloadInterval: null,
		hasAudio: false,
		audioUrl: null,
		isPlayerOpen: false,
		soundPercent: null,
		isPlaying: false,
		SoundID: null,
		isIssueDownloaded: null,
		componentStyles: null,
		imageModePages: null,
		imageModePage: null,
		doubleImageModePages: null,
		doubleImageMode: null,
		deviceType: this.context.deviceType,
		issue: null,
		pagesImagesMap: new Map(),
		articleIDsMap: new Map(),
		isLanguageOpen: false,
		results: null,
		search: '',
	};

	componentDidMount() {
		const { deviceType, config } = this.context;
		const { location } = this.props;
		this.onLoad()
			.then(() => {
				if (location?.hash) {
					window.location.hash = location?.hash;
				}

				if (localStorage.getItem('last-mode')) {
					if (localStorage.getItem('last-mode') === 'responsive') {
						this.setState({
							isImageMode: false,
						});
					} else {
						this.setState({
							isImageMode: true,
						});
					}
				} else {
					if (config?.contentType === 'responsive-replica') {
						if (config?.defaultContentType === 'auto') {
							if (deviceType === 'mobile') {
								this.setState({
									isImageMode: false,
								});
							} else {
								this.setState({
									isImageMode: true,
								});
							}
						} else {
							const isReplica =
								config?.defaultContentType === 'replica';
							this.setState({
								isImageMode: isReplica,
							});
						}
					}
				}
			})
			.catch(console.error);
	}

	onLoad = async () => {
		const { imageMode, device, tracking, subdirectory, config } =
			this.context;
		const { articleID, id } = this.props.match.params;
		let article = null;
		let articles = [];
		let imageModePages = [];
		let imageModePage = null;
		let doubleImageModePages = [];
		let doubleImageModePage = null;
		let page;

		this.setState({
			deviceType: device.type,
		});
		document.addEventListener('keydown', this.onKeydown, false);
		const onResize = () => {
			this.context.onResize();
		};
		window.addEventListener('orientationchange', onResize);
		window.addEventListener('resize', onResize);

		console.info(`Fetching issue`);
		const issue = await this.getIssue();
		this.validateIssueSubscription(issue);
		console.log(`Issue`);
		console.log(issue);
		this.setState({ issue });
		/*console.info(`Fetching articles`)
		articles = await this.getArticles(issue);*/
		this.shouldRedirect(issue);
		if (tracking) {
			tracking.event({
				category: 'User',
				event: 'load_issue',
				label: 'Load Issue',
				value: id,
				location: `/issues/${id}`,
			});
		}

		articles = issue.articles;

		if (articles) {
			articles = articles.map(this.getArticleLanguages);
		}

		let articleIDsMap = new Map();
		if (articles) {
			articleIDsMap = this.getArticlesIDsMap(articles);
			this.setState({ articleIDsMap });
		}

		articles = articles
			.filter((article) => article?.channel?.visibility !== 'hidden')
			.map((article, i) => {
				article.index = i;
				return article;
			});

		if (
			articles &&
			issue?.pages?.length &&
			imageMode?.enabled &&
			config?.contentType !== 'responsive'
		) {
			const pagesImagesMap = this.getPagesImagesMap(articles);
			this.setState({ pagesImagesMap });

			doubleImageModePages = this.getDoublePageImages(
				issue.pages.reduce(this.imageModeReducer, [])
			);
			imageModePages = issue.pages.reduce(this.imageModeReducer, []);
			imageModePage = imageModePages[0];
			doubleImageModePage = doubleImageModePages[0];
			this.setState({
				doubleImageModePages,
				doubleImageModePage,
				imageModePage,
				imageModePages,
			});
		}
		let articleId = null;
		if (articles.length) {
			page = articleID
				? this.getArticleIndexByID(articles, articleID)
				: 0;
			article = articles[page];
			if (!articleID) {
				articleId = article.slug ? article.slug : article.id;
			} else {
				articleId = isNaN(articleID) ? article.slug : article.id;
			}

			let { search } = window.location;
			let url = `/issues/${this.getID()}/${articleId}`;
			if (subdirectory) {
				url = subdirectory.concat(url);
			}
			if (search) {
				url += search;
			}
			localStorage.setItem('last-path', url);
			window.history.pushState({}, null, url);
		}

		const isFavorite = await this.isArticleFavorite(id);
		this.setState({
			isFavorite,
			articles,
			article,
			page,
			issue,
			currentPage: page,
			fontSize: this.getFontSize(),
			isIssueDownloaded: this.isIssueDownloaded(id),
		});

		this.autoDownloadImageInBackground(articles);
		this.loadAudioVersion(article);

		const componentStyles = await this.loadStyles();

		this.setState({
			isLoading: false,
			componentStyles,
		});

		if (articles.filter((a) => a.pages && a.pages.length).length) {
			this.setState({
				isImageMode: true,
				isImageModeCapable: true,
				isFontOpen: false,
				isReaderMode: false,
				imageModePage,
				imageModePages,
			});
		}
	};

	validateIssueSubscription(issue) {
		const { config } = this.context;
		if (!issue) {
			window.location.href = '/';
			return;
		}
		const key = 'subscription-expiration-date';
		// const key = 'subscription-expiration-date';
		if (
			!config.subscriptionExpirationDate ||
			!config.subscriptionExpirationDate.enabled
		) {
			return;
		}

		const salt = config.subscriptionExpirationDate.salt || 1;
		const params = new URLSearchParams(this.props.location.search);
		const expirationParam = params.get('e');

		if (
			!localStorage.getItem(key) ||
			(localStorage.getItem(key) && expirationParam)
		) {
			/* 
				If the expiration date config is enabled but the 
				param is not sent, then return empty
			*/
			if (!expirationParam) {
				window.location.href = '/';
				return;
			}
			let year, month, day;
			const expirationDateValue = `${parseInt(expirationParam) / salt}`;
			year = expirationDateValue.substring(0, 4);
			month = expirationDateValue.substring(4, 6);
			day = expirationDateValue.substring(6, 8);

			params.delete('e');

			localStorage.setItem(key, `${year}/${month}/${day}`);

			this.props.history.push({
				pathname: window.location.pathname,
				search: `?${params.toString()}`,
			});
		}

		const expirationDate = new Date(localStorage.getItem(key));

		if (
			moment(new Date(issue.releaseDate)).isAfter(
				moment(expirationDate),
				'day'
			)
		) {
			window.location.href = '/';
			return;
		}
	}

	getIssue = async () => {
		const { history } = this.props;
		let { search } = window.location;
		const { isOnline, db, saveArticles } = this.context;
		const id = this.getID();
		const isSlug = isNaN(id);

		if (isOnline) {
			console.info(`Fetching issue '${id}' from remote`);
			const issue = await this.getIssueFromRemote();
			if (!issue) {
				history.replace(search ? `/${search}` : '/');
				return;
			}

			try {
				console.debug(`Store issue '${id}' in local cache`);
				await db.updateIssue(issue);
			} catch (e) {
				console.error(e);
			}

			if (!localStorage.getItem('internal-article-map')) {
				localStorage.setItem('internal-article-map', '{}');
			}

			const internalArticleMap = JSON.parse(
				localStorage.getItem('internal-article-map')
			);

			let { articles } = issue;

			articles = articles.map((article, i) => {
				const { id, ArticleID } = article;
				internalArticleMap[ArticleID] = {
					id,
					issue: this.getID(),
				};
				article.index = i;
				return article;
			});

			localStorage.setItem(
				'internal-article-map',
				JSON.stringify(internalArticleMap)
			);

			try {
				console.debug('Store articles in local cache');
				saveArticles(articles, id);
				// console.debug(articles);
				// await db.saveArticles(articles, id);
			} catch (e) {
				console.error(e);
			}

			return issue;
		}

		console.info(`Fetch issue '${id}' from cache`);
		const issue = await db.getIssue(id, isSlug);

		console.debug('Fetch articles from cache');
		issue.articles = await db.getArticlesByIssue(id);

		return issue;
	};

	onSearch = (search) => {
		const { articles, lang, articleIDsMap } = this.state;
		if (search.length < 3) {
			this.setState({ results: null, search });
			return;
		}

		const results = getRelevance(
			search,
			[...articles].map((a) => {
				return { ...a };
			}),
			lang
		).map((article) => articleIDsMap.get(article.ArticleID));

		this.setState({ results, search });
	};

	onScaleChange = (scale) => {
		const { disableSwipe } = this.state;
		if (scale > 1) {
			if (!disableSwipe) {
				this.setState({ disableSwipe: true });
			}
		} else {
			if (disableSwipe) {
				this.setState({ disableSwipe: false });
			}
		}
	};

	imageModeReducer = (acc, page) => {
		const { articleIDsMap, pagesImagesMap } = this.state;
		const { articles } = page;

		if (!articles?.length) {
			return acc;
		}

		const article = articleIDsMap.get(`${articles[0]}`);
		if (article) {
			const { id } = article;
			acc.push({
				image: pagesImagesMap.get(page.page),
				index: page.index,
				ArticleID: id,
				article: article,
				articleIndex: article.index,
			});
		}

		return acc;
	};

	getPagesImagesMap = (articles) => {
		const { pagesImagesMap } = this.state;
		for (const article of articles) {
			const { pages } = article;
			if (!pages) {
				continue;
			}

			for (const pageUrl of pages) {
				const pageImgName = pageUrl.substr(
					pageUrl.lastIndexOf('/') + 1
				);
				if (pageImgName) {
					pagesImagesMap.set(pageImgName, pageUrl);
				}
			}
		}

		return pagesImagesMap;
	};

	getArticlesIDsMap = (articles) => {
		const { articleIDsMap } = this.state;
		for (const article of articles) {
			const { ArticleID } = article;
			articleIDsMap.set(`${ArticleID}`, article);
		}

		return articleIDsMap;
	};

	getDoublePageImages(pages) {
		const doublePageImages = [];
		for (let i = 0; i < pages.length; i++) {
			const page = pages[i];
			const { ArticleID, article, articleIndex, image } = page;
			const nextPage = pages[i + 1];
			if (i === 0) {
				doublePageImages.push({
					ArticleID,
					article,
					articles: [ArticleID],
					articleIndex,
					images: [image],
					index: doublePageImages.length,
				});
				continue;
			}

			if (nextPage) {
				doublePageImages.push({
					ArticleID,
					article,
					articleIndex,
					articles: [ArticleID, nextPage.ArticleID],
					images: [image, nextPage.image],
					index: doublePageImages.length,
				});
				i++;
			} else {
				doublePageImages.push({
					ArticleID,
					article,
					articleIndex,
					articles: [ArticleID],
					images: [image],
					index: doublePageImages.length,
				});
			}
		}
		return doublePageImages;
	}

	componentDidUpdate(prevProps, prevState) {
		let {
			isImageMode,
			article,
			imageModePages,
			doubleImageModePages,
			articles,
			deviceType,
		} = this.state;
		// Is in image mode and the device type change
		if (prevState.deviceType !== deviceType && isImageMode) {
			// Comming from double to single mode image
			if (deviceType === 'mobile') {
				for (let i = 0; i < imageModePages.length; i++) {
					if (imageModePages[i].ArticleID === article.id) {
						this.setState({
							page: i,
						});
						break;
					}
				}
			} else {
				// Comming from single image mode to single
				for (let i = 0; i < doubleImageModePages.length; i++) {
					if (doubleImageModePages[i].articles.includes(article.id)) {
						this.setState({
							page: i,
						});
						break;
					}
				}
			}
		}

		// Is a switch between image modes
		if (prevState.isImageMode !== isImageMode) {
			// Is comming from responsive mode to image mode
			if (prevState.isImageMode === false && isImageMode === true) {
				if (deviceType === 'mobile') {
					if (imageModePages) {
						for (let i = 0; i < imageModePages.length; i++) {
							if (imageModePages[i].ArticleID === article.id) {
								this.setState({
									page: i,
									currentPage: i,
								});
								break;
							}
						}
					}
				} else {
					if (doubleImageModePages) {
						for (let i = 0; i < doubleImageModePages.length; i++) {
							if (
								doubleImageModePages[i].articles.includes(
									article.id
								)
							) {
								const newArticle = this.getArticleByID(
									doubleImageModePages[i].ArticleID
								);
								this.setState({
									page: i,
									currentPage: i,
									article: newArticle,
								});
								break;
							}
						}
					}
				}
			}

			// Is comming from image mode to responsive mode
			if (prevState.isImageMode === true && isImageMode === false) {
				for (let i = 0; i < articles.length; i++) {
					if (articles[i].id === article.id) {
						this.setState({
							page: i,
							currentPage: i,
						});
						break;
					}
				}
			}
		}
	}

	getArticleByID = (id) => {
		const { articles } = this.state;
		for (const article of articles) {
			if (article.id === id) {
				return article;
			}
		}

		return null;
	};

	loadStyles = async () => {
		const { isOnline, api, db } = this.context;
		let styles = [];
		if (!isOnline) {
			try {
				console.debug(`Fetching styles from remote`);
				styles = await api.getStyles();
				styles = this.processStyles(styles);
				await db.saveStyles(styles);
				console.debug(`Success storing the styles in the cache`);
			} catch (e) {
				console.error(e);
			}
		}

		try {
			console.debug(`Fetching styles from cache`);
			styles = await db.getStyles();
			return styles.reduce((acc, style) => {
				acc[`${style.id}`] = style;
				return acc;
			}, {});
		} catch (e) {
			console.error(e);
		}

		return null;
	};

	processStyles = (styles) => {
		const response = [];
		const styleMap = new Map();

		let i = 0;
		for (const style of styles) {
			const { id } = style;
			style.index = i;
			styleMap.set(id, style);
			i++;
		}

		for (let [, style] of styleMap) {
			const { parent, tablet, desktop } = style;
			if (tablet && styleMap.get(tablet)) {
				const tabletStyle = styleMap.get(tablet);
				style.tablet = tabletStyle;
			}
			if (desktop && styleMap.get(desktop)) {
				const desktopStyle = styleMap.get(desktop);
				style.desktop = desktopStyle;
			}
			if (!parent) {
				response.push(style);
				continue;
			}

			response.push(this.mergeStyleWithParent(style, styleMap));
		}

		return response.sort((a, b) => a.index - b.index);
	};

	// Merge all the properties in a single property value
	mergeStyleWithParent = (style, styleMap) => {
		const originalStyle = { ...style };
		let nodes = [style.id];
		do {
			const { parent } = style;
			// 1. Checks that the parent has a value
			// 2. Checks that the parent exist in the map
			// 3. Checks that node is not in the tree (Avoid cyclic dependencies)
			if (parent && styleMap.get(parent) && !nodes.includes(parent)) {
				nodes.push(parent);
				style = styleMap.get(parent);
			}
			break;
			// eslint-disable-next-line no-constant-condition
		} while (true);
		const styleProperties = {};
		nodes = nodes.reverse().map((id) => styleMap.get(id));
		for (const { properties } of nodes) {
			for (const property in properties) {
				// The property didn't exist so i just assign it
				if (styleProperties[property] === undefined) {
					styleProperties[property] = properties[property];
					continue;
				}
				styleProperties[property] = {
					...styleProperties[property],
					...properties[property],
				};
			}
		}
		originalStyle.parent = null;
		originalStyle.properties = styleProperties;
		return originalStyle;
	};

	loadAudioVersion = (article, lang) => {
		const { tts } = this.context;
		if (!article) {
			return null;
		}

		// TTS is disabled
		if (!tts?.enabled) {
			return null;
		}

		const { audio } = article;
		if (!lang) {
			lang = this.state.lang;
		}
		const language = lang || localStorage.getItem('language');
		let interval;

		if (this.sound) {
			if (interval) {
				clearInterval(interval);
			}
			this.sound.unload();
			this.setState({
				SoundID: null,
				hasAudio: false,
				isPlayerOpen: false,
				isPlaying: false,
			});
		}

		if (!audio) {
			return;
		}

		let totalDuration;
		let currentDuration;

		const audioUrl = `${audio}/${language}.mp3`;

		const sound = new Howl({
			src: [audioUrl],
			volume: 0.7,
			html5: true,
			onload: () => {
				totalDuration = (sound.duration() * 1000) | 0;
				console.info(`Total of duration: ${totalDuration}ms`);

				this.setState({
					isPlaying: false,
					isPlayerOpen: false,
					hasAudio: true,
					soundTime: totalDuration / 1000,
				});
			},
			onloaderror: () => {
				console.error(`Could not load: ${audioUrl}`);
				this.setState({
					hasAudio: false,
					soundPercent: 0,
					isPlayerOpen: false,
					isPlaying: false,
				});
			},
			onpause: () => {
				if (interval) {
					clearInterval(interval);
					interval = null;
				}
			},
			onplay: (id) => {
				if (interval) {
					clearInterval(interval);
					interval = null;
				}
				interval = setInterval(() => {
					if (!!sound && sound.state() === 'loaded') {
						currentDuration = (sound.seek() * 1000) | 0;
						const percent = (
							(100 * currentDuration) /
							totalDuration
						).toFixed(0);

						const soundTime = sound.duration() - sound.seek();

						this.setState({
							soundPercent: percent,
							soundTime,
						});
					}
				}, 1000);
				this.setState({
					SoundID: id,
				});
			},
			onend: () => {
				sound.stop();
				this.setState({
					soundPercent: 0,
					soundTime: 0,
					isPlaying: false,
					SoundID: null,
				});
				if (interval) {
					clearInterval(interval);
					interval = null;
				}
			},
		});

		this.sound = sound;
	};

	getArticleIndexByID(articles, id) {
		const isSlug = isNaN(id);
		for (let i = 0; i < articles.length; i++) {
			const article = articles[i];
			if (isSlug) {
				if (`${article.slug}` === `${id}`) {
					return i;
				}
			} else {
				if (`${article.id}` === `${id}`) {
					return i;
				}
			}
		}
		return 0;
	}

	getFontSize = () => {
		if (!localStorage.getItem('fontSize')) {
			localStorage.setItem('fontSize', '62.5');
		}

		return parseFloat(localStorage.getItem('fontSize'));
	};

	goBack = () => {
		const { subdirectory } = this.context;
		window.location.href = `${subdirectory}/`;
	};

	onKeydown = (event) => {
		let { keyCode } = event;
		const { page, articles, isFontOpen, fontSize } = this.state;

		if (!articles || !articles.length) return;

		const lastPage = articles.length - 1;

		const LEFT_ARROW = 39;
		const RIGHT_ARROW = 37;
		const TOC = 73; // Letter I
		const READER_MODE = 82; // Letter R
		const SAVE = 83; // Letter S
		const FONT_SIZE = 84; // Letter T
		// const BACK = 8; // Letter T
		// console.log(keyCode);

		let newFontSize;
		keyCode = null;
		if (keyCode === LEFT_ARROW && page < lastPage) {
			if (isFontOpen) {
				if (fontSize < 100) {
					newFontSize = fontSize + 1;
					this.setState({ fontSize: newFontSize });
					this.onFontSizeChange(newFontSize);

					// Toggle the slider
					this.onIsFontClick();
					this.onIsFontClick();
				}
			} else {
				if (page < lastPage) {
					this.onChangePage({ page: page + 1 });
				}
			}
		} else if (keyCode === RIGHT_ARROW) {
			if (isFontOpen) {
				if (fontSize > 5) {
					newFontSize = fontSize - 1;
					this.setState({ fontSize: newFontSize });
					this.onFontSizeChange(newFontSize);

					// Toggle the slider
					this.onIsFontClick();
					this.onIsFontClick();
				}
			} else {
				if (page > 0) {
					this.onChangePage({ page: page - 1 });
				}
			}
		} else if (keyCode === TOC) {
			// this.onIndexClick();
		} else if (keyCode === READER_MODE) {
			// this.onReaderModeClick();
		} else if (keyCode === SAVE) {
			// this.onIsFavoriteClick();
		} else if (keyCode === FONT_SIZE) {
			// this.onIsFontClick();
		}
	};

	componentWillUnmount() {
		const { downloadInterval } = this.state;
		if (this.sound) {
			console.log(this.sound);
			this.sound.stop();
		}
		if (this.soundInterval) {
			clearInterval(this.soundInterval);
			this.soundInterval = null;
		}

		window.removeEventListener('resize', this.context.onResize, false);
		document.removeEventListener('keydown', this.onKeydown, false);
		if (downloadInterval) {
			console.debug(`Clear download interval`);
			clearInterval(downloadInterval);
		}
	}

	autoDownloadImageInBackground = (articles) => {
		console.log(`Auto download images`);
		const { isOnline, autoDownloadImage } = this.context;

		if (isOnline && autoDownloadImage) {
			this.downloadImageInBackground(articles);
		}
	};

	onDownloadClick = () => {
		const { tracking } = this.context;
		const id = this.getID();
		const { articles, downloadInterval, isIssueDownloaded } = this.state;
		tracking.event({
			category: 'User',
			event: 'download_issue',
			label: 'Download Issue',
			value: id,
		});

		if (!isIssueDownloaded && downloadInterval === null) {
			console.debug(`Try to download images for the issue ${id}`);
			this.downloadImageInBackground(articles);
			const interval = setInterval(() => {
				if (this.isIssueDownloaded(id)) {
					console.debug(
						`I complete download the images for the issue ${id}`
					);
					this.setState({
						isIssueDownloaded: true,
						downloadInterval: null,
					});
					clearInterval(interval);
				}
			}, 2500);
			this.setState({ downloadInterval: interval });
		}
	};

	isIssueDownloaded = (id) => {
		const storageKey = 'downloaded-issues';
		if (!localStorage.getItem(storageKey)) {
			return false;
		}

		const downloadedIssues = JSON.parse(localStorage.getItem(storageKey));
		return !!downloadedIssues[`${id}`];
	};

	downloadImageInBackground = (articles) => {
		const { tracking, wb } = this.context;
		const { issue } = this.state;
		const { id } = issue;
		if (wb) {
			let images = new Set();
			for (let article of articles) {
				const articleImages = this.getImagesFromArticle(article);
				console.debug(
					`Article ${article.id} images ${articleImages.length}`
				);
				for (const image of articleImages) {
					images.add(image);
				}
			}

			images = [...images];

			console.info(`Send job to download ${images.length} images`);
			console.log(images);
			/*wb.messageSW({
				type: 'DOWNLOAD_BACKGROUND_IMAGES',
				payload: {
					images,
				},
			});*/
			wb.messageSW({
				type: 'DOWNLOAD_IMAGES',
				payload: {
					images,
					IssueID: id,
				},
			})
				.then((data) => {
					const { type, payload } = data;
					if (type === 'ERROR') {
						console.error(`Error downloading images`);
						console.error(payload.error);
					} else {
						console.debug(`Images downloaded successfully`);
						console.debug(data);
					}
				})
				.catch((err) => {
					console.error(`Error downloading images`);
					console.error(err);
				});
		}
	};

	isIssueCached = () => {
		try {
			const storageKey = 'downloaded-issues';
			const downloadedIssues = JSON.parse(
				localStorage.getItem(storageKey)
			);

			return !!downloadedIssues[`${this.getID()}`];
		} catch (e) {
			return false;
		}
	};

	getImagesFromArticle = (article) => {
		const images = [];
		for (let component of article.components) {
			if (component.imageurl) {
				images.push(component.imageurl.replace(/\\/g, '/'));
			}

			if (component.component === 'gallery') {
				for (let image of component.images) {
					images.push(image.imageurl.replace(/\\/g, '/'));
				}
			}

			if (component.component === 'columns') {
				for (const column of component.columns) {
					for (const c of column) {
						if (c.imageurl) {
							images.push(c.imageurl.replace(/\\/g, '/'));
						}

						if (c.component === 'gallery') {
							for (const image of c.images) {
								images.push(image.imageurl.replace(/\\/g, '/'));
							}
						}
					}
				}
			}
		}
		return images;
	};

	getID = () => {
		const { id } = this.props.match.params;
		return id;
	};

	isArticleFavorite = async (id) => {
		const { db } = this.context;
		if (id) {
			return await db.isFavorite(id);
		}
		return false;
	};

	shouldRedirect = (issue) => {
		const { isUserAdmin, subdirectory } = this.context;
		const { isPaid, isPurchased, id } = issue;
		if (!isUserAdmin) {
			this.hasSubscription().then((isSubscribed) => {
				if (isSubscribed) return;

				if (isPaid && !isPurchased) {
					let url = `/issues/${id}`;
					if (subdirectory) {
						url = subdirectory.concat(url);
					}

					this.setState({
						redirect: <Redirect to={url} />,
					});
					return;
				}
			});
		}
	};

	hasSubscription = async () => {
		const { api, isOnline } = this.context;
		if (isOnline) {
			const response = await api.getMySubscription();
			if (!!response && !!response.expiredAt) {
				const { expiredAt } = response;
				localStorage.setItem('subscription_expired_at', expiredAt);
				if (moment(new Date()).isBefore(new Date(expiredAt), 'day')) {
					return true;
				}
			}
			return false;
		}

		const subscriptionExpiredDate = localStorage.getItem(
			'subscription_expired_at'
		);

		if (!subscriptionExpiredDate) return false;

		return moment(
			new Date().isBefore(new Date(subscriptionExpiredDate), 'day')
		);
	};

	getIssueFromRemote = async () => {
		const { id } = this.props.match.params;
		const { api } = this.context;

		const issue = isNaN(id)
			? await api.getIssueBySlug(id)
			: await api.getIssueByID(id);

		return issue;
	};

	getArticlesFromRemote = async () => {
		const id = this.getID();
		const { api } = this.context;
		const issue = isNaN(id)
			? await api.getArticlesFromIssueSlug(id)
			: await api.getArticlesFromIssue(id);
		const { articles } = issue;

		return this.uniqueArticles(articles);
	};

	uniqueArticles = (articles) => {
		const articleMap = {};
		let articleOrder = [];
		const response = [];
		for (let article of articles) {
			articleMap[`${article.id}`] = article;
			articleOrder.push(article.id);
		}

		articleOrder = [...new Set(articleOrder)];
		for (let id of articleOrder) {
			response.push(articleMap[id]);
		}

		return response.filter((r) => !!r);
	};

	onIndexClick = () => {
		const { isIndexOpen } = this.state;
		this.setState({ isIndexOpen: !isIndexOpen });
	};

	onReaderModeClick = () => {
		const { isReaderMode } = this.state;
		if (!isReaderMode) {
			this.setState({
				isImageMode: false,
			});
		}
		this.setState({ isReaderMode: !isReaderMode });
	};

	onIsFavoriteClick = () => {
		const { db, tracking } = this.context;

		const { isFavorite, article } = this.state;
		if (!isFavorite) {
			db.saveFavorite(article.id).then(() => {
				tracking.event({
					category: 'User',
					event: 'bookmark_article',
					label: `Bookmark Article "${article.slug || article.id}"`,
					value: article.slug || article.id,
				});

				this.setState({ isFavorite: true });
			});
		} else {
			db.removeFavorite(article.id).then(() => {
				tracking.event({
					category: 'User',
					event: 'unbookmark_article',
					label: `Remove bookmark article "${
						article.slug || article.id
					}"`,
					value: article.slug || article.id,
				});

				this.setState({ isFavorite: false });
			});
		}
	};

	onIsFontClick = () => {
		const { isFontOpen } = this.state;
		this.setState({ isFontOpen: !isFontOpen });
	};

	onFontSizeChange = (fontSize) => {
		console.debug(`Font size: ${fontSize}`);
		localStorage.setItem('fontSize', `${fontSize}`);
		this.setState({ fontSize });
	};

	onBottombarDisplayChange = () => {
		this.setState({ isArticleSettingsDisplay: true });
	};

	onChangePage = ({ page }) => {
		const { subdirectory, tracking } = this.context;
		const { deviceType, lang } = this.state;

		const { articles, imageModePages, isImageMode, doubleImageModePages } =
			this.state;

		if (this.state.page === page) {
			return;
		}

		let location;

		let { search } = window.location;

		if (isImageMode && imageModePages && doubleImageModePages) {
			const { article } =
				deviceType === 'mobile'
					? imageModePages[page]
					: doubleImageModePages[page];

			const { id } = article;

			if (this.state.article.id !== id) {
				let url = location;
				if (search) {
					url += search;
				}
				localStorage.setItem('last-path', url);
				window.history.pushState({}, null, url);
				console.log(`ARTICLE`);
				console.log(article);

				tracking.event({
					category: 'User',
					event: 'load_article',
					label: `Load article "${article.slug || article.id}"`,
					value: article.slug || article.id,
					location: window.location.pathname,
				});

				tracking.pageView({
					title: article?.name[lang],
					location,
				});
			}
			this.checkIfIsArticleFavorite({ id, article, page });
			return;
		}

		// Article Mode
		const article = articles[page];
		if (this.state.article.id !== article.id) {
			this.onChangeStopVideos();
			let { id, languages, slug } = article;
			if (languages && languages.length && !languages.includes(lang)) {
				if (languages[0]) {
					this.setState({ lang: languages[0] });
				}
			}

			if (slug) {
				id = slug;
			}

			location = `/issues/${this.getID()}/${id}`;
			if (subdirectory) {
				location = subdirectory.concat(location);
			}
			if (search) {
				location += search;
			}
			console.log(`Article with lang: ${lang}`);
			console.log(article);
			this.triggerAutoPlayVideo(article);
			// TODO REVIEW WHICH ID TO USE
			tracking.event({
				category: 'User',
				event: 'load_article',
				label: `Load article "${article.slug || article.id}"`,
				value: article.slug || article.id,
				location: window.location.pathname,
			});

			tracking.pageView({
				title: article?.name[lang],
				location,
			});
			localStorage.setItem('last-path', location);
			window.history.pushState({}, null, location);
			this.checkIfIsArticleFavorite({ id, article, page });
		}
	};

	onChangeStopVideos = () => {
		// Get all the videos elements
		const videos = document.querySelectorAll('video');
		// Get all the iframe videos
		const iframeVideos = document.querySelectorAll('iframe');

		// Pause all the HTML videos
		if (videos.length > 0) {
			videos.forEach((videoItem) => {
				videoItem.pause();
			});
		}

		// Pause all the iframe videos
		if (iframeVideos.length > 0) {
			iframeVideos.forEach((iframe) => {
				if (iframe.contentWindow) {
					// Pause Youtube Videos
					if (iframe.src.startsWith('https://www.youtube.com')) {
						iframe.contentWindow.postMessage(
							'{"event":"command","func":"pauseVideo","args":""}',
							'*'
						);
					}

					// Pause Vimeo Videos
					if (iframe.src.startsWith('https://player.vimeo.com/')) {
						iframe.contentWindow.postMessage(
							'{"method":"pause"}',
							'*'
						);
					}

					// Pause Soundcloud Videos
					if (
						iframe.src.startsWith(
							'https://w.soundcloud.com/player/'
						)
					) {
						iframe.contentWindow.postMessage(
							'{"method":"pause"}',
							'*'
						);
					}
				}
			});
		}
	};

	triggerAutoPlayVideo = async (article) => {
		const articleNode = document.getElementById(`article-${article.id}`);
		if (articleNode) {
			console.log(`Trigger autoplay`, article);
			const videoNodes = articleNode.querySelectorAll('video');
			for (const node of videoNodes) {
				if (node.hasAttribute('autoplay')) {
					console.log(`I play`, node);
					await node.play();
				}
			}
		}
	};

	onArticleChange = (article) => {
		let { search } = window.location;
		const { lang } = this.state;
		const { subdirectory } = this.context;
		/*console.log(`On article change`);
		console.log(article);*/
		if (!this.state?.article?.id || !article?.id) {
			return;
		}
		if (this.state?.article?.id !== article?.id) {
			let { id, languages, slug } = article;
			if (languages && languages.length && !languages.includes(lang)) {
				if (languages[0]) {
					this.setState({ lang: languages[0] });
				}
			}
			this.setState({ article });
			id = slug || id;
			let url = `/issues/${this.getID()}/${id}`;
			if (subdirectory) {
				url = subdirectory.concat(url);
			}
			if (search) {
				url += search;
			}
			localStorage.setItem('last-path', url);
			window.history.pushState({}, null, url);
		}
	};

	checkIfIsArticleFavorite = ({ id, article, page }) => {
		this.isArticleFavorite(id).then((isFavorite) => {
			if (this.sound) {
				this.sound.unload();
			}
			this.loadAudioVersion(article);
			this.setState({
				currentPage: page,
				page,
				article,
				isFavorite,
				dragEnabled: true,
				isArticleSettingsDisplay: true,
				disableSwipe: false,
			});
		});
	};

	getPage = () => {
		return this.state.page !== this.state.currentPage
			? this.state.currentPage
			: this.state.page;
	};

	setCurrentPage = (i) => {
		this.setState({ currentPage: i, page: i });
	};

	onArticleClick = (article) => {
		let { search } = window.location;
		const {
			isImageMode,
			articles,
			imageModePages,
			doubleImageModePages,
			deviceType,
		} = this.state;
		const { subdirectory } = this.context;

		let index = 0;
		let art;

		if (isImageMode) {
			if (deviceType === 'mobile') {
				for (let i = 0; i < imageModePages.length; i++) {
					if (imageModePages[i].article.id === article.id) {
						art = imageModePages[i].article;
						index = i;
						break;
					}
				}
			} else {
				for (let i = 0; i < doubleImageModePages.length; i++) {
					if (doubleImageModePages[i].articles.includes(article.id)) {
						art = doubleImageModePages[i].article;
						index = i;
						break;
					}
				}
			}

			if (this.state.article.id !== art.id) {
				this.setState({
					currentPage: index,
					page: index,
					isIndexOpen: false,
					article: art,
				});
				let url = `/issues/${this.getID()}/${art.id}`;
				if (subdirectory) {
					url = subdirectory.concat(url);
				}
				if (search) {
					url += search;
				}
				window.history.pushState({}, null, url);
			}

			return;
		}

		for (let i = 0; i < articles.length; i++) {
			if (articles[i].id === article.id) {
				art = articles[i];
				index = i;
				break;
			}
		}

		if (this.state.article.id !== art.id) {
			this.setState({
				currentPage: index,
				page: index,
				isIndexOpen: false,
				article: art,
			});
			let url = `/issues/${this.getID()}/${art.id}`;
			if (subdirectory) {
				url = subdirectory.concat(url);
			}
			if (search) {
				url += search;
			}
			window.history.pushState({}, null, url);
		}
	};

	onShareClick = () => {
		const { tracking } = this.context;
		const { issue } = this.state;
		if (!!issue && navigator.share) {
			tracking.event({
				category: 'User',
				event: 'share',
				label: 'Share',
				value: window.location.href,
				location: window.location.href,
			});

			const share = {
				title: issue.name || '',
				text: issue.description || '',
				url: window.location.href,
			};
			navigator.share(share);
		}
	};

	onDisableSwipe = (disable) => {
		this.setState({ disableSwipe: disable });
	};

	onImageClick = (image) => {
		const { isImageMode } = this.state;
		const { enableImageZoom, tracking } = this.context;
		if (isImageMode) {
			this.setState({ isImageMode: false });
		}
		if (enableImageZoom) {
			this.setState({
				imageModalSrc: image.imageurl,
				isDisplayImageModal: true,
			});
		}
	};

	onCloseImageDisplay = () => {
		this.setState({ isDisplayImageModal: false });
	};

	onPlayerClick = () => {
		const { isPlayerOpen } = this.state;
		if (!isPlayerOpen) {
			console.log(`The player was close and now is open`);
			this.onPlayClick();
		}

		/*if (!isPlayerOpen) {
			// The player was close and i open it

			tracking.event({
				category: 'User',
				action: 'Open audio player',
			});
		} else {
			tracking.event({
				category: 'User',
				action: 'Close audio player',
			});
		}*/
		this.setState({
			isPlayerOpen: !isPlayerOpen,
		});
	};

	onPlayClick = () => {
		const { tracking } = this.context;
		const sound = this.sound;
		const { isPlaying, SoundID } = this.state;
		if (!sound) {
			return null;
		}

		if (SoundID) {
			if (isPlaying) {
				console.debug(`The audio is playing so i will pause it`);
				sound.pause(SoundID);
			} else {
				sound.play(SoundID);
				console.debug(`The audio is pause so i will resume it`);
			}
		} else {
			if (isPlaying) {
				console.debug(`The audio is playing so i will pause it`);
				sound.pause();
			} else {
				sound.play();
				console.debug(`The audio is pause so i will resume it`);
			}
		}

		/*if (!isPlaying) {
			tracking.event({
				category: 'User',
				action: 'Play article audio',
			});
		} else {
			tracking.event({
				category: 'User',
				action: 'Pause article audio',
			});
		}*/

		this.setState({
			isPlaying: !isPlaying,
		});
	};

	onPlayerSlide = (percent) => {
		const { SoundID } = this.state;
		const sound = this.sound;
		if (sound) {
			/*if (isPlaying) {
				sound.pause(SoundID);
			}*/
			const soundDuration = this.sound.duration();
			const seek = (soundDuration * percent) / 100;
			sound.seek(seek, SoundID);
			const soundTime = soundDuration - seek;

			/*if (isPlaying) {
				sound.play(SoundID);
			}*/

			this.setState({
				soundPercent: percent,
				soundTime,
			});
		}
	};

	goToNextPage = () => {
		const { page, articles, isImageMode, imageModePages } = this.state;
		if (
			page <
			(isImageMode ? imageModePages.length : articles.length) - 1
		) {
			this.onChangePage({ page: page + 1 });
		}
	};

	goToPreviousPage = () => {
		const { page } = this.state;
		if (page > 0) {
			this.onChangePage({ page: page - 1 });
		}
	};

	onIsImageModeClick = () => {
		const {
			article,
			deviceType,
			doubleImageModePages,
			imageModePages,
			articles,
			isImageMode,
		} = this.state;
		const { tracking } = this.context;
		tracking.event({
			category: 'User',
			event: 'image_mode',
			label: 'Image mode',
			value: article.slug || article.id,
			location: window.location.pathname,
		});
		if (!isImageMode) {
			this.setState({
				isFontOpen: false,
				isReaderMode: false,
			});
		}

		const newImageMode = !isImageMode;

		if (newImageMode) {
			if (this.sound) {
				this.sound.pause();
			}

			if (deviceType === 'mobile') {
				for (let i = 0; i < imageModePages.length; i++) {
					const page = imageModePages[i];
					if (page.article.id === article.id) {
						this.setState({
							page: i,
							currentPage: i,
							imageModePage: page,
							isImageMode: true,
							isPlaying: false,
							isPlayerOpen: false,
						});
						break;
					}
				}
			} else {
				for (let i = 0; i < doubleImageModePages.length; i++) {
					const page = doubleImageModePages[i];

					if (page.articles.includes(article.id)) {
						this.setState({
							doubleImageMode: page,
							page: i,
							currentPage: i,
							isImageMode: true,
							isPlaying: false,
							isPlayerOpen: false,
						});
						break;
					}
				}
			}
		} else {
			for (let i = 0; i < articles.length; i++) {
				const page = articles[i];
				if (page.id === article.id) {
					this.setState({
						page: i,
						currentPage: i,
						imageModePage: page,
						isImageMode: false,
					});
					break;
				}
			}
		}
	};

	getArticleLanguages = (article) => {
		const languages = new Set();
		const appendLanguage = (text_lang) => {
			if (text_lang && typeof text_lang === 'object') {
				for (const k in text_lang) {
					languages.add(k);
				}
			}
		};
		for (const component of article.components) {
			let { text_lang } = component;
			appendLanguage(text_lang);

			if (component.component === 'columns') {
				for (const column of component.columns) {
					for (const c of column) {
						text_lang = c.text_lang;
						appendLanguage(text_lang);
					}
				}
			}
		}

		article.languages = [...languages].filter((lang) =>
			this.context.languages.includes(lang)
		);
		return article;
	};

	onLanguageClick = () => {
		const { isLanguageOpen } = this.state;
		this.setState({ isLanguageOpen: !isLanguageOpen });
	};

	onLanguageClose = () => {
		this.setState({ isLanguageOpen: false });
	};

	onLanguageSelected = (lang) => {
		const { article } = this.state;
		this.setState({ lang });
		this.loadAudioVersion(article, lang);
	};

	highlightText = (results) => {
		const { lang, search } = this.state;
		if (!results?.length || !search) {
			return (article) => article;
		}
		const resultSet = results.reduce((acc, result) => {
			acc.add(`${result.id}`);
			return acc;
		}, new Set());

		return (article) => {
			if (resultSet.has(`${article.id}`)) {
				article.components = article.components.map(
					this.highlightComponent({ lang, search })
				);
			}

			return JSON.parse(JSON.stringify(article));
		};
	};

	highlightComponent = ({ lang, search }) => {
		return (component) => {
			const { columns, text_lang } = component;
			// Check if the component has columns
			if (columns?.length) {
				component.columns = component.columns.map((column) =>
					column.map(this.highlightComponent({ lang, search }))
				);
				return component;
			}

			// We check if the component has a text lang
			if (text_lang && text_lang[lang]) {
				component.text_lang[lang] = text_lang[lang].replace(
					new RegExp(search, 'gmi'),
					(match) => {
						return `<mark>${match}</mark>`;
					}
				);
			}

			return component;
		};
	};

	render() {
		const id = this.getID();

		const {
			isOnline,
			iframe,
			isIframeMode,
			theme,
			autoDownloadImage,
			deviceType,
			device,
			config,
		} = this.context;
		const isImageModeEnabled = this.context?.imageMode?.enabled;
		let {
			articles,
			article,
			isIndexOpen,
			isReaderMode,
			isFavorite,
			isFontOpen,
			fontSize,
			page,
			isLoading,
			redirect,
			articleIDsMap,
			isIssueDownloaded,
			hasAudio,
			isPlayerOpen,
			isPlaying,
			isImageMode,
			soundPercent,
			soundTime,
			issue,
			sound,
			pagesImagesMap,
			componentStyles,
			imageModePages,
			doubleImageModePages,
			lang,
			isLanguageOpen,
			downloadInterval,
			results,
			search,
			isImageModeCapable,
		} = this.state;

		if (articles) {
			articles = JSON.parse(JSON.stringify(articles)).map(
				this.highlightText(results)
			);
		}

		if (redirect) {
			return redirect;
		}

		const settingsOptions = {
			toc: {
				selected: isIndexOpen,
				onClick: this.onIndexClick,
			},
			reader: {
				selected: isReaderMode,
				onClick: this.onReaderModeClick,
			},
			favorite: {
				selected: isFavorite,
				onClick: this.onIsFavoriteClick,
			},
			font: {
				selected: isFontOpen && !isImageMode,
				onClick: this.onIsFontClick,
			},
			image: {
				selected: isImageMode,
				onClick: this.onIsImageModeClick,
			},
		};

		if (!isImageModeEnabled) {
			delete settingsOptions.image;
		}

		let fontSizeSlider = null;
		let fontbarComponent = null;
		if (!!isFontOpen && !isImageMode) {
			fontSizeSlider = (
				<FontSizeSlider
					theme={theme.bottomBar}
					size={fontSize}
					onChange={this.onFontSizeChange}
				/>
			);
			fontbarComponent = (
				<Fontbar
					theme={theme.navbar}
					size={fontSize}
					onChange={this.onFontSizeChange}
				/>
			);
		}

		if (isLoading) {
			return <LoadingComponent id={id} />;
		}

		if (!articles.length) {
			return <Empty id={id} />;
		}

		let progressBarComponent = null;
		let navigationArrowStyle = {
			height: undefined,
			marginTop: undefined,
		};
		if (theme && theme.progressBar && theme.progressBar.enabled) {
			let { height } = theme.progressBar;

			if (!height) {
				height = 5;
			}

			const progress =
				((page + 1) * 100) /
				(isImageMode
					? deviceType === 'mobile'
						? imageModePages?.length
						: doubleImageModePages?.length
					: articles.length);
			if (deviceType !== 'mobile') {
				navigationArrowStyle.height = `calc(100% - var(--navbar-height) - ${height}px)`;
			} else {
				navigationArrowStyle.height = `calc(100% - var(--navbar-height) - ${height}px - var(--bottom-bar-height))`;
			}
			navigationArrowStyle.marginTop = `${height}px`;

			progressBarComponent = (
				<ProgressBar
					progress={progress}
					height={height}
					background={
						theme.progressBar.background
							? theme.progressBar.background
							: '#fff'
					}
					color={
						theme.progressBar.color
							? theme.progressBar.color
							: '#000'
					}
				/>
			);
		}

		let previousArticleArrow = null;
		if (
			page > 0 &&
			!isFontOpen &&
			!isIndexOpen &&
			!isLanguageOpen &&
			!device.isTouchable
		) {
			previousArticleArrow = (
				<div
					className={`${styles['issue-navigator-component']} ${styles['left']}`}
					onClick={this.goToPreviousPage}
					style={navigationArrowStyle}
				>
					<div></div>
				</div>
			);
		}

		let nextArticleArrow = null;
		if (
			page <
				(isImageMode
					? deviceType === 'mobile'
						? imageModePages?.length
						: doubleImageModePages?.length
					: articles.length) -
					1 &&
			!isFontOpen &&
			!isIndexOpen &&
			!isLanguageOpen &&
			!device.isTouchable
		) {
			nextArticleArrow = (
				<div
					className={`${styles['issue-navigator-component']} ${styles['right']}`}
					onClick={this.goToNextPage}
					style={navigationArrowStyle}
				>
					<div></div>
				</div>
			);
		}

		let onLanguageClick = this.onLanguageClick;

		if (isImageMode) {
			hasAudio = false;
			isLanguageOpen = false;
			onLanguageClick = null;
			isPlayerOpen = false;
		}

		if (config?.contentType === 'replica' && isImageModeCapable) {
			return (
				<>
					<Header article={article} lang={lang} />
					<ReplicaMode
						article={article}
						issue={issue}
						onArticleChange={this.onArticleChange}
						onClickResponsiveMode={() => {
							this.setState({
								isImageMode: false,
							});
							localStorage.setItem('last-mode', 'responsive');
						}}
						imagesMap={pagesImagesMap}
						componentStyles={componentStyles}
						articles={articles}
						isFavorite={isFavorite}
						pages={imageModePages}
						doblePageSpreadPages={doubleImageModePages}
						lang={lang}
						onIsFavoriteClick={this.onIsFavoriteClick}
						onLanguageSelected={this.onLanguageSelected}
					/>
				</>
			);
		}

		if (
			isImageMode &&
			isImageModeCapable &&
			config?.contentType !== 'responsive'
		) {
			return (
				<>
					<Header article={article} lang={lang} />
					<ReplicaMode
						article={article}
						issue={issue}
						onArticleChange={this.onArticleChange}
						onClickResponsiveMode={() => {
							this.setState({
								isImageMode: false,
							});
							localStorage.setItem('last-mode', 'responsive');
						}}
						imagesMap={pagesImagesMap}
						componentStyles={componentStyles}
						articles={articles}
						isFavorite={isFavorite}
						pages={imageModePages}
						doblePageSpreadPages={doubleImageModePages}
						lang={lang}
						onIsFavoriteClick={this.onIsFavoriteClick}
						onLanguageSelected={this.onLanguageSelected}
					/>
				</>
			);
		}

		return (
			<>
				<Header article={article} lang={lang} />
				<ResponsiveMode
					onChangePage={this.onChangePage}
					isImageModeCapable={isImageModeCapable}
					onImageModeClick={() => {
						this.setState({
							isImageMode: true,
						});
						localStorage.setItem('last-mode', 'replica');
					}}
					lang={lang}
					articleIDsMap={articleIDsMap}
					articles={articles}
					issue={issue}
					results={results}
					article={article}
					isOnline={isOnline}
					isFavorite={isFavorite}
					fontSize={fontSize}
					isDownloaded={isIssueDownloaded}
					isDownloading={downloadInterval !== null}
					onFontSizeChange={this.onFontSizeChange}
					onIsFavoriteClick={this.onIsFavoriteClick}
					onDownloadClick={this.onDownloadClick}
					onLanguageSelected={this.onLanguageSelected}
					onSearch={this.onSearch}
				/>
			</>
		);
	}
}

Issue.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
};

function Header({ article, lang }) {
	let title = '';
	if (article?.name && article?.name[lang]) {
		title = article?.name[lang];
	}

	const url = window.location.href;

	const { description, thumbnail } = article;

	return (
		<Helmet>
			{title ? <title>{title}</title> : null}
			{description ? (
				<meta name="description" content={description} />
			) : null}

			{title ? <meta itemProp="name" content={title} /> : null}
			{description ? (
				<meta itemProp="description" content={description} />
			) : null}
			{thumbnail ? <meta itemProp="image" content={thumbnail} /> : null}

			<meta property="og:url" content={url} />
			<meta property="og:type" content="website"></meta>
			{title ? <meta property="og:title" content={title} /> : null}
			{description ? (
				<meta property="og:description" content={description} />
			) : null}
			{thumbnail ? (
				<meta property="og:image" content={thumbnail} />
			) : null}

			<meta name="twitter:card" content="summary_large_image" />
			{title ? <meta name="twitter:title" content={title} /> : null}
			{description ? (
				<meta name="twitter:description" content={description} />
			) : null}
			{thumbnail ? (
				<meta name="twitter:image" content={thumbnail} />
			) : null}
		</Helmet>
	);
}

Header.propTypes = {
	article: PropTypes.object.isRequired,
	lang: PropTypes.string.isRequired,
};

function ProgressBar({ progress, background, color, height }) {
	return (
		<div
			className={styles['progress-bar-container']}
			style={{
				background,
				height: height ? `${height}px` : undefined,
			}}
		>
			<div
				className={styles['progress-bar']}
				style={{
					width: `${progress}%`,
					background: color,
				}}
			/>
		</div>
	);
}

ProgressBar.propTypes = {
	progress: PropTypes.number.isRequired,
	background: PropTypes.string.isRequired,
	color: PropTypes.string.isRequired,
	height: PropTypes.number,
};

function LoadingComponent() {
	const { isOnline, theme, config, isIframeMode, iframe } =
		useContext(AppContext);
	const removeToolbars = isIframeMode && !iframe?.toolbars;
	return (
		<div className="App">
			<main>
				<section className={styles['issue-articles']}>
					<Loading theme={config?.theme?.loading} />
				</section>
			</main>
		</div>
	);
}

function Empty() {
	const { isOnline, theme, isIframeMode, iframe } = useContext(AppContext);
	const removeToolbars = isIframeMode && !iframe?.toolbars;
	return (
		<div className="App">
			{removeToolbars ? null : (
				<Navbar isOnline={isOnline} backTo={`/`} theme={theme.navbar} />
			)}
			<main>
				<section className={styles['issue-articles']}>
					<EmptyState section={'articles'} />
				</section>
			</main>
		</div>
	);
}

function TOC({ articles, lang, onItemClicked, article }) {
	const { theme } = useContext(AppContext);
	const background = theme?.toc?.background || '#fff';
	const listStyleType = theme?.toc?.listStyle || 'none';
	return (
		<ol
			className={styles['toc']}
			style={{
				background,
				listStyleType,
			}}
		>
			{articles
				.map((art, i) => {
					art.index = i;
					return art;
				})
				.filter((article) => article?.channel?.toc !== 'excluded')
				.map((art, i) => (
					<TOCItem
						key={i}
						lang={lang}
						index={i}
						article={art}
						selected={art?.id === article?.id}
						onClick={onItemClicked}
					/>
				))}
		</ol>
	);
}

TOC.propTypes = {
	articles: PropTypes.array.isRequired,
	article: PropTypes.object,
	lang: PropTypes.string.isRequired,
	onItemClicked: PropTypes.func.isRequired,
};

function TOCItem({ article, selected, lang, index, onClick }) {
	const { theme } = useContext(AppContext);

	const color = theme?.toc?.color || '#c4c4c4';
	const selectedColor = theme?.toc?.selectedColor || '#000';
	const textTransform = theme?.toc?.textTransform || undefined;
	const fontFamily = theme?.toc?.fontFamily || undefined;
	const selectedFontFamily = theme?.toc?.selectedFontFamily || undefined;
	const style = {
		overflow: 'hidden',
		color: selected ? selectedColor : color,
		textTransform,
		fontWeight: selected ? 'bold' : undefined,
		borderBottom: `1px groove ${theme?.toc?.background || '#000'}`,
		borderLeft: selected ? `4px solid ${selectedColor}` : undefined,
		borderRight: `1px groove ${theme?.toc?.background || '#000'}`,
		fontFamily: selected ? selectedFontFamily : fontFamily,
	};
	const { name } = article;
	let componentCSS = `
		#toc-item-${index}:hover {
			color: ${selectedColor} !important;
			border-left: 4px solid ${selectedColor};
		}
	`;

	return (
		<li
			id={`toc-item-${index}`}
			className={`${styles['item']}`}
			style={style}
			onClick={() => {
				onClick(article);
			}}
		>
			{name[lang]}
			<JSXStyle id={`toc-${index}`}>{componentCSS}</JSXStyle>
		</li>
	);
}

TOCItem.propTypes = {
	article: PropTypes.object.isRequired,
	selected: PropTypes.bool.isRequired,
	lang: PropTypes.string.isRequired,
	index: PropTypes.number,
	onClick: PropTypes.func.isRequired,
};

class Article extends React.Component {
	lastScrollPosition = 0;

	state = {
		enableScroll: null,
		childrenRef: null,
	};

	shouldComponentUpdate(nextProps, nextState) {
		if (this.props.readerMode !== nextProps.readerMode) {
			return true;
		}

		if (this.state.enableScroll === null) {
			return true;
		}

		if (this.state.enableScroll === nextState.enableScroll) {
			return false;
		}

		if (this.state.results === nextState.results) {
			return true;
		}

		return true;
	}

	onScroll = () => {
		const { onBottombarDisplayChange, index } = this.props;
		const { clientHeight, scrollTop } = this.refs[`page-${index}`];
		const position = scrollTop + clientHeight / 2;
		const direction = this.lastScrollPosition < position ? 'down' : 'up';
		const scrollDelta = 5;

		if (Math.abs(this.lastScrollPosition - position) >= scrollDelta) {
			if (direction === 'up') {
				onBottombarDisplayChange(true);
			} else {
				onBottombarDisplayChange(false);
			}
		}
		this.lastScrollPosition = position;
	};

	render() {
		let {
			components,
			index,
			backgroundColor,
			isImageMode,
			isDoubleImage,
			canvasStyle,
		} = this.props;

		const pageStyle = { width: '100%', height: '100%' };
		if (isImageMode) {
			pageStyle.display = 'flex';
			pageStyle.justifyContent = 'center';
			pageStyle.alignItems = 'center';
		}

		if (isDoubleImage) {
			delete pageStyle.display;
			delete pageStyle.justifyContent;
			delete pageStyle.alignItems;
		}

		if (canvasStyle) {
			if (canvasStyle.marginTop) {
				pageStyle.marginTop = canvasStyle.marginTop;
			}
		}

		return (
			<div
				id={`page-${index}`}
				ref={`page-${index}`}
				onScroll={this.onScroll}
				style={{
					backgroundColor,
					width: '100%',
					height: '100%',
					marginLeft: 'auto',
					marginRight: 'auto',
					overflowX: 'hidden',
					overflowY: 'auto',
				}}
			>
				<div style={pageStyle}>
					{components}
					<div width={'100%'} style={{ height: '80px' }}></div>
				</div>
			</div>
		);
	}
}

Article.propTypes = {
	readerMode: PropTypes.bool,
	onBottombarDisplayChange: PropTypes.func.isRequired,
	index: PropTypes.number.isRequired,
	components: PropTypes.array.isRequired,
	paddingTop: PropTypes.any,
	backgroundColor: PropTypes.string,
	isImageMode: PropTypes.bool,
	isDoubleImage: PropTypes.bool,
	canvasStyle: PropTypes.object,
};

function mergeStyles(article, styles, deviceType) {
	if (deviceType) {
		return mergeDeviceStyles(article, styles, deviceType);
	}
	return article.style;
}

function mergeDeviceStyles(article, styles, deviceType) {
	if (!styles) {
		return null;
	}

	let style = getStyleByID(styles, article.StyleID);

	if (style && style[deviceType]) {
		return overWriteStyleProperties(article.style, style[deviceType]);
	}

	return article.style;
}

function getStyleByID(styles, id) {
	return styles[id];
}

function processComponents(components) {
	const hasMultipleAdverts =
		components.filter((component) => component.component === 'advert')
			.length > 1;
	if (hasMultipleAdverts) {
		components = components.map((component) => {
			if (component.component === 'advert') {
				component.component = 'image';
			}

			return component;
		});
	}

	return components;
}
