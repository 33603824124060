import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Frame, useMotionValue, useTransform } from 'framer';

import styles from './ArticleSettings.module.css';

import { AppContext } from '../../services/AppContext';

export function FontSizeSlider({ size, onChange, theme }) {
	const { locale } = useContext(AppContext);
	const width = 200;

	const [percent, setPercent] = useState((size * width) / 100);
	const position = useMotionValue(percent);

	useEffect(() => {
		setPercent((size * width) / 100);
	}, [size]);

	if (size === undefined) {
		return null;
	}
	const { slider } = theme;
	const { background, accentColor, color, knob } = slider;

	return (
		<div
			className={styles[`font-size-option-holder`]}
			style={{ background: background }}
		>
			<div>
				<div className={styles['font-icon-container']}>
					<div
						className={`${styles['bottom-bar-icon']} ${styles['icon-font-decrease']}`}
					>
						<button
							onClick={() => {
								let decreaseSize = size - 10;
								if (decreaseSize <= 5) {
									decreaseSize = 5;
								}
								position.set((decreaseSize * width) / 100);
								onChange(decreaseSize);
							}}
							title={locale['BUTTON_TITLE_FONT_SIZE_DECREASE']}
							style={{ background: color }}
						/>
					</div>
				</div>
				<div className={styles['font-slider-container']}>
					<Slider
						min={5}
						max={100}
						knob={knob}
						position={position}
						background={accentColor}
						onChange={onChange}
						width={width}
					/>
				</div>
				<div className={styles['font-icon-container']}>
					<div
						className={`${styles['bottom-bar-icon']} ${styles['icon-font-increase']}`}
					>
						<button
							onClick={() => {
								let increaseSize = size + 10;
								if (increaseSize >= 100) {
									increaseSize = 100;
								}
								position.set((increaseSize * width) / 100);
								onChange(increaseSize);
							}}
							title={locale['BUTTON_TITLE_FONT_SIZE_INCREASE']}
							style={{ background: color }}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

FontSizeSlider.propTypes = {
	size: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	theme: PropTypes.object,
};

function Slider({ width, min, max, onChange, knob, background, position }) {
	width = width || 200;
	background = background || '#000000';

	let knobSize = knob.size || 20;

	const newPercent = useTransform(position, [0, width], [min, max]);

	if (typeof knobSize === 'string') {
		knobSize = JSON.parse(knobSize);
	}

	let offset = -1 * (knobSize / 2);
	return (
		<Frame
			name={'Rail'}
			width={width}
			height={6}
			center
			background={'rgba(0,0,0,.2)'}
			radius={3}
		>
			<Frame
				name={'Fill'}
				width={position}
				height={6}
				radius={3}
				background={background}
			/>
			<Frame
				name={'Knob'}
				size={knobSize}
				center={'y'}
				radius={'50%'}
				background={knob.color}
				shadow={'0 1px 3px 1px #242424'}
				left={offset}
				drag={'x'}
				x={position}
				dragConstraints={{ left: 0, right: width }}
				dragElastic={0}
				dragMomentum={false}
				onDrag={() => {
					if (onChange) onChange(newPercent.get());
				}}
			/>
		</Frame>
	);
}

Slider.propTypes = {
	width: PropTypes.number.isRequired,
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	knob: PropTypes.object.isRequired,
	background: PropTypes.string,
	position: PropTypes.object,
};

export function ArticleSettings({
	articles,
	options,
	display,
	theme,
	onShareClick,
}) {
	const { imageMode, readerMode, fontSizeOption } = useContext(AppContext);
	const { background } = theme;
	const { reader, favorite, font, image } = options;

	let showImageMode = false;
	if (
		articles &&
		articles.filter((a) => a.pages && a.pages.length).length &&
		imageMode?.enabled
	) {
		showImageMode = true;
	}

	return (
		<footer
			className={`${styles['bottom-bar']} ${
				display ? '' : styles['not-display']
			}`}
			style={{ background }}
		>
			<Share theme={theme} onClick={onShareClick} />
			{readerMode?.enabled ? (
				<ReaderModeOption options={reader} theme={theme} />
			) : null}
			<FavoriteOption options={favorite} theme={theme} />
			{fontSizeOption?.enabled ? (
				<FontSizeOption options={font} theme={theme} />
			) : null}
			{showImageMode ? (
				<ImageModeOption options={image} theme={theme} />
			) : null}
		</footer>
	);
}

ArticleSettings.propTypes = {
	articles: PropTypes.array.isRequired,
	options: PropTypes.object.isRequired,
	display: PropTypes.bool,
	onShareClick: PropTypes.func.isRequired,
	theme: PropTypes.object,
};

function Share({ theme, onClick }) {
	const { locale, config } = useContext(AppContext);
	if(!config?.share?.enabled) {
		return null;
	}
	const { color } = theme;
	if (!navigator.share) {
		return null;
	}
	return (
		<div className={styles['bottom-bar-icon-container']}>
			<div
				className={`${styles['bottom-bar-icon']} ${styles['icon-share']}`}
			>
				<button
					title={locale['BUTTON_TITLE_SHARE']}
					style={{ background: color }}
					onClick={onClick}
				/>
			</div>
		</div>
	);
}

Share.propTypes = {
	onClick: PropTypes.func.isRequired,
	theme: PropTypes.object,
};

function ReaderModeOption({ options, theme }) {
	const { locale } = useContext(AppContext);
	const { accentColor, color } = theme;
	const { display, selected, onClick } = options;

	if (display === false) {
		return null;
	}

	return (
		<div className={styles['bottom-bar-icon-container']}>
			<div
				className={`${styles['bottom-bar-icon']} ${
					styles['icon-reader']
				}  ${selected ? styles['selected'] : ''}`}
			>
				<button
					title={locale['BUTTON_TITLE_READER_MODE']}
					style={{ background: selected ? accentColor : color }}
					onClick={() => onClick()}
				/>
			</div>
		</div>
	);
}

ReaderModeOption.propTypes = {
	options: PropTypes.object.isRequired,
	theme: PropTypes.object,
};

function ImageModeOption({ options, theme }) {
	const { locale } = useContext(AppContext);
	const { accentColor, color } = theme;
	const { display, selected, onClick } = options;

	if (display === false) {
		return null;
	}

	return (
		<div className={styles['bottom-bar-icon-container']}>
			<div
				className={`${styles['bottom-bar-icon']} ${
					styles['icon-image-mode']
				}  ${selected ? styles['selected'] : ''}`}
			>
				<button
					title={locale['BUTTON_TITLE_IMAGE_MODE']}
					style={{ background: selected ? accentColor : color }}
					onClick={() => onClick()}
				/>
			</div>
		</div>
	);
}

ImageModeOption.propTypes = {
	options: PropTypes.object.isRequired,
	theme: PropTypes.object,
};

function FavoriteOption({ options, theme }) {
	const { locale } = useContext(AppContext);
	const { accentColor, color } = theme;
	const { display, selected, onClick } = options;

	if (display === false) {
		return null;
	}

	return (
		<div className={styles['bottom-bar-icon-container']}>
			<div
				className={`${styles['bottom-bar-icon']} ${
					styles['icon-bookmark']
				}  ${selected ? styles['selected'] : ''}`}
			>
				<button
					title={locale['BUTTON_TITLE_BOOKMARK']}
					style={{ background: selected ? accentColor : color }}
					onClick={() => onClick()}
				/>
			</div>
		</div>
	);
}

FavoriteOption.propTypes = {
	options: PropTypes.object.isRequired,
	theme: PropTypes.object,
};

function FontSizeOption({ options, theme }) {
	const { locale } = useContext(AppContext);
	const { accentColor, color } = theme;
	const { display, selected, onClick } = options;

	if (display === false) {
		return null;
	}

	return (
		<div className={styles['bottom-bar-icon-container']}>
			<div
				className={`${styles['bottom-bar-icon']} ${
					styles['icon-font']
				}  ${selected ? styles['selected'] : ''}`}
			>
				<button
					title={locale['BUTTON_TITLE_FONT_SIZE']}
					style={{ background: selected ? accentColor : color }}
					onClick={() => onClick()}
				/>
			</div>
		</div>
	);
}

FontSizeOption.propTypes = {
	options: PropTypes.object.isRequired,
	theme: PropTypes.object,
};
