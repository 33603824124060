import { createContext } from 'react';

export const PageContext = createContext({
    dimensions: null,
    name: '',
    setDimensions: () => {},
    hotspotMap: null,
    setHotspotMap: () => {},
    ctx: null,
    setCtx: () => {},
    onClickHotspot: () => {},
});
