export class Player {
	state = 'stop';
	position = 0;
	duration = 0;

	interval = null;
	change = {
		state: null,
		position: null,
	};

	constructor(sound) {
		this.id = new Date().getTime();
		this.sound = sound;
		this.cacheAudio(sound);
		this.duration = sound?.duration();
		this.sound.on('end', this.onEnd);
		this.sound.on('pause', this.onPause);
		this.sound.on('play', this.onPlay);
		this.onChangePosition = () => {}
	}

	cacheAudio(sound) {
		const audioUrl = sound._src;

		console.info(`I should try to cache`);
		if (caches) {
			caches
				.open('audio')
				.then((cache) => cache.addAll([audioUrl]))
				.then(() => console.info(`Success caching: ${audioUrl}`))
				.catch(console.error);
		} else {
			console.info(`CACHES DO NOT EXISTS`);
		}
	}

	playPause() {
		if (this.state == 'pause' || this.state == 'stop') {
			this.play();
		} else {
			this.pause();
		}
	}

	play() {
		this.changeState('playing');
		if (this.SoundID) {
			this.sound.play(this.SoundID);
			return;
		}

		if(this.sound) {
			this.sound.play();
		}
	}

	pause() {
		if(this.sound) {
			this.sound.pause(this.SoundID);
		}
	}

	stop() {
		if (this.SoundID) {
			this.sound?.stop(this.SoundID);
		}
		this.state = 'stop';
		this.position = 0;
		if (this.interval) {
			clearInterval(this.interval);
		}
	}

	forward(seconds) {
		if (!this.SoundID) {
			return;
		}

		console.debug(`Forward: ${seconds}s`);
		if (this.position + seconds < this.duration) {
			this.sound.seek(this.position + seconds, this.SoundID);
			return;
		}

		this.sound.seek(this.duration, this.SoundID);
	}

	rewind(seconds) {
		if (!this.SoundID) {
			return;
		}

		console.debug(`Rewind: ${seconds}s`);
		if (this.position - seconds > 0) {
			this.sound.seek(this.position - seconds, this.SoundID);
			return;
		}

		this.sound.seek(0, this.SoundID);
	}

	getDuration() {
		return this.sound?.duration();
	}

	getState() {
		return this.state;
		// return this.sound?.state();
	}

	onChange(event, fn) {
		if (typeof fn !== 'function') {
			return;
		}

		switch (event) {
			case 'state':
				this.change.state = fn;
				break;
			case 'position':
				this.change.position = fn;
				break;
			default:
				return;
		}
	}

	onEnd = () => {
		this.changeState('stop');
		if (this.interval) {
			clearInterval(this.interval);
			this.interval = null;
		}
		this.changePosition(this.duration);
		this.sound?.stop();
	};

	onPause = () => {
		this.changeState('pause');
		if (this.interval) {
			clearInterval(this.interval);
			this.interval = null;
		}
	};

	onPlay = (id) => {
		this.changeState('playing');
		if (!this.SoundID) {
			this.SoundID = id;
		}

		const sound = this.sound;
		if (this.interval) {
			clearInterval(this.interval);
		}

		this.interval = setInterval(() => {
			this.onChangePosition(this.position);
			this.changePosition(sound.seek(null, this.SoundID));
		}, 500);
	};

	changeState = (state) => {
		this.state = state;
		console.debug(`Player ${this.id}: ${this.state}`);
		if (this.change.state) {
			try {
				this.change.state(state);
			} catch (e) {
				console.error(e);
			}
		}
	};

	changePosition = (position) => {
		if (position !== this.position) {
			this.position = position;
			if (this.change.position) {
				try {
					this.change.position(this.position);
				} catch (e) {
					console.error(e);
				}
			}
		}
	};

	logEvent = () => {};

	close() {
		if (this.sound) {
			this.sound.unload();
			this.sound = null;
		}
	}
}
