/* eslint-disable no-async-promise-executor */
import { gql } from 'apollo-boost';
import { StyleBuilder } from './StyleBuilder';

export const AUTHENTICATE = gql`
	query Authenticate($email: String!, $password: String!) {
		authenticate(email: $email, code: $password) {
			token
			expiredAt
		}
	}
`;

export const AUTHENTICATE_ADMIN = gql`
	query AuthenticateAdmin($email: String!, $password: String!) {
		authenticate(email: $email, code: $password) {
			token
			expiredAt
		}
	}
`;

export const FONTS = gql`
	{
		fonts {
			name
			urls
		}
	}
`;

export const GET_INTERNAL_ARTICLE = gql`
	query GetInternalArticle($id: ID!) {
		getInternalArticle(id: $id) {
			id
			issue
		}
	}
`;

export const SEARCH_ARTICLES_IN_APP = gql`
	query SearchArticlesInApp($search: String!, $lang: String!) {
		articles: searchArticles(search: $search, lang: $lang) {
			id
			name
			description
			IssueID
			thumbnail
			components
		}
	}
`;

export const GET_APP_FEEDS = gql`
	{
		feeds {
			id
			title
			name
			link
			image
			index
			description
			language
			rating
			copyright
			items {
				guid
				title
				author
				link
				description
			}
		}
	}
`;

export const GET_FEED_GROUPS = gql`
	{
		feedGroups {
			id
			name
			index
			feeds {
				id
				title
				name
				link
				image
				index
				description
				language
				rating
				copyright
				items {
					guid
					title
					author
					link
					description
				}
			}
		}
	}
`;

export const GET_FEED_BY_ID = gql`
	query GetFeed($id: ID!) {
		feed: feed(id: $id) {
			id
			title
			link
			description
			language
			rating
			copyright
			items {
				guid
				title
				author
				link
				description
			}
		}
	}
`;

export const GET_PROPERTIES = gql`
	{
		app {
			StripeAccountID
			properties {
				title
				shortTitle
				autoDownloadImage
				enableAuthorization
				tts {
					enabled
				}
				analytics {
					google {
						trackerID
						sampleRate
					}
				}
				enableInstallationOption
				imageMode {
					enabled
					default
				}
				issueMode {
					enabled
				}
				readerMode {
					enabled
				}
				fontSizeOption {
					enabled
				}
				auth {
					google
					apple
					email
				}
				enableZoom
				enableImageZoom
				defaultLanguage
				languages
				theme {
					color
					background
					loading {
						color
					}
					ribbons {
						display
					}
					issues {
						title {
							display
						}
					}
					progressBar {
						color
						background
						enabled
						height
					}
					navbar {
						background
						color
						toc {
							color
							selectedColor
							textTransform
							listStyle
							fontFamily
							selectedFontFamily
						}
						slider {
							background
							accentColor
							color
							knob {
								size
								color
							}
						}
					}
					toc {
						background
						color
						selectedColor
						textTransform
						listStyle
						fontFamily
						selectedFontFamily
					}
					buttons {
						primary {
							color
							background
						}
						secondary {
							color
							background
						}
					}
					bottomBar {
						background
						color
						accentColor
						slider {
							background
							accentColor
							color
							knob {
								size
								color
							}
						}
					}
				}
			}
		}
	}
`;

export const STYLES = gql`
	{
		styles {
			id
			name
			description
			properties
			supportedDevices
			type
			parent
			tablet
			desktop
			created
			lastModified
		}
	}
`;

export const GET_MY_SUBSCRIPTION = gql`
	{
		subscription {
			expiredAt
		}
	}
`;

export const GET_SUBSCRIPTIONS = gql`
	{
		subscriptions {
			id
			name
			description
			price
			currency
			unit
			amount
		}
	}
`;

export const AUTHENTICATION_REQUEST = gql`
	mutation Authenticate($email: String!) {
		authenticationRequest(email: $email)
	}
`;

export const AUTHENTICATION_CODE = gql`
	query Authenticate(
		$email: String!
		$code: String!
		$firstName: String
		$lastName: String
	) {
		authenticate(
			email: $email
			code: $code
			firstName: $firstName
			lastName: $lastName
		) {
			token
			expiredAt
		}
	}
`;

export const AUTHENTICATE_WITH_PROVIDER = gql`
	query Authenticate($provider: AuthProvider!, $token: String!) {
		authenticateWithProvider(provider: $provider, token: $token) {
			token
			expiredAt
		}
	}
`;

export const REFRESH_TOKEN = gql`
	query RefreshToken {
		refreshToken {
			token
			expiredAt
		}
	}
`;

export const REGISTER_USER = gql`
	mutation Register(
		$email: String!
		$password: String!
		$firstName: String!
		$lastName: String!
	) {
		registerUser(
			email: $email
			password: $password
			firstName: $firstName
			lastName: $lastName
		) {
			id
			email
		}
	}
`;

export const ACTIVATE_USER = gql`
	mutation Activate($email: String!, $code: String!) {
		activateUser(email: $email, code: $code) {
			id
			email
		}
	}
`;

export const GET_ISSUES = gql`
	{
		issues {
			id
			name
			description
			pages
			cover
			externalUrl
			price
			index
			slug
			currency
			data
			updated
			created
			isPurchased
			isPaid
			releaseDate
		}
	}
`;

export const GET_ARTICLES_BY_ID = gql`
	query Articles($ids: [ID!]) {
		articles(ids: $ids) {
			id
			ArticleID
			thumbnail
			audio
			name
			style
			slug
			description
			components
			styleProperties
			channel
			pages
			replica {
				name
				page
				image
				hotspots {
					url
					area {
						x
						y
						width
						height
					}
				}
			}
			style
			updated
		}
	}
`;

export const GET_ISSUE_BY_ID = gql`
	query Issue($id: ID!) {
		issue(id: $id) {
			id
			name
			description
			externalUrl
			pages
			textDirection
			cover
			price
			currency
			slug
			data
			releaseDate
			updated
			isPaid
			created
			isPurchased
			articles {
				id
				ArticleID
				thumbnail
				style
				name
				slug
				audio
				index
				channel
				description
				pages
				replica {
					name
					page
					image
					hotspots {
						url
						area {
							x
							y
							width
							height
						}
					}
				}
				components
				styleProperties
				style
				updated
			}
		}
	}
`;

export const GET_ISSUE_BY_SLUG = gql`
	query Issue($slug: ID!) {
		issue(id: $slug, isSlug: true) {
			id
			name
			description
			pages
			textDirection
			cover
			externalUrl
			price
			currency
			slug
			data
			updated
			isPaid
			created
			isPurchased
			releaseDate
			articles {
				id
				ArticleID
				thumbnail
				style
				index
				name
				slug
				audio
				channel
				description
				pages
				replica {
					name
					page
					image
					hotspots {
						url
						area {
							x
							y
							width
							height
						}
					}
				}
				components
				styleProperties
				style
				updated
			}
		}
	}
`;

export const GET_ISSUE = gql`
	query Issue($id: ID!) {
		issue(id: $id) {
			id
			name
			description
			externalUrl
			cover
			pages
			price
			textDirection
			slug
			currency
			data
			updated
			isPaid
			created
			isPurchased
			releaseDate
			articles {
				id
				ArticleID
				thumbnail
				style
				name
				index
				slug
				audio
				channel
				description
				pages
				replica {
					name
					page
					image
					hotspots {
						url
						area {
							x
							y
							width
							height
						}
					}
				}
				components
				styleProperties
				style
				updated
			}
		}
	}
`;

export const GET_ISSUE_SLUG = gql`
	query Issue($slug: ID!) {
		issue(id: $slug, isSlug: true) {
			id
			name
			description
			cover
			pages
			externalUrl
			price
			textDirection
			slug
			currency
			data
			updated
			isPaid
			created
			releaseDate
			isPurchased
			articles {
				id
				ArticleID
				thumbnail
				style
				name
				slug
				audio
				channel
				description
				pages
				replica {
					name
					page
					image
					hotspots {
						url
						area {
							x
							y
							width
							height
						}
					}
				}
				components
				style
				updated
			}
		}
	}
`;

export const GET_PURCHASES = gql`
	{
		purchases {
			id
			name
			date
			type
			price
			currency
		}
	}
`;

export const GET_PAYMENT_INTENT = gql`
	query GetPaymentIntent($amount: Float!, $currency: String!) {
		paymentIntent(amount: $amount, currency: $currency) {
			id
			clientSecret
		}
	}
`;

export const PURCHASE_ISSUE = gql`
	mutation Purchase($id: ID!) {
		purchaseIssue(id: $id)
	}
`;

export const PURCHASE_SUBSCRIPTION = gql`
	mutation Purchase($id: ID!) {
		purchaseSubscription(id: $id)
	}
`;

export const ME = gql`
	{
		me {
			data
		}
	}
`;

export default class Api {
	constructor(client, host, appKey) {
		this.client = client;
		this.host = host;
		this.appKey = appKey;
	}

	assertToken = async () => {
		try {
			const isValidToken = await this.validateToken();
			if (!isValidToken) {
				const response = await this.refreshToken();

				localStorage.setItem('token', response.token);
				localStorage.setItem('expiredAt', response.expiredAt);
			}
		} catch (e) {
			console.error(e);
			localStorage.removeItem('me');
			localStorage.removeItem('token');
			localStorage.removeItem('expiredAt');
			localStorage.removeItem('app-user-role');
			alert(`Session ended please login again`);
			document.location.href = '/';
		}
	};

	getToken = () => {
		return localStorage.getItem('token')
			? localStorage.getItem('token')
			: '';
	};

	authenticate(email, password, role = 'user') {
		console.log(`Email: ${email}`);
		console.log(`Password: ${password}`);
		console.log(`Role: ${role}`);
		return new Promise(async (resolve, reject) => {
			try {
				localStorage.setItem('role', 'admin');
				const result = await this.client.query({
					query: AUTHENTICATE_ADMIN,
					fetchPolicy: 'no-cache',
					variables: {
						email: email,
						password: password,
					},
				});

				resolve(result.data.authenticate);
			} catch (e) {
				reject(e);
			} finally {
				localStorage.removeItem('role');
			}
		});
	}

	getAppArticle(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const result = await this.client.query({
					query: GET_INTERNAL_ARTICLE,
					fetchPolicy: 'no-cache',
					variables: {
						id,
					},
				});

				resolve(result.data.getInternalArticle);
			} catch (e) {
				reject(e);
			}
		});
	}

	authenticationRequest(email) {
		return new Promise(async (resolve, reject) => {
			try {
				const result = await this.client.mutate({
					mutation: AUTHENTICATION_REQUEST,
					fetchPolicy: 'no-cache',
					headers: {
						'app-key': this.appKey,
					},
					variables: {
						email,
					},
				});

				resolve(result.data.authenticationRequest);
			} catch (e) {
				reject(e);
			}
		});
	}

	authenticateCodeValidation(email, code, firstName, lastName) {
		return new Promise(async (resolve, reject) => {
			try {
				const result = await this.client.query({
					query: AUTHENTICATION_CODE,
					fetchPolicy: 'no-cache',
					headers: {
						'app-key': this.appKey,
					},
					variables: {
						email,
						code,
						firstName,
						lastName,
					},
				});

				resolve(result.data.authenticate);
			} catch (e) {
				reject(e);
			}
		});
	}

	refreshToken() {
		return new Promise(async (resolve, reject) => {
			const token = this.getToken();
			try {
				const result = await this.client.query({
					query: REFRESH_TOKEN,
					fetchPolicy: 'no-cache',
					headers: {
						'app-key': this.appKey,
						token,
					},
				});

				resolve(result.data.refreshToken);
			} catch (e) {
				reject(e);
			}
		});
	}

	authenticateWithProvider(provider, token) {
		return new Promise(async (resolve, reject) => {
			try {
				const result = await this.client.query({
					query: AUTHENTICATE_WITH_PROVIDER,
					headers: {
						'app-key': this.appKey,
					},
					variables: {
						provider: provider.toUpperCase(),
						token,
					},
				});

				resolve(result.data.authenticateWithProvider);
			} catch (e) {
				reject(e);
			}
		});
	}

	async validateToken() {
		const token = this.getToken();
		if (!token) {
			return true;
		}

		const response = await fetch(`${this.host}/validate`, {
			method: 'POST', // or 'PUT'
			headers: {
				Authorization: token,
			},
		});

		const data = await response.json();
		return data.success;
	}

	getProperties() {
		return new Promise(async (resolve, reject) => {
			try {
				const headers = {
					'app-key': this.appKey,
				};

				const result = await this.client.query({
					query: GET_PROPERTIES,
					fetchPolicy: 'no-cache',
					headers,
				});
				resolve({
					...result.data.app.properties,
					...{
						StripeAccountID:
							result.data.app.StripeAccountID || null,
					},
				});
			} catch (e) {
				reject(e);
			}
		});
	}

	getIssues() {
		return new Promise(async (resolve, reject) => {
			try {
				const headers = {
					'app-key': this.appKey,
				};

				const token = this.getToken();
				if (token) {
					await this.assertToken();
					headers['token'] = token;
				}

				const result = await this.client.query({
					query: GET_ISSUES,
					fetchPolicy: 'no-cache',
					headers,
				});

				resolve(result.data.issues);
			} catch (e) {
				reject(e);
			}
		});
	}

	getIssueByID(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const token = this.getToken();
				await this.assertToken();
				const result = await this.client.query({
					query: GET_ISSUE_BY_ID,
					fetchPolicy: 'no-cache',
					headers: {
						'app-key': this.appKey,
						token: token,
					},
					variables: {
						id,
					},
				});

				const issue = result.data.issue;
				if (issue && issue.articles) {
					issue.articles = issue.articles
						.sort((a, b) => a.index - b.index)
						.map((article, index) => {
							article.index = index;
							return article;
						});
				}

				resolve(issue);
			} catch (e) {
				reject(e);
			}
		});
	}

	getIssueBySlug(slug) {
		return new Promise(async (resolve, reject) => {
			try {
				const token = this.getToken();
				await this.assertToken();
				const result = await this.client.query({
					query: GET_ISSUE_BY_SLUG,
					fetchPolicy: 'no-cache',
					headers: {
						'app-key': this.appKey,
						token: token,
					},
					variables: {
						slug,
					},
				});

				const issue = result.data.issue;
				console.log(`ISSUE BEFORE`, clone(issue));
				if (issue && issue.articles) {
					issue.articles = issue.articles
						.sort((a, b) => a.index - b.index)
						.map((article, index) => {
							article.index = index;
							return article;
						});
				}

				console.log(`ISSUE AFTER`, issue);

				resolve(issue);
			} catch (e) {
				reject(e);
			}
		});
	}

	getArticlesFromIssue(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const token = this.getToken();
				await this.assertToken();
				const result = await this.client.query({
					query: GET_ISSUE,
					fetchPolicy: 'no-cache',
					headers: {
						'app-key': this.appKey,
						token: token,
					},
					variables: {
						id,
					},
				});

				resolve(Object.assign({}, result.data.issue));
			} catch (e) {
				reject(e);
			}
		});
	}

	getArticlesFromIssueSlug(slug) {
		return new Promise(async (resolve, reject) => {
			try {
				const token = this.getToken();
				await this.assertToken();
				const result = await this.client.query({
					query: GET_ISSUE_SLUG,
					fetchPolicy: 'no-cache',
					headers: {
						'app-key': this.appKey,
						token: token,
					},
					variables: {
						slug,
					},
				});

				resolve(Object.assign({}, result.data.issue));
			} catch (e) {
				reject(e);
			}
		});
	}

	searchArticles(search, lang) {
		return new Promise(async (resolve, reject) => {
			try {
				const token = this.getToken();
				await this.assertToken();
				const result = await this.client.query({
					query: SEARCH_ARTICLES_IN_APP,
					headers: {
						'app-key': this.appKey,
						token: token,
					},
					variables: {
						search,
						lang,
					},
				});

				resolve(result.data.articles);
			} catch (e) {
				reject(e);
			}
		});
	}

	getPurchases() {
		return new Promise(async (resolve, reject) => {
			try {
				const token = this.getToken();
				await this.assertToken();
				const result = await this.client.query({
					query: GET_PURCHASES,
					fetchPolicy: 'no-cache',
					headers: {
						'app-key': this.appKey,
						token: token,
					},
				});

				resolve(result.data.purchases);
			} catch (e) {
				reject(e);
			}
		});
	}

	purchaseIssue(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const token = this.getToken();
				await this.assertToken();
				const result = await this.client.mutate({
					mutation: PURCHASE_ISSUE,
					fetchPolicy: 'no-cache',
					headers: {
						'app-key': this.appKey,
						token: token,
					},
					variables: {
						id,
					},
				});

				resolve(result.data.purchaseIssue);
			} catch (e) {
				reject(e);
			}
		});
	}

	purchaseSubscription(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const token = this.getToken();
				await this.assertToken();
				const result = await this.client.mutate({
					mutation: PURCHASE_SUBSCRIPTION,
					fetchPolicy: 'no-cache',
					headers: {
						'app-key': this.appKey,
						token: token,
					},
					variables: {
						id,
					},
				});
				resolve(result.data.purchaseSubscription);
			} catch (e) {
				reject(e);
			}
		});
	}

	registerUser({ email, password, firstName, lastName }) {
		return new Promise(async (resolve, reject) => {
			try {
				const result = await this.client.mutate({
					mutation: REGISTER_USER,
					variables: {
						email,
						password,
						firstName,
						lastName,
					},
				});

				resolve(result);
			} catch (e) {
				reject(e);
			}
		});
	}

	activateUser({ email, code }) {
		return new Promise(async (resolve, reject) => {
			try {
				const result = await this.client.mutate({
					mutation: ACTIVATE_USER,
					variables: {
						email,
						code,
					},
				});

				resolve(result);
			} catch (e) {
				reject(e);
			}
		});
	}

	me() {
		return new Promise(async (resolve, reject) => {
			try {
				const token = this.getToken();
				await this.assertToken();
				const result = await this.client.query({
					query: ME,
					fetchPolicy: 'no-cache',
					headers: {
						'app-key': this.appKey,
						token: token,
					},
				});

				resolve({ me: result.data.me.data });
			} catch (e) {
				reject(e);
			}
		});
	}

	getFonts() {
		return new Promise(async (resolve, reject) => {
			try {
				const result = await this.client.query({
					query: FONTS,
					fetchPolicy: 'no-cache',
					headers: {
						'app-key': this.appKey,
					},
				});

				resolve(result.data.fonts);
			} catch (e) {
				reject(e);
			}
		});
	}

	getFeedGroups() {
		return new Promise(async (resolve, reject) => {
			try {
				const token = this.getToken();
				await this.assertToken();
				const result = await this.client.query({
					query: GET_FEED_GROUPS,
					fetchPolicy: 'no-cache',
					headers: {
						'app-key': this.appKey,
						token: token,
					},
				});

				const feedGroups = [];
				for (const group of result.data.feedGroups) {
					group.feeds = group.feeds.map((feed) => {
						delete feed['__typename'];
						feed.items = feed.items.map((item) => {
							delete item['__typename'];
							return { ...item };
						});
						return { ...feed };
					});
					feedGroups.push(group);
				}

				resolve(feedGroups);
			} catch (e) {
				reject(e);
			}
		});
	}

	getFeeds() {
		return new Promise(async (resolve, reject) => {
			try {
				const token = this.getToken();
				await this.assertToken();
				const result = await this.client.query({
					query: GET_APP_FEEDS,
					fetchPolicy: 'no-cache',
					headers: {
						'app-key': this.appKey,
						token: token,
					},
				});

				const feeds = [];
				for (const feed of result.data.feeds) {
					feed.items = feed.items.map((item) => {
						delete item['__typename'];
						return { ...item };
					});
					feeds.push(feed);
				}

				resolve(feeds);
			} catch (e) {
				reject(e);
			}
		});
	}

	getFeedById(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const token = this.getToken();
				await this.assertToken();
				const result = await this.client.query({
					query: GET_FEED_BY_ID,
					fetchPolicy: 'no-cache',
					headers: {
						'app-key': this.appKey,
						token: token,
					},
					variables: {
						id,
					},
				});

				const feed = result.data.feed;
				if (!feed) {
					resolve(null);
					return;
				}

				feed.items = feed.items.map((item) => {
					delete item['__typename'];
					return { ...item };
				});

				resolve(feed);
			} catch (e) {
				reject(e);
			}
		});
	}

	getStyles() {
		return new Promise(async (resolve, reject) => {
			try {
				const result = await this.client.query({
					query: STYLES,
					fetchPolicy: 'no-cache',
					headers: {
						'app-key': this.appKey,
					},
				});
				resolve(buildStyles(result.data.styles));
			} catch (e) {
				reject(e);
			}
		});
	}

	getMySubscription() {
		return new Promise(async (resolve, reject) => {
			try {
				const token = this.getToken();
				await this.assertToken();
				const result = await this.client.query({
					query: GET_MY_SUBSCRIPTION,
					fetchPolicy: 'no-cache',
					headers: {
						'app-key': this.appKey,
						token: token,
					},
				});

				resolve(result.data.subscription);
			} catch (e) {
				reject(e);
			}
		});
	}

	getSubscriptions() {
		return new Promise(async (resolve, reject) => {
			try {
				const result = await this.client.query({
					query: GET_SUBSCRIPTIONS,
					fetchPolicy: 'no-cache',
					headers: {
						'app-key': this.appKey,
					},
				});

				resolve(result.data.subscriptions);
			} catch (e) {
				reject(e);
			}
		});
	}
}

function buildStyles(styles) {
	styles = styles.map(StyleBuilder.mapRenameProperties).map(clone);
	styles = StyleBuilder.resolveInheritance(styles);
	return styles;
}

export function overWriteStyleProperties(style, overwrite) {
	const overwriteProperties = {};
	for (let property in overwrite.properties) {
		if (overwrite.properties[property]) {
			if (Array.isArray(overwrite.properties[property])) {
				continue;
			}

			overwriteProperties[property] = overwrite.properties[property];
		}
	}

	const concatStyles = {
		...style,
		...overwriteProperties,
	};

	const properties = {};
	for (const property in concatStyles) {
		if (style[property] && !overwriteProperties[property]) {
			properties[property] = style[property];
		} else if (!style[property] && overwriteProperties[property]) {
			properties[property] = overwriteProperties[property];
		} else if (style[property] && overwriteProperties[property]) {
			properties[property] = {
				...style[property],
				...overwriteProperties[property],
			};
		}
	}
	return properties;
}

function clone(obj) {
	return obj ? JSON.parse(JSON.stringify(obj)) : obj;
}
