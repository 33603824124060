import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ArticleContext } from '../Context';
import styles from '../article.module.css';

import { useIsInViewport } from '../hooks/use-viewport';
import { useComponentAnimation } from '../hooks/use-animation';

export const StyledText = ({
	id,
	text,
	text_lang,
	expandfullwidth,
	bleed,
	dropcap,
	component,
	imageurl,
	imagemargin,
	imagewidth,
	imagefloat,
	imageenabled,
	IMAGECAPTION,
	unit,
	animation,
	style,
}) => {
	const { lang } = useContext(ArticleContext);
	let content = text;
	if (lang && text_lang?.[lang]) {
		content = text_lang[lang];
	}

	return (
		<Text
			id={id}
			bleed={bleed === 'on'}
			text={content}
			style={style}
			imageCaption={IMAGECAPTION}
			dropcap={dropcap === 'on'}
			expandFullWidth={expandfullwidth === 'on'}
			imageUrl={imageurl}
			animation={animation}
			unit={unit}
			imageMargin={`${imagemargin}`}
			imageWidth={`${imagewidth}`}
			imageFloat={`${imagefloat}`}
			imageEnabled={imageenabled === 'on'}
			type={component}
		/>
	);
};

StyledText.propTypes = {
	id: PropTypes.string,
	text: PropTypes.string,
	style: PropTypes.object,
	unit: PropTypes.object,
	IMAGECAPTION: PropTypes.string,
	column: PropTypes.bool,
	imageurl: PropTypes.string,
	animation: PropTypes.any,
	imageenabled: PropTypes.string,
	imagemargin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	imagewidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	imagefloat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	text_lang: PropTypes.object,
	expandfullwidth: PropTypes.string,
	bleed: PropTypes.string,
	dropcap: PropTypes.string,
	columnStyle: PropTypes.object,
	component: PropTypes.any,
};

StyledText.defaultProps = {
	column: false,
	columnStyle: null,
};

export const Text = ({
	id,
	bleed,
	text,
	expandFullWidth,
	type,
	dropcap,
	imageUrl,
	imageMargin,
	imageWidth,
	imageFloat,
	imageEnabled,
	imageCaption,
	unit,
	animation,
	style,
}) => {
	const ref = useRef();
	const [animationClasses, setAnimationClasses] = useState([]);
	const classNames = ['component', 'text', type, styles['component']];
	let css = [];
	let image = '';
	let clearer = '';
	const componentStyle = {};

	useExternalLink(id, type, 500);

	const hasAnimation = animation && animation.type !== 'none';

	const isInViewport = useIsInViewport(ref, hasAnimation);
	useComponentAnimation(ref, isInViewport, animation, setAnimationClasses);

	if (expandFullWidth) {
		componentStyle.minWidth = '100%';
		componentStyle.width = '100%';
	}

	if (id === 'Comp-4668193') {
		console.log(componentStyle);
	}

	if (style?.unit) {
		const { unit } = style;
		if (style['margin_top'] && unit['margin_top']) {
			componentStyle.marginTop = `${style['margin_top']}${unit['margin_top']}`;
		}
		if (style['margin_left'] && unit['margin_left']) {
			componentStyle.marginLeft = `${style['margin_left']}${unit['margin_left']}`;
		}
		if (style['margin_right'] && unit['margin_right']) {
			componentStyle.marginRight = `${style['margin_right']}${unit['margin_right']}`;
		}
		if (style['margin_bottom'] && unit['margin_bottom']) {
			componentStyle.marginBottom = `${style['margin_bottom']}${unit['margin_bottom']}`;
		}
		if (style['padding_top'] && unit['padding_top']) {
			componentStyle.paddingTop = `${style['padding_top']}${unit['padding_top']}`;
		}
		if (style['padding_left'] && unit['padding_left']) {
			componentStyle.paddingLeft = `${style['padding_left']}${unit['padding_left']}`;
		}
		if (style['padding_right'] && unit['padding_right']) {
			componentStyle.paddingRight = `${style['padding_right']}${unit['padding_right']}`;
		}
		if (style['padding_bottom'] && unit['padding_bottom']) {
			componentStyle.paddingBottom = `${style['padding_bottom']}${unit['padding_bottom']}`;
		}
	}

	if (text === '<p></p>') {
		return null;
	}

	if (imageEnabled) {
		if (imageFloat === 'left') {
			imageMargin = `
				margin-right: ${imageMargin}px;
				margin-bottom: ${imageMargin}px;
			`;
		} else {
			imageMargin = `
			margin-left: ${imageMargin}px;
			margin-bottom: ${imageMargin}px;
		`;
		}
		const imageWidthUnit = unit?.['imagewidth'] || 'px';
		css.push(`#${id} figure {
			max-width: ${imageWidth}${imageWidthUnit};
			float: ${imageFloat};
			${imageMargin}
		}`);
		css.push(`#${id} img {
			width: 100%;
		}`);
		if (imageUrl) {
			const caption = imageCaption
				? `<figcaption>${imageCaption}</figcaption>`
				: '';
			componentStyle.display = 'block';
			image = `<figure><img src="${imageUrl}" />${caption}</figure>`;
			clearer = '<span style="display: block; clear: both;"></span>';
		}
	}

	if (bleed) {
		classNames.push(bleed);
	}

	if (dropcap) {
		classNames.push('dropcap');
	}

	if (style) {
		css.push(getDropcapStyle(id, style));
	}

	return (
		<>
			<div
				id={id}
				ref={ref}
				style={componentStyle}
				className={[...classNames, ...animationClasses].join(' ')}
				dangerouslySetInnerHTML={{
					__html: `${image} ${text} ${clearer}`,
				}}
			/>
			<style>{css.join('\n')}</style>
		</>
	);
};

function getDropcapStyle(id, style) {
	const response = [];
	const { dropcap_font_family, dropcap_color } = style;

	if (dropcap_font_family) {
		response.push(`font-family: '${dropcap_font_family}' !important;`);
	}

	if (dropcap_color) {
		response.push(`color: #${dropcap_color.replace('#', '')} !important;`);
	}

	if (!response.length) {
		return '';
	}

	response.push('}');
	response.unshift(`#${id} p:first-of-type:first-letter {`);

	const css = response.join('\n');
	return css;
}

Text.propTypes = {
	id: PropTypes.string,
	imageCaption: PropTypes.string,
	isSelected: PropTypes.bool,
	unit: PropTypes.object,
	bleed: PropTypes.bool,
	text: PropTypes.PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	expandFullWidth: PropTypes.bool,
	css: PropTypes.string,
	imageEnabled: PropTypes.bool,
	imageUrl: PropTypes.string,
	imageMargin: PropTypes.string,
	imageFloat: PropTypes.string,
	imageWidth: PropTypes.string,
	style: PropTypes.object,
	container: PropTypes.object,
	dropcap: PropTypes.bool,
	animation: PropTypes.any,
	type: PropTypes.any,
};

Text.defaultProps = {
	bleed: false,
	expandFullWidth: false,
	css: '',
	style: {},
	dropcap: false,
};

/**
 * React hook that tracks event when an external link is clicked
 *
 * @param id - id of the component
 * @param type - type of text component
 * @param timeout - how long to wait to track the element in the dom
 *
 */
function useExternalLink(id, type, timeout) {
	const { onEventSend } = useContext(ArticleContext);
	useEffect(() => {
		setTimeout(() => {
			document.querySelectorAll(`#${id} a`).forEach((elem) => {
				const { href } = elem;
				if (elem.target === '_blank' && href) {
					elem.onclick = () => {
						onEventSend({
							event: 'external',
							data: {
								url: href,
								componentId: id,
								componentType: type,
							},
						});
					};
				}
			});
		}, timeout);
	}, [id]);
}
