import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import styles from './BottomBar.module.css';
import { AppContext } from '../../services/AppContext';

export default BottomBar;

function BottomBar({ section, isVisible, theme }) {
	const { locale, config, isUserAdmin } = useContext(AppContext);

	const { background, accentColor, color } = theme;
	const selection = section;

	const isSelection = (section) => {
		return selection === section;
	};

	const style = {
		background,
	};

	if (!isVisible) {
		style.display = 'none';
		style.transition = 'display 1s';
	}

	let purchasesComponent = null;
	if (localStorage.getItem('me') && !isUserAdmin) {
		purchasesComponent = (
			<div className={styles['bottom-bar-icon-container']}>
				<Link
					to={`/purchases`}
					className={`${styles['bottom-bar-icon']} ${
						styles['icon-purchases']
					} ${isSelection('purchases') ? styles['selected'] : ''}`}
				>
					<button
						title={locale['BUTTON_TITLE_PURCHASES']}
						style={{
							background: isSelection('purchases')
								? accentColor
								: color,
						}}
					/>
				</Link>
			</div>
		);
	}

	let settingsComponent = (
		<div className={styles['bottom-bar-icon-container']}>
			<Link
				to={`/settings`}
				className={`${styles['bottom-bar-icon']} ${
					styles['icon-setting']
				} ${isSelection('settings') ? styles['selected'] : ''}`}
			>
				<button
					title={locale['BUTTON_TITLE_SETTINGS']}
					style={{
						background: isSelection('settings')
							? accentColor
							: color,
					}}
				/>
			</Link>
		</div>
	);

	return (
		<footer className={styles['bottom-bar']} style={style}>
			<div className={styles['bottom-bar-icon-container']}>
				<Link
					to={`/`}
					className={`${styles['bottom-bar-icon']} ${
						styles['icon-home']
					} ${isSelection('home') ? styles['selected'] : ''}`}
				>
					<button
						title={locale['BUTTON_TITLE_HOME']}
						style={{
							background: isSelection('home')
								? accentColor
								: color,
						}}
					/>
				</Link>
			</div>
			{purchasesComponent}
			{!isUserAdmin && config?.bookmarks?.enabled ? (
				<div className={styles['bottom-bar-icon-container']}>
					<Link
						to={`/bookmarks`}
						className={`${styles['bottom-bar-icon']} ${
							styles['icon-bookmarks']
						} ${isSelection('bookmark') ? styles['selected'] : ''}`}
					>
						<button
							title={locale['BUTTON_TITLE_BOOKMARKS']}
							style={{
								background: isSelection('bookmark')
									? accentColor
									: color,
							}}
						/>
					</Link>
				</div>
			) : null}
			{config?.rssFeed?.enabled ? (
				<div className={styles['bottom-bar-icon-container']}>
					<Link
						to={`/feeds`}
						className={`${styles['bottom-bar-icon']} ${
							styles['icon-feed']
						} ${isSelection('feed') ? styles['selected'] : ''}`}
					>
						<button
							title={locale['BUTTON_TITLE_BOOKMARKS']}
							style={{
								background: isSelection('feed')
									? accentColor
									: color,
							}}
						/>
					</Link>
				</div>
			) : null}
			{settingsComponent}
		</footer>
	);
}

BottomBar.propTypes = {
	section: PropTypes.string.isRequired,
	isVisible: PropTypes.bool,
	theme: PropTypes.object,
};
