export default {
	FREE: 'Бесплатный',
	CONTINUE: 'Продолжить работу по адресу .',
	OK: 'Ok',
	SUBSCRIBE: 'Подписаться на',
	EMPTY_BOOKMARK_TITLE: 'У вас нет сохраненных закладок',
	EMPTY_PUBLICATION_TITLE: 'Данная публикация пуста',
	EMPTY_PURCHASES_TITLE: 'У вас нет покупок',
	EMPTY_ARTICLES_TITLE: 'Этот вопрос пуст.',
	EMPTY_BOOKMARK_CONTENT: `Функция закладок позволяет сохранить и быстро получить доступ к вашим
    любимые статьи. Просто коснитесь значка закладки на любой
    статью, чтобы сохранить ее, а затем просмотреть через меню закладок.`,
	EMPTY_PUBLICATION_CONTENT: `В этой публикации нет никаких проблем, пожалуйста, проверьте еще раз в ближайшее время.`,
	EMPTY_PURCHASES_CONTENT: `Вы не приобрели никакого контента.  После осуществления одного или нескольких платежей вы можете просмотреть 
    детали сделки здесь.`,
	EMPTY_ARTICLES_CONTENT:
		'В этом выпуске нет статей, пожалуйста, проверьте еще раз в ближайшее время.',
	SIGN_UP_WITH_EMAIL: 'Зарегистрироваться с электронной почтой',
	SIGN_UP_WITH_EMAIL_BACK_TITLE: 'Все варианты регистрации',
	SIGN_UP_WITH_EMAIL_CHECK_INBOX: 'Проверьте свою электронную почту',
	SIGN_IN_WITH_EMAIL: 'Войти в систему по электронной почте',
	SIGN_IN_WITH_EMAIL_BACK_TITLE: 'Все зарегистрированы в опциях',
	SIGN_UP_HEADER: 'Присоединяйся к нам',
	SIGN_UP_SUBHEADER: `Создайте аккаунт, чтобы получать отличные истории в вашем
    Входящие, персонализируйте свою домашнюю страницу и следуйте за авторами и
    темы, которые ты любишь.`,
	SIGN_IN_HEADER: 'С возвращением',
	SIGN_IN_SUBHEADER: `Зарегистрируйтесь, чтобы получить персональные рекомендации по истории,
    следить за авторами и темами, которые вам нравятся, и взаимодействовать с ними
    истории.`,
	SIGN_UP_WITH_GOOGLE: 'Зарегистрироваться в Google',
	SIGN_UP_WITH_APPLE: 'Зарегистрироваться в Apple',
	SIGN_IN_WITH_GOOGLE: 'Войти в систему с помощью Google',
	SIGN_IN_WITH_APPLE: 'Войти в систему вместе с Apple',
	AUTHENTICATE_SIGN_IN: 'Вход в систему',
	AUTHENTICATE_YOUR_EMAIL: 'Ваш Email',
	AUTHENTICATE_OFFLINE_ERROR: 'Вы должны быть онлайн, чтобы продолжать',
	AUTHENTICATE_SIGN_UP_MESSAGE: 'У вас уже есть аккаунт?',
	AUTHENTICATE_SIGN_UP: 'Зарегистрироваться',
	AUTHENTICATE_SIGN_IN_MESSAGE: 'Нет аккаунта?',
	AUTHENTICATE_SIGN_IN_CREATE_ONE: 'Создать одного',
	AUTHENTICATE_EMAIL_NOT_EMPTY_ERROR: `Электронная почта не может быть пустой`,
	AUTHENTICATE_EMAIL_INVALID: `Недействительный адрес электронной почты`,
	AUTHENTICATE_EMAIL_DIGIT_MESSAGE:
		'Мы только что отправили шестизначный код по электронной почте на адрес',
	AUTHENTICATE_EMAIL_DIGIT_MESSAGE_SUBMIT:
		'Мы только что отправили шестизначный код по электронной почте на адрес',
	SETTINGS_SIGN_IN: 'Вход в систему',
	SETTINGS_SIGN_OUT: 'Выход',
	SETTINGS_SUBSCRIPTION: 'Подписка',
	SETTINGS_SUBSCRIPTION_INACTIVE_ERROR: 'У вас нет активной подписки',
	SETTINGS_SUBSCRIPTION_EXPIRE_AT: 'Ваша подписка заканчивается',
	SETTINGS_STORAGE: 'Хранение',
	SETTINGS_LANGUAGE: 'Язык',
	SETTINGS_FREE_SPACE: 'Свободное пространство',
	SETTINGS_CLEAR_CACHE: 'Чистый кэш',
	SETTINGS_ASSETS_REMOVED_INTRO_MESSAGE: 'Имущество удалено',
	SETTINGS_CLEAR_CACHE_IMAGES_MESSAGE: 'Изображения',
	SETTINGS_CLEAR_CACHE_ISSUES_MESSAGE: 'Вопросы',
	SETTINGS_CLEAR_CACHE_ARTICLES_MESSAGE: 'Статьи',
	SETTINGS_CLEAR_CACHE_FAVORITE_ARTICLES_IMAGES_MESSAGE: 'Любимые статьи',
	SETTINGS_FREE_SPACE_IMAGES: 'изображения',
	PURCHASE_SUBSCRIPTION_HEADER: 'Подписка',
	PURCHASE_SUBSCRIPTION_SUBHEADER: 'Сообщение о подписке.',
	PURCHASE_OFFLINE_ERROR:
		'Вы не можете купить в автономном режиме, пожалуйста, попробуйте еще раз позже.',
	PURCHASES_OFFLINE_ERROR:
		'Вы должны быть онлайн, чтобы просматривать покупки',
	PURCHASES_PRICE: 'Цена',
	PURCHASES_TYPE_ISSUE: 'Выпуск',
	PURCHASES_TYPE_SUBSCRIPTION: 'Подписка',
};
