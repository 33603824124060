import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import styles from './Search.module.css';

import { AppContext } from '../../services/AppContext';
import { Icon } from '../../components/Icon';
import { Link } from 'react-router-dom';

function debounce(a, b, c) {
	var d, e;
	return function () {
		function h() {
			(d = null), c || (e = a.apply(f, g));
		}
		var f = this,
			g = arguments;
		return (
			clearTimeout(d),
			(d = setTimeout(h, b)),
			c && !d && (e = a.apply(f, g)),
			e
		);
	};
}

export class SmallSearchBox extends React.Component {
	static contextType = AppContext;
	state = {
		search: '',
		articles: [],
		isLoading: false,
		isOpen: false,
	};

	searchArticles = (search) => {
		const { api, lang, db } = this.context;
		if (search) {
			let response = [];
			api.searchArticles(search, lang)
				.then((articles) => {
					console.log(articles);
					response = articles;
					return db.getIssues();
				})
				.then((issues) => {
					const issuesMap = {};
					for (const issue of issues) {
						issuesMap[issue.id] = issue;
					}
					response = response.map((article) => {
						const { IssueID } = article;
						article.issue = issuesMap[IssueID];
						return article;
					});
					this.setState({ articles: response, isLoading: false });
				})
				.catch(() => {
					this.setState({ articles: [], isLoading: false });
				});
			return;
		}

		this.setState({ articles: [], isLoading: false });
	};

	onSearch = debounce((search) => {
		this.searchArticles(search);
	}, 1000);

	onClearClick = () => {
		this.setState({
			search: '',
			articles: [],
			isLoading: false,
			isOpen: false,
		});
	};

	render() {
		const { search, articles, isLoading, isOpen } = this.state;
		const { theme } = this.context;
		const className = [styles['sm-search-container']];
		if (isOpen) {
			className.push(styles['open']);
		} else {
			className.push(styles['close']);
		}

		const searchBar = (
			<div className={styles['sm-search-box']}>
				<span className={styles['search-icon']} />
				<input
					type={'text'}
					placeholder={'Search'}
					onChange={(e) => {
						const search = e.target.value;
						this.setState({ search, isLoading: true });
						this.onSearch(search);
					}}
					value={search}
				/>
				{isLoading ? <Spinner /> : null}
				<button
					className={styles['clear-icon']}
					onClick={this.onClearClick}
				/>
			</div>
		);
		const searchButton = (
			<button
				onClick={() => {
					this.setState({ isOpen: true });
				}}
			>
				<Icon name={'search'} color={theme?.navbar?.color || '#fff'} />
			</button>
		);
		return (
			<div className={className.join(' ')}>
				{isOpen ? searchBar : searchButton}
				{articles.length ? (
					<div className={styles['sm-result-list']}>
						{articles.map((article) => (
							<SmallResultItem
								key={article.id}
								article={article}
							/>
						))}
					</div>
				) : null}
				{!articles.length && search && !isLoading ? <NotFound /> : null}
			</div>
		);
	}
}

function SmallResultItem({ article }) {
	const { lang, config, getReadTime } = useContext(AppContext);
	const { readTime } = config;
	let { id, name, IssueID, description, thumbnail, issue, components } =
		article;
	const title = name[lang] ? name[lang] : '';
	description = description || issue?.name;
	let readTimeText = '';
	if (readTime?.enabled) {
		let { minutes, seconds } = getReadTime(components, lang);
		if (minutes < 1) {
			readTimeText = ' 1 min';
		} else {
			if (seconds > 30) {
				minutes += 1;
			}

			readTimeText = minutes === 1 ? ` 1 min` : ` ${minutes} min`;
		}
	}
	return (
		<Link
			to={`/issues/${IssueID}/${id}`}
			className={styles['sm-result-item']}
		>
			<div className={styles['image']}>
				<img src={thumbnail}></img>
			</div>
			<div className={styles['content']}>
				<h3>{title}</h3>
				{description ? <p>{description}</p> : null}
				{readTimeText ? <small>{readTimeText} read</small> : null}
			</div>
		</Link>
	);
}

SmallResultItem.propTypes = {
	article: PropTypes.object,
};

function Spinner() {
	return <div className={styles['spinner']}></div>;
}

function NotFound() {
	return (
		<div className={styles['sm-result-list']}>
			<div className={styles['sm-result-not-found']}>
				Results not found
			</div>
		</div>
	);
}
