import React, { useContext, useEffect, useState } from 'react';
import InnerHTML from 'dangerously-set-html-content';
import PropTypes from 'prop-types';

import styles from '../article.module.css';
import { ArticleContext } from '../Context';

export const StyledTikTok = ({ id, devices, bleed, params }) => {
	let { lang } = useContext(ArticleContext);

	return (
		<TikTok
			id={id}
			language={lang}
			devices={devices}
			bleed={bleed}
			params={params}
		/>
	);
};

StyledTikTok.propTypes = {
	id: PropTypes.string,
	devices: PropTypes.any,
	bleed: PropTypes.string,
	params: PropTypes.exact({
		videoID: PropTypes.string,
		username: PropTypes.string,
	}),
};

function TikTok({ id, bleed, params }) {
	const { username, videoID } = params;
	const [content, setContent] = useState('');
	const [error, setError] = useState('');

	const className = [styles['tiktok'], 'media', 'tiktok'];

	if (bleed) {
		className.push('bleed');
	}

	if (!username || !videoID) {
		return null;
	}

	useEffect(() => {
		if (content || !username || !videoID) return;
		const url = `https://www.tiktok.com/@${username}/video/${videoID}`;
		fetch(`https://www.tiktok.com/oembed?url=${url}`)
			.then((response) => response.json())
			.then(({ html }) => {
				setContent(html);
			})
			.catch((err) => setError(err.message));
	}, [content]);

	if (error) {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					color: '#fff',
					background: 'rgba(255,69,58,1)',
					padding: 20,
					fontWeight: 'bold',
				}}
			>
				{error}
			</div>
		);
	}

	if (content) {
		return (
			<div id={id} className={className.join(' ')}>
				<InnerHTML html={content} />;
			</div>
		);
	}

	return null;
}

TikTok.propTypes = {
	id: PropTypes.string,
	language: PropTypes.string,
	devices: PropTypes.any,
	bleed: PropTypes.string,
	params: PropTypes.exact({
		videoID: PropTypes.string,
		username: PropTypes.string,
	}),
};
