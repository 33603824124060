import PouchDB from 'pouchdb-browser';
import findPlugin from 'pouchdb-find';
import pouchdbDebug from 'pouchdb-debug';

PouchDB.plugin(findPlugin);
PouchDB.plugin(pouchdbDebug);

PouchDB.debug.enable('*');
PouchDB.debug.disable();

const issueStoreName = 'issues';
const favoritesStoreName = 'favorites';
const articlesStoreName = 'articles';
const stylesStoreName = 'styles';
const feedsStoreName = 'feeds';
const feedGroupsStoreName = 'feed-groups';

export default class Db {
	constructor() {
		const config = {
			auto_compaction: true,
		};

		if (typeof window !== 'undefined') {
			this.issuesStore = new PouchDB(issueStoreName, config);
			this.favoritesStore = new PouchDB(favoritesStoreName, config);
			this.articlesStore = new PouchDB(articlesStoreName, config);
			this.stylesStore = new PouchDB(stylesStoreName, config);
			this.feedsStore = new PouchDB(feedsStoreName, config);
			this.feedGroupsStore = new PouchDB(feedGroupsStoreName, config);
		}
	}

	start = async () => {
		await this.articlesStore.createIndex({
			index: { fields: ['IssueID'] },
		});
	};

	getIssues = async () => {
		const issues = [];
		const docs = await this.issuesStore.allDocs();
		for (const row of docs.rows) {
			const issue = await this.getIssue(row.id);
			issues.push(issue);
		}
		return issues;
	};

	getIssue = async (id, isSlug) => {
		if (isSlug) {
			const docs = await this.issuesStore.allDocs();
			for (const row of docs.rows) {
				const issue = await this.getIssue(row.id);
				if (issue.slug && `${issue.slug}` === id) {
					return issue;
				}
			}
		}
		return await this.issuesStore.get(`${id}`);
	};

	updateIssue = async (issue) => {
		try {
			const cachedIssue = await this.getIssue(issue.id);

			const { _rev } = cachedIssue;

			issue['_id'] = `${issue.id}`;
			issue['_rev'] = _rev;
			delete issue['__typename'];

			await this.issuesStore.put(issue);
		} catch (e) {
			if (!!e.name && e.name === 'not_found') {
				issue['_id'] = `${issue.id}`;
				delete issue['__typename'];
				await this.issuesStore.bulkDocs([issue]);
			} else {
				throw e;
			}
		}
	};

	saveIssues = async (issues) => {
		await this.removeIssues();
		issues = clone(issues);
		issues = uniqueBy(issues, 'id')
			.sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate))
			.map((issue) => {
				issue['_id'] = `${issue.id}`;
				delete issue['__typename'];
				return issue;
			});

		await this.issuesStore.bulkDocs(issues);

		return clone(issues);
	};

	removeIssues = async () => {
		const issues = await this.getIssues();
		for (const issue of issues) {
			await this.issuesStore.remove(issue);
		}
		return issues;
	};

	removeIssue = async (id) => {
		const issue = await this.getIssue(id);
		await this.issuesStore.remove(issue);
	};

	getArticles = async () => {
		const articles = [];
		const docs = await this.articlesStore.allDocs();
		for (const row of docs.rows) {
			const article = await this.articlesStore.get(row.id);
			articles.push(article);
		}
		return articles;
	};

	removeAllArticles = async () => {
		const articles = await this.getArticles();
		for (const article of articles) {
			await this.articlesStore.remove(article);
		}
		return articles;
	};

	getArticlesByIssue = async (IssueID) => {
		const articles = [];

		const response = await this.articlesStore.find({
			selector: {
				IssueID: { $eq: `${IssueID}` },
			},
		});

		for (let article of response.docs) {
			articles.push(article);
		}

		articles.sort((a, b) => (a.index > b.index ? 1 : -1));

		return articles;
	};

	saveArticles = async (articles, IssueID) => {
		try {
			await this.removeArticlesByIssue(IssueID);
		} catch (e) {
			console.error(e);
		}

		articles = articles.map((article) => {
			article.IssueID = `${IssueID}`;
			delete article['__typename'];
			return article;
		});

		articles = uniqueBy(articles, 'id');
		console.debug(`Start saving in the cache`);
		for (const article of articles) {
			try {
				console.debug(`Article: ${article.id}`);
				await this.saveArticle(article);
				console.debug(`Success saving: ${article.id}`);
			} catch (e) {
				console.error(e);
				console.debug(`Error storing the article: ${article.id}`);
			}
		}

		try {
			await this.syncFavorites(articles);
		} catch (e) {
			console.error(e);
		}

		return articles;
	};

	saveArticle = async (article) => {
		article['_id'] = article.id;
		await this.articlesStore.put(article);
	};

	removeArticlesByIssue = async (id) => {
		const articles = await this.getArticlesByIssue(id);
		for (let article of articles) {
			await this.removeArticle(article.id);
		}
	};

	syncFavorites = async (articles) => {
		console.info(`Start favorite sync`);
		// Transform the articles as a map
		articles = articles.reduce((result, article) => {
			result[article.id] = article;
			return result;
		}, {});

		// Fetch all the articles from the cache
		const favoriteArticles = await this.getFavoriteArticles();

		/* 
            We loop all the favorite articles
            if one of them exists in the incoming article
            we update it in the cache if it doesn't we 
            leave it as it is
        */
		const promises = [];
		for (let favoriteArticle of favoriteArticles) {
			if (articles[favoriteArticle.id]) {
				console.info(`Syncing Article ${favoriteArticle.id}`);
				promises.push(this.saveFavorite(favoriteArticle.id));
			}
		}

		await Promise.all(promises);
	};

	removeArticles = async (IssueID) => {
		const promises = [];
		for (let article of await this.getArticlesByIssue(IssueID)) {
			promises.push(await this.removeArticle(article.id));
		}
		return Promise.all(promises);
	};

	getArticleByID = async (id) => {
		return await this.articlesStore.get(id);
	};

	removeArticle = async (id) => {
		const article = await this.getArticleByID(id);
		await this.articlesStore.remove(article);
	};

	saveFavorite = async (id) => {
		try {
			const article = await this.getArticleByID(id);
			article.id = `${id}`;
			delete article['_rev'];
			await this.favoritesStore.put(article);
			return article;
		} catch (e) {
			console.error(e);
			return null;
		}
	};

	removeFavorite = async (id) => {
		try {
			const article = await this.getFavorite(id);
			await this.favoritesStore.remove(article);
			return article;
		} catch (e) {
			console.error(e);
			return null;
		}
	};

	removeFavorites = async () => {
		const articles = await this.getFavoriteArticles();
		for (const article of articles) {
			await this.favoritesStore.remove(article);
		}
		return articles;
	};

	getFavorite = async (id) => {
		return await this.favoritesStore.get(`${id}`);
	};

	getFavoriteArticles = async () => {
		const favorites = [];
		const docs = await this.favoritesStore.allDocs();
		for (const row of docs.rows) {
			const favorite = await this.favoritesStore.get(row.id);
			favorites.push(favorite);
		}
		return favorites;
	};

	isFavorite = async (id) => {
		try {
			const favorite = await this.getFavorite(id);
			return !!favorite;
		} catch (e) {
			return false;
		}
	};

	getStyles = async () => {
		const styles = [];
		const docs = await this.stylesStore.allDocs();
		for (const row of docs.rows) {
			const style = await this.getStyle(row.id);
			styles.push(style);
		}
		return styles;
	};

	getStyle = async (id) => {
		return await this.stylesStore.get(`${id}`);
	};

	updateStyle = async (style) => {
		try {
			const cachedStyle = await this.getStyle(style.id);

			const { _rev } = cachedStyle;

			style['_id'] = `${style.id}`;
			style['_rev'] = _rev;
			delete style['__typename'];

			await this.stylesStore.put(style);
		} catch (e) {
			if (!!e.name && e.name === 'not_found') {
				style['_id'] = `${style.id}`;
				delete style['__typename'];
				await this.stylesStore.bulkDocs([style]);
			} else {
				throw e;
			}
		}
	};

	saveStyles = async (styles) => {
		await this.removeStyles();

		styles = uniqueBy(styles, 'id')
			.sort((a, b) => new Date(b.created) - new Date(a.created))
			.map((style) => {
				style['_id'] = `${style.id}`;
				delete style['__typename'];
				return style;
			});

		await this.stylesStore.bulkDocs(styles);

		return styles;
	};

	removeStyles = async () => {
		const styles = await this.getStyles();
		for (const style of styles) {
			await this.stylesStore.remove(style);
		}
		return styles;
	};

	removeStyle = async (id) => {
		const style = await this.getStyle(id);
		await this.stylesStore.remove(style);
	};

	getFeeds = async () => {
		const feeds = [];
		const docs = await this.feedsStore.allDocs();
		for (const row of docs.rows) {
			const feed = await this.getFeed(row.id);
			feeds.push(feed);
		}
		return feeds;
	};

	getFeed = async (id) => {
		try {
			return await this.feedsStore.get(`${id}`);
		} catch (e) {
			if (!!e.name && e.name === 'not_found') {
				return null;
			} else {
				throw e;
			}
		}
	};

	updateFeed = async (feed) => {
		try {
			const cachedFeed = await this.getFeed(feed.id);

			const { _rev } = cachedFeed;

			feed['_id'] = `${feed.id}`;
			feed['_rev'] = _rev;
			delete feed['__typename'];

			await this.feedsStore.put(feed);
		} catch (e) {
			if (!!e.name && e.name === 'not_found') {
				feed['_id'] = `${feed.id}`;
				delete feed['__typename'];
				await this.feedsStore.bulkDocs([feed]);
			} else {
				throw e;
			}
		}
	};

	saveFeed = async (feed) => {
		feed['_id'] = `${feed.id}`;
		delete feed['__typename'];
		await this.feedsStore.put(feed);
	};

	saveFeeds = async (feeds) => {
		await this.removeFeeds();

		feeds = uniqueBy(feeds, 'id').map((feed) => {
			feed['_id'] = `${feed.id}`;
			delete feed['__typename'];
			return feed;
		});

		await this.feedsStore.bulkDocs(feeds);

		return feeds;
	};

	removeFeeds = async () => {
		const feeds = await this.getFeeds();
		for (const feed of feeds) {
			await this.feedsStore.remove(feed);
		}
		return feeds;
	};

	removeFeed = async (id) => {
		const feed = await this.getFeed(id);
		await this.feedsStore.remove(feed);
	};

	/*GROUPS*/

	getFeedGroups = async () => {
		const feedGroups = [];
		const docs = await this.feedGroupsStore.allDocs();
		for (const row of docs.rows) {
			const feedGroup = await this.getFeedGroup(row.id);
			feedGroups.push(feedGroup);
		}
		return feedGroups;
	};

	getFeedGroup = async (id) => {
		try {
			return await this.feedGroupsStore.get(`${id}`);
		} catch (e) {
			if (!!e.name && e.name === 'not_found') {
				return null;
			} else {
				throw e;
			}
		}
	};

	updateFeedGroup = async (feedGroup) => {
		try {
			const cachedFeedGroup = await this.getFeedGroup(feedGroup.id);

			const { _rev } = cachedFeedGroup;

			feedGroup['_id'] = `${feedGroup.id}`;
			feedGroup['_rev'] = _rev;
			delete feedGroup['__typename'];

			await this.feedGroupsStore.put(feedGroup);
		} catch (e) {
			if (!!e.name && e.name === 'not_found') {
				feedGroup['_id'] = `${feedGroup.id}`;
				delete feedGroup['__typename'];
				await this.feedGroupsStore.bulkDocs([feedGroup]);
			} else {
				throw e;
			}
		}
	};

	saveFeedGroups = async (feedGroups) => {
		await this.removeFeedGroups();
		feedGroups = uniqueBy(feedGroups, 'id').map((feedGroup) => {
			feedGroup['_id'] = `${feedGroup.id}`;
			delete feedGroup['__typename'];
			return feedGroup;
		});

		await this.feedGroupsStore.bulkDocs(feedGroups);

		return feedGroups;
	};

	removeFeedGroups = async () => {
		const feedGroups = await this.getFeedGroups();
		for (const feedGroup of feedGroups) {
			await this.feedGroupsStore.remove(feedGroup);
		}
		return feedGroups;
	};

	removeFeedGroup = async (id) => {
		const feedGroup = await this.getFeedGroup(id);
		await this.feedGroupsStore.remove(feedGroup);
	};
}

function clone(obj) {
	return obj ? JSON.parse(JSON.stringify(obj)) : obj;
}

function uniqueBy(inputs, property) {
	const response = [];
	const mapResponse = {};
	for (let input of inputs) {
		mapResponse[`${input[property]}`] = input;
	}

	for (let input in mapResponse) {
		response.push(mapResponse[input]);
	}

	return response;
}
