import React from 'react';
import PropTypes from 'prop-types';

import styles from '../article.module.css';

export const StyledAudio = ({
	id,
	audiopath,
	type,
	bleed,
	caption,
	captionenabled,
	audioid
}) => {
	return (
		<Audio
			id={id}
			type={type}
			bleed={bleed === 'on'}
			caption={caption}
			audioId={audioid}
			captionEnabled={captionenabled === 'on'}
			audioPath={audiopath}
		/>
	);
};

StyledAudio.propTypes = {
	id: PropTypes.string,
	audiopath: PropTypes.string.isRequired,
	type: PropTypes.oneOf(['mp3', 'soundcloud']),
	audioid: PropTypes.string,
	bleed: PropTypes.string,
	caption: PropTypes.string,
	captionenabled: PropTypes.string,
};

export const Audio = ({
	id,
	type,
	audioPath,
	bleed,
	caption,
	captionEnabled,
	audioId
}) => {
	const className = [styles['audio'], 'media', 'audio'];
	if (bleed) {
		className.push('bleed');
	}

	let component  = null;

	switch (type) {
		case 'soundcloud':
			component = <Soundcloud audioId={audioId}/>
			break;
		default:
			component = <audio controls>
			<source src={audioPath} type="audio/mpeg" />
		</audio>;
	}

	return (
		<figure id={id} className={className.join(' ')}>
			{component}
			{captionEnabled && caption ? <Caption content={caption} /> : null}
		</figure>
	);
};

Audio.propTypes = {
	id: PropTypes.string,
	type: PropTypes.oneOf(['mp3', 'soundcloud']),
	audioId: PropTypes.string,
	audioPath: PropTypes.string.isRequired,
	bleed: PropTypes.bool,
	caption: PropTypes.string,
	captionEnabled: PropTypes.bool,
};

const Caption = ({ content }) => {
	return <figcaption dangerouslySetInnerHTML={{ __html: content }} />;
};

Caption.propTypes = {
	content: PropTypes.string,
};

function Soundcloud({audioId}) {
	return (
		<iframe width="100%" height="300" allow="autoplay" src={`https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${audioId}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`}/>
	);
}

Soundcloud.propTypes = {
	audioId: PropTypes.string,
};
