import React from 'react';
import PropTypes from 'prop-types';
import styles from './article.module.css';

import { CanvasContext } from './Context';

export const Canvas = ({
	id,
	paddingLeft,
	paddingRight,
	children,
	isAd,
	style,
}) => {
	const classNames = [styles['canvas'], 'canvas'];
	if (isAd) {
		classNames.push(styles['ad']);
	}

	let canvasStyle = {
		borderBottom: '1px solid transparent',
		overflow: 'hidden',
	};

	if (style) {
		canvasStyle = { ...canvasStyle, ...style };
	}

	return (
		<div id={id} className={classNames.join(' ')}>
			<div style={canvasStyle}>
				<CanvasContext.Provider
					value={{
						top,
						paddingLeft,
						paddingRight,
					}}
				>
					{children}
				</CanvasContext.Provider>
			</div>
		</div>
	);
};

Canvas.propTypes = {
	id: PropTypes.string,
	color: PropTypes.string,
	style: PropTypes.any,
	backgroundColor: PropTypes.string,
	isAd: PropTypes.bool,
	isSelected: PropTypes.bool,
	paddingLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	paddingRight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	paddingBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	paddingTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

Canvas.defaultProps = {
	color: '#ffffff',
	backgroundColor: '#ffffff',
	paddingTop: 0,
	paddingBottom: 0,
	horizontalPadding: 0,
	isAd: false,
};
