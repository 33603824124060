import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import styles from './Home.module.css';

import { AppContext } from '../services/AppContext';
import { Loading } from '../components/loading/Loading';
import { SearchNavbar } from '../components/navbar/Navbar';
import Sidebar from '../components/sidebar/Sidebar';
import EmptyState from '../components/empty/EmptyState';
import BottomBar from '../components/bottombar/BottomBar';
import { Link } from 'react-router-dom';

export default class Feeds extends React.Component {
	static contextType = AppContext;
	state = {
		isLoading: true,
		feedGroups: [],
	};

	componentDidMount() {
		const { location } = this.props;
		localStorage.setItem('last-path', location.pathname);

		this.getFeedGroups()
			.then((feedGroups) => {
				this.setState({ isLoading: false, feedGroups });
			})
			.catch((e) => {
				console.error(e);
				this.setState({ isLoading: false, error: e.message });
			});
	}

	getFeedGroups = async () => {
		const { api, isOnline, db } = this.context;
		const cachedFeedGroups = await db.getFeedGroups();
		const expiredAt = localStorage.getItem('feeds_groups_expire_at');
		if (!expiredAt) {
			localStorage.setItem(
				'feeds_groups_expire_at',
				moment(new Date()).add(1, 'd')
			);
		}

		if (cachedFeedGroups.length) {
			if (moment(new Date()).isBefore(new Date(expiredAt))) {
				return cachedFeedGroups;
			}
		}

		if (isOnline) {
			console.debug('Fetching feed groups from remote');
			const remoteFeedGroups = await api.getFeedGroups();
			console.debug('Store feeds in cache');
			await db.saveFeedGroups(remoteFeedGroups);
			for(const feedGroup of remoteFeedGroups) {
				await this.saveGroup(feedGroup);
			}

			localStorage.setItem(
				'feeds_groups_expire_at',
				moment(new Date()).add(1, 'd')
			);

			return remoteFeedGroups;
		}

		return await db.getFeedGroups();
	};

	saveGroup = async (group) => {
		const { db } = this.context;
		const { feeds } = group;
		for (const feed of feeds) {
			const { id } = feed;
			const cachedFeed = await db.getFeed(id);
			if (cachedFeed) {
				await db.removeFeed(id);
			}

			await db.saveFeed(feed);
		}
	};

	timeout = async (ms = 500) => {
		return new Promise((resolve) => {
			setTimeout(resolve, ms);
		});
	};

	render() {
		const { isLoading, feedGroups } = this.state;

		if (isLoading) {
			return <LoadingComponent />;
		}

		if (!feedGroups.length) {
			return <Empty />;
		}

		return <FeedList groups={feedGroups} />;
	}
}

Feeds.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

function LoadingComponent() {
	const { isOnline, theme, config } = useContext(AppContext);

	return (
		<div className="App">
			<SearchNavbar
				isOnline={isOnline}
				theme={theme?.navbar || config?.theme?.navbar}
			/>
			<main>
				<section className={styles['home']}>
					<Sidebar
						theme={theme?.bottomBar || config?.theme?.bottomBar}
						section="feed"
					/>
					<Loading theme={config?.theme?.loading} />
				</section>
			</main>
			<BottomBar
				section="feed"
				isVisible={true}
				theme={theme?.bottomBar || config?.theme?.bottomBar}
			/>
		</div>
	);
}

function Empty() {
	const { isOnline, theme } = useContext(AppContext);
	return (
		<div className="App">
			<SearchNavbar isOnline={isOnline} theme={theme.navbar} />
			<main>
				<section className={styles['home']}>
					<Sidebar theme={theme.bottomBar} section="feed" />
					<EmptyState section={'feeds'} />
				</section>
			</main>
			<BottomBar
				section="feed"
				isVisible={true}
				theme={theme.bottomBar}
			/>
		</div>
	);
}

function FeedList({ groups }) {
	const { isOnline, theme } = useContext(AppContext);
	return (
		<div className="App">
			<SearchNavbar isOnline={isOnline} theme={theme.navbar} />

			<main>
				<section
					className={styles['home']}
					style={{
						overflow: groups.length ? 'auto' : 'none',
					}}
				>
					<Sidebar theme={theme.bottomBar} section="feed" />
					<div className={styles['feed-group-list']}>
						{groups.map((group) => (
							<FeedGroup key={group.id} group={group} />
						))}
					</div>
				</section>
			</main>
			<BottomBar
				section="feed"
				isVisible={true}
				theme={theme.bottomBar}
			/>
		</div>
	);
}

FeedList.propTypes = {
	groups: PropTypes.array.isRequired,
};

function FeedGroup({ group }) {
	let { name, id, feeds } = group;
	return (
		<div id={`feed-group-${id}`} className={styles['feed-group']}>
			<div>
				<h3>{name}</h3>
			</div>
			<div>
				{feeds.map((feed) => (
					<Feed key={feed.id} feed={feed} />
				))}
			</div>
		</div>
	);
}

FeedGroup.propTypes = {
	group: PropTypes.object.isRequired,
};

function Feed({ feed }) {
	const { id, name, image } = feed;
	return (
		<Link to={`/feeds/${id}`} className={styles['feed']}>
			<div className={styles['image']}>
				{image ? <img src={image} /> : null}
			</div>
			<div className={styles['name']}>
				<h3>{name}</h3>
			</div>
		</Link>
	);
}

Feed.propTypes = {
	feed: PropTypes.object.isRequired,
};
