import React from 'react';
import PropTypes from 'prop-types';

import styles from '../article.module.css';

export const Anchor = ({ name }) => {
	return <span id={name} className={styles['anchor']} />;
};

Anchor.propTypes = {
	name: PropTypes.string,
};
