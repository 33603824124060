import React from 'react';
import PropTypes from 'prop-types';

import { Link, Redirect } from 'react-router-dom';

import styles from './Settings.module.css';
import doneIcon from './icons/done-icon.svg';

import { AppContext } from '../services/AppContext';

import Navbar from '../components/navbar/Navbar';
import Sidebar from '../components/sidebar/Sidebar';
import BottomBar from '../components/bottombar/BottomBar';
import moment from 'moment';

import { Loading } from '../components/loading/Loading';

export default class Settings extends React.Component {
	static contextType = AppContext;

	state = {
		isAuthenticated: false,
	};

	componentDidMount() {
		const { location } = this.props;
		localStorage.setItem('last-path', location.pathname);
		if (localStorage.getItem('language')) {
			this.setState({
				language: localStorage.getItem('language'),
				isAuthenticated: this.isAuthenticated(),
			});
		}
	}

	onClickLogOut = () => {
		localStorage.removeItem('me', '');
		localStorage.removeItem('token', '');
		localStorage.removeItem('expiredAt', '');
		localStorage.removeItem('subscription_expired_at', '');
		this.setState({
			isAuthenticated: false,
		});
	};

	isAuthenticated = () => {
		return !!localStorage.getItem('me') && !!localStorage.getItem('token');
	};

	onUserClick = () => {
		const { isAuthenticated } = this.state;
		if (isAuthenticated) {
			this.onClickLogOut();
		}
	};

	render() {
		const {
			isOnline,
			theme,
			locale,
			enableAuthorization,
			admin,
			isUserAdmin,
			onAdminLogout,
		} = this.context;
		const { isAuthenticated, language } = this.state;

		let userComponent = (
			<Link to={`/authenticate`}>{locale['SETTINGS_SIGN_IN']}</Link>
		);
		if (isAuthenticated) {
			userComponent = (
				<div onClick={this.onClickLogOut}>
					{locale['SETTINGS_SIGN_OUT']}
				</div>
			);
		}

		let subscriptionComponent = null;
		if (isAuthenticated) {
			subscriptionComponent = (
				<Link to={`/settings/subscription`}>
					{locale['SETTINGS_SUBSCRIPTION']}
				</Link>
			);
		}

		let storageComponent = (
			<Link to={`/settings/storage`}>{locale['SETTINGS_STORAGE']}</Link>
		);
		let languageComponent = (
			<Link
				to={`/settings/language`}
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<div>{locale['SETTINGS_LANGUAGE']}</div>
				<div style={{ color: '#c4c4c4' }}>
					{mapLang(
						localStorage.getItem('language')
							? localStorage.getItem('language')
							: '',
						language
					)}
				</div>
			</Link>
		);

		const aboutComponent = (
			<Link to={`/settings/about`}>{locale['SETTINGS_ABOUT']}</Link>
		);

		const adminComponent =
			admin?.enabled && isUserAdmin ? (
				<div
					style={{ marginTop: 20, color: 'red', fontWeight: 'bold' }}
					onClick={onAdminLogout}
				>
					<div>{locale['SETTINGS_SIGN_OUT']}</div>
				</div>
			) : null;

		return (
			<div className="App">
				<Navbar isOnline={isOnline} theme={theme.navbar} />
				<main>
					<section
						className={styles['settings']}
						style={{ paddingTop: theme.navbar.height }}
					>
						<Sidebar theme={theme.bottomBar} section="settings" />
						<div>
							{enableAuthorization ? (
								<div>{userComponent}</div>
							) : (
								<div style={{ height: 0 }}></div>
							)}

							<div className={styles['group']}>
								{enableAuthorization
									? subscriptionComponent
									: null}
								{storageComponent}
								{languageComponent}
								{aboutComponent}
								{adminComponent}
							</div>
						</div>
					</section>
				</main>
				<BottomBar
					section="settings"
					isVisible={true}
					theme={theme.bottomBar}
				/>
			</div>
		);
	}
}

Settings.propTypes = {
	location: PropTypes.object.isRequired,
};

export class SettingsStorage extends React.Component {
	static contextType = AppContext;

	componentDidMount() {
		const { location } = this.props;
		localStorage.setItem('last-path', location.pathname);
	}

	onClickFreeSpace = async () => {
		const { locale } = this.context;
		// TODO Free Disk Space
		// const { tracking } = this.context;
		/*tracking.event({
			category: 'User',
			action: 'Click free space',
		});*/

		const response = await this.clearImageAssets();
		const { images } = response;
		const message = [
			`${locale['SETTINGS_ASSETS_REMOVED_INTRO_MESSAGE']}:`,
			`- ${images} ${locale['SETTINGS_FREE_SPACE_IMAGES']}`,
		].join('\n');
		alert(message);
	};

	clearImageAssets = async () => {
		if (!('caches' in window)) {
			console.error(`Caches do not exist in window`);
			return {
				images: 0,
			};
		}
		try {
			const hostname = window.location.hostname;
			const imageCache = await caches.open('images');
			const images = await imageCache.keys();
			const regex = new RegExp(`(.)*${hostname}(.)+`, 'i');
			for (const image of images) {
				const imageUrl = image.url;

				// I remove all the assets that do no have the same domain
				if (!regex.test(imageUrl)) {
					await imageCache.delete(image);
				}
			}

			return {
				images: images.length,
			};
		} catch (e) {
			console.error(e);
		} finally {
			localStorage.setItem('downloaded-issues', '{}');
		}

		return {
			images: 0,
		};
	};

	onClickClearCache = async () => {
		const { locale } = this.context;
		/*TODO CLEAR CACHE
		const {tracking} = this.context;
		tracking.event({
			category: 'User',
			action: 'Click clear Cache',
		});*/

		const { images } = await this.clearImageAssets();
		const { issues, articles, favorites } = await this.clearDB();

		const message = [
			`${locale['SETTINGS_ASSETS_REMOVED_INTRO_MESSAGE']}:`,
			`- ${locale['SETTINGS_CLEAR_CACHE_IMAGES_MESSAGE']}: ${images}`,
			`- ${locale['SETTINGS_CLEAR_CACHE_ISSUES_MESSAGE']}: ${issues}`,
			`- ${locale['SETTINGS_CLEAR_CACHE_ARTICLES_MESSAGE']}: ${articles}`,
			`- ${locale['SETTINGS_CLEAR_CACHE_FAVORITE_ARTICLES_IMAGES_MESSAGE']}: ${favorites}`,
		];

		alert(message.join('\n'));
	};

	clearDB = async () => {
		const { db } = this.context;
		try {
			const issues = await db.removeIssues();
			const articles = await db.removeAllArticles();
			const favorites = await db.removeFavorites();

			return {
				issues: issues.length,
				articles: articles.length,
				favorites: favorites.length,
			};
		} catch (e) {
			console.error(e);
		}

		return {
			issues: 0,
			articles: 0,
			favorites: 0,
		};
	};

	render() {
		const { isOnline, theme, locale } = this.context;

		return (
			<div className="App">
				<Navbar
					isOnline={isOnline}
					backTo={'/settings'}
					theme={theme.navbar}
				/>
				<main>
					<section className={styles['settings-storage']}>
						<div className={styles['group']}>
							<div onClick={this.onClickFreeSpace}>
								{locale['SETTINGS_FREE_SPACE']}
							</div>
							<div onClick={this.onClickClearCache}>
								{locale['SETTINGS_CLEAR_CACHE']}
							</div>
						</div>
					</section>
				</main>
			</div>
		);
	}
}

SettingsStorage.propTypes = {
	location: PropTypes.object.isRequired,
};

export class SettingsSubscription extends React.Component {
	static contextType = AppContext;

	componentDidMount() {
		const { location } = this.props;
		localStorage.setItem('last-path', location.pathname);
	}

	state = {
		isAuthenticated:
			!!localStorage.getItem('me') && !!localStorage.getItem('token'),
	};

	isAuthenticated = () => {
		return !!localStorage.getItem('me') && !!localStorage.getItem('token');
	};

	render() {
		const { isOnline, theme, locale } = this.context;
		const { isAuthenticated } = this.state;

		const expirationDate = localStorage.getItem('subscription_expired_at');

		if (!isAuthenticated) {
			return <Redirect to={'/settings'} />;
		}

		let content = (
			<div>
				<h4>{locale['SETTINGS_SUBSCRIPTION_INACTIVE_ERROR']}</h4>
			</div>
		);

		if (expirationDate) {
			content = (
				<div>
					<h4>{locale['SETTINGS_SUBSCRIPTION_EXPIRE_AT']}</h4>
					<p>
						{moment(new Date(expirationDate)).format(
							'MMMM Do YYYY'
						)}
					</p>
				</div>
			);
		}

		return (
			<div className="App">
				<Navbar
					isOnline={isOnline}
					backTo={'/settings'}
					theme={theme.navbar}
				/>
				<main>
					<section className={styles['settings-subscription']}>
						{content}
					</section>
				</main>
			</div>
		);
	}
}

SettingsSubscription.propTypes = {
	location: PropTypes.object.isRequired,
};

export class SettingsLanguage extends React.Component {
	static contextType = AppContext;

	state = {
		language: localStorage.getItem('language')
			? localStorage.getItem('language')
			: 'en',
		languages: [],
	};

	componentDidMount() {
		const { location } = this.props;
		localStorage.setItem('last-path', location.pathname);
	}

	onLangClick = (lang) => {
		const { tracking, onLangChange } = this.context;
		tracking.event({
			category: 'User',
			event: 'change_language',
			label: 'Change language',
			value: lang,
		});
		localStorage.setItem('language', lang);
		onLangChange(lang);
		this.setState({
			language: lang,
		});
	};

	render() {
		const { language } = this.state;
		const { isOnline, theme, languages } = this.context;

		return (
			<div className="App">
				<Navbar
					isOnline={isOnline}
					backTo={'/settings'}
					theme={theme.navbar}
				/>
				<main>
					<section className={styles['settings-language']}>
						<div className={styles['group']}>
							{languages.map((lang) => (
								<LangItem
									key={lang}
									onLangClick={this.onLangClick}
									selectedLanguage={language}
									language={lang}
								/>
							))}
						</div>
					</section>
				</main>
			</div>
		);
	}
}

SettingsLanguage.propTypes = {
	location: PropTypes.object.isRequired,
};

function LangItem({ language, selectedLanguage, onLangClick }) {
	return (
		<div
			key={language}
			onClick={() => {
				onLangClick(language);
			}}
		>
			<div>{mapLang(selectedLanguage, language)}</div>
			<div>
				{language === selectedLanguage ? <img src={doneIcon} /> : ''}
			</div>
		</div>
	);
}

LangItem.propTypes = {
	language: PropTypes.string.isRequired,
	selectedLanguage: PropTypes.string.isRequired,
	onLangClick: PropTypes.func.isRequired,
};

export class SettingsAbout extends React.Component {
	static contextType = AppContext;

	state = {
		clickCounter: 0,
		isAdminOpen: false,
	};

	componentDidMount() {
		const { location } = this.props;
		localStorage.setItem('last-path', location.pathname);
	}

	getCounter = () => {
		return this.state.clickCounter;
	};

	onAdminDialogClose = () => {
		this.setState({ isAdminOpen: false, clickCounter: 0 });
	};

	onOpenDialog = () => {
		this.setState({ isAdminOpen: true });
	};

	onVersionClick = () => {
		const { clickCounter } = this.state;
		if (!clickCounter) {
			setTimeout(() => {
				const counter = this.getCounter();
				if (counter >= 2) {
					this.onOpenDialog();
				}
				this.setState({ clickCounter: 0 });
			}, 400);
		}
		this.setState({ clickCounter: clickCounter + 1 });
	};

	render() {
		const { isAdminOpen } = this.state;
		const { isOnline, theme, locale, version, device, admin, isUserAdmin } =
			this.context;

		return (
			<div className="App">
				<Navbar
					isOnline={isOnline}
					backTo={'/settings'}
					theme={theme.navbar}
				/>
				<main>
					<section className={styles['settings-about']}>
						<div className={styles['group']}>
							<div>
								<div>{locale['ABOUT_VERSION']}</div>
								<div
									style={{ color: '#c4c4c4' }}
									onClick={this.onVersionClick}
								>
									{version}
								</div>
							</div>
						</div>
						<div
							className={styles['group']}
							style={{ marginTop: 20 }}
						>
							<div>
								<div>{locale['ABOUT_DEVICE_TYPE']}</div>
								<div style={{ color: '#c4c4c4' }}>
									{device?.type}
								</div>
							</div>
							<div>
								<div>{locale['ABOUT_DEVICE_ORIENTATION']}</div>
								<div style={{ color: '#c4c4c4' }}>
									{device?.orientation}
								</div>
							</div>
							<div>
								<div>{locale['ABOUT_DEVICE_WIDTH']}</div>
								<div style={{ color: '#c4c4c4' }}>
									{device?.width} px
								</div>
							</div>
							<div>
								<div>{locale['ABOUT_DEVICE_HEIGHT']}</div>
								<div style={{ color: '#c4c4c4' }}>
									{device?.height} px
								</div>
							</div>
						</div>
					</section>
				</main>
				{admin?.enabled && !isUserAdmin ? (
					<AdminLoginDialog
						history={this.props.history}
						onClose={this.onAdminDialogClose}
						isOpen={isAdminOpen}
					/>
				) : null}
			</div>
		);
	}
}

SettingsAbout.propTypes = {
	location: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
};

export class AdminLoginDialog extends React.Component {
	static contextType = AppContext;
	state = {
		email: '',
		password: '',
		isLoading: false,
		error: null,
	};
	onSubmit = (e) => {
		e.preventDefault();

		const { api, onAdminLogin } = this.context;
		const { onClose } = this.props;
		const { email, password, isLoading } = this.state;
		if (isLoading) {
			return;
		}

		this.setState({ isLoading: true });
		api.authenticate(email, password, 'admin')
			.then((authenticate) => {
				const { token, expiredAt } = authenticate;
				localStorage.setItem('token', token);
				localStorage.setItem('expiredAt', expiredAt);
				return api.me();
			})
			.then((response) => {
				this.setState({ isLoading: false });
				const { me } = response;
				localStorage.setItem('me', JSON.stringify(me));
				onAdminLogin();
				onClose();
				this.props.history.replace('/settings');
			})
			.catch((err) => {
				this.setState({
					email: '',
					password: '',
					isLoading: false,
				});
				const message = err.message.replace('GraphQL error: ', '');
				this.showError(message);
			});
	};
	showError = (error) => {
		this.setState({ error });
		setTimeout(() => {
			this.setState({ error: '' });
		}, 3000);
	};
	render() {
		const { email, password, error, isLoading } = this.state;
		const { isOpen, onClose } = this.props;
		const { locale, config } = this.context;

		if (!isOpen) {
			return null;
		}

		return (
			<div className={styles['dialog-overlay']}>
				<div className={styles['admin-dialog-container']}>
					<div className={styles['topbar']}>
						<div></div>
						<h4>{locale['ADMIN']}</h4>
						<button onClick={onClose} />
					</div>
					<form
						className={styles['content']}
						onSubmit={this.onSubmit}
					>
						<div>
							<div
								className={`${styles['error']} ${
									!error ? styles['hide'] : ''
								}`}
							>
								{error}
							</div>
							<img src="/assets/apple-icon-180.png" />
							<input
								type="email"
								name="email"
								autoComplete="username"
								required
								placeholder="Email"
								value={email}
								onChange={(e) => {
									this.setState({ email: e.target.value });
								}}
							/>
							<input
								type="password"
								name="password"
								required
								autoComplete="current-password"
								placeholder="Password"
								value={password}
								onChange={(e) => {
									this.setState({ password: e.target.value });
								}}
							/>
						</div>
						<div>
							{isLoading ? (
								<button style={{ cursor: 'not-allowed' }}>
									<Loading theme={config?.theme?.loading} />
								</button>
							) : email && password ? (
								<button type="submit">
									{locale['SIGN_IN_WITH_EMAIL']}
								</button>
							) : (
								<button type="submit" disabled>
									{locale['SIGN_IN_WITH_EMAIL']}
								</button>
							)}
						</div>
					</form>
				</div>
			</div>
		);
	}
}

AdminLoginDialog.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
};

function mapLang(selectedLanguage, lang) {
	if (selectedLanguage === '') {
		switch (lang) {
			case 'en':
				return 'English';
			case 'de':
				return 'German';
			case 'fr':
				return 'French';
			case 'es':
				return 'Spanish';
			case 'it':
				return 'Italian';
			case 'ja':
				return 'Japanese';
			case 'nl':
				return 'Dutch';
			case 'pl':
				return 'Polish';
			case 'pt-pt':
				return 'Portuguese';
			case 'pt-br':
				return 'Portuguese (Brazilian)';
			case 'ru':
				return 'Russian';
			case 'zh':
				return 'Chinese';
		}
	}

	if (selectedLanguage === 'en') {
		switch (lang) {
			case 'en':
				return 'English';
			case 'de':
				return 'German';
			case 'fr':
				return 'French';
			case 'es':
				return 'Spanish';
			case 'it':
				return 'Italian';
			case 'ja':
				return 'Japanese';
			case 'nl':
				return 'Dutch';
			case 'pl':
				return 'Polish';
			case 'pt-pt':
				return 'Portuguese';
			case 'pt-br':
				return 'Portuguese (Brazilian)';
			case 'ru':
				return 'Russian';
			case 'zh':
				return 'Chinese';
		}
	}
	if (selectedLanguage === 'de') {
		switch (lang) {
			case 'en':
				return 'Englisch';
			case 'de':
				return 'Deutsch';
			case 'fr':
				return 'Französisch';
			case 'es':
				return 'Spanisch';
			case 'it':
				return 'Italienisch';
			case 'ja':
				return 'Japanisch';
			case 'nl':
				return 'Niederländisch';
			case 'pl':
				return 'Polnisch';
			case 'pt-pt':
				return 'Portugiesisch';
			case 'pt-br':
				return 'Portugiesisch (brasilianisch)';
			case 'ru':
				return 'Russisch';
			case 'zh':
				return 'Chinesisch';
		}
	}
	if (selectedLanguage === 'fr') {
		switch (lang) {
			case 'en':
				return 'Anglais';
			case 'de':
				return 'Allemand';
			case 'fr':
				return 'Français';
			case 'es':
				return 'Espagnol';
			case 'it':
				return 'Italien';
			case 'ja':
				return 'Japonais';
			case 'nl':
				return 'Néerlandais';
			case 'pl':
				return 'Polonais';
			case 'pt-pt':
				return 'Portugais';
			case 'pt-br':
				return 'Portugais (brésilien)';
			case 'ru':
				return 'Russe';
			case 'zh':
				return 'Chinois';
		}
	}
	if (selectedLanguage === 'es') {
		switch (lang) {
			case 'en':
				return 'Ingles';
			case 'de':
				return 'Alemán';
			case 'fr':
				return 'Francés';
			case 'es':
				return 'Español';
			case 'it':
				return 'Italiano';
			case 'ja':
				return 'Japonés';
			case 'nl':
				return 'Holandés';
			case 'pl':
				return 'Polaco';
			case 'pt-pt':
				return 'Portugués';
			case 'pt-br':
				return 'Portugués (brasileño)';
			case 'ru':
				return 'Ruso';
			case 'zh':
				return 'Chino';
		}
	}
	if (selectedLanguage === 'it') {
		switch (lang) {
			case 'en':
				return 'Inglese';
			case 'de':
				return 'Tedesco';
			case 'fr':
				return 'Francese';
			case 'es':
				return 'Spagnolo';
			case 'it':
				return 'Italiano';
			case 'ja':
				return 'Giapponese';
			case 'nl':
				return 'Olandese';
			case 'pl':
				return 'Polacco';
			case 'pt-pt':
				return 'Portoghese';
			case 'pt-br':
				return 'Portoghese (brasiliano)';
			case 'ru':
				return 'Russo';
			case 'zh':
				return 'Cinese';
		}
	}
	if (selectedLanguage === 'ja') {
		switch (lang) {
			case 'en':
				return '英語';
			case 'de':
				return 'ドイツ語';
			case 'fr':
				return 'フレンチ';
			case 'es':
				return 'スペイン語';
			case 'it':
				return 'イタリア語';
			case 'ja':
				return 'にほんご';
			case 'nl':
				return '蘭語';
			case 'pl':
				return 'ポーランド語';
			case 'pt-pt':
				return 'ポルトガル語';
			case 'pt-br':
				return 'ポルトガル語（ブラジル語';
			case 'ru':
				return 'ロシア語';
			case 'zh':
				return 'ちゅうごく';
		}
	}
	if (selectedLanguage === 'nl') {
		switch (lang) {
			case 'en':
				return 'Engels';
			case 'de':
				return 'Duits';
			case 'fr':
				return 'Frans';
			case 'es':
				return 'Spaans';
			case 'it':
				return 'Italiaans';
			case 'ja':
				return 'Japans';
			case 'nl':
				return 'Nederlands';
			case 'pl':
				return 'Pools';
			case 'pt-pt':
				return 'Portugees';
			case 'pt-br':
				return 'Portugees (Braziliaans)';
			case 'ru':
				return 'Russisch';
			case 'zh':
				return 'Chinees';
		}
	}
	if (selectedLanguage === 'pl') {
		switch (lang) {
			case 'en':
				return 'Angielski';
			case 'de':
				return 'Niemiecki';
			case 'fr':
				return 'Francuski';
			case 'es':
				return 'Hiszpański';
			case 'it':
				return 'Włoski';
			case 'ja':
				return 'Japończyk';
			case 'nl':
				return 'Holenderski';
			case 'pl':
				return 'Polski';
			case 'pt-pt':
				return 'Portugalski';
			case 'pt-br':
				return 'Portugalczyk (brazylijski)';
			case 'ru':
				return 'Rosyjski';
			case 'zh':
				return 'Chińczyk';
		}
	}
	if (selectedLanguage === 'pt-pt') {
		switch (lang) {
			case 'en':
				return 'Inglês';
			case 'de':
				return 'Alemão';
			case 'fr':
				return 'Francês';
			case 'es':
				return 'Espanhol';
			case 'it':
				return 'Italiano';
			case 'ja':
				return 'Japonês';
			case 'nl':
				return 'Holandês';
			case 'pl':
				return 'Polaco';
			case 'pt-pt':
				return 'Português';
			case 'pt-br':
				return 'Português (Brasileiro)';
			case 'ru':
				return 'Russo';
			case 'zh':
				return 'Chinês';
		}
	}
	if (selectedLanguage === 'pt-br') {
		switch (lang) {
			case 'en':
				return 'Inglês';
			case 'de':
				return 'Alemão';
			case 'fr':
				return 'Francês';
			case 'es':
				return 'Espanhol';
			case 'it':
				return 'Italiano';
			case 'ja':
				return 'Japonês';
			case 'nl':
				return 'Holandês';
			case 'pl':
				return 'Polonês';
			case 'pt-pt':
				return 'Português';
			case 'pt-br':
				return 'Português (brasileiro)';
			case 'ru':
				return 'Russo';
			case 'zh':
				return 'Chinês';
		}
	}
	if (selectedLanguage === 'ru') {
		switch (lang) {
			case 'en':
				return 'английский язык';
			case 'de':
				return 'немецкий язык';
			case 'fr':
				return 'французский';
			case 'es':
				return 'испанский';
			case 'it':
				return 'итальянский';
			case 'ja':
				return 'Японский';
			case 'nl':
				return 'голландский';
			case 'pl':
				return 'польский';
			case 'pt-pt':
				return 'Португальский';
			case 'pt-br':
				return 'португальский (бразильский)';
			case 'ru':
				return 'русский';
			case 'zh':
				return 'китайский язык';
		}
	}
	if (selectedLanguage === 'zh') {
		switch (lang) {
			case 'en':
				return '英文';
			case 'de':
				return '德国';
			case 'fr':
				return '法国人';
			case 'es':
				return '西班牙语';
			case 'it':
				return '意大利';
			case 'ja':
				return '日文';
			case 'nl':
				return '荷兰语';
			case 'pl':
				return '波兰语';
			case 'pt-pt':
				return '葡萄牙语';
			case 'pt-br':
				return '葡萄牙语（巴西)';
			case 'ru':
				return '俄文';
			case 'zh':
				return '中文';
		}
	}
	return lang;
}
