import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ArticleContext } from '../Context';

import { useIsInViewport } from '../hooks/use-viewport';
import { useComponentAnimation } from '../hooks/use-animation';

export const StyledButton = (props) => {
	const {
		id,
		text_lang,
		text,
		align,
		style,
		size,
		width,
		link,
		font_size,
		resource,
		pageid,
		pagetarget,
		animation,
	} = props;

	let { lang } = useContext(ArticleContext);
	let content = text;
	if (lang && text_lang?.[lang]) {
		content = text_lang[lang];
	}
	return (
		<Button
			id={id}
			align={align}
			width={width}
			resource={resource}
			pageId={pageid}
			pageTarget={pagetarget}
			animation={animation}
			size={size}
			link={link}
			fontSize={`${font_size}`}
			StyleID={style}
			textTransform={props?.case}
			text={content}
		/>
	);
};

StyledButton.propTypes = {
	id: PropTypes.string,
	text_lang: PropTypes.string,
	text: PropTypes.string,
	align: PropTypes.string,
	style: PropTypes.string,
	size: PropTypes.string,
	width: PropTypes.string,
	case: PropTypes.string,
	link: PropTypes.string,
	animation: PropTypes.any,
	resource: PropTypes.string,
	pagetarget: PropTypes.string,
	pageid: PropTypes.string,
	externallinktarget: PropTypes.string,
	font_size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const Button = ({
	id,
	align,
	size,
	link,
	text,
	animation,
	// Text
	StyleID,
	resource,
	pageId,
	pageTarget,
}) => {
	const ref = useRef();
	const [animationClasses, setAnimationClasses] = useState([]);
	const { onLightboxTargetClick, onEventSend } = useContext(ArticleContext);

	const hasAnimation = animation && animation.type !== 'none';

	const isInViewport = useIsInViewport(ref, hasAnimation);
	useComponentAnimation(ref, isInViewport, animation, setAnimationClasses);

	const containerStyle = {
		display: 'flex',
		width: '100%',
	};
	const componentStyle = {};

	let onClick = () => {
		console.log(`Nitthin happened`);
		console.log(`In button the resource is: ${resource}`);
		console.log(`Link: ${link}`);
	};

	const attr = {};

	switch (resource) {
		case 'url':
			if (link) {
				onClick = () => {
					onEventSend({
						event: 'external',
						data: {
							url: link,
							componentId: id,
							componentType: 'button',
						},
					});
					window.open(link, '_blank');
				};
			}
			break;
		case 'page':
			if (pageId && pageTarget) {
				onClick = () => {
					onLightboxTargetClick({
						id: pageId,
						resource,
						target: pageTarget,
					});
				};
			}
			break;
		default:
			attr['disabled'] = true;
			break;
	}

	// Alignment
	switch (align) {
		case 'left':
			containerStyle.justifyContent = 'flex-start';
			break;
		case 'right':
			containerStyle.justifyContent = 'flex-end';
			break;
		default:
			containerStyle.justifyContent = 'center';
	}

	// Size
	switch (size) {
		case 'xsmall':
			componentStyle.padding = '2px 5px';
			break;
		case 'small':
			componentStyle.padding = `5px 10px`;
			break;
		case 'medium':
			componentStyle.padding = `8px 18px`;
			break;
		case 'large':
			componentStyle.padding = `12px 30px`;
			break;
		case 'xlarge':
			componentStyle.padding = `14px 40px`;
			break;
	}

	return (
		<div ref={ref} id={id} style={containerStyle}>
			<button
				{...attr}
				className={[StyleID, ...animationClasses].join(' ')}
				style={componentStyle}
				onClick={onClick}
			>
				{text}
			</button>
		</div>
	);
};

Button.propTypes = {
	id: PropTypes.string,
	align: PropTypes.string,
	size: PropTypes.string,
	text: PropTypes.string,
	link: PropTypes.string,
	StyleID: PropTypes.string,
	fontSize: PropTypes.any,
	textTransform: PropTypes.string,
	resource: PropTypes.string,
	pageId: PropTypes.string,
	animation: PropTypes.any,
	pageTarget: PropTypes.string,
};

Button.defaultProps = {
	id: '',
	align: 'center',
	size: 'medium',
	text: '',
	StyleID: 'none',
	link: '',
	fontSize: 1,
};
