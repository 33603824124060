import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { AppContext } from '../../services/AppContext';
import styles from './EmptyState.module.css';

export default EmptyState;

function EmptyState({ section }) {
	const { locale } = useContext(AppContext);
	return (
		<div className={styles['empty-state-container']}>
			<div>
				<div
					className={`${styles['empty-state-image']} ${getImage(
						section
					)}`}
				></div>
				<div className={styles['empty-state-content']}>
					<h3>{getTitle(section, locale)}</h3>

					<p>{getContent(section, locale)}</p>
				</div>
			</div>
		</div>
	);
}

EmptyState.propTypes = {
	section: PropTypes.string.isRequired,
};

function getImage(section) {
	switch (section) {
		case 'bookmark':
			return styles['bookmark'];
		case 'publication':
			return styles['publication'];
		case 'purchases':
			return styles['purchases'];
		case 'feeds':
			return styles['feeds'];
		case 'articles':
			return styles['issue'];
		default:
			return '';
	}
}

function getTitle(section, locale) {
	switch (section) {
		case 'bookmark':
			return locale['EMPTY_BOOKMARK_TITLE'];
		case 'publication':
			return locale['EMPTY_PUBLICATION_TITLE'];
		case 'purchases':
			return locale['EMPTY_PURCHASES_TITLE'];
		case 'articles':
			return locale['EMPTY_ARTICLES_TITLE'];
		case 'feeds':
			return locale['EMPTY_FEEDS_TITLE'];
		default:
			return null;
	}
}

function getContent(section, locale) {
	switch (section) {
		case 'bookmark':
			return locale['EMPTY_BOOKMARK_CONTENT'];
		case 'publication':
			return locale['EMPTY_PUBLICATION_CONTENT'];
		case 'purchases':
			return locale['EMPTY_PURCHASES_CONTENT'];
		case 'articles':
			return locale['EMPTY_ARTICLES_CONTENT'];
		case 'feeds':
			return locale['EMPTY_FEEDS_CONTENT'];
		default:
			return null;
	}
}
