export default {
	FREE: 'Gratuit',
	CONTINUE: 'Continuer',
	OK: 'Ok',
	SUBSCRIBE: 'Souscrire',
	EMPTY_BOOKMARK_TITLE: `Vous n'avez aucun signet enregistré`,
	EMPTY_PUBLICATION_TITLE: 'Cette publication est vide',
	EMPTY_PURCHASES_TITLE: 'You have no purchases',
	EMPTY_ARTICLES_TITLE: `Vous n'avez aucun achat`,
	EMPTY_BOOKMARK_CONTENT: `La fonction de signet vous permet d'enregistrer et d'accéder rapidement à votre
    articles préférés. Appuyez simplement sur l'icône de signet sur n'importe quel
    article pour le sauvegarder, puis voir via le menu des signets.`,
	EMPTY_PUBLICATION_CONTENT: `Il n'y a aucun problème dans cette publication, veuillez vérifier à nouveau sous peu.`,
	EMPTY_PURCHASES_CONTENT: `Vous n'avez acheté aucun contenu. Une fois qu'un ou plusieurs paiements ont été effectués, vous pouvez consulter
    les détails de la transaction ici.`,
	EMPTY_ARTICLES_CONTENT: `Il n'y a pas d'articles dans ce numéro, veuillez vérifier à nouveau sous peu.`,
	SIGN_UP_WITH_EMAIL: 'Inscrivez-vous par e-mail',
	SIGN_UP_WITH_EMAIL_BACK_TITLE: `Toutes les options d'inscription`,
	SIGN_UP_WITH_EMAIL_CHECK_INBOX: `Vérifiez votre boîte de réception`,
	SIGN_IN_WITH_EMAIL: 'Connectez-vous avec e-mail',
	SIGN_IN_WITH_EMAIL_BACK_TITLE: 'Toutes les options de connexion',
	SIGN_IN_WITH_EMAIL_CHECK_INBOX: `Vérifiez votre boîte de réception`,
	SIGN_UP_HEADER: 'Rejoignez-nous',
	SIGN_UP_SUBHEADER: `Créez un compte pour recevoir de belles histoires dans votre
    boîte de réception, personnalisez votre page d'accueil et suivez les auteurs et
    des sujets que vous aimez.`,
	SIGN_IN_HEADER: 'Nous saluons le retour',
	SIGN_IN_SUBHEADER: `Connectez-vous pour obtenir des recommandations d'histoires personnalisées,
    suivez les auteurs et les sujets que vous aimez et interagissez avec
    histoires.`,
	SIGN_UP_WITH_GOOGLE: 'Inscrivez-vous avec Google',
	SIGN_UP_WITH_APPLE: 'Inscrivez-vous avec Apple',
	SIGN_IN_WITH_GOOGLE: 'Connectez-vous avec Google',
	SIGN_IN_WITH_APPLE: 'Connectez-vous avec Apple',
	AUTHENTICATE_SIGN_IN: 'Connectez-vous',
	AUTHENTICATE_YOUR_EMAIL: 'Votre e-mail',
	AUTHENTICATE_OFFLINE_ERROR: 'Vous devez être en ligne pour continuer',
	AUTHENTICATE_SIGN_UP_MESSAGE: 'Vous avez déjà un compte?',
	AUTHENTICATE_SIGN_UP: 'Inscrivez-vous',
	AUTHENTICATE_SIGN_IN_MESSAGE: 'Pas de compte?',
	AUTHENTICATE_SIGN_IN_CREATE_ONE: 'Créer une',
	AUTHENTICATE_EMAIL_NOT_EMPTY_ERROR: `L'e-mail ne peut pas être vide`,
	AUTHENTICATE_EMAIL_INVALID: `Adresse e-mail invalide`,
	AUTHENTICATE_EMAIL_DIGIT_MESSAGE: 'We just emailed a six-digit code to',
	AUTHENTICATE_EMAIL_DIGIT_MESSAGE_SUBMIT: `Nous venons d'envoyer un code à six chiffres à`,
	SETTINGS_SIGN_IN: 'Se connecter',
	SETTINGS_SIGN_OUT: 'Déconnexion',
	SETTINGS_SUBSCRIPTION: 'Abonnement',
	SETTINGS_SUBSCRIPTION_INACTIVE_ERROR: `Vous n'avez pas d'abonnement actif`,
	SETTINGS_SUBSCRIPTION_EXPIRE_AT: 'Votre abonnement prend fin le',
	SETTINGS_STORAGE: 'Espace de rangement',
	SETTINGS_LANGUAGE: 'Langue',
	SETTINGS_FREE_SPACE: 'Espace libre',
	SETTINGS_CLEAR_CACHE: 'Vider le cache',
	SETTINGS_ASSETS_REMOVED_INTRO_MESSAGE: 'Éléments supprimés',
	SETTINGS_CLEAR_CACHE_IMAGES_MESSAGE: 'Images',
	SETTINGS_CLEAR_CACHE_ISSUES_MESSAGE: 'Publications',
	SETTINGS_CLEAR_CACHE_ARTICLES_MESSAGE: 'Des Articles',
	SETTINGS_CLEAR_CACHE_FAVORITE_ARTICLES_IMAGES_MESSAGE: 'Articles préférés',
	SETTINGS_FREE_SPACE_IMAGES: 'images',
	PURCHASE_SUBSCRIPTION_HEADER: 'Abonnement',
	PURCHASE_SUBSCRIPTION_SUBHEADER:
		'Espace réservé pour un message sur les abonnements',
	PURCHASE_OFFLINE_ERROR:
		'Vous ne pouvez pas acheter hors ligne, veuillez réessayer plus tard',
	PURCHASES_OFFLINE_ERROR: 'Vous devez être en ligne pour voir les achats',
	PURCHASES_PRICE: 'Prix',
	PURCHASES_TYPE_ISSUE: 'Publications',
	PURCHASES_TYPE_SUBSCRIPTION: 'Abonnement',
};
