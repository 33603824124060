export default {
	FREE: 'Gratis',
	CONTINUE: 'Ga verder met',
	OK: 'Ok',
	SUBSCRIBE: 'Abonneer u op',
	EMPTY_BOOKMARK_TITLE: 'U heeft geen opgeslagen bladwijzers',
	EMPTY_PUBLICATION_TITLE: 'Deze publicatie is leeg',
	EMPTY_PURCHASES_TITLE: 'Je hebt geen aankopen',
	EMPTY_ARTICLES_TITLE: 'Dit nummer is leeg',
	EMPTY_BOOKMARK_CONTENT: `Met de bladwijzerfunctie kunt u opslaan en snel toegang krijgen tot uw
    favoriete artikelen. Tik gewoon op het bladwijzerpictogram op een
    artikel op te slaan en vervolgens te bekijken via het bladwijzermenu.`,
	EMPTY_PUBLICATION_CONTENT: `Er zijn geen problemen in deze publicatie, controleer dit nog eens kort.`,
	EMPTY_PURCHASES_CONTENT: `U heeft geen inhoud gekocht.  Zodra een of meer betalingen zijn gedaan, kunt u de volgende informatie bekijken 
    de transactie details hier.`,
	EMPTY_ARTICLES_CONTENT:
		'Er staan geen artikelen in dit nummer, kijk nog eens kort na.',
	SIGN_UP_WITH_EMAIL: 'Aanmelden met e-mail',
	SIGN_UP_WITH_EMAIL_BACK_TITLE: 'Alle aanmeldingsopties',
	SIGN_UP_WITH_EMAIL_CHECK_INBOX: 'Controleer uw inbox',
	SIGN_IN_WITH_EMAIL: 'Aanmelden met e-mail',
	SIGN_IN_WITH_EMAIL_BACK_TITLE: 'Alle aanmeldingsopties',
	SIGN_IN_WITH_EMAIL_CHECK_INBOX: 'Controleer uw inbox',
	SIGN_UP_HEADER: 'Sluit u bij ons aan',
	SIGN_UP_SUBHEADER: `Maak een account aan om geweldige verhalen te ontvangen in uw
    inbox, personaliseer uw homepage, en volg de auteurs en
    onderwerpen waar je van houdt.`,
	SIGN_IN_HEADER: 'Welkom terug',
	SIGN_IN_SUBHEADER: `Log in om gepersonaliseerde verhaalaanbevelingen te krijgen,
    volg de auteurs en onderwerpen waar je van houdt, en ga met ze om.
    Verhalen.`,
	SIGN_UP_WITH_GOOGLE: 'Aanmelden bij Google',
	SIGN_UP_WITH_APPLE: 'Aanmelden bij Apple',
	SIGN_IN_WITH_GOOGLE: 'Log in met Google',
	SIGN_IN_WITH_APPLE: 'Log in met Apple',
	AUTHENTICATE_SIGN_IN: 'Aanmelden',
	AUTHENTICATE_YOUR_EMAIL: 'Uw e-mail',
	AUTHENTICATE_OFFLINE_ERROR: 'Je moet online zijn om verder te kunnen gaan',
	AUTHENTICATE_SIGN_UP_MESSAGE: 'Heb je al een account?',
	AUTHENTICATE_SIGN_UP: 'Aanmelden',
	AUTHENTICATE_SIGN_IN_MESSAGE: 'Geen rekening?',
	AUTHENTICATE_SIGN_IN_CREATE_ONE: 'Maak een',
	AUTHENTICATE_EMAIL_NOT_EMPTY_ERROR: `E-mail kan niet leeg zijn`,
	AUTHENTICATE_EMAIL_INVALID: `Ongeldig e-mail adres`,
	AUTHENTICATE_EMAIL_DIGIT_MESSAGE:
		'We hebben net een zescijferige code gemaild naar',
	AUTHENTICATE_EMAIL_DIGIT_MESSAGE_SUBMIT:
		'We just emailed a six-digit code to',
	SETTINGS_SIGN_IN: 'Aanmelden',
	SETTINGS_SIGN_OUT: 'Uitloggen',
	SETTINGS_SUBSCRIPTION: 'Abonnement',
	SETTINGS_SUBSCRIPTION_INACTIVE_ERROR: 'U heeft geen actief abonnement',
	SETTINGS_SUBSCRIPTION_EXPIRE_AT: 'Uw abonnement eindigt op',
	SETTINGS_STORAGE: 'Opslag',
	SETTINGS_LANGUAGE: 'Taal',
	SETTINGS_FREE_SPACE: 'Vrije ruimte',
	SETTINGS_CLEAR_CACHE: 'Duidelijke Cache',
	SETTINGS_ASSETS_REMOVED_INTRO_MESSAGE: 'Verwijderde activa',
	SETTINGS_CLEAR_CACHE_IMAGES_MESSAGE: 'Afbeeldingen',
	SETTINGS_CLEAR_CACHE_ISSUES_MESSAGE: 'Uitgiften',
	SETTINGS_CLEAR_CACHE_ARTICLES_MESSAGE: 'Artikelen',
	SETTINGS_CLEAR_CACHE_FAVORITE_ARTICLES_IMAGES_MESSAGE:
		'Favoriete artikelen',
	SETTINGS_FREE_SPACE_IMAGES: 'afbeeldingen',
	PURCHASE_SUBSCRIPTION_HEADER: 'Abonnement',
	PURCHASE_SUBSCRIPTION_SUBHEADER:
		'Plaatshouder voor een bericht over abonnementen',
	PURCHASE_OFFLINE_ERROR:
		'U kunt niet offline kopen, probeer het later nog eens.',
	PURCHASES_OFFLINE_ERROR: 'U moet online zijn om aankopen te bekijken',
	PURCHASES_PRICE: 'Prijs',
	PURCHASES_TYPE_ISSUE: 'Uitgifte',
	PURCHASES_TYPE_SUBSCRIPTION: 'Abonnement',
};
