const RENAME_MAP_PROPERTIES = {
	padding_bottom: 'paddingBottom',
	padding_top: 'paddingTop',
	padding_left: 'paddingLeft',
	padding_right: 'paddingRight',
	margin_bottom: 'marginBottom',
	margin_top: 'marginTop',
	margin_left: 'marginLeft',
	margin_right: 'marginRight',
	line_height: 'lineHeight',
	background_color: 'backgroundColor',
	border_width: 'borderWidth',
	dropcap_background_color: 'dropcapBackgroundColor',
	dropcap_font_size: 'dropcapFontSize',
	dropcap_line_height: 'dropcapLineHeight',
	dropcap_char_count: 'dropcapCharCount',
	dropcap_line_count: 'dropcapLineCount',
	dropcap_color: 'dropcapColor',
	dropcap_font_family: 'dropcapFontFamily',
	font_family_bold: 'fontFamilyBold',
	font_family_bold_italic: 'fontFamilyBoldItalic',
	font_family_italic: 'fontFamilyItalic',
	dropcap_margin_top: 'dropcapMarginTop',
	dropcap_margin_right: 'dropcapMarginRight',
	dropcap_margin_left: 'dropcapMarginLeft',
	dropcap_margin_bottom: 'dropcapMarginBottom',
	dropcap_padding_top: 'dropcapPaddingTop',
	dropcap_padding_right: 'dropcapPaddingRight',
	dropcap_padding_left: 'dropcapPaddingLeft',
	dropcap_padding_bottom: 'dropcapPaddingBottom',
	text_indent: 'textIndent',
	paragraph_spacing: 'paragraphSpacing',
	font_size: 'fontSize',
	font_style: 'fontStyle',
	body_color: 'bodyColor',
	border_edge: 'borderEdge',
	border_type: 'borderType',
	border_color: 'borderColor',
	border_radius: 'borderRadius',
	font_weight: 'fontStyle',
	font_family: 'fontFamily',
	text_transform: 'textTransform',
	text_decoration: 'textDecoration',
	text_display: 'textDisplay',
	letter_spacing: 'letterSpacing',
	vsmall_height: 'vSmallHeight',
	small_height: 'smallHeight',
	medium_height: 'mediumHeight',
	large_height: 'largeHeight',
	vlarge_height: 'vLargeHeight',
	text_align: 'textAlign',
	horizontal_padding: 'horizontalPadding',
	list_style: 'listStyle',
	caption_position: 'captionPosition',
	component_margin_bottom: 'componentMarginBottom',
	tab_background_color: 'tabBackgroundColor',
	tab_content_top_margin: 'tabContentTopMargin',
	tab_padding_horizontal: 'tabPaddingHorizontal',
	tab_padding_vertical: 'tabPaddingVertical',
	tab_selected_background_color: 'tabSelectedBackgroundColor',
	tab_selected_highlight_color: 'tabSelectedHighlightColor',
	tab_selected_text_color: 'tabSelectedTextColor',
	tab_text_color: 'tabTextColor',
	content_align: 'contentAlign',
};

export class StyleBuilder {
	static resolveInheritance(styles) {
		const response = [];

		const stylesMap = styles.reduce(StyleBuilder.reduceStyles, new Map());

		for (const style of styles) {
			const parentId = style.parent ? `${style.parent}` : null;
			const s = StyleBuilder.mergeParentProperties(
				style,
				parentId,
				stylesMap
			);

			response.push(s);
		}

		return response;
	}

	static reduceStyles(acc, style) {
		acc.set(`${style.id}`, style);
		return acc;
	}

	static mapRenameProperties(style) {
		const { properties } = style;
		const rename = (component) => {
			for (const p in component) {
				if (p === 'unit') {
					for (const u in component.unit) {
						if (RENAME_MAP_PROPERTIES[u]) {
							component.unit[RENAME_MAP_PROPERTIES[u]] =
								JSON.parse(JSON.stringify(component.unit[u]));
							delete component.unit[u];
						}
					}
					continue;
				}

				if (RENAME_MAP_PROPERTIES[p]) {
					component[RENAME_MAP_PROPERTIES[p]] = component[p];
					delete component[p];
				}
			}
		};
		for (const prop in properties) {
			const component = properties[prop];
			if (Array.isArray(component)) {
				for (const item of component) {
					rename(item);
				}
				continue;
			}

			rename(component);
		}

		return style;
	}

	static mergeParentProperties(style, parentId, stylesMap) {
		if (!parentId) {
			return style;
		}

		let parent = stylesMap.get(`${parentId}`);

		if (!parent) {
			return style;
		}

		const parentStyle = StyleBuilder.mergeParentProperties(
			clone(parent),
			`${parent.parent}`,
			stylesMap
		);

		// Avoids recalculation of inheritance
		parentStyle.parent = null;
		stylesMap.set(`${parent.id}`, clone(parentStyle));

		const properties = StyleBuilder.overwriteProperties(
			parentStyle.properties,
			style.properties
		);

		style.properties = properties;

		style.parent = null;
		stylesMap.set(`${style.id}`, style);

		return style;
	}

	static mergeStyles(styles) {
		styles = styles.map((s) => clone(s));
		let result = null;
		for (const style of styles) {
			if (result === null) {
				result = style;
				continue;
			}
			if (!style || !style.properties) {
				continue;
			}
			result.properties = StyleBuilder.overwriteProperties(
				result.properties,
				style.properties
			);
		}
		return result;
	}

	static overwriteProperties(properties, overwrite) {
		const result = Object.assign({}, properties);
		overwrite = Object.assign({}, overwrite);

		for (const property in overwrite) {
			// If the property is an array add it
			if (Array.isArray(overwrite[property])) {
				if (!result[property]) {
					result[property] = [];
				}
				result[property] = result[property].concat(overwrite[property]);

				continue;
			}

			if (!result[property]) {
				result[property] = {};
			}

			// If the property is an object overwrite it
			result[property] = StyleBuilder.mergeDeep(
				result[property],
				overwrite[property]
			);
		}

		if (
			result['divider'] &&
			result['dividers'] &&
			result['dividers'].length
		) {
			const { style, bleed, width, color, margin, thickness } =
				result['divider'];

			const dividers = result.dividers.map((props) =>
				StyleBuilder.mergeDeep(
					{
						style,
						bleed,
						width,
						color,
						margin,
						thickness,
					},
					props
				)
			);

			result['dividers'] = dividers;
		}

		return result;
	}

	/**
	 * Deep merge two objects.
	 * @param target
	 * @param ...sources
	 */
	static mergeDeep(target, ...sources) {
		if (!sources.length) return target;
		const source = sources.shift();

		if (isObject(target) && isObject(source)) {
			for (const key in source) {
				if (isObject(source[key])) {
					if (!target[key]) Object.assign(target, { [key]: {} });
					StyleBuilder.mergeDeep(target[key], source[key]);
				} else {
					Object.assign(target, { [key]: source[key] });
				}
			}
		}

		return StyleBuilder.mergeDeep(target, ...sources);
	}
}

function isObject(item) {
	return item && typeof item === 'object' && !Array.isArray(item);
}

function clone(obj) {
	if (!obj) return obj;
	return JSON.parse(JSON.stringify(obj));
}
