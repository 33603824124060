export default {
	FREE: 'Free',
	CONTINUE: 'Continue',
	OK: 'Ok',
	SUBSCRIBE: 'Subscribe',
	EMPTY_BOOKMARK_TITLE: 'You have no saved bookmarks',
	EMPTY_PUBLICATION_TITLE: 'This publication is empty',
	EMPTY_FEEDS_TITLE: 'Empty Feed :(',
	EMPTY_PURCHASES_TITLE: 'You have no purchases',
	EMPTY_ARTICLES_TITLE: 'This issue is empty',
	EMPTY_BOOKMARK_CONTENT: `Tap the bookmark icon on any article to save it, 
	then access using the bookmark menu.`,
	EMPTY_PUBLICATION_CONTENT: `There are no issues in this publication, 
	please check again shortly.`,
	EMPTY_PURCHASES_CONTENT: `You have not purchased any content.  Once one or 
	more payments have been made you can view 
    the transaction details here.`,
	EMPTY_FEEDS_CONTENT: `There are no active feeds`,
	EMPTY_ARTICLES_CONTENT:
		'There are no articles in this issue, please check again shortly.',
	SIGN_UP_WITH_EMAIL: 'Sign up with email',
	SIGN_UP_WITH_EMAIL_BACK_TITLE: 'All sign up options',
	SIGN_UP_WITH_EMAIL_CHECK_INBOX: 'Check your inbox',
	SIGN_IN_WITH_EMAIL: 'Sign in with email',
	SIGN_IN_WITH_EMAIL_BACK_TITLE: 'All sign in options',
	SIGN_IN_WITH_EMAIL_CHECK_INBOX: 'Check your inbox',
	SIGN_UP_HEADER: 'Join Us',
	SIGN_UP_SUBHEADER: `Create an account to receive great stories in your
        inbox, personalize your homepage, and follow authors and
        topics that you love.`,
	SIGN_IN_HEADER: 'Welcome Back',
	SIGN_IN_SUBHEADER: `Sign in to get personalized story recommendations,
    follow authors and topics you love, and interact with
    stories.`,
	SIGN_UP_WITH_GOOGLE: 'Sign up with Google',
	SIGN_UP_WITH_APPLE: 'Sign up with Apple',
	SIGN_IN_WITH_GOOGLE: 'Sign in with Google',
	SIGN_IN_WITH_APPLE: 'Sign in with Apple',
	AUTHENTICATE_SIGN_IN: 'Sign In',
	AUTHENTICATE_YOUR_EMAIL: 'Your Email',
	AUTHENTICATE_FIRST_NAME: 'First name',
	AUTHENTICATE_LAST_NAME: 'Last name',
	AUTHENTICATE_EMAIL: 'Email',
	AUTHENTICATE_OFFLINE_ERROR: 'You need to be Online to continue',
	AUTHENTICATE_SIGN_UP_MESSAGE: 'Already have an account?',
	AUTHENTICATE_SIGN_UP: 'Sign Up',
	AUTHENTICATE_SIGN_IN_MESSAGE: 'No Account?',
	AUTHENTICATE_SIGN_IN_CREATE_ONE: 'Create One',
	AUTHENTICATE_EMAIL_NOT_EMPTY_ERROR: `Email can't be empty`,
	AUTHENTICATE_EMAIL_INVALID: `Invalid email address`,
	AUTHENTICATE_EMAIL_DIGIT_MESSAGE: 'We just emailed a six-digit code to',
	AUTHENTICATE_EMAIL_DIGIT_MESSAGE_SUBMIT:
		'We just emailed a six-digit code to',
	SETTINGS_SIGN_IN: 'Sign In',
	SETTINGS_SIGN_OUT: 'Sign Out',
	SETTINGS_SUBSCRIPTION: 'Subscription',
	SETTINGS_SUBSCRIPTION_INACTIVE_ERROR:
		'You do not have an active subscription',
	SETTINGS_SUBSCRIPTION_EXPIRE_AT: 'Your subscription ends on',
	SETTINGS_STORAGE: 'Storage',
	SETTINGS_LANGUAGE: 'Language',
	SETTINGS_FREE_SPACE: 'Free Space',
	SETTINGS_CLEAR_CACHE: 'Clear Cache',
	SETTINGS_ASSETS_REMOVED_INTRO_MESSAGE: 'Assets removed',
	SETTINGS_CLEAR_CACHE_IMAGES_MESSAGE: 'Images',
	SETTINGS_CLEAR_CACHE_ISSUES_MESSAGE: 'Issues',
	SETTINGS_CLEAR_CACHE_ARTICLES_MESSAGE: 'Articles',
	SETTINGS_CLEAR_CACHE_FAVORITE_ARTICLES_IMAGES_MESSAGE: 'Favorite Articles',
	SETTINGS_FREE_SPACE_IMAGES: 'images',
	PURCHASE_SUBSCRIPTION_HEADER: 'Subscription',
	PURCHASE_SUBSCRIPTION_SUBHEADER:
		'Placeholder for a message about subscriptions',
	PURCHASE_OFFLINE_ERROR:
		'You can not purchase offline, please try again later',
	PURCHASES_OFFLINE_ERROR: 'You need to be online to view purchases',
	PURCHASES_PRICE: 'Price',
	PURCHASES_TYPE_ISSUE: 'Issue',
	PURCHASES_TYPE_SUBSCRIPTION: 'Subscription',
	BUTTON_TITLE_HOME: 'Home',
	BUTTON_TITLE_FEEDS: 'Feeds',
	BUTTON_TITLE_BOOKMARKS: 'Bookmarks',
	BUTTON_TITLE_SETTINGS: 'Settings',
	BUTTON_TITLE_PURCHASES: 'Purchases',
	BUTTON_TITLE_SHARE: 'Share',
	BUTTON_TITLE_READER_MODE: 'Reader Mode',
	BUTTON_TITLE_BOOKMARK: 'Bookmark',
	BUTTON_TITLE_FONT_SIZE: 'Font Size',
	BUTTON_TITLE_FONT_SIZE_INCREASE: 'Increase Font',
	BUTTON_TITLE_FONT_SIZE_DECREASE: 'Decrease Font',
	BUTTON_TITLE_BACK: 'Back',
	BUTTON_TITLE_DOWNLOAD: 'Download',
	BUTTON_TITLE_PLAYER: 'Player',
	BUTTON_TITLE_TOC: 'Table of content',
	BUTTON_TITLE_PLAY: 'Play',
	BUTTON_TITLE_PAUSE: 'Pause',
	BUTTON_TITLE_IMAGE_MODE: 'Image Mode',
	UNAVAILABLE_IMAGE_MODE_TITLE: 'No preview available',
	UNAVAILABLE_IMAGE_MODE_SUBTITLE:
		"Sorry, we can't preview this article right now",
	SETTINGS_ABOUT: 'About',
	ABOUT_VERSION: 'Version',
	ABOUT_DEVICE_WIDTH: 'Width',
	ABOUT_DEVICE_HEIGHT: 'Height',
	ABOUT_DEVICE_TYPE: 'Type',
	ABOUT_DEVICE_ORIENTATION: 'Orientation',
	ADMIN: 'Admin',
	ADMIN_SIGN_IN: 'Sign In (Admin)',
};
