import React from 'react';
import PropTypes from 'prop-types';

export const StyledSpacer = ({ id, bleed, margin }) => {
	let type = 'medium';
	switch (margin) {
		case 'margin-1':
			type = 'vSmall';
			break;
		case 'margin-20':
			type = 'small';
			break;
		case 'margin-75':
			type = 'large';
			break;
		case 'margin-100':
			type = 'vLarge';
			break;
	}

	return (
		<Spacer id={id} type={type} bleed={bleed === 'on'} background={null} />
	);
};

StyledSpacer.propTypes = {
	id: PropTypes.string,
	bleed: PropTypes.string,
	margin: PropTypes.string,
};

export const Spacer = ({ id, bleed, background, type }) => {
	const containerStyle = {
		background,
		clear: 'both',
	};

	let className = ['spacer'];

	switch (type) {
		case 'vSmall':
			className.push('v-small');
			break;
		case 'small':
			className.push('small');
			break;
		case 'large':
			className.push('large');
			break;
		case 'vLarge':
			className.push('v-large');
			break;
		default:
			className.push('medium');
			break;
	}

	if (bleed) {
		className.push('bleed');
	}

	return (
		<div id={id} className={className.join(' ')} style={containerStyle}>
			<br />
		</div>
	);
};

Spacer.propTypes = {
	id: PropTypes.string,
	bleed: PropTypes.bool,
	height: PropTypes.number,
	background: PropTypes.string,
	type: PropTypes.string,
};

Spacer.defaultProps = {
	background: null,
};
