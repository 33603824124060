export default {
	FREE: '無料',
	CONTINUE: '続ける',
	OK: '宜しい',
	SUBSCRIBE: 'サブスクライブ',
	EMPTY_BOOKMARK_TITLE: 'ブックマークが保存されていません',
	EMPTY_PUBLICATION_TITLE: 'この出版物は空です',
	EMPTY_PURCHASES_TITLE: 'あなたは購入していません。',
	EMPTY_ARTICLES_TITLE: 'この問題は空です。',
	EMPTY_BOOKMARK_CONTENT: `ブックマーク機能を使えば、保存しておいて、すぐに
    お気に入りの記事をタップしてください。お気に入りの記事の上でブックマークアイコンをタップするだけです。
    記事を保存し、ブックマークメニューから表示します。`,
	EMPTY_PUBLICATION_CONTENT: `この出版物には問題がありません。`,
	EMPTY_PURCHASES_CONTENT: `あなたはコンテンツを購入していません。 1回以上の支払いが完了すると、以下のコンテンツを閲覧することができます。
    取引の詳細はこちら`,
	EMPTY_ARTICLES_CONTENT:
		'今号の記事はありませんので、お手数ですが再度ご確認ください。',
	SIGN_UP_WITH_EMAIL: 'メールで登録する',
	SIGN_UP_WITH_EMAIL_BACK_TITLE: 'すべてのサインアップオプション',
	SIGN_UP_WITH_EMAIL_CHECK_INBOX: '受信箱を確認する',
	SIGN_IN_WITH_EMAIL: 'メールでログイン',
	SIGN_IN_WITH_EMAIL_BACK_TITLE: 'すべてのサインインオプション',
	SIGN_IN_WITH_EMAIL_CHECK_INBOX: '受信箱を確認する',
	SIGN_UP_HEADER: '参加する',
	SIGN_UP_SUBHEADER: `アカウントを作成して、素敵なストーリーをお届けします。
    受信トレイ、あなたのホームページをパーソナライズし、著者とフォロー
    あなたの好きな話題`,
	SIGN_IN_HEADER: 'おかえりなさい',
	SIGN_IN_SUBHEADER: `サインインして、個人的なストーリーの推薦を受けることができます。
    好きな作家やトピックをフォローして、交流する
    の話をしています。`,
	SIGN_UP_WITH_GOOGLE: 'Googleに登録する',
	SIGN_UP_WITH_APPLE: 'Appleにサインアップ',
	SIGN_IN_WITH_GOOGLE: 'Googleでログイン',
	SIGN_IN_WITH_APPLE: 'Appleにサインイン',
	AUTHENTICATE_SIGN_IN: 'サインイン',
	AUTHENTICATE_YOUR_EMAIL: 'あなたのEメール',
	AUTHENTICATE_OFFLINE_ERROR: '続けるにはオンラインである必要があります',
	AUTHENTICATE_SIGN_UP_MESSAGE: 'すでにアカウントをお持ちですか？',
	AUTHENTICATE_SIGN_UP: 'サインアップ',
	AUTHENTICATE_SIGN_IN_MESSAGE: 'アカウントはないのか？',
	AUTHENTICATE_SIGN_IN_CREATE_ONE: '1つを作成する',
	AUTHENTICATE_EMAIL_NOT_EMPTY_ERROR: `メールを空にすることはできません`,
	AUTHENTICATE_EMAIL_INVALID: `無効なメールアドレス`,
	AUTHENTICATE_EMAIL_DIGIT_MESSAGE: '6桁のコードをメールで送りました',
	AUTHENTICATE_EMAIL_DIGIT_MESSAGE_SUBMIT: '6桁のコードをメールで送りました',
	SETTINGS_SIGN_IN: 'サインイン',
	SETTINGS_SIGN_OUT: 'サインアウト',
	SETTINGS_SUBSCRIPTION: 'サブスクリプション',
	SETTINGS_SUBSCRIPTION_INACTIVE_ERROR:
		'アクティブなサブスクリプションを持っていません',
	SETTINGS_SUBSCRIPTION_EXPIRE_AT: 'サブスクリプションは次の日に終了します。',
	SETTINGS_STORAGE: 'ストレージ',
	SETTINGS_LANGUAGE: '言語',
	SETTINGS_FREE_SPACE: 'フリースペース',
	SETTINGS_CLEAR_CACHE: 'キャッシュのクリア',
	SETTINGS_ASSETS_REMOVED_INTRO_MESSAGE: '撤去された資産',
	SETTINGS_CLEAR_CACHE_IMAGES_MESSAGE: '画像',
	SETTINGS_CLEAR_CACHE_ISSUES_MESSAGE: '問題点',
	SETTINGS_CLEAR_CACHE_ARTICLES_MESSAGE: '記事',
	SETTINGS_CLEAR_CACHE_FAVORITE_ARTICLES_IMAGES_MESSAGE: 'お気に入りの記事',
	SETTINGS_FREE_SPACE_IMAGES: '像',
	PURCHASE_SUBSCRIPTION_HEADER: 'サブスクリプション',
	PURCHASE_SUBSCRIPTION_SUBHEADER: '購読に関するメッセージのプレースホルダ',
	PURCHASE_OFFLINE_ERROR: 'オフラインでの購入ができません。',
	PURCHASES_OFFLINE_ERROR:
		'購入品を表示するにはオンラインである必要があります',
	PURCHASES_PRICE: '価格',
	PURCHASES_TYPE_ISSUE: '問題',
	PURCHASES_TYPE_SUBSCRIPTION: 'サブスクリプション',
};
