import React, { Component, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { StyledImage } from './Image';
import { StyledAdvert } from './Advert';
import { StyledSpacer } from './Spacer';
import { StyledVideo } from './Video';
import { StyledAudio } from './Audio';
import { StyledButton } from './Button';
import { StyledDivider } from './Divider';
import { StyledTwitterTimeline } from './Twitter';
import { StyledText } from './Text';
import { StyledInfogram } from './Infogram';
import { StyledGallery } from './Gallery';
import { StyledCustom } from './Custom';
import { Anchor } from './Anchor';
// import { StyledMap } from './Map';

import { ArticleContext } from '../Context';
import styles from '../article.module.css';
import { useIsInViewport } from '../hooks/use-viewport';
import { useComponentAnimation } from '../hooks/use-animation';
import { isTextComponent } from '../Article.constants';

const PT_TO_EM_FACTOR_LINE_HEIGHT = 1 / 10.111;

export class StyledColumns extends Component {
	static contextType = ArticleContext;
	state = {
		paddingLeft: null,
		paddingRight: null,
		backgroundColor: null,
		customCSS: [],
	};
	componentDidMount() {
		let { styles, id, bleed } = this.props;

		if (typeof styles === 'string') {
			if (!styles) {
				styles = [];
			} else {
				styles = styles.split(',');
			}
		}

		if (typeof styles === 'number') {
			styles = [styles];
		}

		const properties = {
			mobile: [],
			tablet: [],
			desktop: [],
		};

		for (const styleId of styles) {
			const style = clone(this.context.styles?.get(`${styleId}`));
			if (!style) {
				continue;
			}
			const supportedDevices = style.supportedDevices;
			if (!supportedDevices) {
				continue;
			}
			if (!supportedDevices.length) {
				continue;
			}
			for (const device of supportedDevices) {
				properties[device].push(style.properties);
			}
		}
		for (const device in properties) {
			properties[device] = properties[device].reduce(
				this.reduceProperties,
				{}
			);
		}

		this.build(properties, id, bleed).then((css) => {
			this.setState({
				css,
			});
		});
	}

	async build(properties, id, bleed, hasParent = false) {
		let css = '';

		for (const prop in properties) {
			const columnCSS = new ColumnCSS(
				id,
				clone(properties[prop]),
				this.onCustomCSS,
				bleed === 'on',
				prop,
				hasParent
			);
			css += (await columnCSS.build()) + '\n';
		}
		return css;
	}

	onCustomCSS = (css) => {
		const { customCss } = this.state;
		customCss.push(css);
		this.setState({
			customCss,
		});
	};

	reduceProperties = (acc, properties) => {
		acc = mergeDeep(acc, properties);
		for (let property in properties) {
			// The property do not exist so we just add it
			if (!acc[property]) {
				acc[property] = properties[property];
				continue;
			}

			// The property is an array so we just concatenate
			if (Array.isArray(properties[property])) {
				acc[property] = [...acc[property], ...properties[property]];
				continue;
			}

			// acc[property] = { ...acc[property], ...properties[property] };
			acc[property] = mergeDeep(acc[property], properties[property]);
		}
		return acc;
	};

	byComponent = (component) => {
		if (isTextComponent(component.component)) {
			return <StyledText key={component.id} {...component} />;
		}
		switch (component.component) {
			case 'image':
				return <StyledImage key={component.id} {...component} />;
			case 'advert':
				return <StyledAdvert key={component.id} {...component} />;
			case 'infogram':
				return <StyledInfogram key={component.id} {...component} />;
			case 'map':
				return null;
			// return <StyledMap key={i}  {...component}/>;
			case 'button':
				return <StyledButton key={component.id} {...component} />;
			case 'spacer':
				return <StyledSpacer key={component.id} {...component} />;
			case 'video':
				return <StyledVideo key={component.id} {...component} />;
			case 'audio':
				return <StyledAudio key={component.id} {...component} />;
			case 'twitter':
				return (
					<StyledTwitterTimeline key={component.id} {...component} />
				);
			case 'divider':
				return <StyledDivider key={component.id} {...component} />;
			case 'columns':
				return (
					<StyledColumns
						hasParent={true}
						key={component.id}
						{...component}
					/>
				);
			case 'custom':
				return <StyledCustom key={component.id} {...component} />;
			case 'anchor':
				return <Anchor key={component.id} {...component} />;
			case 'gallery':
				if (component['control-speed']) {
					component.control_speed = component['control-speed'];
				}
				return <StyledGallery key={component.id} {...component} />;
			default:
				return null;
		}
	};

	byDevice = ({ devices }) => {
		const { device } = this.context;
		if (
			(devices.phone === 'off' && device === 'mobile') ||
			(devices.tablet === 'off' && device === 'tablet') ||
			(devices.desktop === 'off' && device === 'desktop')
		) {
			return false;
		}

		return true;
	};

	render() {
		const { device } = this.context;
		let {
			id,
			bleed,
			expandfullwidth,
			backgroundcolor,
			columns,
			imageurl,
			backgroundimage,
			imagepositiontop,
			imagepositionleft,
			imageopacity,
			colsplit,
			collapsetype,
			contentmode,
			multicolwidth,
			multicolcount,
			width,
			align,
			verticalpadding,
			horizontalpadding,
			animation,
			gutter,
			style,
			background,
		} = this.props;

		const { css, customCSS } = this.state;
		let collapse = false;
		if (
			(collapsetype === 'responsive' && device === 'mobile') ||
			(collapsetype === 'tablet' && device === 'tablet')
		) {
			collapse = true;
		}
		if (multicolcount !== undefined) {
			multicolcount = parseInt(`${multicolcount}`);
		}
		if (multicolwidth !== undefined) {
			multicolwidth = parseInt(`${multicolwidth}`);
		}
		const content = columns.map((column, i) => (
			<div key={i}>
				{column.filter(this.byDevice).map(this.byComponent)}
			</div>
		));

		return (
			<Columns
				id={id}
				bleed={bleed === 'on'}
				expandFullWidth={expandfullwidth === 'on'}
				backgroundColor={backgroundcolor}
				css={css}
				customCSS={customCSS}
				imageUrl={imageurl}
				width={width}
				align={align}
				collapse={collapse}
				backgroundImage={backgroundimage === 'on'}
				imagePositionTop={imagepositiontop}
				imagePositionLeft={imagepositionleft}
				imageOpacity={imageopacity}
				colSplit={colsplit}
				style={style}
				background={background}
				contentMode={contentmode}
				verticalPadding={verticalpadding}
				animation={animation}
				horizontalPadding={horizontalpadding}
				multicolCount={multicolcount}
				multicolWidth={multicolwidth}
				gutter={gutter}
			>
				{content}
			</Columns>
		);
	}
}

StyledColumns.propTypes = {
	id: PropTypes.string,
	hasParent: PropTypes.bool,
	bleed: PropTypes.string,
	device: PropTypes.string,
	background: PropTypes.string,
	gutter: PropTypes.number,
	expandfullwidth: PropTypes.string,
	backgroundcolor: PropTypes.string,
	collapsetype: PropTypes.string,
	animation: PropTypes.any,
	style: PropTypes.any,
	imageopacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	imagepositiontop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	contentmode: PropTypes.string,
	align: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	multicolwidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	multicolcount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	verticalpadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	horizontalpadding: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	imageurl: PropTypes.string,
	backgroundimage: PropTypes.string,
	imagepositionleft: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	columns: PropTypes.array,
	colsplit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	styles: PropTypes.oneOfType([
		PropTypes.array,
		PropTypes.string,
		PropTypes.number,
	]),
};

export const Columns = ({
	id,
	collapse,
	children,
	css,
	customCSS,
	bleed,
	colSplit,
	align,
	width,
	gutter,
	backgroundColor,
	imageUrl,
	backgroundImage,
	imagePositionTop,
	imagePositionLeft,
	imageOpacity,
	contentMode,
	multicolCount,
	// multicolWidth,
	verticalPadding,
	horizontalPadding,
	style,
	animation,
	background,
}) => {
	const ref = useRef();
	const [animationClasses, setAnimationClasses] = useState([]);
	const hasAnimation = animation && animation.type !== 'none';
	const isInViewport = useIsInViewport(ref, hasAnimation);
	useComponentAnimation(ref, isInViewport, animation, setAnimationClasses);

	if (!children) {
		return null;
	}

	if (!children.length) {
		return null;
	}

	const total = children.length;

	const gridTemplateColumns = getColSplit(total, colSplit);

	const componentStyle = {
		gridTemplateColumns,
		backgroundSize: 'cover',
		width: '100%',
	};

	if (typeof gutter === 'number') {
		componentStyle.gridColumnGap = gutter;
	}

	let classNames = [
		styles[`columns`],
		styles[`column-${total}`],
		`columns-${id}`,
	];
	if (collapse) {
		classNames.push(styles['collapse']);
		componentStyle.display = 'flex';
	}

	if (backgroundImage && imageUrl) {
		backgroundColor = `#${backgroundColor.replaceAll('#', '')}`;
		imageUrl = encodeURI(imageUrl).replaceAll('%5C', '/');
		const alpha = parseFloat(imageOpacity) / 100;
		const rgba = hexToRGB(backgroundColor, alpha);
		componentStyle.background = `linear-gradient(${rgba}, ${rgba}), url("${imageUrl}")`;
		componentStyle.backgroundPosition = `${imagePositionLeft}% ${imagePositionTop}%`;
	}

	if (contentMode === 'multicol') {
		classNames.push('multicol');
		classNames.push(styles['multicol']);
		componentStyle.columns = `${multicolCount}`;
		componentStyle.display = 'block';
		componentStyle.gridTemplateColumns = 'unset';
		componentStyle.gridColumnGap = 'unset';
		componentStyle.gridTemplateRows = 'unset';
		componentStyle.gap = 'unset';
		componentStyle.columnRule = '1px solid #ccc';
		componentStyle.columnGap = '3rem';
		componentStyle.gridTemplateAreas = 'unset';
	}

	const containerClasses = ['columns'];
	const containerStyle = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	};
	if (bleed) {
		containerClasses.push('bleed');
	}

	if (`${width}` !== '100') {
		componentStyle.width = `${width}%`;
		switch (align) {
			case 'right':
				containerStyle.justifyContent = 'flex-end';
				break;
			case 'left':
				containerStyle.justifyContent = 'flex-start';
				break;
			default:
				containerStyle.justifyContent = 'center';
		}
	}

	if (horizontalPadding) {
		try {
			componentStyle.paddingLeft = `${horizontalPadding}%`;
			componentStyle.paddingRight = `${horizontalPadding}%`;
		} catch (e) {
			console.error(e);
		}
	}

	if (verticalPadding) {
		try {
			componentStyle.paddingTop = `${verticalPadding}%`;
			componentStyle.paddingBottom = `${verticalPadding}%`;
		} catch (e) {
			console.error(e);
		}
	}

	if (id === 'Col-854205633') {
		console.log(containerStyle);
	}

	if (background === 'solid' && style?.background_color) {
		containerStyle.background = style?.background_color;
	}

	if (background === 'gradient' && style?.colors?.length) {
		const total = style.colors.reduce((acc, item) => {
			const { weight } = item;
			acc += parseInt(`${weight}`);
			return acc;
		}, 0);
		let gradientAcc = 0;
		const linearGradient = style.colors.map((item) => {
			const { color, weight } = item;
			const totalPercent = (100 * parseFloat(weight)) / total;
			gradientAcc += totalPercent;
			return `#${color.replace(`#`, '')} ${gradientAcc}%`;
		});
		const backgroundImage = `linear-gradient(${linearGradient.join()})`;
		containerStyle.backgroundImage = backgroundImage;
	}

	return (
		<section
			id={id}
			ref={ref}
			className={[...containerClasses, ...animationClasses].join(' ')}
			style={containerStyle}
		>
			<div className={classNames.join(' ')} style={componentStyle}>
				{children}
			</div>
			{css ? <style>{css}</style> : null}
			{customCSS.length ? <style>{customCSS.join('\n')}</style> : null}
		</section>
	);
};

Columns.propTypes = {
	id: PropTypes.string,
	bleed: PropTypes.bool,
	gutter: PropTypes.number,
	background: PropTypes.string,
	columns: PropTypes.array,
	customCSS: PropTypes.array,
	collapse: PropTypes.bool,
	animation: PropTypes.any,
	style: PropTypes.any,
	expandFullWidth: PropTypes.bool,
	verticalPadding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	horizontalPadding: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	css: PropTypes.string,
	align: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	colSplit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	backgroundColor: PropTypes.string,
	imageOpacity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	imagePositionTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	imagePositionLeft: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	imageUrl: PropTypes.string,
	contentMode: PropTypes.string,
	multicolWidth: PropTypes.number,
	multicolCount: PropTypes.number,
	backgroundImage: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

Columns.defaultProps = {
	style: {},
	css: '',
	customCSS: [],
};

function getColSplit(total, colSplit) {
	if (total === 1) {
		return '1fr';
	}

	return colSplit
		.split('-')
		.map((split) => `${split}fr`)
		.join(' ');
}

function hexToRGB(hex, alpha) {
	var r = parseInt(hex.slice(1, 3), 16),
		g = parseInt(hex.slice(3, 5), 16),
		b = parseInt(hex.slice(5, 7), 16);

	if (typeof alpha !== 'undefined') {
		return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
	} else {
		return 'rgb(' + r + ', ' + g + ', ' + b + ')';
	}
}

export const Column = ({ id, components }) => {
	const byComponent = (component, i) => {
		if (component.expandfullwidth === 'on') {
			component.expandfullwidth = 'off';
		}
		if (isTextComponent(component.component)) {
			return <StyledText key={i} {...component} />;
		}
		switch (component.component) {
			case 'image':
				return <StyledImage key={i} {...component} />;
			case 'button':
				return <StyledButton key={i} {...component} />;
			case 'infogram':
				return <StyledInfogram key={i} {...component} />;
			case 'map':
				return null;
			// return <StyledMap key={i}  {...component}/>;
			case 'spacer':
				return <StyledSpacer key={i} {...component} />;
			case 'video':
				return <StyledVideo key={i} {...component} />;
			case 'audio':
				return <StyledAudio key={i} {...component} />;
			case 'twitter':
				return <StyledTwitterTimeline key={i} {...component} />;
			case 'divider':
				return <StyledDivider key={i} {...component} />;
			default:
				return null;
		}
	};
	return <div id={id}>{components.map(byComponent)}</div>;
};

Column.propTypes = {
	id: PropTypes.string,
	components: PropTypes.array,
};

export class ColumnCSS {
	constructor(id, properties, onCustomCSS, bleed, device, hasParent = false) {
		this.id = id;
		this.properties = clone(properties);

		this.onCustomCSS = onCustomCSS;
		this.bleed = bleed;
		this.device = device;
		this.hasParent = hasParent;
	}

	async build() {
		const {
			paddingLeft,
			paddingRight,
			paddingTop,
			paddingBottom,
			marginTop,
			marginBottom,
			marginLeft,
			marginRight,
		} = this.getBleedPadding();

		const canvas = this.getCanvasCSS(
			paddingLeft,
			paddingRight,
			paddingTop,
			paddingBottom,
			marginTop,
			marginBottom,
			marginLeft,
			marginRight,
			this.bleed
		);

		const caption = this.getCaptionCSS();
		const text = await this.getTextCSS();
		const anchor = this.getAnchorCSS();
		const spacer = this.getSpacerCSS();
		const buttons = this.getButtonsCSS();

		let items = [canvas, caption, text, anchor, spacer, buttons];
		if (this.hasParent) {
			const reset = this.resetColums();
			items.unshift(reset);
		}
		const css = items.filter((i) => i).join('\n');

		if (this.device === 'desktop') {
			return `@media only screen and (min-width: 1201px) {
			  ${css}
			}`;
		} else if (this.device === 'tablet') {
			return `@media only screen and (min-width:767px) and (max-width:1200px) {
			  ${css}
			}`;
		}

		return css;
	}

	resetColums() {
		return `#${this.id} { width: unset; margin: 0; padding: 0;}`;
	}

	getBleedPadding = () => {
		if (!this.properties?.container) {
			return {
				paddingLeft: '0px',
				paddingRight: '0px',
			};
		}

		let {
			paddingLeft,
			padding_left,
			paddingRight,
			padding_right,
			paddingTop,
			padding_top,
			paddingBottom,
			padding_bottom,
			marginTop,
			margin_top,
			margin_bottom,
			marginBottom,
			marginLeft,
			margin_left,
			marginRight,
			margin_right,
			unit,
		} = this.properties?.container;

		// Padding
		paddingLeft = paddingLeft !== undefined ? paddingLeft : padding_left;
		paddingRight =
			paddingRight !== undefined ? paddingRight : padding_right;
		paddingTop = paddingTop !== undefined ? paddingTop : padding_top;
		paddingBottom =
			paddingBottom !== undefined ? paddingBottom : padding_bottom;
		// Margin
		marginBottom =
			marginBottom !== undefined ? marginBottom : margin_bottom;
		marginTop = marginTop !== undefined ? marginTop : margin_top;
		marginLeft = marginLeft !== undefined ? marginLeft : margin_left;
		marginRight = marginRight !== undefined ? marginRight : margin_right;

		return {
			paddingLeft: paddingLeft
				? `${paddingLeft}${unit['paddingLeft']}`
				: '0px',
			paddingRight: paddingRight
				? `${paddingRight}${unit['paddingRight']}`
				: '0px',
			paddingTop: paddingTop
				? `${paddingTop}${unit['paddingTop']}`
				: '0px',
			paddingBottom: paddingBottom
				? `${paddingBottom}${unit['paddingBottom']}`
				: '0px',
			marginTop: marginTop ? `${marginTop}${unit['marginTop']}` : '0px',
			marginBottom: marginBottom
				? `${marginBottom}${unit['marginBottom']}`
				: '0px',
			marginLeft: marginLeft
				? `${marginLeft}${unit['marginLeft']}`
				: '0px',
			marginRight: marginRight
				? `${marginRight}${unit['marginRight']}`
				: '0px',
		};
	};

	getCanvasCSS = (
		paddingLeft,
		paddingRight,
		paddingTop,
		paddingBottom,
		marginTop,
		marginBottom,
		marginLeft,
		marginRight,
		bleed
	) => {
		const id = this.id;

		if (!this.properties?.container) {
			return '';
		}
		let {
			backgroundColor,
			border_color,
			borderColor,
			border_edge,
			borderEdge,
			border_type,
			borderType,
			border_width,
			borderWidth,
			border_radius,
			borderRadius,
		} = this.properties?.container;

		borderEdge = borderEdge || border_edge;
		borderColor = borderColor || border_color;
		borderType = borderType || border_type;
		borderWidth = borderWidth || border_width;
		borderRadius = borderRadius || border_radius;

		backgroundColor = backgroundColor
			? `background-color: #${backgroundColor.replace('#', '')};`
			: '';
		paddingTop = paddingTop ? `padding-top: ${paddingTop};` : '';
		paddingBottom = paddingBottom
			? `padding-bottom: ${paddingBottom};`
			: '';

		paddingLeft = `padding-left: ${paddingLeft};`;
		paddingRight = `padding-right: ${paddingRight};`;

		let css = [];
		css.push(`#${id} {
			${backgroundColor}
			${paddingLeft}
			${paddingRight}
			${paddingTop}
			${paddingBottom}
		}`);

		if (!bleed) {
			css.push(`#${id} {
				margin-top: ${marginTop};
				margin-bottom: ${marginBottom};
				margin-left: ${marginLeft};
				margin-right: ${marginRight};
			}`);
		}

		if (borderType !== 'none') {
			let border = [];
			borderWidth = borderWidth ? `${borderWidth}px` : `1px`;
			borderColor = borderColor
				? `#${borderColor.replace('#', '')}`
				: `#000`;
			if (borderEdge) {
				if (borderEdge === 'all') {
					border.push(
						`border: ${borderWidth} ${borderType} ${borderColor};`
					);
				} else {
					for (const edge of borderEdge.split('-')) {
						border.push(
							`border-${edge}: ${borderWidth} ${borderType} ${borderColor};`
						);
					}
				}
			}
			if (borderRadius) {
				border.push(`border-radius: ${borderRadius}pt;`);
			}
			border = border.join('\n');
			css.push(`#${id} {
				${border}
			}`);
		}

		return css.join('\n');
	};

	getCaptionCSS = () => {
		const id = this.id;
		if (!this.properties?.imagecaption) {
			return '';
		}
		let {
			color,
			fontFamily,
			fontSize,
			fontStyle,
			fontWeight,
			letterSpacing,
			lineHeight,
			marginBottom,
			marginTop,
			marginLeft,
			marginRight,
			textAlign,
			textDecoration,
			textTransform,
			unit,
		} = this.properties?.imagecaption;

		color = color ? `color: #${color.replace('#', '')};` : '';
		fontFamily = fontFamily ? `font-family: ${fontFamily};` : '';
		fontSize = fontSize ? `font-size: ${fontSize}${unit['fontSize']};` : '';
		fontStyle = fontStyle ? `font-style: ${fontStyle};` : '';
		fontWeight = fontWeight ? `font-weight: ${fontWeight};` : '';
		letterSpacing = letterSpacing
			? `letter-spacing: ${parseFloat(letterSpacing)}${
					unit['letterSpacing']
			  };`
			: '';
		lineHeight = lineHeight
			? `line-height: ${lineHeight}${unit['lineHeight']};`
			: '';
		marginBottom = marginBottom
			? `margin-bottom: ${marginBottom}${unit['marginBottom']};`
			: '';
		marginTop = marginTop
			? `margin-top: ${marginBottom}${unit['marginTop']};`
			: '';
		marginLeft = marginLeft
			? `margin-left: ${marginLeft}${unit['marginLeft']};`
			: '';
		marginRight = marginRight
			? `margin-right: ${marginRight}${unit['marginRight']};`
			: '';
		textAlign = textAlign ? `text-align: ${textAlign};` : '';
		textDecoration = textDecoration
			? `text-decoration: ${textDecoration};`
			: '';
		textTransform = textTransform
			? `text-transform: ${textTransform};`
			: '';

		return `#${id} figure figcaption {
			${color}
			${fontFamily}
			${fontSize}
			${fontStyle}
			${fontWeight}
			${letterSpacing}
			${lineHeight}
			${marginBottom}
			${marginTop}
			${marginLeft}
			${marginRight}
			${textAlign}
			${textDecoration}
			${textTransform}
		}`;
	};

	getCreditCSS = () => {};

	getTextCSS = async () => {
		const id = this.id;
		const css = [''];
		let textComponents = new Set([
			'headline',
			'title',
			'subtitle',
			'intro',
			'body',
			'crosshead',
			'byline',
			'blockquote',
			'footer',
		]);
		for (let i = 0; i < 40; i++) {
			textComponents.add(`text${i + 1}`);
		}
		textComponents = [...textComponents];
		// Mobile
		for (const component in this.properties) {
			// const properties = style.properties[property];
			// The component is not a text so we ignore it
			if (
				!textComponents.includes(component) ||
				!this.properties[component]
			) {
				continue;
			}

			const properties = this.properties[component];

			let {
				fontFamily,
				fontSize,
				color,
				fontWeight,
				fontStyle,
				textDecoration,
				lineHeight,
				textTransform,
				textAlign,
				letterSpacing,
				marginBottom,
				marginTop,
				textDisplay,
				// Dropcap
				dropcapFontSize,
				dropcapLineHeight,
				dropcapPaddingRight,
				dropcapColor,
				// Font Weight
				fontFamilyBold,
				fontFamilyItalic,
				fontFamilyBoldItalic,
				// Padding
				paddingTop,
				paddingBottom,
				paddingLeft,
				paddingRight,
				unit,
			} = properties;

			textDisplay = textDisplay ? `display: ${textDisplay};` : '';

			if (properties.css) {
				// eslint-disable-next-line no-undef
				/*const sass = new Sass();
				sass.compile(
					`#${id} > .${component} {
					${properties.css};
				}`,
					(result) => {
						if (result?.text) {
							this.onCustomCSS(result.text);
						}
					}
				);*/
			}

			css.push(`#${id} .${component} p {
				${textDisplay}
			}
			#${id} .${component} > sub {
				font-size: 65%;
				line-height: 0;
				vertical-align: sub;
			}
			#${id} ${component} > sup{
				font-size: 65%;
				line-height: 0;
				vertical-align: super;
			}
			#${id} .${component} i {
				font-style: italic;
			}`);

			fontFamily = fontFamily ? `font-family: ${fontFamily};` : '';
			fontSize = fontSize
				? `font-size: ${fontSize}${unit['fontSize']};`
				: '';
			color = color ? `color: #${color.replace('#', '')};` : '';
			fontWeight = fontWeight ? `font-weight: ${fontWeight};` : '';
			fontStyle = fontStyle ? `font-style: ${fontStyle};` : '';
			textDecoration = textDecoration
				? `text-decoration: ${textDecoration};`
				: '';
			if (lineHeight && unit && unit['lineHeight'] === 'pt') {
				lineHeight =
					lineHeight !== undefined
						? `line-height: ${
								(lineHeight * PT_TO_EM_FACTOR_LINE_HEIGHT) / 1.6
						  }rem;`
						: '';
			} else {
				lineHeight =
					lineHeight !== undefined
						? `line-height: ${lineHeight}${unit['lineHeight']};`
						: '';
			}
			textTransform = textTransform
				? `text-transform: ${textTransform};`
				: '';
			textAlign = textAlign ? `text-align: ${textAlign};` : '';
			letterSpacing = letterSpacing
				? `letter-spacing: ${parseFloat(letterSpacing)}${
						unit['letterSpacing']
				  };`
				: '';
			marginBottom = marginBottom
				? `margin-bottom: ${marginBottom}${unit['marginBottom']};`
				: '';
			marginTop = marginTop
				? `margin-top: ${marginTop}${unit['marginTop']};`
				: '';

			dropcapLineHeight = dropcapLineHeight
				? `line-height: ${parseFloat(dropcapFontSize + '')}${
						unit['dropcapLineHeight']
				  };`
				: '';
			dropcapFontSize = dropcapFontSize
				? `font-size: ${parseFloat(dropcapFontSize + '')}${
						unit['dropcapFontSize']
				  };`
				: 'font-size: 4em;';
			dropcapPaddingRight = dropcapPaddingRight
				? `padding-right: ${dropcapPaddingRight}${unit['dropcapPaddingRight']};`
				: 'padding-right: 8px;';
			dropcapColor = dropcapColor
				? `color: #${dropcapColor.replace('#', '')};`
				: '';

			paddingBottom = paddingBottom
				? `padding-bottom: ${paddingBottom}${unit['paddingBottom']};`
				: '';
			paddingTop = paddingTop
				? `padding-top: ${paddingTop}${unit['paddingTop']};`
				: '';
			paddingLeft = paddingLeft
				? `padding-left: ${paddingLeft}${unit['paddingLeft']};`
				: '';
			paddingRight = paddingRight
				? `padding-right: ${paddingRight}${unit['paddingRight']};`
				: '';

			css.push(`#${id} > div > div > .${component} {
				overflow-wrap: break-word;
				
				${fontFamily}
				${color}
				${fontWeight}
				${fontStyle}
				${textDecoration}
				${textTransform}
				${textAlign}
				${letterSpacing}
				${marginBottom}
				${marginTop}
				${paddingTop}
				${paddingBottom}
				${paddingLeft}
				${paddingRight}
			}`);
			css.push(`#${id} > div > div > .${component} p,
				#${id} > div > div > .${component} ul,
				#${id} > div > div > .${component} ol {
				${fontSize}
				${lineHeight}
			}`);
			css.push(`#${id} .${component}.dropcap > :first-child::first-letter {
				float: left;
				margin-left: -2px;
				${dropcapFontSize}
				${dropcapLineHeight}
				${dropcapPaddingRight}
				${dropcapColor}
			}`);
			if (fontFamilyBold) {
				css.push(`#${id} .${component} > p b {
					font-family: ${fontFamilyBold} !important;
					font-weight: normal;
				}`);
			}

			if (fontFamilyItalic) {
				css.push(`#${id} .${component} > p i {
					font-family: ${fontFamilyItalic};
					font-style: normal;
				}`);
			}

			if (fontFamilyBoldItalic) {
				css.push(`#${id} .${component} > p b i {
					font-family: ${fontFamilyBoldItalic};
					font-weight: normal;
					font-style: normal;
				}`);
			}
		}
		return css.join('\n');
	};

	getAnchorCSS = () => {
		const id = this.id;
		if (!this.properties['anchor']) {
			return '';
		}
		let { fontStyle, color, fontWeight, textDecoration, textTransform } =
			this.properties['anchor'];

		color = color ? `color: #${color.replace('#', '')};` : '';
		fontStyle = fontStyle ? `font-style: ${fontStyle};` : '';
		fontWeight = fontWeight ? `font-weight: ${fontWeight};` : '';
		textDecoration = textDecoration
			? `text-decoration: ${textDecoration};`
			: '';
		textTransform = textTransform
			? `text-transform: ${textTransform};`
			: '';

		return `#${id} a {
			${color}
			${fontStyle}
			${fontWeight}
			${textDecoration}
			${textTransform}
		}`;
	};

	getSpacerCSS = () => {
		const id = this.id;
		const css = [];
		if (!this.properties.spacer) {
			return '';
		}
		let {
			vSmallHeight,
			smallHeight,
			mediumHeight,
			largeHeight,
			vLargeHeight,
			unit,
		} = this.properties['spacer'];

		if (vSmallHeight) {
			css.push(`#${id} .spacer.v-small {
				height: ${vSmallHeight}${unit['vSmallHeight']};
			}`);
		}
		if (smallHeight) {
			css.push(`#${id} .spacer.small {
				height: ${smallHeight}${unit['smallHeight']};
			}`);
		}
		if (mediumHeight) {
			css.push(`#${id} .spacer.medium {
				height: ${mediumHeight}${unit['mediumHeight']};
			}`);
		}
		if (largeHeight) {
			css.push(`#${id} .spacer.large {
				height: ${largeHeight}${unit['largeHeight']};
			}`);
		}
		if (vLargeHeight) {
			css.push(`#${id}.spacer.v-large {
				height: ${vLargeHeight}${unit['vLargeHeight']};
			}`);
		}

		return css.join('\n');
	};

	getButtonsCSS = () => {
		return '';
	};
}

function clone(obj) {
	if (!obj) return obj;
	if (structuredClone) return structuredClone(obj);
	return JSON.parse(JSON.stringify(obj));
}

function mergeDeep(target, ...sources) {
	if (!sources.length) return target;
	const source = sources.shift();

	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key]) Object.assign(target, { [key]: {} });
				mergeDeep(target[key], source[key]);
			} else {
				Object.assign(target, { [key]: source[key] });
			}
		}
	}

	return mergeDeep(target, ...sources);
}

function isObject(item) {
	return item && typeof item === 'object' && !Array.isArray(item);
}
