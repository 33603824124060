import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import styles from './Bookmarks.module.css';

import { AppContext } from '../services/AppContext';

import { Loading } from '../components/loading/Loading';
import Navbar from '../components/navbar/Navbar';
import Fontbar from '../components/navbar/Fontbar';
import Sidebar from '../components/sidebar/Sidebar';
import EmptyState from '../components/empty/EmptyState';
import BottomBar from '../components/bottombar/BottomBar';
import {
	ArticleSettings,
	FontSizeSlider,
} from '../components/bottombar/ArticleSettings';
import { Article } from '../components/canvasflow/article/Article';

export default class Bookmarks extends React.Component {
	static contextType = AppContext;

	state = {
		bookmarks: [],
		isLoading: true,
	};

	componentDidMount() {
		const { location } = this.props;
		localStorage.setItem('last-path', location.pathname);
		const { db } = this.context;
		const { tracking } = this.context;
		tracking.pageView(window.location.pathname);
		db.getFavoriteArticles()
			.then((bookmarks) => {
				this.setState({ bookmarks: bookmarks, isLoading: false });
			})
			.catch(console.error);
	}

	render() {
		const { isLoading, bookmarks } = this.state;
		const { isOnline, theme } = this.context;

		if (isLoading) {
			return <LoadingComponent />;
		}

		if (!bookmarks.length) {
			return <Empty />;
		}

		return (
			<div className="App">
				<Navbar isOnline={isOnline} theme={theme.navbar} />
				<main style={{ height: '100%' }}>
					<section className={styles['bookmarks']}>
						<Sidebar theme={theme.bottomBar} section="bookmark" />
						<BookmarkList bookmarks={bookmarks} />
					</section>
				</main>
				<BottomBar
					section="bookmark"
					isVisible={true}
					theme={theme.bottomBar}
				/>
			</div>
		);
	}
}

Bookmarks.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

export class Bookmark extends React.Component {
	static contextType = AppContext;

	articleRef = React.createRef();
	lastScrollPosition = 0;

	state = {
		article: null,
		lang: localStorage.getItem('language'),
		isReaderMode: false,
		isFavorite: true,
		isFontOpen: false,
		fontSize: 0,
		isArticleSettingsDisplay: true,
		isLoading: true,
		imageModalSrc: null,
		isDisplayImageModal: false,
	};

	getID = () => {
		const { id } = this.props.match.params;
		return id;
	};

	getFontSize = () => {
		if (!localStorage.getItem('fontSize')) {
			localStorage.setItem('fontSize', '62.5');
		}

		return parseFloat(localStorage.getItem('fontSize'));
	};

	componentDidMount() {
		const { db, tracking } = this.context;
		const id = this.getID();

		tracking.pageView(window.location.pathname);
		tracking.event({
			category: 'User',
			action: 'Load bookmarked article',
		});

		db.getArticleByID(id).then((article) => {
			this.setState({
				article,
				isLoading: false,
				fontSize: this.getFontSize(),
			});
		});
	}

	onIsFontClick = () => {
		const { isFontOpen } = this.state;
		this.setState({ isFontOpen: !isFontOpen });
	};

	onIsFavoriteClick = () => {
		const { db } = this.context;
		const { isFavorite, article } = this.state;

		if (!isFavorite) {
			db.saveFavorite(article.id).then(() => {
				this.setState({ isFavorite: true });
			});
		} else {
			db.removeFavorite(article.id).then(() => {
				this.setState({ isFavorite: false });
			});
		}
	};

	onReaderModeClick = () => {
		const { tracking } = this.context;
		const { isReaderMode } = this.state;
		tracking.event({
			category: 'User',
			action: 'Click reader mode',
		});
		if (!isReaderMode) {
			tracking.event({
				category: 'User',
				action: 'Enable reader mode',
			});
		} else {
			tracking.event({
				category: 'User',
				action: 'Disable reader mode',
			});
		}

		this.setState({ isReaderMode: !isReaderMode });
	};

	onFontSizeChange = (fontSize) => {
		const { tracking } = this.context;

		tracking.event({
			category: 'User',
			action: 'Change font size',
		});

		localStorage.setItem('fontSize', `${fontSize}`);
		this.setState({ fontSize });
	};

	onBottombarDisplayChange = () => {
		this.setState({ isArticleSettingsDisplay: true });
	};

	loading = () => {
		const { isOnline, theme, config } = this.context;

		return (
			<div className="App">
				<Navbar
					isOnline={isOnline}
					theme={theme?.navbar || config?.theme?.navbar}
					backTo={`/bookmarks`}
				/>
				<main>
					<section className={styles['article-container']}>
						<Loading theme={config?.theme?.loading} />
					</section>
				</main>
			</div>
		);
	};

	onScroll = (e) => {
		e.stopPropagation();
		/*
		const scrollTop = this.articleRef.current.scrollTop;
		if(scrollTop - this.lastScrollPosition > 0) {
			this.setState({isArticleSettingsDisplay: false});
		} else {
			this.setState({isArticleSettingsDisplay: true});
		}
		this.lastScrollPosition = scrollTop;*/
	};

	onImageClick = (image) => {
		const { tracking } = this.context;

		tracking.event({
			category: 'User',
			action: 'Click article image',
		});

		this.setState({
			imageModalSrc: image.imageurl,
			isDisplayImageModal: true,
		});
	};

	onCloseImageDisplay = () => {
		const { tracking } = this.context;

		tracking.event({
			category: 'User',
			action: 'Close article image',
		});

		this.setState({ isDisplayImageModal: false });
	};

	render() {
		const { isOnline, theme, lang } = this.context;
		const {
			isReaderMode,
			isFavorite,
			isFontOpen,
			article,
			fontSize,
			isArticleSettingsDisplay,
			isLoading,
		} = this.state;

		if (isLoading) {
			return this.loading();
		}

		const settingsOptions = {
			toc: {
				display: false,
			},
			reader: {
				selected: isReaderMode,
				onClick: this.onReaderModeClick,
			},
			favorite: {
				selected: isFavorite,
				onClick: this.onIsFavoriteClick,
			},
			font: {
				selected: isFontOpen,
				onClick: this.onIsFontClick,
			},
		};

		let fontSizeSlider = null;
		let fontbarComponent = null;
		if (isFontOpen) {
			fontSizeSlider = (
				<FontSizeSlider
					theme={theme.bottomBar}
					size={fontSize}
					onChange={this.onFontSizeChange}
				/>
			);
			fontbarComponent = (
				<Fontbar
					theme={theme.navbar}
					size={fontSize}
					onChange={this.onFontSizeChange}
				/>
			);
		}

		const { id, style, components } = article;

		return (
			<div className="App">
				<Navbar
					isOnline={isOnline}
					display={true}
					theme={theme.navbar}
					settingsOptions={settingsOptions}
					backTo={`/bookmarks`}
				/>
				{fontbarComponent}
				<main>
					<section
						className={styles['article-container']}
						onScroll={this.onScroll}
						style={{
							paddingTop: isArticleSettingsDisplay
								? 'var(--navbar-height)'
								: 0,
						}}
						ref={this.articleRef}
					>
						<Article
							id={id}
							index={0}
							style={`${style}`}
							fontSize={fontSize}
							styles={this.context.styles}
							lang={lang}
							components={components}
						/>
					</section>
				</main>
				{fontSizeSlider}
				<ArticleSettings
					theme={theme.bottomBar}
					display={isFontOpen || isArticleSettingsDisplay}
					options={settingsOptions}
				/>
			</div>
		);
	}
}

Bookmark.propTypes = {
	match: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

function BookmarkList({ bookmarks }) {
	bookmarks = bookmarks.map((bookmark, i) => {
		return <BookmarkItem key={i} bookmark={bookmark} />;
	});

	return <div className={styles['container']}>{bookmarks}</div>;
}

BookmarkList.propTypes = {
	bookmarks: PropTypes.array.isRequired,
};

function BookmarkItem({ bookmark }) {
	const { tracking } = useContext(AppContext);
	const { id, name, description, issue } = bookmark;

	const title = name[localStorage.getItem('language')]
		? name[localStorage.getItem('language')]
		: name['en'];

	return (
		<Link
			to={`/bookmarks/${id}`}
			className={styles['bookmark']}
			onClick={() => {
				tracking.event({
					category: 'User',
					action: 'Click bookmarked article',
				});
			}}
			style={{
				textDecoration: 'none',
				color: 'black',
			}}
		>
			<div className={styles['title']}>{title}</div>
			<div className={styles['description']}>{description}</div>
			<div className={styles['description']}>{issue}</div>
		</Link>
	);
}

BookmarkItem.propTypes = {
	bookmark: PropTypes.object.isRequired,
};

function LoadingComponent() {
	const { isOnline, theme, config } = useContext(AppContext);
	return (
		<div className="App">
			<Navbar isOnline={isOnline} theme={theme.navbar} />
			<main>
				<section className={styles['bookmarks']}>
					<Loading theme={config?.theme?.loading} />
				</section>
			</main>
			<BottomBar
				section="bookmark"
				isVisible={true}
				theme={theme.bottomBar}
			/>
		</div>
	);
}

function Empty() {
	const { isOnline, theme } = useContext(AppContext);
	return (
		<div className="App">
			<Navbar isOnline={isOnline} theme={theme.navbar} />
			<main>
				<section className={styles['bookmarks']}>
					<Sidebar theme={theme.bottomBar} section="bookmark" />
					<EmptyState section={'bookmark'} />
				</section>
			</main>
			<BottomBar
				section="bookmark"
				isVisible={true}
				theme={theme.bottomBar}
			/>
		</div>
	);
}
