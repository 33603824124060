import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { InstagramEmbed } from 'react-social-media-embed';

import styles from '../article.module.css';
import { ArticleContext } from '../Context';

export const StyledInstagram = (props) => {
	const { id, bleed, params } = props;
	const { lang } = useContext(ArticleContext);

	return (
		<Instagram
			id={id}
			language={lang}
			bleed={bleed === 'on'}
			params={params}
		/>
	);
};

StyledInstagram.propTypes = {
	id: PropTypes.string,
	bleed: PropTypes.string,
	params: PropTypes.any,
};

function Instagram({ id, bleed, params }) {
	const { type, videoID } = params;

	const className = [styles['instagram'], 'media', 'instagram'];

	if (bleed) {
		className.push('bleed');
	}

	if (!type || !videoID) {
		return null;
	}

	let url = '';
	switch (type) {
		case 'tv':
		case 'reel':
			url = `https://www.instagram.com/${type}/${videoID}/`;
			break;
		default:
			url = `https://www.instagram.com/p/${videoID}/`;
			break;
	}

	return (
		<div id={id} className={className.join(' ')}>
			<InstagramEmbed url={url} width={328} />
		</div>
	);
}

Instagram.propTypes = {
	id: PropTypes.string,
	bleed: PropTypes.bool,
	language: PropTypes.string,
	params: PropTypes.any,
};
