import React from 'react';
import PropTypes from 'prop-types';

import styles from './Lock.module.css';

export default class Lock extends React.Component {
	state = {
		password: '',
	};

	onSubmit = (e) => {
		e.preventDefault();
		const { onSuccess, password } = this.props;
		if (password.value === this.state.password) {
			onSuccess();
		} else {
			alert(`Wrong password`);
			this.setState({
				password: '',
			});
		}
	};

	onChange = (e) => {
		this.setState({ password: e.target.value });
	};
	render() {
		const { password } = this.state;

		return (
			<div className="App">
				<main>
					<section
						className={styles['lock']}
						style={{
							background: '#fafafa',
						}}
					>
						<form onSubmit={this.onSubmit}>
							<div className={styles['lock-icon']}></div>
							<div>
								<input
									onChange={this.onChange}
									value={password}
									style={{
										height: 46,
									}}
									type="password"
									placeholder="Password"
								/>
							</div>
							<div className={styles['lock-button']}>
								<button type={'submit'}>Sign In</button>
							</div>
						</form>
					</section>
				</main>
			</div>
		);
	}
}

Lock.propTypes = {
	onSuccess: PropTypes.func.isRequired,
	password: PropTypes.string.isRequired,
};
