import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';

import styles from './Home.module.css';

import { AppContext } from '../services/AppContext';
import { Loading } from '../components/loading/Loading';
import { SearchNavbar } from '../components/navbar/Navbar';
import Sidebar from '../components/sidebar/Sidebar';
import EmptyState from '../components/empty/EmptyState';
import BottomBar from '../components/bottombar/BottomBar';
import InstallationOption from '../components/installation-option/InstallationOption';
import { Icon } from '../components/Icon';
import { useEffect } from 'react';

export default class Home extends React.Component {
	static contextType = AppContext;
	state = {
		issues: [],
		isLoading: true,
		isSubscribed: false,
		displayInstallationOption: false,
		redirect: null,
		isStandalone: false,
	};

	componentDidMount() {
		this.onLoad()
			.then(() => {
				console.log(`Home view loaded`);
			})
			.catch(console.error);
	}

	onLoad = async () => {
		const { location } = this.props;
		localStorage.setItem('last-path', location.pathname);

		const { tracking, issueMode } = this.context;
		if (tracking) {
			tracking.pageView({
				location: window.location.pathname,
			});
		}

		this.displayInstallation();
		this.setState({ isLoading: true });
		try {
			let issues = this.filterSubscriptionExpirationDate(
				await this.getData()
			);

			if (issueMode?.enabled && issues.length && issues[0].id) {
				let { search } = window.location;
				console.debug(`Issue mode enabled`);
				const issue = issues[0];
				const { slug } = issue;
				let url = `/issues/${slug ? slug : issue.id}`;
				if (search) {
					url += search;
				}
				this.setState({
					redirect: <Redirect to={url} />,
				});
				return;
			}

			this.setState({ isLoading: false, issues: clone(issues) });
		} catch (e) {
			console.error(e);
			this.setState({ isLoading: false, error: e.message });
		}
	};

	// If expirationDate is enabled it filter the issues
	filterSubscriptionExpirationDate(issues) {
		const { config } = this.context;
		const key = 'subscription-expiration-date';
		if (
			!config.subscriptionExpirationDate ||
			!config.subscriptionExpirationDate.enabled
		) {
			return issues;
		}
		const salt = config.subscriptionExpirationDate.salt || 1;
		const params = new URLSearchParams(this.props.location.search);
		const expirationParam = params.get('e');

		console.log(`The expiration date param is: `, expirationParam);

		// 1. Is the first time loading the app
		// 2. The user is overriding the expiration date
		if (
			!localStorage.getItem(key) ||
			(localStorage.getItem(key) && expirationParam)
		) {
			/* 
				If the expiration date config is enabled but the 
				param is not sent, then return empty
			*/
			if (!expirationParam) {
				return [];
			}
			let year, month, day;
			const expirationDateValue = `${parseInt(expirationParam) / salt}`;
			year = expirationDateValue.substring(0, 4);
			month = expirationDateValue.substring(4, 6);
			day = expirationDateValue.substring(6, 8);

			params.delete('e');

			localStorage.setItem(key, `${year}/${month}/${day}`);

			this.props.history.push({
				pathname: '/',
				search: `?${params.toString()}`,
			});
		}

		const expirationDate = new Date(localStorage.getItem(key));

		const byReleaseDate = (issue) => {
			return moment(new Date(issue.releaseDate)).isSameOrBefore(
				moment(expirationDate),
				'day'
			);
		};

		const filteredIssues = issues.filter(byReleaseDate);
		console.log(`FILTERED ISSUES BEFORE`, filteredIssues);
		return filteredIssues;
	}

	getData = async () => {
		const { isUserAdmin } = this.context;
		if (!isUserAdmin) {
			const isSubscribed = await this.hasSubscription();
			this.setState({ isSubscribed });
		} else {
			this.setState({ isSubscribed: true });
		}

		let issues = await this.loadIssues();

		return issues;
	};

	displayInstallation = () => {
		if (this.isIOSDevice()) {
			// @ts-ignore
			if (!window.navigator?.standalone) {
				if (!localStorage.getItem('installation-requested')) {
					localStorage.setItem('installation-requested', 'true');
					this.setState({
						dragEnabled: false,
						displayInstallationOption: true,
					});
				}
			}
		}
	};

	isIOSDevice = () => {
		// @ts-ignore
		return window.navigator.standalone !== undefined;
	};

	onCloseInstallation = () => {
		this.getData()
			.then((issues) => {
				this.setState({
					isLoading: false,
					issues,
					dragEnabled: true,
					displayInstallationOption: false,
				});
			})
			.catch((e) => {
				this.setState({
					isLoading: false,
					error: e.message,
					dragEnabled: true,
					displayInstallationOption: false,
				});
			});
	};

	loadIssues = async () => {
		// stripeKey
		const { api, isOnline, db, StripeAccountID, isUserAdmin } =
			this.context;
		let issues;

		const downloadedIssues = JSON.parse(
			localStorage.getItem('downloaded-issues')
		);

		if (isOnline) {
			console.debug('Fetching issues from remote');
			let remoteIssues = await api.getIssues();

			if (!StripeAccountID && !isUserAdmin) {
				remoteIssues = remoteIssues.filter((issue) => {
					const { isPaid, price } = issue;
					if (isPaid && price) {
						return false;
					}

					return true;
				});
			}

			let retryCount = 3;
			while (remoteIssues.length === 0 && !!retryCount) {
				await this.timeout();
				remoteIssues = await api.getIssues();
				retryCount--;
			}

			issues = remoteIssues.map((issue) => {
				issue.isDownloaded = !!downloadedIssues[`${issue.id}`];
				return issue;
			});

			issues = await db.saveIssues(issues);
			return issues;
		}

		console.debug('Fetching issues from cache');
		issues = await db.getIssues();

		issues = issues.map((issue) => {
			issue.isDownloaded = !!downloadedIssues[`${issue.id}`];
			return issue;
		});

		return issues;
	};

	timeout = async (ms = 500) => {
		return new Promise((resolve) => {
			setTimeout(resolve, ms);
		});
	};

	hasSubscription = async () => {
		const { api, isOnline } = this.context;
		if (isOnline) {
			const response = await api.getMySubscription();
			if (!!response && !!response.expiredAt) {
				const { expiredAt } = response;
				localStorage.setItem('subscription_expired_at', expiredAt);
				if (moment(new Date()).isBefore(new Date(expiredAt), 'day')) {
					return true;
				}
			}
			return false;
		}

		const subscriptionExpiredDate = localStorage.getItem(
			'subscription_expired_at'
		);

		if (!subscriptionExpiredDate) return false;

		return moment(
			// @ts-ignore
			new Date().isBefore(new Date(subscriptionExpiredDate), 'day')
		);
	};

	render() {
		// const { issueMode } = this.context;
		let {
			issues,
			isLoading,
			isSubscribed,
			displayInstallationOption,
			dragEnabled,
			redirect,
		} = this.state;

		if (redirect) {
			return redirect;
		}

		/*if (issueMode?.enabled) {
			return null;
		}*/

		if (isLoading) {
			return <LoadingComponent />;
		}

		if (!issues) {
			return <LoadingComponent />;
		}

		if (!!issues && !issues.length) {
			return <Empty onLoad={this.onLoad} />;
		}

		return (
			<NetflixLayout
				// @ts-ignore
				dragEnabled={dragEnabled}
				isSubscribed={isSubscribed}
				onLoad={this.onLoad}
				issues={clone(
					issues.sort(
						(a, b) =>
							new Date(b.releaseDate) - new Date(a.releaseDate)
					)
				)}
				displayInstallationOption={displayInstallationOption}
				onCloseInstallation={this.onCloseInstallation}
			/>
		);
	}
}

Home.propTypes = {
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

function LoadingComponent() {
	const { isOnline, theme, config } = useContext(AppContext);
	return (
		<div className="App">
			<SearchNavbar
				onLogoClick={() => {}}
				isOnline={isOnline}
				theme={theme?.navbar || config?.theme?.navbar}
			/>
			<main>
				<section
					className={styles['home']}
					style={{ paddingTop: theme?.navbar?.height }}
				>
					<Sidebar
						theme={theme?.bottomBar || config?.theme?.bottomBar}
						section="home"
					/>
					<Loading theme={config?.theme?.loading} />
				</section>
			</main>
			<BottomBar
				section="home"
				isVisible={true}
				theme={theme?.bottomBar || config?.theme?.bottomBar}
			/>
		</div>
	);
}

function Empty({ onLoad }) {
	const { isOnline, theme } = useContext(AppContext);
	return (
		<div className="App">
			<SearchNavbar
				onLogoClick={() => {
					onLoad()
						.then(() => {})
						.catch(console.error);
				}}
				isOnline={isOnline}
				theme={theme.navbar}
			/>
			<main>
				<section className={styles['home']}>
					<Sidebar theme={theme.bottomBar} section="home" />
					<EmptyState section={'publication'} />
				</section>
			</main>
			<BottomBar
				section="home"
				isVisible={true}
				theme={theme.bottomBar}
			/>
		</div>
	);
}

Empty.propTypes = {
	onLoad: PropTypes.func,
};

function NetflixLayout({
	issues,
	isSubscribed,
	displayInstallationOption,
	onCloseInstallation,
	onLoad,
}) {
	const {
		isOnline,
		theme,
		// @ts-ignore
		enableInstallationOption,
		// @ts-ignore
		enableAuthorization,
		device,
	} = useContext(AppContext);
	const deviceType = getDeviceType({ deviceWidth: device.width });

	useEffect(() => {
		console.log(`NETFLIX LAYOUT ISSUES`, issues);
	}, []);

	let issuesInComponent = [...issues].filter((issue) => {
		if (!enableAuthorization && issue.isPaid) {
			return false;
		}

		return true;
	});
	const issue = issuesInComponent.shift();

	const { height } = theme.navbar;

	return (
		<div className="App">
			<SearchNavbar
				onLogoClick={() => {
					onLoad()
						.then(() => {})
						.catch(console.error);
				}}
				isOnline={isOnline}
				theme={theme.navbar}
			/>
			{displayInstallationOption && enableInstallationOption ? (
				<InstallationOption onCloseClick={onCloseInstallation} />
			) : null}
			<main>
				<section
					className={styles['home']}
					style={{
						overflow: issuesInComponent.length ? 'auto' : 'none',
						paddingTop: height,
					}}
				>
					<Sidebar theme={theme.bottomBar} section="home" />
					<div
						className={
							styles[
								`netflix-layout-container${
									issuesInComponent.length === 0 &&
									deviceType === 'mobile'
										? '-single'
										: ''
								}`
							]
						}
					>
						<NetflixBanner
							isSubscribed={isSubscribed}
							issue={issue}
						/>
						{issuesInComponent.length ? (
							<div className={styles['netflix-list']}>
								{issuesInComponent.map((issue, i) => (
									<NetflixItem
										isSubscribed={isSubscribed}
										key={i}
										issue={issue}
									/>
								))}
							</div>
						) : null}
					</div>
				</section>
			</main>
			<BottomBar
				section="home"
				isVisible={true}
				theme={theme.bottomBar}
			/>
		</div>
	);
}

NetflixLayout.propTypes = {
	issues: PropTypes.array.isRequired,
	isSubscribed: PropTypes.bool.isRequired,
	displayInstallationOption: PropTypes.bool,
	onCloseInstallation: PropTypes.func,
	onLoad: PropTypes.func,
};

function NetflixBanner({ issue, isSubscribed }) {
	// @ts-ignore
	const { locale, theme } = useContext(AppContext);
	const showRibbon = theme?.ribbons?.display;

	let { id, cover, isPaid, isPurchased, price, currency, slug } = issue;
	let url = `/purchase/issue/${id}`;
	let ribbon = null;
	let priceText = null;
	let downloadButton = <DownloadIssueButton issue={issue} />;

	if (slug) {
		id = slug;
	}

	if (isSubscribed || !isPaid || (isPaid && isPurchased)) {
		url = `/issues/${id}`;
	}

	if (isPaid) {
		if (!isPurchased) {
			priceText = `${currencySymbol(currency)}${price.toFixed(2)}`;
			ribbon = (
				<div className={`${styles['corner-ribbon']} ${styles['paid']}`}>
					{currencySymbol(currency)}
					{price.toFixed(2)}
				</div>
			);
			downloadButton = null;
		}
	} else {
		priceText = locale['FREE'];
		ribbon = <div className={styles['corner-ribbon']}>{priceText}</div>;
	}

	if (!showRibbon) {
		ribbon = null;
	}

	return (
		<div className={styles['netflix-banner']}>
			<div className={styles['cover-blur-netflix']}>
				<img src={cover} alt="cover" />
			</div>
			<div className={styles['cover-item']}>
				{downloadButton}
				{ribbon}
				<Link to={url}>
					<img src={cover} alt="cover" width={600} height={800} />
				</Link>
			</div>
		</div>
	);
}

NetflixBanner.propTypes = {
	issue: PropTypes.object.isRequired,
	isSubscribed: PropTypes.bool.isRequired,
};

function DownloadIssueButton({ issue }) {
	const { isOnline, api, db } = useContext(AppContext);
	const { id } = issue;
	const [isLoading, setIsLoading] = useState(false);
	const [isDownloaded, setIsDownloaded] = useState(null);
	if (!isOnline) {
		return null;
	}

	let downloadedIssues = JSON.parse(
		localStorage.getItem('downloaded-issues') || '{}'
	);

	useEffect(() => {
		if (downloadedIssues[id]) {
			setIsDownloaded(null);
		} else {
			setIsDownloaded(false);
		}
	}, []);

	if (isDownloaded === null) {
		return null;
	}

	if (isDownloaded) {
		return (
			<button
				title={'Downloaded'}
				aria-label={'Downloaded'}
				className={styles['corner-download']}
				disabled={true}
			>
				<Icon name={'cloud-done'} />
			</button>
		);
	}

	const onClick = async () => {
		setIsLoading(true);
		let articles;
		const images = [];
		// Get articles from remote
		try {
			const response = await api.getArticlesFromIssue(id);
			articles = uniqueArticles(response.articles);
			articles = response.articles.map((article, i) => {
				article.index = i;
				images.push(...getImagesFromArticle(article.components));
				return article;
			});
		} catch (e) {
			console.error(e);
			setIsLoading(false);
			return;
		}

		// Store articles in local cache
		try {
			console.debug('Store articles in local cache');
			await db.saveArticles(articles, id);
		} catch (e) {
			console.error(e);
		}

		// Get images from components
		console.debug(`Images to download: ${images.length}`);
		// console.log(images);

		for (const image of images) {
			const img = new Image();
			img.src = image;
		}

		downloadedIssues = JSON.parse(
			localStorage.getItem('downloaded-issues') || '{}'
		);
		downloadedIssues[id] = true;
		localStorage.setItem(
			'downloaded-issues',
			JSON.stringify(downloadedIssues)
		);
		setIsDownloaded(true);
		setIsLoading(false);
	};

	return (
		<button
			title={'Download Issue'}
			aria-label={'Download Issue'}
			className={styles['corner-download']}
			onClick={onClick}
			disabled={isLoading}
		>
			<Icon name={isLoading ? 'pending' : 'cloud-queue'} />
		</button>
	);
}

function uniqueArticles(articles) {
	const articleMap = {};
	let articleOrder = [];
	const response = [];
	for (let article of articles) {
		articleMap[`${article.id}`] = article;
		articleOrder.push(article.id);
	}

	articleOrder = [...new Set(articleOrder)];
	for (let id of articleOrder) {
		response.push(articleMap[id]);
	}

	return response.filter((r) => !!r);
}

function getImagesFromArticle(components) {
	const images = new Set();
	for (const component of components) {
		const { imageurl, columns } = component;
		// Components with images
		if (imageurl) {
			images.add(component.imageurl);
		}

		// Galleries
		if (component.images) {
			for (const image of component.images) {
				if (image.imageurl) {
					images.add(image.imageurl);
				}
			}
		}

		// Columns
		if (columns) {
			for (const column of columns) {
				getImagesFromArticle(column).forEach((i) => images.add(i));
			}
		}
	}

	return [...images].map((image) => image.replace(/\\/g, '/'));
}

DownloadIssueButton.propTypes = {
	issue: PropTypes.object.isRequired,
};

function NetflixItem({ issue, isSubscribed }) {
	let { search } = window.location;
	let { id, cover, name, isPaid, price, currency, isPurchased, slug } = issue;
	if (slug) {
		id = slug;
	}

	// @ts-ignore
	const { locale, theme } = useContext(AppContext);
	const showRibbon = theme?.ribbons?.display;
	const showTitle = theme?.issues?.title?.display;

	let downloadButton = <DownloadIssueButton issue={issue} />;

	let ribbon = null;
	let priceText = null;
	let url = `/purchase/issue/${id}`;
	if (isSubscribed) {
		url = `/issues/${id}`;
	} else {
		if (isPaid) {
			if (!isPurchased) {
				priceText = `${currencySymbol(currency)}${price.toFixed(2)}`;
				ribbon = (
					<div
						className={`${styles['corner-ribbon']} ${styles['paid']}`}
					>
						{currencySymbol(currency)}
						{price.toFixed(2)}
					</div>
				);
				downloadButton = null;
			} else {
				url = `/issues/${id}`;
			}
		} else {
			url = `/issues/${id}`;
			priceText = locale['FREE'];
			ribbon = <div className={styles['corner-ribbon']}>{priceText}</div>;
		}
	}

	if (!showRibbon) {
		ribbon = null;
	}

	if (search) {
		url += search;
	}

	return (
		<div className={styles['netflix-item']}>
			<div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<div className={styles['thumbnail-item']}>
						{downloadButton}
						{ribbon}
						<Link to={url}>
							<img src={cover} alt={name} />
						</Link>
					</div>
				</div>
				{showTitle ? (
					<div className={styles['description']}>{name}</div>
				) : null}
			</div>
		</div>
	);
}

NetflixItem.propTypes = {
	issue: PropTypes.object.isRequired,
	isSubscribed: PropTypes.bool.isRequired,
};

function currencySymbol(currency) {
	currency = currency.toUpperCase();
	switch (currency) {
		case 'EUR':
			return '€';
		case 'USD':
			return '$';
		case 'GBP':
			return '£';
		default:
			return currency;
	}
}

function getDeviceType({ deviceWidth }) {
	return deviceWidth < 769
		? 'mobile'
		: deviceWidth < 1199
		? 'tablet'
		: 'desktop';
}

function clone(obj) {
	return obj ? JSON.parse(JSON.stringify(obj)) : obj;
}
