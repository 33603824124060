import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { ArticleContext } from '../Context';
import styles from '../article.module.css';

export const StyledInfogram = ({
	id,
	fixedwidth,
	expandfullwidth,
	bleed,
	width,
	dataid,
}) => {
	return (
		<Infogram
			id={id}
			dataId={dataid}
			bleed={bleed === 'on'}
			fixedWidth={fixedwidth === 'on'}
			expandFullWidth={expandfullwidth === 'on'}
			width={parseInt(`${width}`)}
		/>
	);
};

StyledInfogram.propTypes = {
	id: PropTypes.string,
	fixedwidth: PropTypes.string,
	expandfullwidth: PropTypes.string,
	bleed: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	fullWidth: PropTypes.string,
	fixedWidth: PropTypes.string,
	dataid: PropTypes.string,
};

export const Infogram = ({
	id,
	dataId,
	bleed,
	fullWidth,
	expandFullWidth,
	fixedWidth,
	width,
}) => {
	if (!dataId) {
		return null;
	}
	useEffect(() => {
		// eslint-disable-next-line no-unused-expressions
		!(function (e, i, n) {
			var t = 'InfogramEmbeds',
				d = e.getElementsByTagName('script')[0];
			if (window[t] && window[t].initialized)
				window[t].process && window[t].process();
			else if (!e.getElementById(n)) {
				var o = e.createElement('script');
				// eslint-disable-next-line no-unused-expressions
				(o.async = 1),
					(o.id = n),
					(o.src =
						'https://e.infogram.com/js/dist/embed-loader-min.js'),
					d.parentNode.insertBefore(o, d);
			}
		})(document, 0, 'infogram-async');
	}, [dataId]);
	const articleContext = useContext(ArticleContext);
	const containerStyle = {};
	let componentStyle = {};

	if (fullWidth) {
		containerStyle.minWidth = '100%';
		containerStyle.width = '100%';
	}

	if (expandFullWidth) {
		componentStyle.minWidth = '100%';
		componentStyle.width = '100%';
	}

	if (fixedWidth && width) {
		componentStyle = {
			minWidth: width,
			width: width,
			maxWidth: width,
		};
	}

	if (fullWidth && expandFullWidth) {
		componentStyle = {
			minWidth: articleContext.width,
			width: articleContext.width,
		};
	}
	return (
		<div
			id={id}
			className={`${styles['infogram']} ${
				bleed && !fullWidth ? 'bleed' : ''
			}`}
			style={containerStyle}
		>
			<div
				className="infogram-embed"
				data-id={dataId}
				style={componentStyle}
				data-type="interactive"
			/>
		</div>
	);
};

Infogram.propTypes = {
	id: PropTypes.string,
	dataId: PropTypes.string,
	bleed: PropTypes.bool,
	fullWidth: PropTypes.bool,
	expandFullWidth: PropTypes.bool,
	width: PropTypes.number,
	fixedWidth: PropTypes.bool,
};

Infogram.defaultProps = {
	id: '',
	dataId: '',
	bleed: false,
	expandFullWidth: false,
	fullWidth: false,
};
