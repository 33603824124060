import { createContext } from 'react';
export const ReplicaModeContext = createContext({
	issue: null,
	article: null,
	lang: 'en',
	onClickToc: null,
	isTocOpen: false,
	mode: 'single',
	onClickPageMode: null,
	pages: [],
	selected: null,
	setSelected: null,
	selectedIndex: null,
	setSelectedIndex: null,
});
