import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';

import { useIsInViewport } from '../hooks/use-viewport';
import { useComponentAnimation } from '../hooks/use-animation';

import InnerHTML from 'dangerously-set-html-content';

export const StyledCustom = ({ id, bleed, content, animation }) => {
	return <Custom id={id} bleed={bleed === 'on'} content={content} animation={animation} />;
};

StyledCustom.propTypes = {
	id: PropTypes.string,
	bleed: PropTypes.string,
	content: PropTypes.string,
	animation: PropTypes.any,
};

export const Custom = ({ id, bleed, content, animation }) => {
	const ref = useRef();
	const [animationClasses, setAnimationClasses] = useState([]);
	const classNames = ['component', 'custom'];

	if(bleed) {
		classNames.push(bleed);
	}

	const hasAnimation = animation && animation.type !== 'none';
	const isInViewport = useIsInViewport(ref, hasAnimation);
	useComponentAnimation(ref, isInViewport, animation, setAnimationClasses);

	if (!content) {
		return null;
	}

	return (
		<div ref={ref} id={id} className={[...classNames, ...animationClasses].join(' ')}>
			<InnerHTML html={content} />
		</div>
	);
};

Custom.propTypes = {
	id: PropTypes.string.isRequired,
	bleed: PropTypes.bool,
	content: PropTypes.string,
	animation: PropTypes.any,
};

Custom.defaultProps = {
	bleed: false,
};
