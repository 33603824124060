import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { ArticleContext } from '../Context';
import { Caption } from './Caption';
import styles from '../article.module.css';

export const StyledAdvert = ({
	id,
	fullwidth,
	align,
	expandfullwidth,
	bleed,
	caption,
	width,
	credit,
	imagelink,
	captionenabled,
	fixedwidth,
	creditenabled,
	imageurl,
	imageclip,
	linktype,
	advertlink,
}) => {
	const { lang, style } = useContext(ArticleContext);
	if (typeof caption === 'object') {
		caption = caption[lang] ? caption[lang] : '';
	}
	if (typeof credit === 'object') {
		credit = credit[lang] ? credit[lang] : '';
	}

	if (typeof width === 'string') {
		width = parseInt(width);
	}

	let marginBottom = 0;
	if (style?.canvas?.componentBottomMargin) {
		marginBottom = parseInt(`${style['canvas']?.componentBottomMargin}`);
	}

	return (
		<Advert
			id={id}
			align={align}
			bleed={bleed === 'on'}
			fullWidth={fullwidth === 'on'}
			expandFullWidth={expandfullwidth === 'on'}
			caption={caption}
			imageClip={imageclip}
			captionEnabled={captionenabled === 'on'}
			credit={
				credit
					? typeof credit === 'string'
						? credit
						: credit[lang]
					: ''
			}
			creditEnabled={creditenabled === 'on'}
			link={imagelink}
			url={imageurl}
			linkType={linktype}
			advertLink={advertlink}
			marginBottom={marginBottom}
			fixedWidth={fixedwidth === 'on'}
		/>
	);
};

StyledAdvert.propTypes = {
	id: PropTypes.string,
	align: PropTypes.string,
	bleed: PropTypes.string,
	fullwidth: PropTypes.string,
	expandfullwidth: PropTypes.string,
	caption: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	captionenabled: PropTypes.string,
	captionposition: PropTypes.string,
	credit: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	creditenabled: PropTypes.string,
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	fixedwidth: PropTypes.string,
	imagelink: PropTypes.string,
	advertlink: PropTypes.string,
	imageurl: PropTypes.string,
	imageClip: PropTypes.string,
	onClick: PropTypes.func,
	linktype: PropTypes.string,
	imageclip: PropTypes.string,
};

export const Advert = ({
	id,
	align,
	bleed,
	fullWidth,
	expandFullWidth,
	caption,
	captionEnabled,
	credit,
	creditEnabled,
	width,
	fixedWidth,
	link,
	url,
	imageClip,
	onClick,
	marginBottom,
	linkType,
	advertLink,
}) => {
	const articleContext = useContext(ArticleContext);
	let containerStyle = {
		marginBottom,
	};
	let imageStyle = {};

	containerStyle.alignItems = getAlignment(align);

	if (expandFullWidth) {
		bleed = true;
		fullWidth = false;
	}

	/*if (bleed && !fullWidth) {
        containerStyle.marginLeft = `-${paddingLeft}`;
        containerStyle.marginRight = `-${paddingRight}`;
    }*/

	if (fullWidth) {
		containerStyle.minWidth = '100%';
		containerStyle.width = '100%';
	}

	if (expandFullWidth) {
		imageStyle.minWidth = '100%';
		imageStyle.width = '100%';
	}

	if (fixedWidth && width) {
		imageStyle = {
			maxWidth: width,
		};
	}

	imageStyle.clipPath = getClipPath(imageClip);

	let click = () => {};
	const classNames = [
		'component',
		styles['component'],
		'media',
		styles['image'],
		styles['advert'],
	];
	if (bleed && !fullWidth) {
		classNames.push('bleed');
	}

	if (linkType === 'web' && link && isValidUrl(link)) {
		containerStyle.cursor = 'pointer';
		imageStyle.cursor = 'pointer';
		return (
			<figure
				id={id}
				className={classNames.join(' ')}
				style={containerStyle}
				onClick={() => {
					window.open(link);
				}}
			>
				<LazyLoadImage
					src={`${url.replace(/\\/g, '/')}`}
					width={articleContext.width}
					height={400}
					alt={caption || ''}
					style={imageStyle}
				/>
				{captionEnabled && caption ? (
					<Caption content={caption} />
				) : null}
				{creditEnabled && credit ? <Credit content={credit} /> : null}
			</figure>
		);
	}

	if (
		linkType === 'page' &&
		advertLink &&
		/\/article\/\d+/gim.test(advertLink)
	) {
		return (
			<figure
				id={id}
				className={classNames.join(' ')}
				style={containerStyle}
				onClick={click}
			>
				<a href={advertLink}>
					<LazyLoadImage
						src={`${url.replace(/\\/g, '/')}`}
						width={articleContext.width}
						height={400}
						alt={caption || ''}
						style={imageStyle}
					/>
					{captionEnabled && caption ? (
						<Caption content={caption} />
					) : null}
					{creditEnabled && credit ? (
						<Credit content={credit} />
					) : null}
				</a>
			</figure>
		);
	}

	if (onClick) {
		containerStyle.cursor = 'pointer';
		click = onClick;
	}

	return (
		<figure
			id={id}
			className={classNames.join(' ')}
			style={containerStyle}
			onClick={click}
		>
			<LazyLoadImage
				src={`${url.replace(/\\/g, '/')}`}
				width={articleContext.width}
				height={400}
				alt={caption || ''}
				style={imageStyle}
			/>
			{captionEnabled && caption ? <Caption content={caption} /> : null}
			{creditEnabled && credit ? <Credit content={credit} /> : null}
		</figure>
	);
};

Advert.propTypes = {
	id: PropTypes.string,
	align: PropTypes.string,
	bleed: PropTypes.bool,
	fullWidth: PropTypes.bool,
	expandFullWidth: PropTypes.bool,
	caption: PropTypes.string,
	captionEnabled: PropTypes.bool,
	captionPosition: PropTypes.bool,
	credit: PropTypes.string,
	creditEnabled: PropTypes.bool,
	width: PropTypes.number,
	fixedWidth: PropTypes.bool,
	link: PropTypes.string,
	advertLink: PropTypes.string,
	url: PropTypes.string,
	imageClip: PropTypes.string,
	onClick: PropTypes.func,
	linkType: PropTypes.string,
	marginBottom: PropTypes.number,
};

Advert.defaultProps = {
	captionEnabled: true,
	creditEnabled: true,
	marginBottom: 0,
};

function getClipPath(imageClip) {
	switch (imageClip) {
		case 'circle':
			return 'circle(50% at 50% 50%)';
		case 'ellipse':
			return 'ellipse(25% 40% at 50% 50%)';
		case 'triangle':
			return 'polygon(50% 0%, 0% 100%, 100% 100%)';
		case 'trapezoid':
			return 'polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%)';
		case 'parallelogram':
			return 'polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)';
		case 'rhombus':
			return 'polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%)';
		case 'pentagon':
			return 'polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%)';
		case 'hexagon':
			return 'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)';
		case 'heptagon':
			return 'polygon(50% 0%,90% 20%, 100% 60%,75% 100%, 25% 100%,0% 60%,10% 20%)';
		case 'octagon':
			return 'polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)';
		case 'nonagon':
			return 'polygon(50% 0%, 83% 12%, 100% 43%, 94% 78%, 68% 100%, 32% 100%, 6% 78%, 0% 43%, 17% 12%)';
		case 'decogon':
			return 'polygon(50% 0%, 80% 10%, 100% 35%, 100% 70%, 80% 90%, 50% 100%, 20% 90%, 0% 70%, 0% 35%, 20% 10%)';
		case 'bevel':
			return 'polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%)';
		case 'rabbet':
			return 'polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%)';
		case 'leftarrow':
			return 'polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%)';
		case 'rightarrow':
			return 'polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%)';
		case 'leftpoint':
			return 'polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%)';
		case 'rightpoint':
			return 'polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)';
		case 'rightchevron':
			return 'polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)';
		case 'leftchevron':
			return 'polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%)';
		case 'star':
			return 'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)';
		case 'close':
			return 'polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%)';
		default:
			return null;
	}
}

function getAlignment(align) {
	switch (align) {
		case 'left':
			return 'flex-start';
		case 'right':
			return 'flex-end';
		default:
			return 'center';
	}
}

const Credit = ({ content }) => {
	return (
		<cite
			className={styles['credit']}
			dangerouslySetInnerHTML={{ __html: content }}
		/>
	);
};

Credit.propTypes = {
	content: PropTypes.string,
};

Credit.defaultProps = {
	content: '',
};

function isValidUrl(urlString) {
	try {
		return Boolean(new URL(urlString));
	} catch (e) {
		return false;
	}
}
