import Home from './Home';
import Feeds from './Feeds';
import Feed from './feeds/Feed';
import Bookmarks, { Bookmark } from './Bookmarks';
import Authenticate from './Authenticate';
import InternalArticle from './InternalArticle';
import Purchases from './Purchases';
import { PurchaseIssue, PurchaseSubscription } from './Purchase';
import Issue from './Issue';
import Lock from './Lock';
import Settings, {
	SettingsStorage,
	SettingsSubscription,
	SettingsLanguage,
	SettingsAbout,
} from './Settings';

export default {
	Bookmark,
	Bookmarks,
	Authenticate,
	Purchases,
	PurchaseIssue,
	PurchaseSubscription,
	Issue,
	Home,
	Lock,
	Settings,
	SettingsStorage,
	SettingsSubscription,
	SettingsLanguage,
	InternalArticle,
	SettingsAbout,
	Feeds,
	Feed,
};
