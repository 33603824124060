import React from 'react';
import PropTypes from 'prop-types';

import JSXStyle from 'styled-jsx/style';

import styles from './Loading.module.css';

export function Loading({ theme }) {
	let { color, type } = theme;

	let loadingComponent = null;
	switch (type) {
		case 'ellipsis':
			loadingComponent = <Ellipsis background={color} />;
			break;
		case 'rotating-circle':
			loadingComponent = <RotatingCircle background={color} />;
			break;
		case 'rectangle-pulse':
			loadingComponent = <RectanglePulse background={color} />;
			break;
		case 'ring':
			loadingComponent = <RingLoader background={color} />;
			break;
		case 'double-ring':
			loadingComponent = <DoubleRingLoader background={color} />;
			break;
		case 'roller':
			loadingComponent = <RollerLoader background={color} />;
			break;
		case 'grid':
			loadingComponent = <GridLoader background={color} />;
			break;
		case 'hourglass':
			loadingComponent = <HourglassLoader background={color} />;
			break;
		case 'spinner':
			loadingComponent = <SpinnerLoader background={color} />;
			break;
		default:
			loadingComponent = <DefaultLoader background={color} />;
	}

	return (
		<div className={styles['loading-container']}>{loadingComponent}</div>
	);
}

Loading.propTypes = {
	theme: PropTypes.object,
};

export function Spinner({ size, border, background, color }) {
	if (!size) {
		size = 30;
	}

	if (!border) {
		border = 4;
	}

	if (!background) {
		background = '#000';
	}

	if (!color) {
		color = '#000';
	}

	const isEllipsis = true;
	if (isEllipsis) {
		return (
			<div className={styles['loading-containter']}>
				<Ellipsis background={background} />
			</div>
		);
	}

	return (
		<div className={styles['loading-containter']}>
			<div className={styles['lds-ripple']}>
				<div style={{ border: `${border}px solid ${color}` }}></div>
				<div style={{ border: `${border}px solid ${color}` }}></div>
			</div>
		</div>
	);
}

Spinner.propTypes = {
	size: PropTypes.number,
	border: PropTypes.number,
	background: PropTypes.string,
	color: PropTypes.string,
};

export function Ellipsis({ background }) {
	return (
		<div className={styles['lds-ellipsis']}>
			<div style={{ background }}></div>
			<div style={{ background }}></div>
			<div style={{ background }}></div>
			<div style={{ background }}></div>
		</div>
	);
}

Ellipsis.propTypes = {
	background: PropTypes.string,
};

export function RotatingCircle({ background }) {
	return (
		<div className={styles['lds-circle']}>
			<div style={{ background }} />
		</div>
	);
}

RotatingCircle.propTypes = {
	background: PropTypes.string,
};

export function RectanglePulse({ background }) {
	return (
		<div className={styles['lds-rectangle-pulse']}>
			<div style={{ background }}></div>
			<div style={{ background }}></div>
			<div style={{ background }}></div>
		</div>
	);
}

RectanglePulse.propTypes = {
	background: PropTypes.string,
};

export function RingLoader({ background }) {
	const style = {
		border: `8px solid ${background}`,
		borderColor: `${background} transparent transparent transparent`,
	};
	return (
		<div className={styles['lds-ring']}>
			<div style={style}></div>
			<div style={style}></div>
			<div style={style}></div>
			<div style={style}></div>
		</div>
	);
}

RingLoader.propTypes = {
	background: PropTypes.string,
};

export function DoubleRingLoader({ background }) {
	const id = Date.now();
	const componentCSS = `
	.jsx-${id}:after {
		border: 6px solid ${background};
		border-color: ${background} transparent ${background} transparent;
	}`;
	return (
		<div className={`${styles['lds-dual-ring']} jsx-${id}`}>
			<JSXStyle id={id}>{componentCSS}</JSXStyle>
		</div>
	);
}

DoubleRingLoader.propTypes = {
	background: PropTypes.string,
};

export function RollerLoader({ background }) {
	const id = Date.now();
	const componentCSS = `
	.jsx-${id} div:after {
		background: ${background};
	}`;
	return (
		<div className={`${styles['lds-roller']} jsx-${id}`}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<JSXStyle id={id}>{componentCSS}</JSXStyle>
		</div>
	);
}

RollerLoader.propTypes = {
	background: PropTypes.string,
};

export function DefaultLoader({ background }) {
	const id = Date.now();
	const componentCSS = `
	.jsx-${id} div {
		background: ${background};
	}`;
	return (
		<div className={`${styles['lds-default']} jsx-${id}`}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<JSXStyle id={id}>{componentCSS}</JSXStyle>
		</div>
	);
}

DefaultLoader.propTypes = {
	background: PropTypes.string,
};

export function GridLoader({ background }) {
	const id = Date.now();
	const componentCSS = `
	.jsx-${id} div {
		background: ${background};
	}`;
	return (
		<div className={`${styles['lds-grid']} jsx-${id}`}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<JSXStyle id={id}>{componentCSS}</JSXStyle>
		</div>
	);
}

GridLoader.propTypes = {
	background: PropTypes.string,
};

export function HourglassLoader({ background }) {
	const id = Date.now();
	const componentCSS = `
	.jsx-${id}:after {
		border: 32px solid ${background};
		border-color: ${background} transparent ${background} transparent;
	}`;
	return (
		<div className={`${styles['lds-hourglass']} jsx-${id}`}>
			<JSXStyle id={id}>{componentCSS}</JSXStyle>
		</div>
	);
}

HourglassLoader.propTypes = {
	background: PropTypes.string,
};

export function SpinnerLoader({ background }) {
	const id = Date.now();
	const componentCSS = `
	.jsx-${id} div:after {
		background: ${background};
	}`;
	return (
		<div className={`${styles['lds-spinner']} jsx-${id}`}>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<JSXStyle id={id}>{componentCSS}</JSXStyle>
		</div>
	);
}

SpinnerLoader.propTypes = {
	background: PropTypes.string,
};
