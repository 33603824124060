import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ArticleContext } from '../Context';
import JSXStyle from 'styled-jsx/style';

import { useIsInViewport } from '../hooks/use-viewport';
import { useComponentAnimation } from '../hooks/use-animation';

export const StyledDivider = ({
	id,
	bleed,
	style,
	expandfullwidth,
	animation,
}) => {
	return (
		<Divider
			id={id}
			bleed={bleed === 'on'}
			StyleID={style}
			animation={animation}
			expandFullWidth={expandfullwidth === 'on'}
		/>
	);
};

StyledDivider.propTypes = {
	id: PropTypes.string,
	bleed: PropTypes.string,
	margin: PropTypes.string,
	style: PropTypes.string,
	animation: PropTypes.any,
	expandfullwidth: PropTypes.bool,
};

export const Divider = ({ id, bleed, expandFullWidth, StyleID, animation }) => {
	const ref = useRef();
	const [animationClasses, setAnimationClasses] = useState([]);

	const { style } = useContext(ArticleContext);
	const hasAnimation = animation && animation.type !== 'none';

	const isInViewport = useIsInViewport(ref, hasAnimation);
	useComponentAnimation(ref, isInViewport, animation, setAnimationClasses);

	const { css } = getStyle(clone(style.properties), StyleID, id);

	const containerStyle = {
		clear: 'both',
	};
	const componentStyle = {};

	if (expandFullWidth) {
		componentStyle.minWidth = '100%';
		componentStyle.width = '100%';
	}

	const classNames = [`jsx-${id}`];
	if (bleed) {
		classNames.push('bleed');
	}

	return (
		<div
			ref={ref}
			id={id}
			style={containerStyle}
			className={[...classNames, ...animationClasses].join(' ')}
		>
			<hr style={componentStyle} />
			<JSXStyle id={id}>{css}</JSXStyle>
		</div>
	);
};

Divider.propTypes = {
	id: PropTypes.string,
	bleed: PropTypes.bool,
	expandFullWidth: PropTypes.bool,
	StyleID: PropTypes.string,
	animation: PropTypes.any,
};

function getStyle(style, StyleID, id) {
	const response = {
		margin: 0,
		css: '',
	};
	if (!style) {
		return response;
	}

	let dividerStyle = style['divider'];
	const styles = style['dividers'];

	if (StyleID && styles && styles.length) {
		for (const s of styles) {
			if (`${s.id}` === `${StyleID}`) {
				dividerStyle = mergeDeep(dividerStyle, s);
				break;
			}
		}
	}

	let color = dividerStyle?.color
		? `#${dividerStyle.color.replace('#', '')}`
		: 'black';
	let thickness = dividerStyle?.thickness
		? `${dividerStyle.thickness}px`
		: '1px';
	let margin = [];
	if (dividerStyle?.marginTop) {
		margin.push(
			`margin-top: ${dividerStyle?.marginTop}${
				dividerStyle?.unit?.margintTop || 'px'
			};`
		);
	}
	if (dividerStyle?.marginBottom) {
		margin.push(
			`margin-bottom: ${dividerStyle?.marginBottom}${
				dividerStyle?.unit?.margintBottom || 'px'
			};`
		);
	}

	margin = margin.join('\n');
	const width = dividerStyle?.width ? dividerStyle.width : 100;

	response.css = `.jsx-${id} hr{
		border: 0px;
		width: ${width}%;
		box-sizing: content-box;
	}

	.jsx-${id} {
		${margin}
	}
	
	`;

	let overwriteStyle = {};

	for (const k in styles) {
		const s = styles[k];
		if (s.id === StyleID) {
			overwriteStyle = s;
			break;
		}
	}

	let css = '';
	if (overwriteStyle.css) {
		css = `.jsx-${id} hr {${overwriteStyle.css}}`;
	}

	if (dividerStyle.style) {
		switch (dividerStyle.style) {
			case 'blur':
				response.css += `.jsx-${id} hr {
					height: 0;
					box-shadow: 0 0 10px 1px ${color};
				}
				.jsx-${id} hr:after{
					content: "\\00a0";
				}`;
				break;
			case 'dashed':
				response.css += `.jsx-${id} hr {
					border-bottom: ${thickness} dashed ${color};
				}`;
				break;
			case 'dotted':
				response.css += `.jsx-${id} hr {
				border-bottom: ${thickness} dotted ${color};
			}`;
				break;
			case 'chapter':
				response.css += `.jsx-${id} hr {
						padding: 0;
						border-top: medium double ${color};
						color: ${color};
						text-align: center;
					}
					.jsx-${id} hr:after {
						content: "§";
						display: inline-block;
						position: relative; 
						top: -0.7em;  
						font-size: 1.5em;
						padding: 0 0.25em;
					}`;
				break;
			case 'elegant':
				response.css += `.jsx-${id} hr {
					height: 20px;
					border-style: solid;
					border-color: ${color};
					border-width: 1px 0 0 0;
					border-radius: 20px;
				}
				.jsx-${id} hr:before {
					display: block;
					content: "";
					height: 20px;
					margin-top: -21px;
					border-style: solid;
					border-color: ${color};
					border-width: 0 0 1px 0;
					border-radius: 20px;
				}`;
				break;
			case 'innerfade':
				response.css += `.jsx-${id} hr {
					height: 2px;
					background: ###color#;
					background-image: -webkit-linear-gradient(left, #fff, ${color}, #fff);
					background-image: linear-gradient(left, #fff, ${color}, #fff);
				}`;
				break;
			case 'inset':
				response.css += `.jsx-${id} hr {
					height: 0;
					border-top: 1px solid rgba(0, 0, 0, 0.1);
					border-bottom: 1px solid rgba(255, 255, 255, 0.3);
				}`;
				break;
			case 'shadow':
				response.css += `.jsx-${id} hr{
					height: 12px;
      				box-shadow: inset 0 10px 12px -12px rgba(0,0,0,0.5);
				}`;
				break;
			default:
				response.css += `.jsx-${id} hr{
					border-bottom: ${thickness} solid ${color};
				}`;
		}
	}

	if (dividerStyle) {
		switch (dividerStyle.align) {
			case 'left':
				response.css += `.jsx-${id} hr {
					margin-left: 0px;
				}`;
				break;
			case 'right':
				response.css += `.jsx-${id} hr {
					margin-right: 0px;
				}`;
				break;
			default:
				break;
		}
	}

	if (!response.css) {
		response.css = css;
	}
	return response;
}

Divider.propTypes = {
	id: PropTypes.string,
	style: PropTypes.string,
	bleed: PropTypes.bool,
	margin: PropTypes.string,
};

Divider.defaultProps = {
	bleed: false,
};

function mergeDeep(target, ...sources) {
	if (!sources.length) return target;
	const source = sources.shift();

	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key]) Object.assign(target, { [key]: {} });
				mergeDeep(target[key], source[key]);
			} else {
				Object.assign(target, { [key]: source[key] });
			}
		}
	}

	return mergeDeep(target, ...sources);
}

function isObject(item) {
	return item && typeof item === 'object' && !Array.isArray(item);
}

function clone(obj) {
	return obj ? JSON.parse(JSON.stringify(obj)) : obj;
}
