export default class Styles {
	articleStyleMap = new Map();
	deviceStyleMap = new Map();
	customStyleMap = new Map();

	styles = new Map();
	constructor(styles) {
		/*for (const style of styles) {
			const { id, type } = style;
			switch (type) {
				case 'article':
					this.articleStyleMap.set(`${id}`, style);
					break;
				case 'device':
					this.deviceStyleMap.set(`${id}`, style);
					break;
				default:
					this.customStyleMap.set(`${id}`, style);
			}
		}

		this.processInheritance();

		this.remapStyleProperties(this.articleStyleMap);
		this.remapStyleProperties(this.deviceStyleMap);
		this.remapStyleProperties(this.customStyleMap);
		
		this.styles = new Map([
			...this.articleStyleMap,
			...this.customStyleMap,
			...this.deviceStyleMap,
		]);*/

		this.styles = styles.reduce(this.reduceStyles, new Map());
	}

	reduceStyles(acc, style) {
		acc.set(`${style.id}`, style);
		return acc;
	}

	asArray = () => {
		const styles = [];
		for (let [, style] of this.styles.entries()) {
			styles.push(style);
		}

		return styles;
	};

	// Create the inheritance tree for all the styles
	processInheritance = () => {
		// Process article styles inheritance
		for (let [id, style] of this.articleStyleMap.entries()) {
			const { parent, properties } = style;
			if (!properties.buttons) {
				properties.buttons = [];
			}

			let overwriteProperties = { ...properties };
			const dependencies = this.getDependencies(parent, [
				clone(overwriteProperties),
			]);

			for (const dependency of dependencies) {
				overwriteProperties = this.overwriteStyle(
					dependency,
					clone(overwriteProperties)
				);
			}
			style.properties = overwriteProperties;
			this.articleStyleMap.set(id, style);
		}

		// Process custom styles inheritance
		for (let [id, style] of this.customStyleMap.entries()) {
			const { parent, properties } = style;
			if (!properties.buttons) {
				properties.buttons = [];
			}

			let overwriteProperties = { ...properties };
			const dependencies = this.getDependencies(parent, [
				clone(overwriteProperties),
			]);

			for (const dependency of dependencies) {
				overwriteProperties = this.overwriteStyle(
					dependency,
					clone(overwriteProperties)
				);
			}
			style.properties = overwriteProperties;
			this.customStyleMap.set(id, style);
		}
	};

	static reduceProperties = (acc, properties) => {
		for (const property in properties) {
			// The property do not exist so we just add it
			if (!acc[property]) {
				acc[property] = properties[property];
				continue;
			}

			// The property is an array so we just concatenate
			if (Array.isArray(properties[property])) {
				acc[property] = [...acc[property], ...properties[property]];
				continue;
			}

			acc[property] = { ...acc[property], ...properties[property] };
		}
		return acc;
	};

	getDependencies(parent, dependencies) {
		const style = parent ? this.articleStyleMap.get(parent) : null;
		if (style) {
			const { parent, properties } = style;
			if (properties) {
				dependencies.unshift({ ...properties });
			}
			return this.getDependencies(parent, dependencies);
		}

		return dependencies;
	}

	overwriteStyle(style, overwrite = {}) {
		if (!overwrite) {
			return clone(style);
		}
		overwrite = { ...overwrite };
		style = clone(style);
		for (const property in overwrite) {
			// The property do not exist so we just add it
			if (!style[property]) {
				style[property] = overwrite[property];
				continue;
			}

			// The property is an array so we just concatenate
			if (Array.isArray(overwrite[property])) {
				if (!style[property]) {
					style[property] = [];
				}
				style[property] = [...style[property], ...overwrite[property]];
				continue;
			}

			style[property] = { ...style[property], ...overwrite[property] };
		}

		if (style['styleblocks']) {
			delete style['styleblocks'];
		}

		return style;
	}

	remapStyleProperties = (styleMap) => {
		for (const [id, style] of styleMap.entries()) {
			style.properties = this.mapDeviceProperties(
				clone(style.properties)
			);
			styleMap.set(id, style);
		}
	};

	mapDeviceProperties = (style) => {
		for (const property in style) {
			if (Array.isArray(style[property])) {
				style[property] = style[property].map((i) =>
					Styles.mapProperties(i)
				);
				continue;
			}
			style[property] = Styles.mapProperties(style[property]);
		}
		return style;
	};

	static mapProperties(properties) {
		if (!properties) {
			return {};
		}
		properties = clone(properties);
		return Styles.clean({
			case: properties['case'],
			componentBottomMargin: properties['component_margin_bottom'],
			fontFamily: properties['font_family'],
			tabTextColor: properties['tab_text_color']
				? `#${properties['tab_text_color']}`
				: null,
			tabPaddingVertical: properties['tab_padding_vertical']
				? parseInt(`${properties['tab_padding_vertical']}`)
				: null,
			tabPaddingHorizontal: properties['tab_padding_horizontal']
				? parseInt(`${properties['tab_padding_horizontal']}`)
				: null,
			tabSelectedTextColor: properties['tab_selected_text_color']
				? `#${properties['tab_selected_text_color']}`
				: null,
			tabSelectedHighlightColor: properties[
				'tab_selected_highlight_color'
			]
				? `#${properties['tab_selected_highlight_color']}`
				: null,
			tabSelectedBackgroundColor: properties[
				'tab_selected_background_color'
			]
				? `#${properties['tab_selected_background_color']}`
				: null,
			tabBackgroundColor: properties['tab_background_color']
				? `#${properties['tab_background_color'].replace('#', '')}`
				: null,
			tabContentTopMargin: properties['tab_content_top_margin'],
			fontSize: properties['font_size'],
			color: properties['color']
				? `#${properties['color'].replace('#', '')}`
				: null,
			fontWeight: properties['font_weight'],
			fontStyle: properties['font_style'],
			fontFamilyBold: properties['font_family_bold'],
			fontFamilyItalic: properties['font_family_italic'],
			fontFamilyBoldItalic: properties['font_family_bold_italic'],
			backgroundColor: properties['background_color']
				? `#${properties['background_color'].replace('#', '')}`
				: null,
			backgroundColorEnabled: properties['background_color_enabled'],
			textDecoration: properties['text_decoration'],
			lineHeight: properties['line_height'],
			textTransform: properties['text_transform'],
			paragraphSpacing: properties['paragraph_spacing'],
			marginLeft: properties['margin_left'],
			marginRight: properties['margin_right'],
			marginBottom: properties['margin_bottom'],
			marginTop: properties['margin_top'],
			textAlign: properties['text_align'],
			letterSpacing: properties['letter_spacing'],
			css: properties['css'],
			textIndent: properties['text_indent'],
			contentAlign: properties['content_align'],
			borderType: properties['border_type'],
			borderEdge: properties['border_edge'],
			borderWidth: properties['border_width'],
			borderRadius: properties['border_radius'],
			borderColor: properties['border_color']
				? `#${properties['border_color'].replace('#', '')}`
				: null,
			dropcapFontFamily: properties['dropcap_font_family'],
			dropcapBackgroundColor: properties['dropcap_background_color']
				? `#${properties['dropcap_background_color'].replace('#', '')}`
				: null,
			dropcapFontSize: properties['dropcap_font_size'],
			dropcapLineHeight: properties['dropcap_line_height'],
			dropcapMarginTop: properties['dropcap_margin_top'],
			dropcapMarginBottom: properties['dropcap_margin_bottom'],
			dropcapMarginLeft: properties['dropcap_margin_left'],
			dropcapMarginRight: properties['dropcap_margin_right'],
			dropcapPaddingTop: properties['dropcap_padding_top'],
			dropcapPaddingBottom: properties['dropcap_padding_bottom'],
			dropcapPaddingLeft: properties['dropcap_padding_left'],
			dropcapPaddingRight: properties['dropcap_padding_right'],
			dropcapColor: properties['dropcap_color']
				? `#${properties['dropcap_color'].replace('#', '')}`
				: null,
			textDisplay: properties['text_display'],
			paddingTop: properties['padding_top'],
			paddingBottom: properties['padding_bottom'],
			paddingLeft: properties['padding_left'],
			paddingRight: properties['padding_right'],
			horizontalPadding: properties['horizontal_padding'],
			bodyColor: properties['body_color']
				? `#${properties['body_color'].replace('#', '')}`
				: null,
			spacingUnit: properties['spacing_unit'],
			listStyle: properties['list_style'],
			style: properties['style'],
			thickness: properties['thickness'],
			margin: properties['margin'],
			width: properties['width'],
			captionPosition:
				properties['captionposition'] || properties['caption_position'],
			text: properties['text'],
			align: properties['align'],
			size: properties['size'],
			name: properties['name'],
			id: properties['id'],
			vSmallHeight: properties['vsmall_height'],
			smallHeight: properties['small_height'],
			mediumHeight: properties['medium_height'],
			largeHeight: properties['large_height'],
			vLargeHeight: properties['vlarge_height'],
		});
	}

	static clean(obj) {
		for (var propName in obj) {
			if (obj[propName] === null || obj[propName] === undefined) {
				delete obj[propName];
			}
		}
		return obj;
	}

	get(id) {
		if (id) {
			return this.styles.get(id);
		}
		return this.styles;
	}
}

function clone(obj) {
	if (obj == null || typeof obj != 'object') return obj;

	var temp = new obj.constructor();
	for (var key in obj) temp[key] = clone(obj[key]);

	return temp;
}
