import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../article.module.css';
import { ArticleContext } from '../Context';
import { StyledText } from './Text';
import { StyledImage } from './Image';
import { StyledAdvert } from './Advert';
import { StyledInfogram } from './Infogram';
import { StyledSpacer } from './Spacer';
import { StyledButton } from './Button';
import { Anchor } from './Anchor';
import { StyledVideo } from './Video';
import { StyledAudio } from './Audio';
import { StyledTwitterTimeline } from './Twitter';
import { StyledDivider } from './Divider';
import { StyledColumns } from './Columns';
import { StyledGallery } from './Gallery';
import { StyledCustom } from './Custom';

export const StyledTabs = ({ id, tabs, columns, language }) => {
	return <Tabs id={id} tabs={tabs} columns={columns} language={language} />;
};

StyledTabs.propTypes = {
	id: PropTypes.string,
	tabs: PropTypes.array,
	columns: PropTypes.array,
	language: PropTypes.string,
};

export const Tabs = ({ id, tabs, columns, language }) => {
	const [selected, setSelected] = useState(0);
	if (!language) {
		language = 'en';
	}
	if (!tabs.length || !columns.length || tabs.length !== columns.length) {
		return null;
	}

	return (
		<section id={id} className={[styles['tabs'], 'tabs'].join(' ')}>
			<div>
				<nav>
					{tabs.map((tab, i) => (
						<Tab
							key={i}
							index={i}
							isSelected={selected === i}
							setIndex={setSelected}
							label={tab?.label[language]}
						/>
					))}
				</nav>
				<Content components={columns[selected]} />
			</div>
		</section>
	);
};

Tabs.propTypes = {
	id: PropTypes.string,
	tabs: PropTypes.array,
	columns: PropTypes.array,
	language: PropTypes.string,
};
const Tab = ({ index, isSelected, setIndex, label }) => {
	const classNames = [styles['tab'], 'tab'];
	if (isSelected) {
		classNames.push(styles['selected']);
		classNames.push('selected');
	}
	return (
		<div
			className={classNames.join(' ')}
			onClick={() => {
				setIndex(index);
			}}
		>
			{label}
		</div>
	);
};

Tab.propTypes = {
	index: PropTypes.number,
	isSelected: PropTypes.bool,
	setIndex: PropTypes.func,
	tab: PropTypes.object,
	label: PropTypes.string,
};

const Content = ({ components }) => {
	return (
		<div className={[styles['content'], 'content'].join(' ')}>
			{components
				.filter(byDevice)
				.filter((i) => !!i)
				.map(byComponent)
				.filter((i) => !!i)}
		</div>
	);
};

Content.propTypes = {
	components: PropTypes.array,
};

const byDevice = ({ devices }) => {
	const { device } = useContext(ArticleContext);
	if (
		(devices.phone === 'off' && device === 'mobile') ||
		(devices.tablet === 'off' && device === 'tablet') ||
		(devices.desktop === 'off' && device === 'desktop')
	) {
		return false;
	}

	return true;
};

const byComponent = (component, i) => {
	switch (component.component) {
		case 'headline':
		case 'title':
		case 'subtitle':
		case 'intro':
		case 'body':
		case 'crosshead':
		case 'byline':
		case 'blockquote':
		case 'text1':
		case 'text2':
		case 'text3':
		case 'text4':
		case 'text5':
		case 'text6':
		case 'text7':
		case 'text8':
		case 'text9':
		case 'text10':
		case 'text11':
		case 'text12':
		case 'text13':
		case 'text14':
		case 'text15':
		case 'text16':
		case 'text17':
		case 'text18':
		case 'text19':
		case 'text20':
		case 'footer':
			return <StyledText key={i} {...component} />;
		case 'image':
			return <StyledImage key={i} {...component} />;
		case 'advert':
			return <StyledAdvert key={i} {...component} />;
		case 'infogram':
			return <StyledInfogram key={i} {...component} />;
		case 'map':
			return null;
		// return <StyledMap key={i}  {...component}/>;
		case 'spacer':
			return <StyledSpacer key={i} {...component} />;
		case 'button':
			return <StyledButton key={i} {...component} />;
		case 'anchor':
			return <Anchor key={i} {...component} />;
		case 'video':
			return <StyledVideo key={i} {...component} />;
		case 'audio':
			return <StyledAudio key={i} {...component} />;
		case 'twitter':
			return <StyledTwitterTimeline key={i} {...component} />;
		case 'divider':
			return <StyledDivider key={i} {...component} />;
		case 'columns':
			return <StyledColumns key={i} {...component} />;
		case 'gallery':
			if (component['control-speed']) {
				component.control_speed = component['control-speed'];
			}
			return <StyledGallery key={i} {...component} />;
		case 'custom':
			return <StyledCustom key={i} {...component} />;
		default:
			return null;
	}
};
