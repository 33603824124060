import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styles from './lightbox.module.css';
import { ArticleContext } from '../Context';

export function Image({
	id,
	imageurl,
	caption,
	credit,
	captionenabled,
	creditenabled,
}) {
	const { lang } = useContext(ArticleContext);
	if (typeof caption === 'object') {
		caption = caption[lang] ? caption[lang] : '';
	}
	if (typeof credit === 'object') {
		credit = credit[lang] ? credit[lang] : '';
	}
	return (
		<figure className={styles['image']}>
			<img id={`image-lightbox-${id}`} src={imageurl} />
			{caption && captionenabled === 'on' ? (
				<figcaption dangerouslySetInnerHTML={{ __html: caption }} />
			) : null}
			{credit && creditenabled === 'on' ? (
				<small dangerouslySetInnerHTML={{ __html: credit }} />
			) : null}
		</figure>
	);
}

Image.propTypes = {
	id: PropTypes.string,
	imageurl: PropTypes.string,
	caption: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	captionenabled: PropTypes.string,
	credit: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	creditenabled: PropTypes.string,
};
