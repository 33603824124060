export const MAX_TEXT_COMPONENTS = 60;

export const textComponents = new Set([
	'headline',
	'title',
	'subtitle',
	'intro',
	'body',
	'crosshead',
	'byline',
	'blockquote',
	'footer',
	'paragraph',
]);

for (let i = 0; i < MAX_TEXT_COMPONENTS; i++) {
	textComponents.add(`text${i + 1}`);
}

export function isTextComponent(type) {
	return textComponents.has(type);
}
