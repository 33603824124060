import { useEffect } from 'react';

/**
 * React hook that triggers pulse animation in replica mode
 *
 * @param {('single' | 'double')} type - Type of replica mode
 * @param {number} index - Index of the page
 * @param {Map<String, Object>} mapping - Maping of the articles to the pages
 *
 */
export function useHotspotAnimation(type, index, mapping) {
	useEffect(() => {
		if (index === null) {
			return null;
		}
		const querySelector =
			type === 'single'
				? `#page-${index} svg rect`
				: `[data-index="${index}"] svg rect`;
		triggerHotspotAnimation(querySelector);
	}, [index, mapping]);
}

function triggerHotspotAnimation(querySelector) {
	setTimeout(() => {
		const elements = document.querySelectorAll(querySelector);
		for (const element of elements) {
			element.removeAttribute('class');
			setTimeout(() => {
				element.setAttribute('class', 'pulse-animation');
			}, 100);
		}
	}, 100);
}
