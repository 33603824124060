import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import styles from './Navbar.module.css';
import { Link } from 'react-router-dom';

import imgLogo from './logo.png';

import { AppContext } from '../../services/AppContext';
import { SmallSearchBox } from './Search';

function Navbar({
	display,
	isOnline,
	theme,
	articles,
	isIndexOpen,
	onMenuClick,
	onArticleClick,
	lang,
	displayDownload,
	onDownloadClick,
	displayPlayer,
	isPlayerOpen,
	onPlayerClick,
	isIssueDownloaded,
	onLogoClick,
	article,
	onLanguageClick,
	onLanguageCloseClick,
	onLanguageSelected,
	isLanguageOpen,
	isImageMode,
}) {
	const { locale } = useContext(AppContext);
	const { background, color, height } = theme;
	let offlineIcon = isOnline ? null : (
		<div
			className={styles['icon-offline']}
			style={{ background: color, marginLeft: '15px' }}
		/>
	);

	if (display === false) {
		return (
			<nav
				className={`${styles['navbar']} ${styles['not-display']}`}
				style={{ background }}
			/>
		);
	}

	const downloadComponent =
		!!displayDownload && !!isOnline ? (
			<div
				style={{ height: '100%', marginLeft: '15px', display: 'flex' }}
			>
				<button
					title={locale['BUTTON_TITLE_DOWNLOAD']}
					className={`${styles['icon-download']}  ${
						isIssueDownloaded ? styles['selected'] : ''
					}`}
					style={{
						cursor: 'pointer',
						background: color,
					}}
					onClick={onDownloadClick}
				/>
			</div>
		) : null;

	let playerComponent = displayPlayer ? (
		<div style={{ height: '100%', marginLeft: '15px', display: 'flex' }}>
			<button
				title={locale['BUTTON_TITLE_PLAYER']}
				className={`${styles['icon-player']}  ${
					isPlayerOpen ? styles['selected'] : ''
				}`}
				style={{ cursor: 'pointer', background: color }}
				onClick={onPlayerClick}
			/>
		</div>
	) : null;

	let menuComponent =
		!!articles && !!articles.length ? (
			<div
				style={{ height: '100%', marginLeft: '15px', display: 'flex' }}
			>
				<button
					title={locale['BUTTON_TITLE_TOC']}
					className={`${styles['icon-menu']} ${
						isIndexOpen ? styles['selected'] : ''
					}`}
					style={{
						cursor: 'pointer',
						background: color,
						display: isImageMode ? 'initial' : undefined,
					}}
					onClick={onMenuClick}
				/>
			</div>
		) : null;

	let languageComponent = null;

	if (
		onLanguageSelected &&
		onLanguageClick &&
		onLanguageCloseClick &&
		article &&
		article.languages &&
		article.languages.length > 1
	) {
		languageComponent = (
			<div
				style={{
					height: '100%',
					marginLeft: '15px',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<button
					className={styles['icon-language']}
					style={{ cursor: 'pointer', background: color }}
					onClick={onLanguageClick}
				/>
			</div>
		);
	}

	const listStyleType = theme?.toc?.listStyle || 'none';

	return (
		<nav
			className={`${styles['navbar']} ${
				isIndexOpen ? styles['open'] : ''
			}`}
			style={{ background, maxHeight: height }}
		>
			<div>
				<div className={styles['navbar-logo']}>
					{onLogoClick ? (
						<div>
							<img src={imgLogo} style={{ height }} alt="logo" />
						</div>
					) : (
						<Link to={`/`}>
							<img src={imgLogo} style={{ height }} alt="logo" />
						</Link>
					)}
				</div>
				<div className={styles['navbar-right']}>
					{playerComponent}
					{offlineIcon}
					{downloadComponent}
					{menuComponent}
				</div>
			</div>
			{languageComponent &&
			isLanguageOpen &&
			article &&
			article.languages &&
			article.languages.length > 1 ? (
				<LanguageComponent
					color={color}
					locale={locale}
					lang={lang}
					onClose={onLanguageCloseClick}
					article={article}
					onSelected={onLanguageSelected}
				/>
			) : null}

			<ol
				className={`${styles['toc']} ${
					isIndexOpen ? styles['open'] : ''
				}`}
				style={{ color, listStyleType }}
			>
				{!!articles && !!articles.length
					? articles
							.filter((art) => {
								return art?.channel?.toc !== 'excluded';
							})
							.map((art, i) => {
								const title = art.name[lang];
								art.index = i;
								return (
									<TocItem
										key={i}
										article={article}
										art={art}
										isIndexOpen={isIndexOpen}
										color={color}
										title={title}
										theme={theme?.toc}
										onArticleClick={onArticleClick}
									/>
								);
							})
					: null}
			</ol>
		</nav>
	);
}

Navbar.propTypes = {
	backTo: PropTypes.string,
	display: PropTypes.bool,
	isOnline: PropTypes.bool,
	theme: PropTypes.object,
	articles: PropTypes.array,
	isIndexOpen: PropTypes.bool,
	onMenuClick: PropTypes.func,
	onArticleClick: PropTypes.func,
	lang: PropTypes.any,
	displayDownload: PropTypes.bool,
	onDownloadClick: PropTypes.func,
	displayPlayer: PropTypes.bool,
	isPlayerOpen: PropTypes.bool,
	onPlayerClick: PropTypes.func,
	isIssueDownloaded: PropTypes.bool,
	onLogoClick: PropTypes.func,
	settingsOptions: PropTypes.object,
	onShareClick: PropTypes.func,
	article: PropTypes.object,
	onLanguageClick: PropTypes.func,
	onLanguageCloseClick: PropTypes.func,
	onLanguageSelected: PropTypes.func,
	isLanguageOpen: PropTypes.bool,
	isImageMode: PropTypes.bool,
};

function TocItem({
	article,
	isIndexOpen,
	art,
	color,
	title,
	onArticleClick,
	theme,
}) {
	let isExcluded = art?.channel?.toc === 'excluded';
	const style = {
		display: isIndexOpen ? undefined : 'hidden',
		textAlign: 'center',
		pointerEvents: isIndexOpen ? 'auto' : 'none',
		height: isExcluded ? 0 : isIndexOpen ? 56 : 0,
		maxHeight: isExcluded ? 0 : isIndexOpen ? 56 : 0,
		overflow: 'hidden',
		opacity: isExcluded ? 0 : isIndexOpen ? 1 : 0,
		color: art.id === article.id ? color : 'rgba(0,0,0,.3)',
	};
	if (theme) {
		if (theme.color && theme.selectedColor) {
			style.color =
				art.id === article.id ? theme.selectedColor : theme.color;
		}

		let textTransform = 'none';
		if (theme.textTransform) {
			switch (theme.textTransform) {
				case 'capitalize':
				case 'uppercase':
				case 'lowercase':
					textTransform = theme.textTransform;
					break;
				default:
					textTransform = 'none';
			}
			style.textTransform = textTransform;
		}
	}

	return (
		<li
			onClick={() => {
				onArticleClick(art);
			}}
			style={style}
		>
			{title}
		</li>
	);
}

TocItem.propTypes = {
	article: PropTypes.object,
	art: PropTypes.object,
	isIndexOpen: PropTypes.bool,
	color: PropTypes.string,
	title: PropTypes.string,
	onArticleClick: PropTypes.func,
	theme: PropTypes.object,
};

function ReaderModeOption({ options, color }) {
	const { locale } = useContext(AppContext);
	const { display, selected, onClick } = options;

	if (display === false) {
		return null;
	}

	return (
		<div>
			<button
				title={locale['BUTTON_TITLE_READER_MODE']}
				onClick={onClick}
				style={{ background: color }}
				className={`${styles['reader-mode-button']} ${
					selected ? styles['selected'] : ''
				}`}
			/>
		</div>
	);
}

ReaderModeOption.propTypes = {
	options: PropTypes.object.isRequired,
	color: PropTypes.string,
};

function ImageModeOption({ options, color }) {
	const { locale } = useContext(AppContext);
	const { display, selected, onClick } = options;

	if (display === false) {
		return null;
	}

	return (
		<div>
			<button
				title={locale['BUTTON_TITLE_IMAGE_MODE']}
				onClick={onClick}
				style={{ background: color }}
				className={`${styles['image-mode-button']} ${
					selected ? styles['selected'] : ''
				}`}
			/>
		</div>
	);
}

ImageModeOption.propTypes = {
	options: PropTypes.object.isRequired,
	color: PropTypes.string,
};

function BookmarkOption({ options, color }) {
	const { locale, config } = useContext(AppContext);
	const { display, selected, onClick } = options;

	if (display === false) {
		return null;
	}

	if (!config?.bookmarks?.enabled) {
		return null;
	}

	return (
		<div>
			<button
				title={locale['BUTTON_TITLE_BOOKMARK']}
				onClick={onClick}
				style={{ background: color }}
				className={`${styles['bookmark-button']} ${
					selected ? styles['selected'] : ''
				}`}
			/>
		</div>
	);
}

BookmarkOption.propTypes = {
	options: PropTypes.object.isRequired,
	color: PropTypes.string,
};

function FontSizeOption({ options, color }) {
	const { locale } = useContext(AppContext);
	const { display, selected, onClick } = options;

	if (display === false) {
		return null;
	}

	return (
		<div>
			<button
				title={locale['BUTTON_TITLE_FONT_SIZE']}
				onClick={onClick}
				style={{ background: color }}
				className={`${styles['font-size-button']} ${
					selected ? styles['selected'] : ''
				}`}
			/>
		</div>
	);
}

FontSizeOption.propTypes = {
	options: PropTypes.object.isRequired,
	color: PropTypes.string,
};

export function SearchNavbar({ onLogoClick, isOnline, theme }) {
	const { config } = useContext(AppContext);
	const { background, color, height } = theme;

	let offlineIcon = isOnline ? null : (
		<div
			className={styles['icon-offline']}
			style={{ background: color, marginLeft: '15px' }}
		/>
	);

	return (
		<nav
			className={`${styles['navbar']}`}
			style={{ background, height }}
		>
			<div>
				<div className={styles['navbar-logo']}>
					{onLogoClick ? (
						<div onClick={onLogoClick}>
							<img src={imgLogo} style={{ height }} alt="logo" />
						</div>
					) : (
						<Link to={`/`}>
							<img src={imgLogo} style={{ height }} alt="logo" />
						</Link>
					)}
				</div>
				<div className={styles['navbar-right']}>
					{offlineIcon}
					{config?.search?.enabled ? <SmallSearchBox /> : null}
				</div>
			</div>
		</nav>
	);
}

SearchNavbar.propTypes = {
	onLogoClick: PropTypes.func,
	isOnline: PropTypes.bool.isRequired,
	theme: PropTypes.object.isRequired,
};

function LanguageComponent({ onClose, locale, article, onSelected, lang }) {
	const systemLang = localStorage.getItem('language')
		? localStorage.getItem('language')
		: 'en';
	return (
		<div className={styles['language-container']}>
			<div className={styles['language-modal']}>
				<div className={styles['header']}>
					<div></div>
					<div className={styles['title']}>
						{locale['SETTINGS_LANGUAGE']}
					</div>
					<div className={styles['close']}>
						<button
							className={styles['icon-close']}
							style={{ cursor: 'pointer', background: '#000' }}
							onClick={onClose}
						/>
					</div>
				</div>
				<div className={styles['content']}>
					<ul>
						{article.languages.map((language, i) => {
							return (
								<li
									key={i}
									onClick={() => {
										onSelected(language);
									}}
								>
									<div>{mapLang(systemLang, language)}</div>
									<div>
										{language === lang ? (
											<button
												className={styles['icon-check']}
												style={{
													background: '#000',
												}}
											/>
										) : null}
									</div>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</div>
	);
}

LanguageComponent.propTypes = {
	color: PropTypes.string,
	lang: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
	onSelected: PropTypes.func.isRequired,
	article: PropTypes.object.isRequired,
	locale: PropTypes.object.isRequired,
};

function mapLang(selectedLanguage, lang) {
	if (selectedLanguage === '') {
		switch (lang) {
			case 'en':
				return 'English';
			case 'de':
				return 'German';
			case 'fr':
				return 'French';
			case 'es':
				return 'Spanish';
			case 'it':
				return 'Italian';
			case 'ja':
				return 'Japanese';
			case 'nl':
				return 'Dutch';
			case 'pl':
				return 'Polish';
			case 'pt-pt':
				return 'Portuguese';
			case 'pt-br':
				return 'Portuguese (Brazilian)';
			case 'ru':
				return 'Russian';
			case 'zh':
				return 'Chinese';
		}
	}

	if (selectedLanguage === 'en') {
		switch (lang) {
			case 'en':
				return 'English';
			case 'de':
				return 'German';
			case 'fr':
				return 'French';
			case 'es':
				return 'Spanish';
			case 'it':
				return 'Italian';
			case 'ja':
				return 'Japanese';
			case 'nl':
				return 'Dutch';
			case 'pl':
				return 'Polish';
			case 'pt-pt':
				return 'Portuguese';
			case 'pt-br':
				return 'Portuguese (Brazilian)';
			case 'ru':
				return 'Russian';
			case 'zh':
				return 'Chinese';
		}
	}
	if (selectedLanguage === 'de') {
		switch (lang) {
			case 'en':
				return 'Englisch';
			case 'de':
				return 'Deutsch';
			case 'fr':
				return 'Französisch';
			case 'es':
				return 'Spanisch';
			case 'it':
				return 'Italienisch';
			case 'ja':
				return 'Japanisch';
			case 'nl':
				return 'Niederländisch';
			case 'pl':
				return 'Polnisch';
			case 'pt-pt':
				return 'Portugiesisch';
			case 'pt-br':
				return 'Portugiesisch (brasilianisch)';
			case 'ru':
				return 'Russisch';
			case 'zh':
				return 'Chinesisch';
		}
	}
	if (selectedLanguage === 'fr') {
		switch (lang) {
			case 'en':
				return 'Anglais';
			case 'de':
				return 'Allemand';
			case 'fr':
				return 'Français';
			case 'es':
				return 'Espagnol';
			case 'it':
				return 'Italien';
			case 'ja':
				return 'Japonais';
			case 'nl':
				return 'Néerlandais';
			case 'pl':
				return 'Polonais';
			case 'pt-pt':
				return 'Portugais';
			case 'pt-br':
				return 'Portugais (brésilien)';
			case 'ru':
				return 'Russe';
			case 'zh':
				return 'Chinois';
		}
	}
	if (selectedLanguage === 'es') {
		switch (lang) {
			case 'en':
				return 'Ingles';
			case 'de':
				return 'Alemán';
			case 'fr':
				return 'Francés';
			case 'es':
				return 'Español';
			case 'it':
				return 'Italiano';
			case 'ja':
				return 'Japonés';
			case 'nl':
				return 'Holandés';
			case 'pl':
				return 'Polaco';
			case 'pt-pt':
				return 'Portugués';
			case 'pt-br':
				return 'Portugués (brasileño)';
			case 'ru':
				return 'Ruso';
			case 'zh':
				return 'Chino';
		}
	}
	if (selectedLanguage === 'it') {
		switch (lang) {
			case 'en':
				return 'Inglese';
			case 'de':
				return 'Tedesco';
			case 'fr':
				return 'Francese';
			case 'es':
				return 'Spagnolo';
			case 'it':
				return 'Italiano';
			case 'ja':
				return 'Giapponese';
			case 'nl':
				return 'Olandese';
			case 'pl':
				return 'Polacco';
			case 'pt-pt':
				return 'Portoghese';
			case 'pt-br':
				return 'Portoghese (brasiliano)';
			case 'ru':
				return 'Russo';
			case 'zh':
				return 'Cinese';
		}
	}
	if (selectedLanguage === 'ja') {
		switch (lang) {
			case 'en':
				return '英語';
			case 'de':
				return 'ドイツ語';
			case 'fr':
				return 'フレンチ';
			case 'es':
				return 'スペイン語';
			case 'it':
				return 'イタリア語';
			case 'ja':
				return 'にほんご';
			case 'nl':
				return '蘭語';
			case 'pl':
				return 'ポーランド語';
			case 'pt-pt':
				return 'ポルトガル語';
			case 'pt-br':
				return 'ポルトガル語（ブラジル語';
			case 'ru':
				return 'ロシア語';
			case 'zh':
				return 'ちゅうごく';
		}
	}
	if (selectedLanguage === 'nl') {
		switch (lang) {
			case 'en':
				return 'Engels';
			case 'de':
				return 'Duits';
			case 'fr':
				return 'Frans';
			case 'es':
				return 'Spaans';
			case 'it':
				return 'Italiaans';
			case 'ja':
				return 'Japans';
			case 'nl':
				return 'Nederlands';
			case 'pl':
				return 'Pools';
			case 'pt-pt':
				return 'Portugees';
			case 'pt-br':
				return 'Portugees (Braziliaans)';
			case 'ru':
				return 'Russisch';
			case 'zh':
				return 'Chinees';
		}
	}
	if (selectedLanguage === 'pl') {
		switch (lang) {
			case 'en':
				return 'Angielski';
			case 'de':
				return 'Niemiecki';
			case 'fr':
				return 'Francuski';
			case 'es':
				return 'Hiszpański';
			case 'it':
				return 'Włoski';
			case 'ja':
				return 'Japończyk';
			case 'nl':
				return 'Holenderski';
			case 'pl':
				return 'Polski';
			case 'pt-pt':
				return 'Portugalski';
			case 'pt-br':
				return 'Portugalczyk (brazylijski)';
			case 'ru':
				return 'Rosyjski';
			case 'zh':
				return 'Chińczyk';
		}
	}
	if (selectedLanguage === 'pt-pt') {
		switch (lang) {
			case 'en':
				return 'Inglês';
			case 'de':
				return 'Alemão';
			case 'fr':
				return 'Francês';
			case 'es':
				return 'Espanhol';
			case 'it':
				return 'Italiano';
			case 'ja':
				return 'Japonês';
			case 'nl':
				return 'Holandês';
			case 'pl':
				return 'Polaco';
			case 'pt-pt':
				return 'Português';
			case 'pt-br':
				return 'Português (Brasileiro)';
			case 'ru':
				return 'Russo';
			case 'zh':
				return 'Chinês';
		}
	}
	if (selectedLanguage === 'pt-br') {
		switch (lang) {
			case 'en':
				return 'Inglês';
			case 'de':
				return 'Alemão';
			case 'fr':
				return 'Francês';
			case 'es':
				return 'Espanhol';
			case 'it':
				return 'Italiano';
			case 'ja':
				return 'Japonês';
			case 'nl':
				return 'Holandês';
			case 'pl':
				return 'Polonês';
			case 'pt-pt':
				return 'Português';
			case 'pt-br':
				return 'Português (brasileiro)';
			case 'ru':
				return 'Russo';
			case 'zh':
				return 'Chinês';
		}
	}
	if (selectedLanguage === 'ru') {
		switch (lang) {
			case 'en':
				return 'английский язык';
			case 'de':
				return 'немецкий язык';
			case 'fr':
				return 'французский';
			case 'es':
				return 'испанский';
			case 'it':
				return 'итальянский';
			case 'ja':
				return 'Японский';
			case 'nl':
				return 'голландский';
			case 'pl':
				return 'польский';
			case 'pt-pt':
				return 'Португальский';
			case 'pt-br':
				return 'португальский (бразильский)';
			case 'ru':
				return 'русский';
			case 'zh':
				return 'китайский язык';
		}
	}
	if (selectedLanguage === 'zh') {
		switch (lang) {
			case 'en':
				return '英文';
			case 'de':
				return '德国';
			case 'fr':
				return '法国人';
			case 'es':
				return '西班牙语';
			case 'it':
				return '意大利';
			case 'ja':
				return '日文';
			case 'nl':
				return '荷兰语';
			case 'pl':
				return '波兰语';
			case 'pt-pt':
				return '葡萄牙语';
			case 'pt-br':
				return '葡萄牙语（巴西)';
			case 'ru':
				return '俄文';
			case 'zh':
				return '中文';
		}
	}
	return lang;
}

export default Navbar;
