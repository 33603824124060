import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import styles from './Purchases.module.css';

import { AppContext } from '../services/AppContext';

import Navbar from '../components/navbar/Navbar';
import Sidebar from '../components/sidebar/Sidebar';
import BottomBar from '../components/bottombar/BottomBar';
import EmptyState from '../components/empty/EmptyState';
import { Loading } from '../components/loading/Loading';

export default class Purchases extends React.Component {
	static contextType = AppContext;

	state = {
		purchases: [],
		isLoading: true,
	};

	componentDidMount() {
		const { location } = this.props;
		localStorage.setItem('last-path', location.pathname);

		const { api, isOnline } = this.context;
		if (isOnline) {
			api.getPurchases()
				.then((purchases) => {
					purchases = uniqueBy(purchases, 'id').sort((a, b) => {
						let dateA = new Date(a.date),
							dateB = new Date(b.date);
						return dateB - dateA;
					});
					this.setState({ purchases, isLoading: false });
				})
				.catch((e) => {
					console.error(e);
					alert(e.message);
				});
		}
	}

	render() {
		const { isOnline } = this.context;
		const { isLoading, purchases } = this.state;

		if (!isOnline) {
			return <Offline />;
		}

		if (isLoading) {
			return <LoadingComponent />;
		}

		if (!purchases.length) {
			return <Empty />;
		}

		return <PurchaseList purchases={purchases} />;
	}
}

Purchases.propTypes = {
	location: PropTypes.object.isRequired,
};

function uniqueBy(inputs, property) {
	const response = [];
	const mapResponse = {};
	for (let input of inputs) {
		mapResponse[`${input[property]}`] = input;
	}

	for (let input in mapResponse) {
		response.push(mapResponse[input]);
	}

	return response;
}

function Offline() {
	const { isOnline, theme, locale } = useContext(AppContext);
	return (
		<div className="App">
			<Navbar isOnline={isOnline} theme={theme.navbar} />
			<main>
				<section className={styles['purchases']}>
					<Sidebar theme={theme.bottomBar} section="purchases" />
					<p>{locale['PURCHASES_OFFLINE_ERROR']}</p>
				</section>
			</main>
			<BottomBar
				section="purchases"
				isVisible={true}
				theme={theme.bottomBar}
			/>
		</div>
	);
}

function LoadingComponent() {
	const { isOnline, theme, config } = useContext(AppContext);
	return (
		<div className="App">
			<Navbar isOnline={isOnline} theme={theme.navbar} />
			<main>
				<section className={styles['purchases']}>
					<Sidebar theme={theme.bottomBar} section="purchases" />
					<Loading theme={config?.theme?.loading} />
				</section>
			</main>
			<BottomBar
				section="purchases"
				isVisible={true}
				theme={theme.bottomBar}
			/>
		</div>
	);
}

function Empty() {
	const { isOnline, theme } = useContext(AppContext);
	return (
		<div className="App">
			<Navbar isOnline={isOnline} theme={theme.navbar} />
			<main>
				<section className={styles['purchases']}>
					<Sidebar theme={theme.bottomBar} section="purchases" />
					<EmptyState section={'purchases'} />
				</section>
			</main>
			<BottomBar
				section="purchase"
				isVisible={true}
				theme={theme.bottomBar}
			/>
		</div>
	);
}

function PurchaseList({ purchases }) {
	const { isOnline, theme } = useContext(AppContext);

	return (
		<div className="App">
			<Navbar isOnline={isOnline} theme={theme.navbar} />
			<main>
				<section className={styles['purchases']}>
					<Sidebar theme={theme.bottomBar} section="purchases" />
					<div className={styles['content']}>
						<div className={styles['card-list-container']}>
							{purchases.map((purchase, i) => {
								return <Purchase key={i} purchase={purchase} />;
							})}
						</div>
					</div>
				</section>
			</main>
			<BottomBar
				section="purchases"
				isVisible={true}
				theme={theme.bottomBar}
			/>
		</div>
	);
}

PurchaseList.propTypes = {
	purchases: PropTypes.array.isRequired,
};

function Purchase({ purchase }) {
	const { locale } = useContext(AppContext);
	const { name, date, price, type, currency } = purchase;

	let amountComponent = (
		<div className={styles['description']}>
			{locale['PURCHASES_PRICE']}:{' '}
			{price ? `${currencySymbol(currency)}${price}` : locale['FREE']}
		</div>
	);

	let dateComponent = null;
	if (date) {
		dateComponent = (
			<div className={styles['description']}>
				{new Date(date).toDateString()}
			</div>
		);
	}

	return (
		<div className={styles['card-item']}>
			<div className={styles['title']}>
				{type === 'issue'
					? locale['PURCHASES_TYPE_ISSUE']
					: locale['PURCHASES_TYPE_SUBSCRIPTION']}
				: {name}
			</div>
			{amountComponent}
			{dateComponent}
		</div>
	);
}

Purchase.propTypes = {
	purchase: PropTypes.object.isRequired,
};

function currencySymbol(currency) {
	currency = currency.toUpperCase();
	switch (currency) {
		case 'EUR':
			return '€';
		case 'USD':
			return '$';
		case 'GBP':
			return '£';
		default:
			return currency;
	}
}
