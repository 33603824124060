export default {
	FREE: 'Bezpłatnie',
	CONTINUE: 'Kontynuuj',
	OK: 'Ok',
	SUBSCRIBE: 'Subskrybuj',
	EMPTY_BOOKMARK_TITLE: 'Nie masz zapisanych zakładek',
	EMPTY_PUBLICATION_TITLE: 'Niniejsza publikacja jest pusta',
	EMPTY_PURCHASES_TITLE: 'Nie masz żadnych zakupów',
	EMPTY_ARTICLES_TITLE: 'Ta sprawa jest pusta.',
	EMPTY_BOOKMARK_CONTENT: `Funkcja zakładki umożliwia zapisywanie i szybki dostęp do
    ulubione artykuły. Wystarczy dotknąć ikonę zakładki na dowolnym
    artykuł, aby go zapisać, a następnie wyświetlić za pomocą menu zakładki.`,
	EMPTY_PUBLICATION_CONTENT: `Nie ma żadnych problemów w tej publikacji, proszę wkrótce ponownie sprawdzić.`,
	EMPTY_PURCHASES_CONTENT: `Nie zakupiłeś żadnej treści.  Po dokonaniu jednej lub więcej płatności możesz obejrzeć 
    szczegóły transakcji tutaj.`,
	EMPTY_ARTICLES_CONTENT:
		'Nie ma żadnych artykułów w tej sprawie, proszę wkrótce ponownie sprawdzić.',
	SIGN_UP_WITH_EMAIL: 'Zarejestruj się za pomocą poczty elektronicznej',
	SIGN_UP_WITH_EMAIL_BACK_TITLE: 'Wszystkie opcje zapisu',
	SIGN_UP_WITH_EMAIL_CHECK_INBOX: 'Sprawdź swoją skrzynkę odbiorczą',
	SIGN_IN_WITH_EMAIL: 'Zaloguj się za pomocą poczty elektronicznej',
	SIGN_IN_WITH_EMAIL_BACK_TITLE: 'Wszystkie opcje logowania się',
	SIGN_IN_WITH_EMAIL_CHECK_INBOX: 'Sprawdź swoją skrzynkę odbiorczą',
	SIGN_UP_HEADER: 'Przyłącz się do nas',
	SIGN_UP_SUBHEADER: `Załóż konto, aby otrzymywać wspaniałe historie w swoim
    Skrzynka odbiorcza, spersonalizuj swoją stronę główną i śledź autorów oraz
    tematy, które kochasz.`,
	SIGN_IN_HEADER: 'Witamy z powrotem',
	SIGN_IN_SUBHEADER: `Zaloguj się, aby uzyskać spersonalizowane rekomendacje dotyczące historii,
    podążać za autorami i tematami, które kochasz, i wchodzić w interakcje z
    Historie.`,
	SIGN_UP_WITH_GOOGLE: 'Zarejestruj się w Google',
	SIGN_UP_WITH_APPLE: 'Zarejestruj się w Apple',
	SIGN_IN_WITH_GOOGLE: 'Zaloguj się w Google',
	SIGN_IN_WITH_APPLE: 'Zaloguj się z Apple',
	AUTHENTICATE_SIGN_IN: 'Zaloguj się',
	AUTHENTICATE_YOUR_EMAIL: 'Twój Email',
	AUTHENTICATE_OFFLINE_ERROR: 'Musisz być online, aby kontynuować',
	AUTHENTICATE_SIGN_UP_MESSAGE: 'Masz już konto?',
	AUTHENTICATE_SIGN_UP: 'Zarejestruj się',
	AUTHENTICATE_SIGN_IN_MESSAGE: 'Nie masz konta?',
	AUTHENTICATE_SIGN_IN_CREATE_ONE: 'Create One',
	AUTHENTICATE_EMAIL_NOT_EMPTY_ERROR: `Stwórz jeden`,
	AUTHENTICATE_EMAIL_INVALID: `Nieważny adres e-mail`,
	AUTHENTICATE_EMAIL_DIGIT_MESSAGE:
		'Właśnie wysłaliśmy sześciocyfrowy kod do',
	AUTHENTICATE_EMAIL_DIGIT_MESSAGE_SUBMIT:
		'Właśnie wysłaliśmy sześciocyfrowy kod do',
	SETTINGS_SIGN_IN: 'Zaloguj się',
	SETTINGS_SIGN_OUT: 'Wyloguj się',
	SETTINGS_SUBSCRIPTION: 'Subskrypcja',
	SETTINGS_SUBSCRIPTION_INACTIVE_ERROR: 'Nie masz aktywnej subskrypcji',
	SETTINGS_SUBSCRIPTION_EXPIRE_AT: 'Twoja subskrypcja kończy się w dniu',
	SETTINGS_STORAGE: 'Przechowywanie',
	SETTINGS_LANGUAGE: 'Język',
	SETTINGS_FREE_SPACE: 'Wolna przestrzeń',
	SETTINGS_CLEAR_CACHE: 'Czysta pamięć podręczna',
	SETTINGS_ASSETS_REMOVED_INTRO_MESSAGE: 'Aktywa usunięte',
	SETTINGS_CLEAR_CACHE_IMAGES_MESSAGE: 'Zdjęcia',
	SETTINGS_CLEAR_CACHE_ISSUES_MESSAGE: 'Zagadnienia',
	SETTINGS_CLEAR_CACHE_ARTICLES_MESSAGE: 'Artykuły',
	SETTINGS_CLEAR_CACHE_FAVORITE_ARTICLES_IMAGES_MESSAGE: 'Ulubione artykuły',
	SETTINGS_FREE_SPACE_IMAGES: 'obrazy',
	PURCHASE_SUBSCRIPTION_HEADER: 'Subskrypcja',
	PURCHASE_SUBSCRIPTION_SUBHEADER: 'Posiadacz wiadomości o subskrypcji',
	PURCHASE_OFFLINE_ERROR:
		'Nie można kupić w trybie offline, spróbuj ponownie później.',
	PURCHASES_OFFLINE_ERROR: 'Musisz być online, aby zobaczyć zakupy',
	PURCHASES_PRICE: 'Cena',
	PURCHASES_TYPE_ISSUE: 'Wydanie',
	PURCHASES_TYPE_SUBSCRIPTION: 'Subskrypcja',
};
