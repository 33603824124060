import { useState, useEffect, useMemo } from 'react';
/**
 * React hook that returns if the element is being displayed
 *
 * @param ref - Reference of the element
 * @return if the element is in viewport
 *
 */
export function useIsInViewport(ref, hasAnimation) {
	const [isIntersecting, setIsIntersecting] = useState(false);

	const observer = useMemo(
		() =>
			hasAnimation
				? new IntersectionObserver(([entry]) => {
						setIsIntersecting(entry.isIntersecting);
				  })
				: null,
		[hasAnimation, ref]
	);

	useEffect(() => {
		if (observer) {
			if (ref?.current instanceof Element) {
				observer.observe(ref.current);
			}
		}
		return () => {
			if (observer) {
				observer.disconnect();
			}
		};
	}, [ref, observer, hasAnimation]);

	return isIntersecting;
}
