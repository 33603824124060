/* eslint-disable no-async-promise-executor */
import React, { Component } from 'react';
import 'animate.css';
import { Workbox } from 'workbox-window';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ReactGA from 'react-ga';

import Styles from './components/canvasflow/article/Styles';

import { AppContext } from './services/AppContext';
import Api from './services/Api';
import Db from './services/Db';
import { getLocale } from './services/i18n';
import View from './views/views';
import ApolloClient from 'apollo-boost';
import './App.css';

import config from './config';
import { Tracking } from './services/Tracking';

import { Installation } from './components/installation/Installation';

class App extends Component {
	state = {
		db: null,
		api: null,
		client: null,
		width: null,
		isStandalone: window.navigator.standalone,
		isUserAdmin:
			localStorage.getItem('app-user-role') === 'admin' &&
			!!localStorage.getItem('token'),
		isOnline: null,
		version: localStorage.getItem('version'),
		lang: localStorage.getItem('language')
			? localStorage.getItem('language')
			: config.defaultLanguage,
		locale: {},
		showOnlineStatusBar: false,
		hasAccess: false,
		isTouchDevice: window.matchMedia('(pointer: coarse)').matches,
		properties: null,
		wb: null,
		isPrinting: false,
	};

	componentDidMount() {
		const swUrl = config?.assetDirectory
			? `${config.assetDirectory}sw.js`
			: 'sw.js';
		console.log(`Service worker URL: ${swUrl}`);
		if ('serviceWorker' in navigator) {
			try {
				const wb = new Workbox(swUrl);
				console.debug(`Registering service worker`);
				wb.addEventListener('message', this.onServiceWorkerEvent);

				wb.register();
				this.setState({ wb });
			} catch (e) {
				console.error(e);
			}
		}

		window.addEventListener('beforeprint', this.onBeforePrint);
		window.addEventListener('afterprint', this.onAfterPrint);
		window
			.matchMedia('(orientation: portrait)')
			.addEventListener('change', this.onRotate);

		this.onLoad()
			.then(() => {
				console.log(`Application loaded successfully`);
			})
			.catch((err) => {
				console.error(`Error loading the application`);
				console.error(err);
			});
	}

	onRotate = () => {
		const viewport = document.querySelector('meta[name=viewport]');
		viewport.setAttribute(
			'content',
			'width=device-width, initial-scale=1.0, maximum-scale=1.0, viewport-fit=cover'
		);
		setTimeout(() => {
			viewport.setAttribute(
				'content',
				'width=device-width, initial-scale=1.0, maximum-scale=5.0, viewport-fit=cover'
			);
		}, 1000);
	};

	componentWillUnmount() {
		window.removeEventListener('beforeprint', this.onBeforePrint);
		window.removeEventListener('afterprint', this.onAfterPrint);
	}

	onBeforePrint = () => {
		this.setState({
			isPrinting: true,
		});
	};

	onAfterPrint = () => {
		this.setState({
			isPrinting: false,
		});
	};

	saveArticles = (articles, IssueId) => {
		const { db } = this.state;
		db.saveArticles(articles, IssueId).then(() => {
			console.log(`Successfully stored the issue: "${IssueId}"`);
		});
	};

	onLoad = async () => {
		const { host, appKey, password, version, lastLocation } = config;

		if (localStorage.getItem('language') === null) {
			localStorage.setItem('language', config.defaultLanguage);
		}

		const lang = localStorage.getItem('language');

		const pathname = window.location.pathname;

		if (lastLocation?.enabled) {
			if (localStorage.getItem('last-path')) {
				const lastPath = localStorage.getItem('last-path');
				if (lastPath !== '/' && pathname === '/') {
					window.history.pushState({}, null, lastPath);
				}
			}
		}

		window.addEventListener('online', this.onConnectionChange);
		window.addEventListener('offline', this.onConnectionChange);
		const onDeviceChange = () => {
			this.onGetDeviceInformation();
		};
		window.addEventListener('orientationchange', onDeviceChange);
		window.addEventListener('resize', onDeviceChange);

		if (!localStorage.getItem('version')) {
			localStorage.setItem('version', version);
		}
		const client = this.getApolloClient({ host, appKey });
		const api = new Api(client, host, appKey);

		if (password && password.enabled && password.value) {
			this.setState({
				hasAccess: localStorage.getItem(`pwa-password-${appKey}`),
			});
		}

		if (!localStorage.getItem('downloaded-issues')) {
			localStorage.setItem('downloaded-issues', '{}');
		}

		let isOnline = await this.isOnline();
		isOnline = !!isOnline;
		const db = await this.installDB();
		console.info(`Installed db successfully`);
		await Promise.all([
			new Promise(async (resolve, reject) => {
				try {
					const fonts = await this.getFonts(api, config, isOnline);
					await this.loadFonts(fonts);
					console.info(`Load fonts succesfully`);
					resolve();
				} catch (e) {
					reject(e);
				}
			}),
			new Promise(async (resolve, reject) => {
				try {
					const properties = await this.getProperties(api);
					console.info(`Get properties successfully`);
					this.setState({ properties });
					resolve();
				} catch (e) {
					reject(e);
				}
			}),
			new Promise(async (resolve, reject) => {
				try {
					const styles = await this.getStyles(api, isOnline, db);
					console.info(`Load styles successfully`);
					this.setState({ styles });
					resolve();
				} catch (e) {
					reject(e);
				}
			}),
		]);

		this.onGetDeviceInformation();

		this.setState({
			isOnline,
			db,
			width: document.documentElement.clientWidth,
			client,
			api,
			version,
			locale: getLocale(lang),
			lang,
		});
	};

	getStyles = async (api, isOnline, db) => {
		if (isOnline) {
			try {
				console.debug(`Fetching styles from remote`);
				await db.saveStyles(await api.getStyles());
				console.debug(`Success storing the styles in the cache`);
			} catch (e) {
				console.error(e);
			}
		}

		try {
			console.debug(`Fetching styles from cache`);
			return new Styles(await db.getStyles()).styles;
		} catch (e) {
			console.error(e);
		}

		return new Map();
	};

	onAdminLogin = () => {
		localStorage.setItem('app-user-role', 'admin');
		this.setState({ isUserAdmin: true });
	};

	onAdminLogout = () => {
		localStorage.removeItem('me');
		localStorage.removeItem('token');
		localStorage.removeItem('expiredAt');
		localStorage.removeItem('app-user-role');
		this.setState({ isUserAdmin: false });
	};

	downloadImagesInBackground = (images, cb) => {
		const { wb } = this.state;
		if (wb) {
			wb.messageSW({
				type: 'DOWNLOAD_IMAGES',
				payload: {
					images,
				},
			})
				.then((data) => {
					if (cb) {
						cb(null, data);
					}
					cb(null, data);
				})
				.catch((err) => {
					console.error(`Error downloading images`);
					console.error(err);
				});
			return;
		}

		cb();
	};

	onConnectionChange = () => {
		const timeout = 1000 * 3;
		if (navigator.onLine) {
			this.isOnline().then((isOnline) => {
				this.setState({
					isOnline: !!isOnline,
					showOnlineStatusBar: true,
				});
				setTimeout(() => {
					this.setState({ showOnlineStatusBar: false });
				}, timeout);
			});
		} else {
			this.setState({ isOnline: false, showOnlineStatusBar: true });
			setTimeout(() => {
				this.setState({ showOnlineStatusBar: false });
			}, timeout);
		}
	};

	onServiceWorkerEvent = (event) => {
		const { type, payload } = event.data;
		switch (type) {
			case 'DOWNLOAD_IMAGES':
				this.onDownloadImages(payload);
				break;
			case 'DOWNLOAD_IMAGE':
				this.onDownloadImage(payload);
				break;
			default:
				return;
		}
	};

	getApolloClient({ host, appKey }) {
		return new ApolloClient({
			uri: `${host}/graphql`,
			request: (operation) => {
				const token = localStorage.getItem('token');
				const role = localStorage.getItem('role')
					? localStorage.getItem('role')
					: undefined;
				operation.setContext({
					headers: {
						authorization: token ? `${token}` : '',
						'app-key': appKey,
						'app-user-role': role,
					},
				});
			},
		});
	}

	getProperties = async (api) => {
		const isOnline = await this.isOnline();

		if (isOnline) {
			const properties = await api.getProperties();
			if (properties['__typename']) {
				delete properties['__typename'];
			}

			localStorage.setItem('properties', JSON.stringify(properties));
			return properties;
		}

		return JSON.parse(localStorage.getItem('properties'));
	};

	getFonts = async (api, config, isOnline) => {
		const fonts = [];
		if (config?.font?.fonts) {
			for (const font of config.font.fonts) {
				fonts.push({
					name: font,
					urls: [`/assets/fonts/${font}.woff2`],
				});
			}

			if (config.font.default) {
				if (!localStorage.getItem('default-font')) {
					localStorage.setItem('default-font', config.font.default);
				}
			}
		}
		if (api && isOnline) {
			const remoteFonts = await api.getFonts();
			localStorage.setItem('fonts', JSON.stringify(remoteFonts));
			return [...fonts, ...remoteFonts];
		}

		const cachedFonts = localStorage.getItem('fonts')
			? JSON.parse(localStorage.getItem('fonts'))
			: [];

		return [...fonts, ...cachedFonts];
	};

	onResize = () => {
		this.onGetDeviceInformation();
	};

	onGetDeviceInformation = () => {
		const width = document.documentElement.clientWidth;
		const height = document.documentElement.clientHeight;

		const orientation = width > height ? 'landscape' : 'portrait';
		const deviceType =
			width <= 767 ? 'mobile' : width <= 1200 ? 'tablet' : 'desktop';

		const isTouchable = window.matchMedia('(pointer: coarse)').matches;

		const device = {
			width,
			height,
			orientation,
			type: deviceType,
			isTouchable,
		};
		this.setState({
			device,
			deviceType,
			width,
			orientation,
		});
	};

	onDownloadImages = ({ IssueID }) => {
		const storageKey = 'downloaded-issues';

		const downloadedIssues = JSON.parse(localStorage.getItem(storageKey));

		downloadedIssues[`${IssueID}`] = true;
		localStorage.setItem(storageKey, JSON.stringify(downloadedIssues));
	};

	onDownloadImage = ({ image, cacheName }) => {
		const img = new Image();
		console.debug(`Downloading image: '${image}'`);
		img.src = image;
		caches
			.open(cacheName)
			.then((cache) => cache.addAll([image]))
			.then(() => {
				console.debug(`Add image to the cache: '${image}'`);
			})
			.catch(console.error);
	};

	isOnline = async () => {
		let { host } = config;
		return new Promise(async (resolve) => {
			try {
				const isOnline = await this.isReachable(host);
				resolve(!!isOnline);
			} catch (e) {
				resolve(false);
			}
		});
	};

	getReadTime = (components, lang) => {
		const words = components
			.filter((component) => {
				switch (component.component) {
					case 'headline':
					case 'title':
					case 'subtitle':
					case 'intro':
					case 'body':
					case 'crosshead':
					case 'byline':
					case 'blockquote':
					case 'text1':
					case 'text2':
					case 'text3':
					case 'text4':
					case 'text5':
					case 'text6':
					case 'text7':
					case 'text8':
					case 'text9':
					case 'text10':
					case 'text11':
					case 'text12':
					case 'text13':
					case 'text14':
					case 'text15':
					case 'text16':
					case 'text17':
					case 'text18':
					case 'text19':
					case 'text20':
					case 'footer':
						return true;
				}
				return false;
			})
			.map((component) => {
				const { text_lang } = component;
				let text = text_lang?.[lang];
				if (text) {
					return text.replace(/(<([^>]+)>)/gi, '').split(' ');
				}
				return [];
			})
			.reduce((acc, words) => {
				acc = [...acc, ...words];
				return acc;
			}, []);

		const result = words.length / 200;
		const minutes = Math.floor(result);
		const seconds = Math.floor((result - minutes) * 60);

		return {
			minutes,
			seconds,
		};
	};

	isReachable = async (url) => {
		const resp = await fetch(url, { method: 'HEAD', mode: 'no-cors' });
		return resp && (resp.ok || resp.type === 'opaque');
	};

	installDB = async () => {
		const db = new Db();
		await db.start();
		return db;
	};

	loadFonts = async (fonts) => {
		try {
			await Promise.all(fonts.map((font) => this.loadFont(font)));
		} catch (e) {
			console.error(e);
		}
	};

	loadFont = async (font) => {
		const { name, urls } = font;
		const promises = [];

		for (let url of urls) {
			if (!/.woff2$/.test(url)) {
				continue;
			}

			promises.push(
				new Promise((resolve) => {
					const fontFace = new FontFace(name, `url(${url})`);
					fontFace
						.load()
						.then((loadedFace) => {
							document.fonts.add(loadedFace);
						})
						.catch(console.error)
						.finally(resolve);
				})
			);
		}

		await Promise.all(promises);
	};

	onLangChange = (lang) => {
		this.setState({
			lang,
			locale: getLocale(lang),
		});
	};

	onPasswordSuccess = () => {
		const { appKey } = config;
		localStorage.setItem(`pwa-password-${appKey}`, true);
		this.setState({
			hasAccess: true,
		});
	};

	onAuthDisabled = () => {
		const { subdirectory } = config;
		const { properties } = this.state;
		if (!properties?.enableAuthorization) {
			console.info(`Authorization is not enabled`);
			const lastPath = `${subdirectory}/`;
			localStorage.setItem('last-path', lastPath);
			window.location.href = `${subdirectory}/`;
		}
	};

	render() {
		const {
			host,
			appKey,
			password,
			stripeKey,
			admin,
			theme,
			version,
			iframe,
			domains,
			auth,
			bookmarks,
			contentProtection,
			subdirectory,
			navArrows,
			languages,
			analytics,
			PublicationID,
		} = config;
		const {
			isOnline,
			db,
			api,
			client,
			isUserAdmin,
			width,
			lang,
			hasAccess,
			locale,
			isTouchDevice,
			properties,
			wb,
			device,
			styles,
			isPrinting,
			orientation,
		} = this.state;

		let tracking = new Tracking();

		if (
			!client ||
			!db ||
			!api ||
			!width ||
			typeof isOnline !== 'boolean' ||
			!properties ||
			!device
		) {
			return null;
		}

		if (!hasAccess && password && password.enabled && password.value) {
			return (
				<View.Lock
					onSuccess={this.onPasswordSuccess}
					password={password}
				/>
			);
		}

		let {
			title,
			autoDownloadImage,
			enableAuthorization,
			enableInstallationOption,
			enableImageZoom,
			fontSizeOption,
			imageMode,
			readerMode,
			issueMode,
			StripeAccountID,
			tts,
		} = properties;

		if (analytics) {
			const providers = {
				ga: null,
				ackee: null,
			};
			if (analytics?.google?.trackerID) {
				let sampleRate = 1;
				if (
					!!analytics.google.sampleRate &&
					typeof analytics.google.sampleRate === 'number'
				) {
					if (
						analytics.google.sampleRate > 0 &&
						analytics.google.sampleRate < 100
					) {
						sampleRate = analytics.google.sampleRate;
					}
				}

				console.debug(`Analytics are supported`);
				console.debug(`Tracker id: '${analytics.google.trackerID}'`);
				console.debug(`Sample rate: ${sampleRate}`);

				ReactGA.initialize(analytics.google.trackerID, {
					debug: false,
					titleCase: false,
					siteSpeedSampleRate: sampleRate,
					alwaysSendToDefaultTracker: true,
				});
				providers.ga = ReactGA;
			}

			if (analytics?.ackee?.domainId) {
				providers.ackee = {
					server: analytics?.ackee?.server,
					domainId: analytics?.ackee?.domainId,
				};
			}

			tracking = new Tracking(providers);
		}

		const deviceType =
			width <= 767 ? 'mobile' : width <= 1200 ? 'tablet' : 'desktop';

		let isIframeMode = window !== window.top;

		const url =
			window.location != window.parent.location
				? document.referrer
				: document.location.href;

		var referrer = url ? new URL(url) : '';

		if (iframe?.enabled === false && isIframeMode) {
			// return null;
		}

		if (iframe?.enabled && iframe?.only && !isIframeMode) {
			// return null;
		}

		let isIFrameDomainValid = true;
		const validDomains = iframe?.domains || [];
		if (validDomains.length && referrer) {
			isIFrameDomainValid = validDomains.includes(referrer.host);
		}

		if (iframe?.enabled && isIframeMode) {
			if (!isIFrameDomainValid) {
				// return null;
			}
		}

		// Invalid domain
		if (domains?.length && !domains.includes(referrer.host)) {
			// return null;
		}

		return (
			<AppContext.Provider
				value={{
					device,
					db,
					isTouchDevice,
					api,
					host,
					theme,
					wb,
					isOnline,
					appKey,
					enableInstallationOption,
					PublicationID,
					width,
					deviceType,
					styles,
					StripeAccountID,
					autoDownloadImage,
					enableImageZoom,
					enableAuthorization,
					auth,
					stripeKey,
					imageMode,
					issueMode,
					locale,
					lang,
					languages,
					readerMode,
					fontSizeOption,
					tts,
					admin,
					isUserAdmin,
					config,
					version,
					iframe,
					tracking,
					isIframeMode,
					isPrinting,
					orientation,
					subdirectory,
					navArrows,
					mapLang,
					onAuthDisabled: this.onAuthDisabled,
					onResize: this.onResize,
					onConnectionChange: this.onConnectionChange,
					onLangChange: this.onLangChange,
					onAdminLogin: this.onAdminLogin,
					onAdminLogout: this.onAdminLogout,
					getReadTime: this.getReadTime,
					saveArticles: this.saveArticles,
				}}
			>
				<Helmet>
					<meta charSet="utf-8" />
					<title>{title}</title>
				</Helmet>
				<div
					data-publication-id={PublicationID}
					style={{
						width: '100%',
						height: '100%',
						fontFamily: localStorage.getItem('default-font')
							? localStorage.getItem('default-font')
							: undefined,
					}}
					className={
						contentProtection?.enabled ? 'content-protection' : ''
					}
				>
					<Router basename={subdirectory ? subdirectory : undefined}>
						<Switch>
							<Route
								exact
								path={`/issues/:id/:articleID`}
								component={View.Issue}
							/>
							<Route
								exact
								path={`/issues/:id`}
								component={View.Issue}
							/>
							{!isUserAdmin ? (
								<Route
									exact
									path={`/purchase/issue/:id`}
									component={View.PurchaseIssue}
								/>
							) : null}
							{!isUserAdmin ? (
								<Route
									exact
									path={`/bookmarks/:id`}
									component={View.Bookmark}
								/>
							) : null}
							{!isUserAdmin ? (
								<Route
									exact
									path={`/purchase/subscription`}
									component={View.PurchaseSubscription}
								/>
							) : null}
							{!isUserAdmin && bookmarks?.enabled ? (
								<Route
									exact
									path={`/bookmarks`}
									component={View.Bookmarks}
								/>
							) : null}
							{!isUserAdmin ? (
								<Route
									exact
									path={`/purchases`}
									component={View.Purchases}
								/>
							) : null}
							<Route
								exact
								path={`/settings`}
								component={View.Settings}
							/>
							<Route
								exact
								path={`/settings/storage`}
								component={View.SettingsStorage}
							/>
							<Route
								exact
								path={`/settings/about`}
								component={View.SettingsAbout}
							/>
							<Route
								exact
								path={`/settings/subscription`}
								component={View.SettingsSubscription}
							/>
							<Route
								exact
								path={`/settings/language`}
								component={View.SettingsLanguage}
							/>

							<Route
								exact
								path={`authenticate`}
								component={View.Authenticate}
							/>

							<Route
								exact
								path={`/article/:id`}
								component={View.InternalArticle}
							/>

							{config?.rssFeed?.enabled ? (
								<Route
									exact
									path={`/feeds`}
									component={View.Feeds}
								/>
							) : null}
							{config?.rssFeed?.enabled ? (
								<Route
									exact
									path={`/feeds/:id`}
									component={View.Feed}
								/>
							) : null}
							<Route exact path={`/`} component={View.Home} />
						</Switch>
					</Router>
					<Installation />
				</div>
			</AppContext.Provider>
		);
	}
}

function mapLang(selectedLanguage, lang) {
	if (selectedLanguage === '') {
		switch (lang) {
			case 'en':
				return 'English';
			case 'de':
				return 'German';
			case 'fr':
				return 'French';
			case 'es':
				return 'Spanish';
			case 'it':
				return 'Italian';
			case 'ja':
				return 'Japanese';
			case 'nl':
				return 'Dutch';
			case 'pl':
				return 'Polish';
			case 'pt-pt':
				return 'Portuguese';
			case 'pt-br':
				return 'Portuguese (Brazilian)';
			case 'ru':
				return 'Russian';
			case 'zh':
				return 'Chinese';
		}
	}

	if (selectedLanguage === 'en') {
		switch (lang) {
			case 'en':
				return 'English';
			case 'de':
				return 'German';
			case 'fr':
				return 'French';
			case 'es':
				return 'Spanish';
			case 'it':
				return 'Italian';
			case 'ja':
				return 'Japanese';
			case 'nl':
				return 'Dutch';
			case 'pl':
				return 'Polish';
			case 'pt-pt':
				return 'Portuguese';
			case 'pt-br':
				return 'Portuguese (Brazilian)';
			case 'ru':
				return 'Russian';
			case 'zh':
				return 'Chinese';
		}
	}
	if (selectedLanguage === 'de') {
		switch (lang) {
			case 'en':
				return 'Englisch';
			case 'de':
				return 'Deutsch';
			case 'fr':
				return 'Französisch';
			case 'es':
				return 'Spanisch';
			case 'it':
				return 'Italienisch';
			case 'ja':
				return 'Japanisch';
			case 'nl':
				return 'Niederländisch';
			case 'pl':
				return 'Polnisch';
			case 'pt-pt':
				return 'Portugiesisch';
			case 'pt-br':
				return 'Portugiesisch (brasilianisch)';
			case 'ru':
				return 'Russisch';
			case 'zh':
				return 'Chinesisch';
		}
	}
	if (selectedLanguage === 'fr') {
		switch (lang) {
			case 'en':
				return 'Anglais';
			case 'de':
				return 'Allemand';
			case 'fr':
				return 'Français';
			case 'es':
				return 'Espagnol';
			case 'it':
				return 'Italien';
			case 'ja':
				return 'Japonais';
			case 'nl':
				return 'Néerlandais';
			case 'pl':
				return 'Polonais';
			case 'pt-pt':
				return 'Portugais';
			case 'pt-br':
				return 'Portugais (brésilien)';
			case 'ru':
				return 'Russe';
			case 'zh':
				return 'Chinois';
		}
	}
	if (selectedLanguage === 'es') {
		switch (lang) {
			case 'en':
				return 'Ingles';
			case 'de':
				return 'Alemán';
			case 'fr':
				return 'Francés';
			case 'es':
				return 'Español';
			case 'it':
				return 'Italiano';
			case 'ja':
				return 'Japonés';
			case 'nl':
				return 'Holandés';
			case 'pl':
				return 'Polaco';
			case 'pt-pt':
				return 'Portugués';
			case 'pt-br':
				return 'Portugués (brasileño)';
			case 'ru':
				return 'Ruso';
			case 'zh':
				return 'Chino';
		}
	}
	if (selectedLanguage === 'it') {
		switch (lang) {
			case 'en':
				return 'Inglese';
			case 'de':
				return 'Tedesco';
			case 'fr':
				return 'Francese';
			case 'es':
				return 'Spagnolo';
			case 'it':
				return 'Italiano';
			case 'ja':
				return 'Giapponese';
			case 'nl':
				return 'Olandese';
			case 'pl':
				return 'Polacco';
			case 'pt-pt':
				return 'Portoghese';
			case 'pt-br':
				return 'Portoghese (brasiliano)';
			case 'ru':
				return 'Russo';
			case 'zh':
				return 'Cinese';
		}
	}
	if (selectedLanguage === 'ja') {
		switch (lang) {
			case 'en':
				return '英語';
			case 'de':
				return 'ドイツ語';
			case 'fr':
				return 'フレンチ';
			case 'es':
				return 'スペイン語';
			case 'it':
				return 'イタリア語';
			case 'ja':
				return 'にほんご';
			case 'nl':
				return '蘭語';
			case 'pl':
				return 'ポーランド語';
			case 'pt-pt':
				return 'ポルトガル語';
			case 'pt-br':
				return 'ポルトガル語（ブラジル語';
			case 'ru':
				return 'ロシア語';
			case 'zh':
				return 'ちゅうごく';
		}
	}
	if (selectedLanguage === 'nl') {
		switch (lang) {
			case 'en':
				return 'Engels';
			case 'de':
				return 'Duits';
			case 'fr':
				return 'Frans';
			case 'es':
				return 'Spaans';
			case 'it':
				return 'Italiaans';
			case 'ja':
				return 'Japans';
			case 'nl':
				return 'Nederlands';
			case 'pl':
				return 'Pools';
			case 'pt-pt':
				return 'Portugees';
			case 'pt-br':
				return 'Portugees (Braziliaans)';
			case 'ru':
				return 'Russisch';
			case 'zh':
				return 'Chinees';
		}
	}
	if (selectedLanguage === 'pl') {
		switch (lang) {
			case 'en':
				return 'Angielski';
			case 'de':
				return 'Niemiecki';
			case 'fr':
				return 'Francuski';
			case 'es':
				return 'Hiszpański';
			case 'it':
				return 'Włoski';
			case 'ja':
				return 'Japończyk';
			case 'nl':
				return 'Holenderski';
			case 'pl':
				return 'Polski';
			case 'pt-pt':
				return 'Portugalski';
			case 'pt-br':
				return 'Portugalczyk (brazylijski)';
			case 'ru':
				return 'Rosyjski';
			case 'zh':
				return 'Chińczyk';
		}
	}
	if (selectedLanguage === 'pt-pt') {
		switch (lang) {
			case 'en':
				return 'Inglês';
			case 'de':
				return 'Alemão';
			case 'fr':
				return 'Francês';
			case 'es':
				return 'Espanhol';
			case 'it':
				return 'Italiano';
			case 'ja':
				return 'Japonês';
			case 'nl':
				return 'Holandês';
			case 'pl':
				return 'Polaco';
			case 'pt-pt':
				return 'Português';
			case 'pt-br':
				return 'Português (Brasileiro)';
			case 'ru':
				return 'Russo';
			case 'zh':
				return 'Chinês';
		}
	}
	if (selectedLanguage === 'pt-br') {
		switch (lang) {
			case 'en':
				return 'Inglês';
			case 'de':
				return 'Alemão';
			case 'fr':
				return 'Francês';
			case 'es':
				return 'Espanhol';
			case 'it':
				return 'Italiano';
			case 'ja':
				return 'Japonês';
			case 'nl':
				return 'Holandês';
			case 'pl':
				return 'Polonês';
			case 'pt-pt':
				return 'Português';
			case 'pt-br':
				return 'Português (brasileiro)';
			case 'ru':
				return 'Russo';
			case 'zh':
				return 'Chinês';
		}
	}
	if (selectedLanguage === 'ru') {
		switch (lang) {
			case 'en':
				return 'английский язык';
			case 'de':
				return 'немецкий язык';
			case 'fr':
				return 'французский';
			case 'es':
				return 'испанский';
			case 'it':
				return 'итальянский';
			case 'ja':
				return 'Японский';
			case 'nl':
				return 'голландский';
			case 'pl':
				return 'польский';
			case 'pt-pt':
				return 'Португальский';
			case 'pt-br':
				return 'португальский (бразильский)';
			case 'ru':
				return 'русский';
			case 'zh':
				return 'китайский язык';
		}
	}
	if (selectedLanguage === 'zh') {
		switch (lang) {
			case 'en':
				return '英文';
			case 'de':
				return '德国';
			case 'fr':
				return '法国人';
			case 'es':
				return '西班牙语';
			case 'it':
				return '意大利';
			case 'ja':
				return '日文';
			case 'nl':
				return '荷兰语';
			case 'pl':
				return '波兰语';
			case 'pt-pt':
				return '葡萄牙语';
			case 'pt-br':
				return '葡萄牙语（巴西)';
			case 'ru':
				return '俄文';
			case 'zh':
				return '中文';
		}
	}
	return lang;
}

export default App;
